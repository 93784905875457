import { User } from "@treefort/api-spec"

import { debug as appDebug } from "../../../lib/logging"
import { DisplayMode } from "../../tokens-provider"

const debug = appDebug.extend("web-embed")

const availableTokens = [
  "userId",
  "subscriptionStatus",
  "subscriptionId",
  "subscriptionPlanId",
  "displayMode",
  "contentId",
] as const
type Token = (typeof availableTokens)[number]

export type TokenReplacements = {
  user: User | null
  contentId: number
  displayMode: DisplayMode
}

const tokenRegex = new RegExp(`{{(${availableTokens.join("|")})}}`)

const tokenReplacers: Record<
  (typeof availableTokens)[number],
  (replacements: TokenReplacements) => string
> = {
  userId: ({ user }) => {
    return user?.id || ""
  },
  subscriptionId: ({ user }) => {
    return user?.subscription.id?.toString() || ""
  },
  subscriptionPlanId: ({ user }) => {
    return user?.subscription.subscriptionPlanId?.toString() || ""
  },
  contentId: ({ contentId }) => {
    return contentId.toString()
  },
  displayMode: ({ displayMode }) => {
    return displayMode
  },
  subscriptionStatus: ({ user }) => {
    return user?.subscription.subscriptionStatus || ""
  },
}

function isToken(value: string): value is Token {
  return availableTokens.includes(value as Token)
}

export function hasPlaceholders(template: string) {
  const result = tokenRegex.test(template)
  debug("Tested template for placeholders", { template, result })
  return result
}

export function replaceWebEmbedPlaceholders(
  template: string,
  tokenReplacements: TokenReplacements,
) {
  debug("Replacing web embed template placeholders", {
    tokenReplacements,
    template,
  })

  if (!template.length) {
    debug("Template length is 0; returning early")
    return template
  }

  const empty = () => ""

  const contentParts: string[] = []
  let lastMatchIndex = 0
  const matches = template.matchAll(new RegExp(tokenRegex, "g"))

  debug("Tokens detected in template:", { template, matches })

  for (const match of matches) {
    const matchIndex = match.index || 0
    contentParts.push(template.slice(lastMatchIndex, matchIndex))

    const matchValue = match[1]

    if (!isToken(matchValue)) {
      lastMatchIndex = matchIndex
      continue
    }

    lastMatchIndex = matchIndex + match[0].length

    contentParts.push(
      encodeURIComponent(
        (tokenReplacers[matchValue] || empty)(tokenReplacements),
      ),
    )
  }

  contentParts.push(template.slice(lastMatchIndex))

  const processedContent = contentParts.join("")
  debug("Returning content with replacements:", {
    processedContent,
  })

  return processedContent
}
