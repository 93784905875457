import React from "react"
import { useTranslation } from "react-i18next"

import useAppManifest from "../../hooks/use-app-manifest"
import { useSetting } from "../../hooks/use-setting"
import { consentSettingsEnabled } from "../../lib/consent"
import settings from "../../lib/settings"
import { AppLink } from "../app-link"
import { Button } from "../button"
import Column from "../column"
import Modal from "../modal"
import Row from "../row"
import Text from "../text"

const CONSENT_POPUP_MAX_WIDTH_PX = 400

export function ConsentPopup() {
  const { t } = useTranslation()
  const manifest = useAppManifest()
  const enabled = consentSettingsEnabled(manifest)
  const [shown, setShown] = useSetting<boolean>({
    key: "consent.popupShown",
    strategy: "localOrRemote",
    settings,
    profileId: null,
  })

  // Hide the popup if any of the following are true:
  // - Consent settings are disabled
  // - We've already shown the popup
  // - We're loading the "shown" setting (we're not sure if we've shown the
  //   popup or not)
  if (!enabled || shown || shown === undefined) {
    return null
  }

  return (
    <Modal
      open
      type="sheet"
      showScrim={false}
      floatingAlignmentVertical="bottom"
      floatingAlignmentHorizontal="left"
      maxWidth={CONSENT_POPUP_MAX_WIDTH_PX}
    >
      <Column gap="small" alignItems="stretch" padding="small">
        <Text textStyle="body">
          {t(
            "We use cookies to make this app better. If you continue to use this app we will assume that you are fine with that.",
          )}
        </Text>
        <Row gap="medium" flexWrap="wrap">
          <Button
            type="primary"
            onPress={() => setShown(true)}
            id="consent-button"
          >
            {t("Accept cookies")}
          </Button>
          <AppLink
            to={{ type: "url", url: "/menu/settings#consent" }}
            aria-label={t("Cookie settings")}
            onPress={() => setShown(true)}
          >
            <Text textStyle="body" color="accent">
              {t("Cookie settings")}
            </Text>
          </AppLink>
        </Row>
      </Column>
    </Modal>
  )
}
