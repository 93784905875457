import React from "react"

import ButtonView, { ButtonType, ButtonViewProps } from "./button-view"
import Touchable, { TouchableProps } from "./touchable"

export type { ButtonType }

export type ButtonProps = Omit<
  TouchableProps & ButtonViewProps,
  "children" | "aria-label"
> &
  (
    | {
        children: string
      }
    | { children: React.ReactNode; "aria-label": string }
  )

export function Button({
  children,
  type,
  size,
  icon,
  onPress,
  loading,
  feedback = "ripple-or-opacity",
  borderRadius,
  ...touchableProps
}: ButtonProps): JSX.Element {
  return (
    <Touchable
      {...touchableProps}
      onPress={onPress}
      role="button"
      feedback={feedback}
      aria-label={
        "aria-label" in touchableProps
          ? touchableProps["aria-label"]
          : typeof children === "string"
            ? children
            : ""
      }
    >
      <ButtonView
        disabled={touchableProps.disabled}
        type={type}
        size={size}
        icon={icon}
        loading={loading}
        borderRadius={borderRadius}
      >
        {children}
      </ButtonView>
    </Touchable>
  )
}
