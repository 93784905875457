import { useCallback } from "react"

import queryString from "query-string"

import { simpleHash } from "@treefort/lib/simple-hash"

import { history } from "../../lib/history.web"
import { getPageFromPath, Params } from "../../navigation/routes"
import useAppManifest from "../use-app-manifest"

export function useNavigate(): (
  path: string,
  params?: Params,
  // This is only applicable on native. It will have no effect here.
  mode?: string,
) => void {
  const manifest = useAppManifest()
  return useCallback(
    (path, params) => {
      const nextPath =
        path + (params ? "?" + queryString.stringify(params) : "")
      const nextPage = getPageFromPath(nextPath, manifest)
      const currentPath = window.location.pathname + window.location.search
      const currentPage = getPageFromPath(currentPath, manifest)

      // Navigate unless we're already on the requested page. This protects
      // against quirky behavior if a page ends up linking to itself.
      if (
        !nextPage ||
        nextPage.id !== currentPage?.id ||
        simpleHash(params || {}) !==
          simpleHash(queryString.parse(window.location.search))
      ) {
        history.push(nextPath)
      }
    },
    [manifest],
  )
}
