import React, {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react"
import { Animated, StyleProp, ViewStyle, Easing } from "react-native"

import styled from "styled-components/native"

import filter from "@treefort/lib/filter"

import { useNativeDriver } from "../lib/animation-use-native-driver"
import { startAnimation } from "../lib/start-animation"
import ActivityIndicator from "./activity-indicator"
import { AsyncButton } from "./async-button"
import Column from "./column"
import { Heading } from "./heading"
import Spacer from "./spacer"
import Text, { TextColor } from "./text"

const DEFAULT_STATE = "loading"

const MESSAGE_MAX_WIDTH_PX = 280

const ANIMATION_DURATION_MS = 1800

// The positioning strategy used for the activity indicator shown in the view
// while loading
type IndicatorPosition = "absolute" | "fixed"

export type AsyncViewState = "loading" | "success" | "error" | "offline"

export type AsyncViewProps = {
  hideLoadingIndicator?: boolean
  state?: AsyncViewState
  title?: string
  message?: string
  primaryAction?: {
    label: string
    onPress: () => void | Promise<void>
  }
  secondaryAction?: {
    label: string
    onPress: () => void | Promise<void>
  }
}

export type AsyncViewContext = {
  props: AsyncViewProps
  setProps: (props: AsyncViewProps) => void
}

const Container = styled.View`
  position: relative;
  flex: auto;
  width: 100%;
`

const Overlay = styled(Animated.View)<{
  indicatorPosition: IndicatorPosition
  paddingBottom: number
  paddingTop: number
  backgroundColor: string
}>`
  position: ${(props) => props.indicatorPosition};
  top: ${(props) => props.paddingTop}px;
  bottom: ${(props) => props.paddingBottom}px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor in theme.colors.background
      ? theme.colors.background[
          backgroundColor as keyof typeof theme.colors.background
        ]
      : backgroundColor};
  left: 0;
  right: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled(Animated.View)`
  width: 100%;
  flex: auto;
`

export const AsyncViewContext = React.createContext<AsyncViewContext>({
  props: {},
  setProps: () => {},
})

/**
 * This updates the AsyncViewContext with new props. This is particularly
 * helpful when a child component needs to control the state of a parent
 * AsyncView.
 */
export function useAsyncViewProps(nextProps: AsyncViewProps): void {
  const { props, setProps } = useContext(AsyncViewContext)
  useEffect(() => {
    if (props.state !== nextProps.state) {
      setProps(nextProps)
    }
  }, [props.state, setProps, nextProps])
}

function OverlayContainer({
  state = DEFAULT_STATE,
  title,
  message,
  primaryAction,
  secondaryAction,
  paddingBottom,
  paddingTop,
  backgroundColor,
  indicatorPosition,
  textColor,
  hideLoadingIndicator,
}: AsyncViewProps & {
  paddingTop: number
  paddingBottom: number
  backgroundColor: string
  indicatorPosition: IndicatorPosition
  textColor: string
}): JSX.Element | null {
  const [showOverlay, setShowOverlay] = useState(state !== "success")
  const overlayOpacity = useRef(new Animated.Value(state === "success" ? 0 : 1))

  // Trigger the overlay fade-out animation when the state changes to "success"
  // or a fade-in animation when the state changes to "offline".
  useLayoutEffect(() => {
    switch (state) {
      // Fade the overlay out when moving to a success state
      case "success": {
        const animation = Animated.timing(overlayOpacity.current, {
          useNativeDriver,
          toValue: 0,
          duration: ANIMATION_DURATION_MS,
          easing: Easing.out(Easing.exp),
        })
        return startAnimation(animation, () => setShowOverlay(false))
      }
      // Fade the overlay in when moving to an offline state
      case "offline": {
        setShowOverlay(true)
        const animation = Animated.timing(overlayOpacity.current, {
          useNativeDriver,
          toValue: 1,
          duration: ANIMATION_DURATION_MS,
          easing: Easing.out(Easing.exp),
        })
        return startAnimation(animation)
      }
      // Snap the overlay in when moving to a loading or an error state
      case "loading":
      case "error": {
        overlayOpacity.current.setValue(1)
        setShowOverlay(true)
        break
      }
    }
  }, [state])

  return showOverlay ? (
    <Overlay
      indicatorPosition={indicatorPosition}
      paddingBottom={paddingBottom}
      paddingTop={paddingTop}
      style={{ opacity: overlayOpacity.current }}
      pointerEvents={state === "success" ? "none" : undefined}
      backgroundColor={backgroundColor}
    >
      {state === "loading" ? (
        hideLoadingIndicator ? null : (
          <ActivityIndicator size="xlarge" color={textColor} />
        )
      ) : state === "error" || state === "offline" ? (
        <Column paddingBottom="small">
          {title ? (
            <>
              <Spacer size="small" />
              <Heading
                level={2}
                textStyle="headingMedium"
                alignment="center"
                maxWidth={MESSAGE_MAX_WIDTH_PX}
                color={textColor}
              >
                {title}
              </Heading>
            </>
          ) : null}
          {message ? (
            <>
              <Spacer size="small" />
              <Text
                textStyle="body"
                alignment="center"
                maxWidth={MESSAGE_MAX_WIDTH_PX}
                color={textColor}
              >
                {message}
              </Text>
            </>
          ) : null}
          {primaryAction ? (
            <>
              <Spacer size="small" />
              <AsyncButton type="primary" onPress={primaryAction.onPress}>
                {primaryAction.label}
              </AsyncButton>
            </>
          ) : null}
          {secondaryAction ? (
            <>
              <Spacer size="small" />
              <AsyncButton onPress={secondaryAction.onPress}>
                {secondaryAction.label}
              </AsyncButton>
            </>
          ) : null}
        </Column>
      ) : null}
    </Overlay>
  ) : null
}

export function AsyncViewProvider({
  children,
  ...overrideProps
}: AsyncViewProps & {
  children: ReactNode
}): JSX.Element {
  const [props, setProps] = useState<AsyncViewProps>({
    state: DEFAULT_STATE,
  })
  const context = useMemo(() => {
    return {
      props: {
        ...props,
        ...filter(overrideProps, (prop) => prop !== undefined),
      },
      setProps,
    }
  }, [props, overrideProps])
  return (
    <AsyncViewContext.Provider value={context}>
      {children}
    </AsyncViewContext.Provider>
  )
}

/**
 * Render a view that can an activity indicator while data loads asyncronously
 * or a message and action buttons if the data fails to load. This is a fairly
 * generic component that can be used to show a loading state at any level - for
 * an entire page or for a small subview within a page.
 */
export default function AsyncView({
  children,
  style,
  contentContainerStyle,
  backgroundColor = "primary",
  indicatorPosition = "absolute",
  textColor = "primary",
  paddingBottom = 0,
  paddingTop = 0,
  hideLoadingIndicator,
  ...props
}: AsyncViewProps & {
  children?: ReactNode
  style?: StyleProp<ViewStyle>
  contentContainerStyle?: StyleProp<ViewStyle>
  backgroundColor?: string
  indicatorPosition?: IndicatorPosition
  textColor?: TextColor
  paddingBottom?: number
  paddingTop?: number
}): JSX.Element {
  const context = useContext(AsyncViewContext)
  const {
    state = context.props.state || DEFAULT_STATE,
    title = context.props.title,
    message = context.props.message,
    primaryAction = context.props.primaryAction,
    secondaryAction = context.props.secondaryAction,
  } = props
  return (
    <Container style={style}>
      {
        // Don't render content when offline. This avoids unnecessary rendering
        // work and network requests. This also ensures that when we come back
        // online the content is mounted fresh and images, queries, etc. are
        // re-fetched.
        state !== "offline" ? (
          <ContentContainer
            style={[contentContainerStyle, { paddingBottom, paddingTop }]}
          >
            {children}
          </ContentContainer>
        ) : null
      }
      <OverlayContainer
        hideLoadingIndicator={hideLoadingIndicator}
        state={state}
        indicatorPosition={indicatorPosition}
        textColor={textColor}
        title={title}
        message={message}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        backgroundColor={backgroundColor}
      />
    </Container>
  )
}
