import throttle from "lodash/throttle"
import queryString from "query-string"

import { addEventListeners, triggerAndWaitForEvent } from "@treefort/lib/dom"
import { AudioError } from "@treefort/lib/errors"

import { attachHlsJs, isHlsUrl, isHlsSupportedNatively } from "../../av/hls.web"
import MediaSession from "../../av/media-session"
import { SkipMode } from "../../av/types"
import { addPlaybackStateListener, addSeekableListener } from "../../av/web"
import {
  PlaybackState,
  Track,
  AudioPlayer,
  Event,
  PROGRESS_UPDATE_INTERVAL,
} from "../base"

/**
 * An audio player based on the web's Audio API.
 */
class WebAudioPlayer extends AudioPlayer {
  private audio: HTMLAudioElement
  private seeking = false
  private cleanupHls: () => void = () => {}
  private mediaSession: MediaSession

  constructor() {
    super()

    this.audio = new window.Audio()

    this.mediaSession = new MediaSession("audioPlayer", {
      onSkipTrack: (interval: number) =>
        interval > 0 ? this.skipForward() : this.skipBackward(),
      onPlay: this.triggerPlay,
      onPause: this.triggerPause,
    })

    addPlaybackStateListener(this.audio, this.setPlaybackState)

    addSeekableListener(this.audio, this.setSeekable)

    addEventListeners(this.audio, {
      durationchange: () => this.publishProgress(),
      timeupdate: throttle(() => {
        // Don't send out progress updates while we're seeking or the audio's
        // idea of the current progress tends to run over the value the user
        // has tentatively selected
        if (!this.seeking) {
          this.publishProgress()
        }
      }, PROGRESS_UPDATE_INTERVAL),
      emptied: () =>
        this.publishProgress({
          position: 0,
          duration: 0,
          index: this.getIndex(),
        }),
      // Automatically progress to the next track
      ended: async () => {
        const tracks = this.getTracks()
        const loopMode = this.getLoopMode()
        const nextIndex = (this.getIndex() ?? -1) + 1
        if (loopMode === "one") {
          await this.skipToPosition(0, { autoPlay: true })
        } else if (loopMode === "all" && nextIndex >= tracks.length) {
          await this.skipToTrack(0, { autoPlay: true })
        } else if (nextIndex > 0 && nextIndex < tracks.length) {
          await this.skipToTrack(nextIndex, { autoPlay: true })
        } else if (nextIndex === tracks.length) {
          this.publishFinished()
        }
      },
      error: (error: unknown) => {
        const cause = this.audio.error || error
        this.handleError(new AudioError("Playback failed", { cause }))
      },
    })

    // Trigger a play action on the html element the first the user signals an
    // intent to play. On iOS safari this unlocks the ability for us to
    // programatically start playback when we're actually ready (after loading
    // progress, etc.). Without this we would get a "permission denied" error the
    // first time we attempt to play.
    this.on(Event.PlayIntent, () => {
      if (!this.audio.dataset.ready) {
        this.audio
          ?.play()
          .catch(() => {})
          .finally(() => {
            this.audio.dataset.ready = "true"
          })
      }
    })

    // Activate the media session
    this.emitter.on(Event.PlaybackState, (playbackState) => {
      const track = this.getTrack()
      if (
        track &&
        playbackState !== PlaybackState.Idle &&
        playbackState !== PlaybackState.Error
      ) {
        this.mediaSession.activate({ track })
      }
    })

    // Deactivate the media session
    this.emitter.on(Event.Tracks, this.mediaSession.deactivate)
  }

  protected *loadPositionGenerator(
    position: number,
  ): Generator<unknown, boolean> {
    // We can't seek if no track is loaded
    if (!this.getTrack()) {
      return false
    }

    // Indicate that we're seeking through the audio. This will disable any
    // future playback state and progress events until we're done seeking (so
    // this must be turned on *after* updating the plaback state above).
    this.seeking = true

    // Mute the player if it's not paused because otherwise the browser will
    // keep playing at at the old position during the process of loading the new
    // which can make the seek action feel broken (only applicable on pretty
    // slow networks)..
    if (!this.audio.paused) {
      this.audio.muted = true
    }

    // If the audio is not seekable yet, wait until it is
    if (!this.getSeekable()) {
      yield new Promise<void>((resolve) => {
        const removeListener = this.on(Event.Seekable, (seekable) => {
          if (seekable) {
            removeListener()
            resolve()
          }
        })
      })
    }

    // And we're finally ready to seek! Round the position to the nearest
    // millisecond in case someone tried to get overly specific and then wait
    // until the seek has registered to continue. This is helpful for situations
    // where we want to seek first and then start playing.
    yield triggerAndWaitForEvent(
      () => (this.audio.currentTime = Math.round(position) / 1000),
      this.audio,
      "seeked",
    )

    // Back to our normal programming
    if (this.audio.muted) {
      this.audio.muted = false
    }
    this.seeking = false

    return true
  }

  protected loadPositionCleanup = () => {
    this.seeking = false
    if (this.audio.muted) {
      this.audio.muted = false
    }
  }

  protected async *loadTrackGenerator({
    track,
  }: {
    track: Track
  }): AsyncGenerator<unknown, boolean> {
    // Cleanup any existing hls.js instance
    this.cleanupHls()

    // Our media CDN requires the Origin header which is only set if CORS is
    // enabled.
    this.audio.crossOrigin = track.cors || null

    if (isHlsUrl(track.url) && !isHlsSupportedNatively) {
      // Load the audio
      this.audio.removeAttribute("src")
      const hls = await attachHlsJs(this.audio, (cause) =>
        this.handleError(new AudioError("Failed to attach hls.js", { cause })),
      )
      this.cleanupHls = () => hls.destroy()
      yield // Allow cancellation _after_ initializing a cleanup function
      hls.loadSource(queryString.stringifyUrl(track))
    } else {
      this.audio.src = queryString.stringifyUrl(track)
      this.audio.load()
    }

    // Load the playback rate again (it gets reset when you change the audio's
    // source)
    this.loadPlaybackRate(this.getPlaybackRate())

    return true
  }

  protected loadTrackCleanup = (): void => {
    this.cleanupHls()
  }

  protected getTrackPosition = (): number =>
    this.audio.currentTime === undefined ? 0 : this.audio.currentTime * 1000

  protected getTrackDuration = (): number => {
    const index = this.getIndex()
    return this.audio.duration === undefined ||
      isNaN(this.audio.duration) ||
      index === undefined
      ? 0
      : this.audio.duration * 1000
  }

  protected loadPlaybackRate = async (playbackRate: number) => {
    this.audio.playbackRate = playbackRate
  }

  protected loadSkipMode = async (_skipMode: SkipMode) => {
    // Not supported on the web
  }

  protected loadTracks = async (): Promise<void> => {
    this.audio.removeAttribute("src")
    this.setPlaybackState(PlaybackState.Idle)
  }

  protected triggerPlay = async (): Promise<void> => {
    await this.audio.play()
  }

  protected triggerPause = async (): Promise<void> => {
    this.audio.pause()
  }
}

const player = new WebAudioPlayer()

export default player

export * from "../base"
