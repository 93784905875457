import React, { ReactNode } from "react"

import Text, { TextProps } from "./text"
import TextPlaceholder from "./text-placeholder"

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

export type HeadingProps = Omit<
  TextProps & {
    level: HeadingLevel
    loading?: boolean
  },
  "children"
> &
  ({ children: ReactNode; "aria-label": string } | { children: string })

export function Heading({
  color,
  children,
  loading,
  maxWidth,
  ...extraProps
}: HeadingProps): JSX.Element {
  return loading ? (
    <TextPlaceholder {...extraProps} />
  ) : (
    <Text
      {...extraProps}
      role="heading"
      aria-level={extraProps.level}
      color={color}
      maxWidth={maxWidth}
      aria-label={
        "aria-label" in extraProps
          ? extraProps["aria-label"]
          : typeof children === "string"
            ? children
            : ""
      }
    >
      {children}
    </Text>
  )
}
