import { t } from "i18next"

import { GroupMembershipCodeValidationResponse } from "@treefort/api-spec"
import strictEncodeUriComponent from "@treefort/lib/strict-encode-uri-component"

import api from "./api"
import authenticator from "./authenticator"

export const getInvalidGroupMembershipCodeMessage = (res: {
  reason: string
}) => {
  switch (res.reason) {
    case "expiredGroupMembership":
      return t(
        "This subscription code has expired. Please contact the person who gave you the code.",
      )
    case "emailNotAllowed":
      return t(
        "You don't have permission to use this subscription code. Please contact the person who gave you the code.",
      )
    case "fullGroupMembership":
      return t(
        "This subscription code has reached its usage limit. Please contact the person who gave you the code.",
      )
    default:
      return t("Invalid promo code.")
  }
}

export const checkGroupMembershipCode = async (
  membershipCode: string,
): Promise<GroupMembershipCodeValidationResponse> => {
  const res = await api.get<GroupMembershipCodeValidationResponse>(
    `/group-membership-codes/validation/${strictEncodeUriComponent(
      membershipCode,
    )}`,
  )
  return res.data
}

export async function signUpForGroupMembership({
  subscriptionPlanId,
  membershipCode,
}: {
  subscriptionPlanId: number
  membershipCode: string
}) {
  const user = await authenticator.getUser()
  return api.post(`/users/${user?.id}/subscriptions`, {
    provider: "groupMembership",
    subscriptionPlanId,
    membershipCode,
  })
}
