"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCancellable = exports.runCancellable = void 0;
const react_1 = require("react");
/**
 * This takes in a generator and starts executing it. It returns a function that
 * can be used to cancel the execution and a promise that will resolve to the
 * final result of the generator (or `undefined` if the execution is cancelled).
 */
function runCancellable(generator, cleanup) {
    let cancelled = false;
    const promise = (async () => {
        let next;
        do {
            if (cancelled)
                generator.return(null);
            next = await generator.next();
            await next.value;
        } while (!next.done);
        return next.value;
    })();
    return {
        cancel: () => {
            cancelled = true;
            if (cleanup) {
                cleanup();
            }
        },
        promise,
    };
}
exports.runCancellable = runCancellable;
/**
 * This React hook makes `runCancellable` a bit easier to use from a component.
 * It takes in a generator function and a dependency list (usage looks very
 * similar to useCallback). It returns a tuple where the first element is a
 * callback that will trigger execution of the generator and the second argument
 * is a ref to the resulting Cancellable (an object that can be used to cancel
 * execution or access a promise that will resolve to the the final result of
 * the execution).
 */
function useCancellable(generatorFunc, deps) {
    const running = (0, react_1.useRef)();
    const runner = (0, react_1.useCallback)((...args) => {
        var _a;
        (_a = running.current) === null || _a === void 0 ? void 0 : _a.cancel();
        running.current = runCancellable(generatorFunc(...args));
        // Exhaustive deps should be enforced for useCancellable. This can be done
        // with the "additionalHooks" option of the react-hooks/exhaustive-deps rule
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    return [runner, running];
}
exports.useCancellable = useCancellable;
