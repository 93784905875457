import { getClient } from "../../db"
import { SourceAwareModel } from "../../models/source-aware-model"
import { syncManager } from "../../sync"
import { DatabaseFieldsOf } from "../types"
import { Finder } from "./finder"

export class Updater<TModel extends SourceAwareModel> {
  private table: string
  private finder: Finder<TModel>

  constructor(table: string, finder: Finder<TModel>) {
    this.table = table
    this.finder = finder
  }

  async update(id: string, fields: Partial<DatabaseFieldsOf<TModel>>) {
    const result = await getClient().write(async () => {
      const model = await this.finder.find(id)

      if (!model) {
        throw new Error(`${this.table} not found for id '${id}'`)
      }

      await model.update((m) => {
        Object.assign(m, fields)
        m.updatedAtDate = new Date().getTime()
      })
    })

    await syncManager.requestSync({ syncType: "periodic" })

    return result
  }
}
