import { useCallback, useMemo, useState } from "react"
interface HoverProps {
  onMouseOver: () => void
  onMouseLeave: () => void
}

export default function useHover(): [
  isHovering: boolean,
  hoverProps: HoverProps,
] {
  const [isHovering, setIsHovering] = useState(false)

  const onMouseOver = useCallback(() => {
    if (!isHovering) {
      setIsHovering(true)
    }
  }, [isHovering])

  const onMouseLeave = useCallback(() => {
    setIsHovering(false)
  }, [])

  const hoverProps = useMemo(
    () => ({
      onMouseOver,
      onMouseLeave,
    }),
    [onMouseOver, onMouseLeave],
  )

  return [isHovering, hoverProps]
}
