import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import styled from "styled-components/native"

import { PodcastResponse, PodcastEpisodeResponse } from "@treefort/api-spec"
import { joinContributorNames } from "@treefort/lib/contributor"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"
import rawTokens from "@treefort/tokens/app"
import icons from "@treefort/tokens/app/icons"

import config from "../../config"
import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAppTab } from "../../hooks/use-app-tab"
import useHover from "../../hooks/use-hover"
import { useNavigate } from "../../hooks/use-navigate"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import {
  canDownloadConsumableContent,
  getKeyFromConsumableContent,
  getConsumableContentFromPodcastResponse,
  PodcastEpisodeConsumableContent,
} from "../../lib/consumable-content"
import { playContentAudio } from "../../lib/content-audio"
import { formatDate } from "../../lib/date"
import { getPathFromPodcastEpisode, NO_TAB } from "../../navigation/routes"
import AddToLibraryButton, { canAddToLibrary } from "../add-to-library-button"
import AsyncView from "../async-view"
import Box from "../box"
import Column from "../column"
import DownloadButton from "../download-button"
import { Heading } from "../heading"
import IconButton from "../icon-button"
import { ImageWithFade } from "../image-with-fade"
import LockedContentIndicator from "../locked-content-indicator"
import { MetadataText } from "../metadata"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import Spacer from "../spacer"
import Text from "../text"
import TextToggle from "../text-toggle"
import { useTokens } from "../tokens-provider"
import Touchable from "../touchable"

const playIcon = config.DOWNLOADS_SUPPORTED
  ? icons.playCircle.fill
  : icons.playCircle.outline

const playIconSize: keyof typeof rawTokens.icon.size = "xlarge"

export type PodcastModulePresentation = "wide" | "narrow"

const Artwork = styled(ImageWithFade)`
  border-radius: ${(props) => props.theme.borderRadius.roundedLarge}px;
  background-color: ${(props) => props.theme.colors.loading.image};
  width: ${(props) => props.theme.podcastModule.artwork.size}px;
  height: ${(props) => props.theme.podcastModule.artwork.size}px;
`

const ArtworkTitleLockup = styled(Row)<{ fullWidth: boolean }>`
  flex-shrink: 1;
  ${(props) => (props.fullWidth ? "" : "max-width: 40%;")}
`

const TitleContainer = styled(View)`
  flex: 1;
`

const ArtworkContainer = styled.View`
  position: relative;
`

const TouchableContainer = styled(Touchable)<{ hovering?: boolean }>`
  background-color: ${({ hovering, theme }) =>
    hovering ? theme.colors.background.primary : "transparent"};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.background.primary};
`

const PodcastDescriptionWide = styled(View)`
  justify-content: center;
  flex: 1;
  align-self: stretch;
`

const DescriptionTextToggle = styled(TextToggle)`
  margin-top: auto;
  margin-bottom: auto;
`

export const PodcastModuleEpisodeSeparator = styled.View`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary};
`

const NarrowEpisodeButtons = styled(Row)`
  /* Scoot the buttons left until the icon in the first button is left-aligned
     with the episode text. */
  margin-left: -${(props) => (props.theme.minTapTarget - props.theme.icon.size.large) / 2}px;
`

function PodcastEpisodeTitle({
  consumableContent,
  children,
}: {
  consumableContent: PodcastEpisodeConsumableContent
  children: string
}) {
  return (
    <Row>
      <ProgressForConsumableContent
        consumableContent={consumableContent}
        childrenAfter={<Spacer size="tiny" horizontal />}
        includeFinishedBadge
        key={`finished-badge-${getKeyFromConsumableContent(consumableContent)}`}
      />
      <Text textStyle="headingSmall" numberOfLines={1}>
        {children}
      </Text>
    </Row>
  )
}

function PodcastEpisodeNarrow({
  consumableContent,
  height,
  isLocked,
  isLoading,
  onPressPlay,
  onPress,
}: {
  consumableContent: PodcastEpisodeConsumableContent
  height?: number
  isLocked?: boolean
  isLoading?: boolean
  onPressPlay: () => void
  onPress: () => void
}): JSX.Element {
  const { t, i18n } = useTranslation()

  const showDownloadButton = canDownloadConsumableContent(consumableContent)
  const showAddToLibraryButton = canAddToLibrary({
    manifest: useAppManifest(),
    consumableContent,
  })
  const showPlayButton = !isLocked
  const key = getKeyFromConsumableContent(consumableContent)
  const episode = consumableContent.podcastEpisode

  return (
    <TouchableContainer
      onPress={onPress}
      feedback="ripple-or-highlight"
      disabled={isLoading}
    >
      <Column
        paddingHorizontal="large"
        paddingTop="medium"
        paddingBottom="small"
        justifyContent="center"
        alignItems="stretch"
        height={height}
      >
        {episode.date ? (
          <>
            <Text textStyle="captionStrong" color="secondary">
              {formatDate(new Date(episode.date), {
                strategy: "naturalDate",
                i18n,
              })}
            </Text>
            <Spacer size="tiny" />
          </>
        ) : null}
        <PodcastEpisodeTitle consumableContent={consumableContent}>
          {episode.title}
        </PodcastEpisodeTitle>
        {episode.description ? (
          <>
            <Spacer size="tiny" />
            <Text textStyle="caption" color="secondary" numberOfLines={2}>
              {episode.description}
            </Text>
          </>
        ) : null}
        <Spacer size="xsmall" />
        <NarrowEpisodeButtons>
          {showPlayButton ? (
            <IconButton
              label={t("Play episode")}
              onPress={onPressPlay}
              source={playIcon}
              color="secondary"
              iconSize={playIconSize}
              state={isLoading ? "loading" : "default"}
              key={`play-${episode.episode}`}
            />
          ) : undefined}
          {showDownloadButton ? (
            <DownloadButton
              consumableContent={consumableContent}
              key={`download-${key}`}
              height={rawTokens.icon.size[playIconSize]}
            />
          ) : null}
          {showAddToLibraryButton ? (
            <AddToLibraryButton
              consumableContent={consumableContent}
              key={`add-to-library-${key}`}
              height={rawTokens.icon.size[playIconSize]}
            />
          ) : null}
          <ProgressForConsumableContent
            consumableContent={consumableContent}
            flex={1}
            includeProgressLabel
            includeProgressBar
            childrenBefore={
              <Spacer
                size={
                  showDownloadButton || showAddToLibraryButton
                    ? "tiny"
                    : "small"
                }
                horizontal
              />
            }
            key={`progress-${key}`}
          />
        </NarrowEpisodeButtons>
      </Column>
    </TouchableContainer>
  )
}

function PodcastEpisodeWide({
  consumableContent,
  height,
  isLocked,
  isLoading,
  onPressPlay,
  onPress,
}: {
  consumableContent: PodcastEpisodeConsumableContent
  height?: number
  isLocked?: boolean
  isLoading?: boolean
  onPressPlay: () => void
  onPress: () => void
}): JSX.Element {
  const { t, i18n } = useTranslation()

  const [hovering, hoverProps] = useHover()
  const { tokens } = useTokens()
  const showDownloadButton = canDownloadConsumableContent(consumableContent)
  const showAddToLibraryButton = canAddToLibrary({
    manifest: useAppManifest(),
    consumableContent,
  })
  const showPlayButton = !isLocked
  const key = getKeyFromConsumableContent(consumableContent)
  const episode = consumableContent.podcastEpisode

  return (
    <TouchableContainer
      hovering={hovering}
      onPress={onPress}
      feedback="ripple-or-highlight"
      disabled={isLoading}
    >
      <Row
        backgroundColor={
          hovering ? tokens.colors.background.tertiary : "transparent"
        }
        paddingHorizontal="large"
        paddingTop="large"
        paddingBottom="large"
        justifyContent="space-between"
        alignItems="center"
        height={height}
        style={{ position: "relative" }}
        {...hoverProps}
      >
        <Box flex={4}>
          {episode.date ? (
            <>
              <Text textStyle="captionStrong" color="secondary">
                {formatDate(new Date(episode.date), {
                  strategy: "naturalDate",
                  i18n,
                })}
              </Text>
              <Spacer size="tiny" />
            </>
          ) : null}
          <PodcastEpisodeTitle consumableContent={consumableContent}>
            {episode.title}
          </PodcastEpisodeTitle>
          {episode.description ? (
            <>
              <Spacer size="tiny" />
              <Text
                textStyle="caption"
                color="secondary"
                numberOfLines={2}
                maxWidth="description"
              >
                {episode.description}
              </Text>
            </>
          ) : null}
        </Box>
        <Row
          justifyContent="flex-end"
          paddingLeft="medium"
          flex={
            // Give the progress bar a little more space if we're cramming both
            // the download button and add to library buttons into the layout.
            showDownloadButton && showAddToLibraryButton ? 1.75 : 1
          }
        >
          <ProgressForConsumableContent
            consumableContent={consumableContent}
            includeProgressLabel
            includeProgressBar
            alignment="right"
            childrenAfter={
              <Spacer size={showDownloadButton ? "tiny" : "small"} horizontal />
            }
            flex={1}
            key={`progress-${key}`}
          />
          {showAddToLibraryButton ? (
            <AddToLibraryButton
              consumableContent={consumableContent}
              key={`add-to-library-${key}`}
            />
          ) : null}
          {showDownloadButton ? (
            <DownloadButton
              consumableContent={consumableContent}
              key={`download-${key}`}
            />
          ) : null}
          {showPlayButton ? (
            <IconButton
              label={t("Play episode")}
              onPress={onPressPlay}
              source={playIcon}
              color="secondary"
              iconSize="xlarge"
              state={isLoading ? "loading" : "default"}
              key={`play-${key}`}
            />
          ) : null}
        </Row>
      </Row>
    </TouchableContainer>
  )
}

export function PodcastModuleEpisode({
  podcast,
  episode,
  height,
  presentation,
}: {
  podcast: PodcastResponse
  episode: PodcastEpisodeResponse
  height?: number
  presentation: PodcastModulePresentation
}): JSX.Element {
  const profileId = useActiveProfileId()
  const [isLoading, setIsLoading] = useState(false)
  const willUnmount = useWillUnmount()
  const navigate = useNavigate()
  const openCheckoutPage = useOpenCheckoutPage({
    availability: podcast.details.rssFeedMedia,
    contentId: podcast.id,
  })
  const tab = useAppTab()
  const consumableContent = useMemo(
    () => getConsumableContentFromPodcastResponse(podcast, episode.episode),
    [podcast, episode.episode],
  )
  const isLocked = podcast.details.rssFeedMedia?.status === "notAvailable"

  const onPress = useCallback(() => {
    navigate(
      getPathFromPodcastEpisode(
        podcast.id,
        episode.episode,
        tab.id === "menu" ? NO_TAB : tab,
      ),
    )
  }, [tab, navigate, podcast, episode])

  const onPressPlay = useCallback(() => {
    if (isLocked) {
      openCheckoutPage()
    } else if (!isLocked) {
      setIsLoading(true)
      playContentAudio({ consumableContent, profileId }).finally(() => {
        if (!willUnmount.current) {
          setIsLoading(false)
        }
      })
    }
  }, [consumableContent, isLocked, openCheckoutPage, willUnmount, profileId])

  const EpisodeComponent =
    presentation === "wide" ? PodcastEpisodeWide : PodcastEpisodeNarrow

  return (
    <EpisodeComponent
      consumableContent={consumableContent}
      height={height}
      isLoading={isLoading}
      isLocked={isLocked}
      onPressPlay={onPressPlay}
      onPress={onPress}
    />
  )
}

export function PodcastModuleSummary({
  presentation,
  podcast,
  showLockIcon,
}: {
  presentation: PodcastModulePresentation
  podcast: PodcastResponse
  showLockIcon: boolean
}): JSX.Element {
  const { tokens } = useTokens()
  const [ready, setReady] = useState(!podcast.description)
  const descriptionText = podcast.description ? (
    <DescriptionTextToggle
      textStyle="body"
      onReady={() => setReady(true)}
      numberOfLines={3}
      type="modal"
      title={podcast.title}
      withLinks
    >
      {podcast.description}
    </DescriptionTextToggle>
  ) : undefined

  const host = joinContributorNames(podcast.contributors, "host")
  const publisher = joinContributorNames(podcast.contributors, "publisher")

  return (
    <Box
      paddingHorizontal={
        presentation === "wide" ? undefined : "pagePaddingHorizontal"
      }
    >
      <AsyncView state={ready ? "success" : "loading"}>
        <Row alignItems="flex-start">
          <ArtworkTitleLockup fullWidth={presentation === "narrow"}>
            <ArtworkContainer>
              <Artwork
                resizeMode="cover"
                source={{
                  uri: getOptimizedImageSource(
                    podcast.artworkMedia?.original.url,
                    tokens.podcastModule.artwork.size,
                  ),
                }}
              />
              {showLockIcon ? <LockedContentIndicator /> : null}
            </ArtworkContainer>
            <Spacer
              horizontal={true}
              size={presentation === "wide" ? "xlarge" : "medium"}
            />
            <TitleContainer>
              <Heading level={1} textStyle="headingLarge" numberOfLines={2}>
                {podcast.title}
              </Heading>
              <Spacer size="tiny" />
              {host && (
                <Text
                  textStyle={presentation === "wide" ? "body" : "strong"}
                  color="secondary"
                  numberOfLines={1}
                >
                  {host}
                </Text>
              )}
              {publisher && <MetadataText>{publisher}</MetadataText>}
            </TitleContainer>
          </ArtworkTitleLockup>
          {presentation === "wide" && descriptionText ? (
            <>
              <Spacer horizontal={true} size="xlarge" />
              <PodcastDescriptionWide>{descriptionText}</PodcastDescriptionWide>
            </>
          ) : undefined}
        </Row>
      </AsyncView>
      <Spacer
        size={presentation === "narrow" && descriptionText ? "large" : "none"}
      />
      {presentation === "narrow" ? descriptionText : null}
    </Box>
  )
}

export function PodcastModuleEpisodesHeading(): JSX.Element {
  const { t } = useTranslation()

  return (
    <Box paddingHorizontal="large">
      <Heading textStyle="headingMedium" level={2}>
        {t("Episodes")}
      </Heading>
      <Spacer size="medium" />
    </Box>
  )
}
