import { refetchUserSubscriptions } from "../../../hooks/subscriptions"
import api from "../../api"
import { requestGroupMembership } from "../request-group-membership"
import { requestPayment } from "../request-payment"
import { CheckoutSession } from "../session"

export const verifiedCheckout = async ({
  checkoutSession,
}: {
  checkoutSession: CheckoutSession
}): Promise<void> => {
  switch (checkoutSession.type) {
    case "paidPlan":
      await requestPayment({ checkoutSession })
      break
    case "paidPlanResubscription":
      if (checkoutSession.plan.provider === "webPayment") {
        await requestPayment({ checkoutSession })
      } else {
        await api.patch("/integrations/stripe/subscription", {
          type: "resubscription",
          subscriptionPlanId: checkoutSession.plan.id,
          promoCode: checkoutSession.promoCode,
        })
      }
      break
    case "paidPlanWithProrationPreview":
      await api.patch("/integrations/stripe/subscription", {
        type: "proration",
        subscriptionPlanId: checkoutSession.plan.id,
        prorationDate: checkoutSession.prorationDate,
        stripeSubscriptionId: checkoutSession.stripeSubscriptionId,
        promoCode: checkoutSession.promoCode,
      })
      break
    case "groupMembership":
      await requestGroupMembership({ checkoutSession: checkoutSession })
      break
  }
  await refetchUserSubscriptions()
}
