"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.videoModule = void 0;
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
exports.videoModule = {
    fullWidthSignUpButton: (0, helpers_1.displayWidthToken)({
        0: true,
        475: false,
    }),
    actionButtonsInHeading: (0, helpers_1.displayWidthToken)({
        0: false,
        [base_1.breakpoints.desktop]: true,
    }),
    maxWidth: 960,
};
