export const prompt = ({
  title,
  message,
}: {
  title?: string
  message: string
}) => {
  return new Promise((resolve) =>
    resolve(
      window.prompt(
        title
          ? `${title}
      
${message}`
          : message,
      ),
    ),
  )
}
