"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkoutScreen = void 0;
const base_1 = require("../base");
exports.checkoutScreen = {
    cardsContainer: {
        paddingVertical: base_1.spacing.small,
        paddingHorizontal: {
            wide: base_1.spacing.small,
            narrow: 0,
        },
    },
    container: {
        paddingHorizontal: {
            wide: 0,
            narrow: 18,
        },
    },
};
