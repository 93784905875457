import React from "react"

import ListView from "../list-view"
import { HorizontalListViewWithArrowsProps } from "./types"

// Unlocks a scroll view during body scroll locking on the web
const WEB_SCROLL_UNLOCK = { webScrollUnlock: true }

export default function HorizontalListViewWithArrowsMobile<T>({
  arrowButtonHeight: _arrowButtonHeight,
  ...props
}: HorizontalListViewWithArrowsProps<T>): JSX.Element {
  return (
    <ListView
      {...props}
      horizontal
      showsHorizontalScrollIndicator={false}
      scrollViewDataSet={WEB_SCROLL_UNLOCK}
    />
  )
}
