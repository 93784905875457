import React from "react"
import PageLayout from "../../layouts/page"
import {
  SearchInput,
  SearchProvider,
  SearchResults,
} from "../../../components/search"
import Spacer from "../../../components/spacer"
import useSafeAreaInsetAppHeader from "../../../hooks/use-safe-area-inset-app-header"
import { useTokens } from "../../../components/tokens-provider"
import CenteredContent from "../../../components/centered-content"

export default function SearchScreen(): JSX.Element {
  const { tokens } = useTokens()
  const appHeaderSafeAreaInset = useSafeAreaInsetAppHeader()
  return (
    <SearchProvider>
      <PageLayout
        headerDropShadow={false}
        headerChildren={
          <CenteredContent paddingHorizontal={0}>
            <SearchInput />
          </CenteredContent>
        }
        headerChildrenHeight={
          tokens.searchScreen.textInput.height +
          tokens.searchScreen.textInput.underlineHeight
        }
      >
        <Spacer
          size={
            appHeaderSafeAreaInset +
            tokens.searchScreen.textInput.height +
            tokens.searchScreen.textInput.underlineHeight
          }
        />
        <CenteredContent paddingHorizontal={0}>
          <SearchResults />
        </CenteredContent>
      </PageLayout>
    </SearchProvider>
  )
}
