import React, { ReactNode } from "react"
import {
  SafeAreaProvider,
  useSafeAreaInsets,
} from "react-native-safe-area-context"

const INITIAL_METRICS = {
  frame: {
    width: -1,
    height: -1,
    x: -1,
    y: -1,
  },
  insets: {
    top: -1,
    bottom: -1,
    left: -1,
    right: -1,
  },
}

/**
 * Prevents the app from rendering until the safe area insets have been
 * calculated. This prevents a layout flash on devices without a notch.
 */
function SafeAreaProviderProcastinator(props: {
  children: ReactNode
}): JSX.Element | null {
  const safeAreaInsets = useSafeAreaInsets()
  if (safeAreaInsets === INITIAL_METRICS.insets) {
    return null
  } else {
    return <>{props.children}</>
  }
}

function SafeAreaProviderSync({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  return (
    <SafeAreaProvider initialMetrics={INITIAL_METRICS}>
      <SafeAreaProviderProcastinator>{children}</SafeAreaProviderProcastinator>
    </SafeAreaProvider>
  )
}

export default SafeAreaProviderSync
