import { useCallback } from "react"
import queryString from "query-string"
import { history } from "../../lib/history.web"
import { Params } from "../../navigation/routes"
import { useRoute } from "../use-route"

export function useRouteParams<Key extends string = string>(): [
  Params<Key>,
  (params: Params<Key>) => void,
] {
  const route = useRoute()
  const setParams = useCallback(
    (params: Params<Key>) =>
      history.replace({ search: queryString.stringify(params) }),
    [],
  )
  return [route.params, setParams]
}
