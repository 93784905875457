import { UseQueryOptions, useQuery } from "react-query"

import { FreeTrialEligibility } from "@treefort/api-spec"

import {
  AvailableSubscriptionPlan,
  getFreeTrialEligibilities,
  getSubscriptionPlans,
  getAvailableSubscriptionPlans,
  getSubscriptionPlan,
  getPaymentProvidersForPlatform,
} from "../lib/subscription-plans"
import useAppPreview from "./use-app-preview"
import useQueryKey from "./use-query-key"

export type SignUpOptionsData = {
  availableSubscriptionPlans: AvailableSubscriptionPlan[]
  recommendedSubscriptionPlans: AvailableSubscriptionPlan[]
  freeTrialEligibilities: FreeTrialEligibility[]
}

/**
 * Fetches data from the server that `getSignUpOptions` needs to generate the
 * the list of sign up options
 */
export function useSignUpOptionsData({
  offeringIds,
  groupMembershipPlanId,
  ...queryOptions
}:
  | ({
      offeringIds?: number[]
      groupMembershipPlanId?: number
    } & UseQueryOptions<SignUpOptionsData>)
  | undefined = {}) {
  const appPreview = useAppPreview()
  const appPreviewToken =
    appPreview.state === "on" ? appPreview.token : undefined
  return useQuery(
    useQueryKey([
      "sign-up-options-data",
      offeringIds,
      groupMembershipPlanId,
      appPreviewToken,
    ]),
    async () => {
      if (groupMembershipPlanId) {
        // If groupMembershipPlanId is passed then only that one plan needs to
        // be fetched
        const plan = await getSubscriptionPlan(groupMembershipPlanId)
        if (plan.archivedAt) {
          throw new Error("[Checkout] Can't show archived plan", {
            cause: new Error(
              `Encountered archived plan with id: ${groupMembershipPlanId}`,
            ),
          })
        }

        if (plan.provider !== "groupMembership") {
          throw new Error(
            '[Checkout] Expected a group membership plan, but plan.provider is not "groupMembership"',
            {
              cause: new Error(
                `Encounted plan with id ${groupMembershipPlanId} and unexpected provider "${plan.provider}"`,
              ),
            },
          )
        }

        return {
          availableSubscriptionPlans: [plan] as AvailableSubscriptionPlan[],
          recommendedSubscriptionPlans: [] as AvailableSubscriptionPlan[],
          freeTrialEligibilities: [] as FreeTrialEligibility[],
        }
      } else {
        const providers = getPaymentProvidersForPlatform()
        const allSubscriptionPlans = await getSubscriptionPlans({
          providers,
          appPreviewToken,
        })
        const [
          availableSubscriptionPlans,
          subscriptionPlansWithAccessToContent,
          freeTrialEligibilities,
        ] = await Promise.all([
          getAvailableSubscriptionPlans(allSubscriptionPlans),
          offeringIds &&
            getSubscriptionPlans({
              offeringIds,
              providers,
              appPreviewToken,
            }),
          getFreeTrialEligibilities(allSubscriptionPlans.map(({ id }) => id)),
        ])

        return {
          availableSubscriptionPlans,
          recommendedSubscriptionPlans: subscriptionPlansWithAccessToContent
            ? availableSubscriptionPlans.filter((availablePlan) =>
                subscriptionPlansWithAccessToContent.some(
                  (planWithAccessToContent) =>
                    planWithAccessToContent.id === availablePlan.id,
                ),
              )
            : availableSubscriptionPlans,
          freeTrialEligibilities,
        }
      }
    },
    queryOptions,
  )
}
