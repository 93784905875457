import React from "react"
import { useTranslation } from "react-i18next"

import { isAndroid, isIos } from "@braintree/browser-detection"

import AppStoreCTA from "../../assets/images/app-store-cta.svg"
import PlayStoreCTA from "../../assets/images/play-store-cta.svg"
import {
  APP_STORE_APP_PAGE_URL,
  PLAY_STORE_APP_PAGE_URL,
} from "../../lib/app-stores"
import { AppLink } from "../app-link"
import Row from "../row"

export function CallToDownloadApp() {
  const { t } = useTranslation()
  const showAndroid = PLAY_STORE_APP_PAGE_URL && !isIos()
  const showIOS = APP_STORE_APP_PAGE_URL && !isAndroid()
  return showAndroid || showIOS ? (
    <Row justifyContent="center" gap="medium">
      {showIOS ? (
        <AppLink
          to={{ type: "url", url: APP_STORE_APP_PAGE_URL }}
          aria-label={t("Get it on the App Store")}
        >
          <img src={AppStoreCTA} height="42px" />
        </AppLink>
      ) : null}
      {showAndroid ? (
        <AppLink
          to={{ type: "url", url: PLAY_STORE_APP_PAGE_URL }}
          aria-label={t("Get it on the Play Store")}
        >
          <img src={PlayStoreCTA} height="42px" />
        </AppLink>
      ) : null}
    </Row>
  ) : null
}
