import { useSafeAreaInsets } from "react-native-safe-area-context"

import { AppHeaderState } from "../components/app-header/types"
import { useTokens } from "../components/tokens-provider"

/**
 * This returns the number of pixels at the top of the viewport that the app
 * header may cover. This can be used to push content down so that it is not
 * obscured by the header.
 */
const useSafeAreaInsetAppHeader = (
  state: AppHeaderState = "expanded",
): number => {
  const { tokens } = useTokens()
  const { top } = useSafeAreaInsets()
  return (
    top +
    (state === "expanded"
      ? tokens.appHeader.maxHeight
      : tokens.appHeader.minHeight)
  )
}

export default useSafeAreaInsetAppHeader
