"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.links = void 0;
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
exports.links = {
    row: {
        gap: 32,
    },
    column: {
        maxCount: (0, helpers_1.displayWidthToken)({
            0: 1,
            520: 2,
            1280: 3,
            1792: 4,
        }),
        gutterWidth: (0, helpers_1.displayWidthToken)({
            0: 20,
            640: 35,
            1024: 50,
        }),
    },
    title: {
        textStyle: "headingMedium",
        paddingBottom: base_1.spacing.medium,
    },
    paddingLeft: base_1.spacing.small,
    paddingRight: base_1.spacing.medium,
};
