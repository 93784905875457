import React from "react"
import { StyleProp, ViewStyle } from "react-native"

import { AppBannerItem } from "@treefort/api-spec"
import { BANNER_SIZES } from "@treefort/constants"
import { clamp } from "@treefort/lib/clamp"

import { getAbsoluteLineHeight } from "../lib/text-style"
import { AppLink } from "./app-link"
import BannerImageContainer from "./banner-image-container"
import { BoxPadding } from "./box"
import { Heading } from "./heading"
import Spacer from "./spacer"
import Text from "./text"
import { ResolvedTokens } from "./tokens-provider"
import UnobtrusiveButtonView from "./unobtrusive-button-view"

export const getBannerItemHeight = ({
  tokens,
  viewportHeight,
  size,
}: {
  tokens: ResolvedTokens
  viewportHeight: number
  size?: (typeof BANNER_SIZES)[number]["value"]
}): number => {
  const bannerSize =
    BANNER_SIZES.find((s) => s.value === size) || BANNER_SIZES[0]
  // Determine the minimum height necessary to support all banner text. This
  // will be the minimum height for all banners, regardless of whether they
  // contain text or not.
  const minHeight =
    tokens.spacing.jumbo +
    getAbsoluteLineHeight("headingXSmall", tokens) +
    tokens.spacing.small +
    getAbsoluteLineHeight("headingXLarge", tokens) * 2 +
    tokens.spacing.xsmall +
    getAbsoluteLineHeight("caption", tokens) * 3 +
    tokens.spacing.small +
    getAbsoluteLineHeight("caption", tokens) +
    tokens.spacing.jumbo
  return clamp(
    viewportHeight * bannerSize.heightAsFractionOfViewport,
    minHeight,
    Math.max(bannerSize.maxHeight, minHeight),
  )
}

export default function BannerItem({
  bannerItem,
  height,
  backgroundColor,
  style,
  touchableArea = "content",
  gradientCoverage = 1,
  gradientOffset = 0,
  paddingBottom = "medium",
}: {
  bannerItem: AppBannerItem
  height: number | string
  backgroundColor: string
  touchableArea?: "view" | "content"
  gradientCoverage?: number
  gradientOffset?: number
  style?: StyleProp<ViewStyle>
  paddingBottom?: BoxPadding
}): JSX.Element {
  const content = (
    <>
      {bannerItem.secondaryTitle ? (
        <Text
          shadow
          textStyle="headingXSmall"
          color="translucent"
          numberOfLines={1}
        >
          {bannerItem.secondaryTitle}
        </Text>
      ) : null}
      {bannerItem.title ? (
        <>
          <Spacer size="tiny" />
          <Heading
            shadow
            level={3}
            numberOfLines={2}
            textStyle="headingXLarge"
            color="highContrast"
            maxWidth="title"
          >
            {bannerItem.title}
          </Heading>
        </>
      ) : null}
      {bannerItem.description ? (
        <>
          <Spacer size="xsmall" />
          <Text
            shadow
            numberOfLines={3}
            textStyle="caption"
            maxWidth="description"
            color="translucent"
          >
            {bannerItem.description}
          </Text>
        </>
      ) : null}
      {bannerItem.actionLink ? (
        <>
          <Spacer size={bannerItem.description ? "small" : "xsmall"} />
          <UnobtrusiveButtonView>
            {bannerItem.actionLink.title}
          </UnobtrusiveButtonView>
        </>
      ) : null}
    </>
  )
  const output = (
    <BannerImageContainer
      style={
        // If we're wrapping the whole view in a link then we'll apply the
        // styles to the link instead
        !(touchableArea === "view" && bannerItem.actionLink) ? style : undefined
      }
      height={height}
      gradientCoverage={gradientCoverage}
      gradientOffset={gradientOffset}
      source={{
        uri: bannerItem.imageUrl,
      }}
      paddingBottom={paddingBottom}
      backgroundColor={backgroundColor}
    >
      {touchableArea === "content" && bannerItem.actionLink ? (
        <AppLink
          to={bannerItem.actionLink.link}
          aria-label={bannerItem.title || bannerItem.actionLink.title}
        >
          {content}
        </AppLink>
      ) : (
        content
      )}
    </BannerImageContainer>
  )
  return touchableArea === "view" && bannerItem.actionLink ? (
    <AppLink
      to={bannerItem.actionLink.link}
      style={style}
      aria-label={bannerItem.title || bannerItem.actionLink.title}
    >
      {output}
    </AppLink>
  ) : (
    output
  )
}
