import React from "react"

import { AppModuleFeature } from "@treefort/api-spec"

import {
  FeatureModule,
  getFeatureHeight,
  getBannerItemHeight,
} from "../../../../components/modules/feature"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleFeature["config"]

export class FeatureListViewModule
  implements ListViewModule<AppModuleFeature, Item>
{
  readonly appModule: AppModuleFeature
  readonly appHeaderPresentation = "transparent"
  readonly appHeaderState = undefined
  readonly gapSize = 0
  readonly maxWidth = undefined

  constructor(appModule: AppModuleFeature) {
    this.appModule = appModule
  }

  private getBannerItemHeight = (args: ItemArgs<Item>) =>
    getBannerItemHeight({
      tokens: args.tokens,
      viewportHeight: args.displayHeight - args.safeAreaInsets.top,
      size: "small",
    })

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <FeatureModule
      {...args.item}
      bannerItemHeight={
        this.getBannerItemHeight(args) + args.safeAreaInsets.top
      }
      scrollRestorationKey={this.getItemKey(args)}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id

  getItemSize = (args: ItemArgs<Item>) =>
    getFeatureHeight({
      tokens: args.tokens,
      bannerItemHeight: this.getBannerItemHeight(args),
      itemShape: args.item.itemShape,
      preferredShowItemTitles: args.item.showItemTitles,
      preferredShowItemDates: args.item.showItemDates,
    }) + args.safeAreaInsets.top
}
