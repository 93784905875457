import { i18n, t } from "i18next"
import { stringify } from "query-string"

import {
  IntegrationProvider,
  SubscriptionPlanResponse,
  UserSubscription,
} from "@treefort/api-spec"
import {
  FreeTrialEligibility,
  FreeTrialEligibilityList,
} from "@treefort/api-spec"
import filter from "@treefort/lib/filter"

import config from "../../config"
import api from "../api"
import openUrl from "../open-url"
import { openStripeBillingPortal } from "../stripe"
import { openWebPaymentBillingPortal } from "../web-payment"

export { getAvailableSubscriptionPlans } from "./get-available-subscription-plans"
export {
  canCancelPlanFromProvider,
  canPurchasePlanFromProvider,
  canOpenBillingPortalForProvider,
  getPaymentProvidersForPlatform,
} from "./providers"
export type { AvailableSubscriptionPlan } from "./get-available-subscription-plans"

/**
 * NOTE: This will not include group membership subscription plans since users
 * don't have permission to fetch all of those at once
 */
export async function getSubscriptionPlans(options?: {
  offeringIds?: number[]
  providers?: Omit<IntegrationProvider, "groupMembership">[]
  appPreviewToken?: string
}) {
  const result = await api.get<
    Exclude<SubscriptionPlanResponse, { provider: "groupMembership" }>[]
  >("/subscription/plans", {
    params: {
      offeringId: options?.offeringIds,
      provider: options?.providers,
    },
    headers: filter(
      { "X-Treefort-App-Preview-Token": options?.appPreviewToken },
      Boolean,
    ),
  })
  return result.data
}

export const getSubscriptionPlan = async (
  id: number,
): Promise<SubscriptionPlanResponse> => {
  const result = await api.get<SubscriptionPlanResponse>(
    `/subscription/plans/${id}`,
  )
  return result.data
}

export function formatPlanIntervalShort(
  plan: Pick<
    Exclude<SubscriptionPlanResponse, { provider: "groupMembership" }>,
    "interval" | "intervalCount"
  >,
) {
  switch (plan.interval) {
    case "month":
      return t("/{{number}} months", { number: plan.intervalCount })
    case "year":
      return t("/{{number}} years", { number: plan.intervalCount })
  }
}

export function formatPlanIntervalLong(
  plan: Pick<
    Exclude<SubscriptionPlanResponse, { provider: "groupMembership" }>,
    "interval" | "intervalCount"
  >,
) {
  switch (plan.interval) {
    case "month":
      return t("Billed every {{number}} months", {
        number: plan.intervalCount,
      })
    case "year":
      return t("Billed every {{number}} years", {
        number: plan.intervalCount,
      })
  }
}

export const getFreeTrialEligibilities = async (
  subscriptionPlanIds: number[],
): Promise<FreeTrialEligibility[]> => {
  if (!subscriptionPlanIds.length) {
    return []
  }
  const result = await api.get<FreeTrialEligibilityList>(
    `/subscription/free-trial-eligibility`,
    { params: { planId: subscriptionPlanIds } },
  )
  return result.data.eligibilities
}

export async function openBillingPortal({
  subscription,
  subscriptionPlan,
  i18n,
}: {
  subscription: UserSubscription | undefined
  subscriptionPlan: SubscriptionPlanResponse
  i18n: i18n
}): Promise<void> {
  switch (subscriptionPlan.provider) {
    case "playStore": {
      // Google allows us to link directly to a specific subscription if it is
      // active. If the subscription is not active or the user doesn't have one
      // then we need to omit these query parameters. This will open the
      // top-level subscription page instead of triggering a potential 404.
      const queryString =
        subscription && !subscription.deactivatedAt
          ? `?${stringify({ package: config.ANDROID_APP_ID, sku: subscriptionPlan.productId })}`
          : ""
      return openUrl(
        `https://play.google.com/store/account/subscriptions${queryString}`,
        { openInApp: false, parentalGateway: false },
      )
    }
    case "appStore": {
      return openUrl("https://apps.apple.com/account/subscriptions", {
        openInApp: false,
        parentalGateway: false,
      })
    }
    case "stripe":
      return openStripeBillingPortal({ i18n })
    case "webPayment":
      return openWebPaymentBillingPortal({ i18n })
  }
}
