import React from "react"

import { useTracks } from "../../hooks/audio"
import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import { useNavigate } from "../../hooks/use-navigate"
import { Track } from "../../lib/audio-player"
import { stopAudioPlayer } from "../../lib/content-audio"
import { getPathFromTrack, NO_TAB } from "../../navigation/routes"
import FloatingAudioPlayer from "../audio-player-floating"
import BottomTabBar from "../bottom-tab-bar"

function AppFooterDesktop(
  _props: Omit<Parameters<typeof BottomTabBar>[0], "height" | "safeAreaInsets">,
): JSX.Element | null {
  const tracks = useTracks()
  const navigate = useNavigate()
  const profileId = useActiveProfileId()

  function onAudioPlayerPressTitle(track: Track) {
    const path = getPathFromTrack(track, NO_TAB)
    navigate(path, undefined, "push")
  }

  function onAudioPlayerRequestClose() {
    stopAudioPlayer({ profileId })
  }

  return tracks.length > 0 ? (
    <FloatingAudioPlayer
      onPressTitle={onAudioPlayerPressTitle}
      onRequestClose={onAudioPlayerRequestClose}
    />
  ) : null
}

export default AppFooterDesktop
