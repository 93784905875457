import { useEffect, useMemo, useState } from "react"
import { Dimensions, Platform, ScaledSize } from "react-native"

import debounce from "lodash/debounce"

import { addEventListener } from "@treefort/lib/dom"

import { getWindowDimensions } from "../lib/get-window-dimensions"

/**
 * The current state of our window dimension listening logic. The reason for the
 * complexity here is two fold:
 * 1. For performance reasons we stop listening to window dimension updates when
 *    the app is in the foreground but not actually visible (e.g. when it's
 *    behind a fullscreen video player)
 * 2. There is a bug in React Native where window dimensions get flipped (width
 *    is reported as height and vice versa) if the orientation is changed while
 *    a video is played in fullscreen on iOS. See:
 *    https://github.com/facebook/react-native/issues/29290
 */
type State =
  | { status: "active" }
  | { status: "suspended"; suspendedDimensions: ScaledSize }
  | {
      status: "resumeOnChange"
      suspendedDimensions: ScaledSize
      resumedDimensions: ScaledSize
    }

let state: State = { status: "active" }

const useWindowDimensions = ({
  wait = 250,
  updateOnHeightChange = false,
}: {
  wait?: number
  // Listen for height changes. This allows the caller to listen to resize
  // changes triggered by mobile web browsers that collapse their address
  // bar/footer when the user scrolls. Listening to these changes is more
  // expensive so it's an opt-in feature.
  updateOnHeightChange?: boolean
} = {}): ScaledSize => {
  // If we're updating on height changes we want to use the window's "inner"
  // height on the web. In most mobile browser's window's "client" height does
  // not change when the browser's UI collapses/expands.
  const webHeightMode = updateOnHeightChange ? "inner" : "client"
  const [windowDimensions, setWindowDimensions] = useState<ScaledSize>(
    state.status === "suspended" || state.status === "resumeOnChange"
      ? state.suspendedDimensions
      : getWindowDimensions({ webHeightMode }),
  )

  // A debounced function that requires dimensions to settle for a certain
  // number of milliseconds (determined by "wait") before updating them.
  const fetchDimensions = useMemo(() => {
    const changeDetected = (
      a: { width: number; height: number },
      b: { width: number; height: number },
    ) => a.width !== b.width || (a.height !== b.height && updateOnHeightChange)
    return debounce(
      () => {
        const next = getWindowDimensions({ webHeightMode })
        setWindowDimensions((prev) => {
          if (
            state.status === "resumeOnChange" &&
            changeDetected(next, state.resumedDimensions)
          ) {
            state = { status: "active" }
            return next
          } else if (state.status === "active" && changeDetected(next, prev)) {
            return next
          } else {
            return prev
          }
        })
      },
      wait,
      { leading: true, trailing: true },
    )
  }, [wait, updateOnHeightChange, webHeightMode])

  // Call fetchDimensions anytime dimensions change _unless_ we're suspended
  useEffect(() => {
    const handler = () => {
      if (state.status !== "suspended") {
        fetchDimensions()
      }
    }
    const sub = Dimensions.addEventListener("change", handler)
    return () => sub.remove()
  }, [fetchDimensions])

  // iOS Safari doesn't always trigger a resize event when the browser UI
  // collapses due to scrolling, so we have to listen to the scroll event as
  // well.
  useEffect(() => {
    if (Platform.OS === "web" && updateOnHeightChange) {
      return addEventListener(
        document.documentElement,
        "scroll",
        fetchDimensions,
      )
    }
  }, [fetchDimensions, updateOnHeightChange])

  return windowDimensions
}

/**
 * Suspend all window dimension updates. This is used when an orientation change
 * is expected for the video player and we don't want to thrash the app UI
 * behind the video player.
 */
export const suspendWindowDimensionUpdates = (): void => {
  state = {
    status: "suspended",
    suspendedDimensions:
      state.status === "active"
        ? getWindowDimensions()
        : state.suspendedDimensions,
  }
}

/**
 * Resume window dimension updates the next time they change
 */
export const resumeWindowDimensionUpdates = (): void => {
  if (state.status === "suspended") {
    state = {
      status: "resumeOnChange",
      suspendedDimensions: state.suspendedDimensions,
      resumedDimensions: getWindowDimensions(),
    }
  }
}

export default useWindowDimensions
