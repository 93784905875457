"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.planCard = void 0;
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
exports.planCard = {
    card: {
        boxShadow: (0, helpers_1.displayModeToken)({
            light: "0 4px 22px rgba(0, 0, 0, 0.125)",
            dark: "none",
        }),
        borderRadius: base_1.borderRadius.roundedLarge,
        borderWidth: {
            highlighted: 3,
            default: (0, helpers_1.displayModeToken)({ light: 0, dark: 1 }),
        },
        borderColor: {
            highlighted: base_1.colors.accent,
            default: base_1.colors.border.shadowAdjacent,
        },
        backgroundColor: (0, helpers_1.displayModeToken)({
            dark: base_1.colors.background.primary.dark,
            light: base_1.colors.white,
        }),
        padding: base_1.spacing.medium,
    },
    cardContent: {
        paddingTop: 0,
        paddingHorizontal: {
            narrow: 0,
            wide: base_1.spacing.small,
        },
        paddingBottom: {
            narrow: base_1.spacing.medium,
            wide: base_1.spacing.large,
        },
    },
    benefitsToggle: {
        iconColor: base_1.colors.gray500,
        textColor: base_1.colors.gray500,
    },
    lineItem: {
        iconColor: {
            default: base_1.colors.gray500,
            checked: base_1.colors.accent,
        },
        textColor: {
            default: base_1.colors.gray500,
            checked: base_1.colors.text.primary,
        },
    },
    button: {
        backgroundColor: base_1.colors.background.primary,
        borderColor: base_1.colors.text.primary,
    },
};
