import React, { useEffect, useMemo } from "react"

import styled from "styled-components/native"

import { VideoResponse } from "@treefort/api-spec"

import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import { useIsFocused } from "../../hooks/use-is-focused"
import useWindowDimensions from "../../hooks/use-window-dimensions"
import { IS_BOT } from "../../lib/is-bot"
import { ProfilesOverlayEvent, profilesOverlay } from "../../lib/profiles"
import { Event, VideoPlayer } from "../../lib/video-player"
import { videoPlayerManager } from "../../lib/video-player-manager"
import ActivityIndicator from "../activity-indicator"
import TokensProvider from "../tokens-provider"
import { VideoPlayNextOverlay } from "../video-play-next-overlay"
import Player from "./player"
import Poster from "./poster"

// The video player is always in dark mode
const DISPLAY_MODE = "dark"

const Container = styled.View`
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
  align-items: center;
  justify-content: center;
`

/**
 * A wrapper around the VideoPlayer component that unmounts the component and
 * shows a poster instead when the player instance is suspended.
 */
function SuspendablePlayer({
  videoPlayer,
  poster,
  dimensions,
  autoPlay,
  onPressPoster,
}: {
  videoPlayer: VideoPlayer
  dimensions: { width: number; height: number }
  poster?: string
  autoPlay?: boolean
  onPressPoster?: () => void
}): JSX.Element {
  const suspended = useEventEmitterValue(
    videoPlayer,
    Event.Suspended,
    videoPlayer.getSuspended,
  )
  const { open: profilesOverlayOpen } = useEventEmitterValue(
    profilesOverlay,
    ProfilesOverlayEvent.StateChanged,
    profilesOverlay.getState,
  )

  const focused = useIsFocused()

  // Register the video player with the AV manager. The AV manage will
  // coordinate this video player's state with any other video players as well
  // as the audio player.
  useEffect(() => {
    videoPlayerManager.registerVideoPlayer(videoPlayer)
    return () => videoPlayerManager.unregisterVideoPlayer(videoPlayer)
  }, [videoPlayer])

  // Publish our intent to start playback for autoplaying videos
  useEffect(() => {
    if (!videoPlayer.getSuspended() && autoPlay) {
      videoPlayer.publishPlayIntent()
    }
  }, [videoPlayer, autoPlay])

  // Suspend the video player when the route is not focused (e.g. the user
  // switches to another page or tab) or the profiles overlay is open
  useEffect(() => {
    if (!focused || profilesOverlayOpen) {
      videoPlayer.suspend()
    }
  }, [focused, profilesOverlayOpen, videoPlayer])

  return suspended ? (
    <Poster source={{ uri: poster }} onPress={onPressPoster} />
  ) : (
    <Player
      videoPlayer={videoPlayer}
      dimensions={dimensions}
      autoPlay={autoPlay}
    />
  )
}

/**
 * This takes in a VideoPlayer object, renders a video element using the
 * VideoPlayer React component (not to be confused with the object), and keeps
 * the rendered player and the player object in sync.
 */
export default function VideoPlayerInline({
  videoPlayer,
  poster,
  autoPlay,
  loading,
  onPressPoster,
  nextVideo,
}: {
  // A VideoPlayer instance. Hold on to this - you can use it to trigger actions
  // on the player or listen to state changes.
  videoPlayer?: VideoPlayer
  // Pass the URL of an image to display when videoPlayer is unset or suspended.
  poster?: string
  // If true then playback will start on mount if possible. Not supported in
  // Safari. See #1222
  autoPlay?: boolean
  // Set this to true show a loading state when no VideoPlayer object is
  // provided. This is ignored if a VideoPlayer object is provided.
  loading?: boolean
  // Listen for press events on the poster. The poster is shown when the poster
  // props is set, no VideoPlayer object is provided, and the loading prop is
  // falsey.
  onPressPoster?: () => void
  // The video to play after the current one. If set then the play next overlay
  // will be shown when the current video finishes.
  nextVideo?: VideoResponse
}): JSX.Element {
  const windowDimensions = useWindowDimensions()
  const dimensions = useMemo(
    () => ({
      width: windowDimensions.width,
      // Display at a 16:9 aspect ratio but constrain the height to 2/3 of the
      // window height.
      height: Math.min(
        windowDimensions.height * (2 / 3),
        windowDimensions.width * (9 / 16),
      ),
    }),
    [windowDimensions],
  )

  return (
    <TokensProvider displayMode={DISPLAY_MODE}>
      <Container style={dimensions}>
        {
          // Don't load videos for bots (waste of time and bandwidth)
          IS_BOT ? (
            <Poster source={{ uri: poster }} />
          ) : videoPlayer ? (
            <SuspendablePlayer
              videoPlayer={videoPlayer}
              poster={poster}
              dimensions={dimensions}
              autoPlay={autoPlay}
              onPressPoster={onPressPoster}
            />
          ) : loading ? (
            <ActivityIndicator size="jumbo" />
          ) : (
            <Poster source={{ uri: poster }} onPress={onPressPoster} />
          )
        }
        <VideoPlayNextOverlay
          videoPlayer={videoPlayer}
          nextVideo={nextVideo}
          playerDimensions={dimensions}
        />
      </Container>
    </TokensProvider>
  )
}
