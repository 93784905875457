import React from "react"

import styled from "styled-components/native"

import Spacer from "./spacer"
import Text from "./text"

const ShrinkingText = styled(Text)`
  flex-shrink: 1;
`

export function MetadataText({ children }: { children: string }) {
  return (
    <ShrinkingText textStyle="caption" color="secondary" numberOfLines={1}>
      {children}
    </ShrinkingText>
  )
}

export function MetadataSpacer() {
  return (
    <>
      <Spacer size="xsmall" horizontal />
      {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
      <Text textStyle="body" color="secondary">
        •
      </Text>
      <Spacer size="xsmall" horizontal />
    </>
  )
}
