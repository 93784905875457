"use strict";
// See the epub cfi spec: https://idpf.org/epub/linking/cfi
Object.defineProperty(exports, "__esModule", { value: true });
exports.compareCfis = void 0;
/**
 * Parse a CFI so that it can be compared
 */
function parse(epubCfi) {
    // Get the part of the cfi between the opening "epubcfi(" and the closing ")"
    const path = epubCfi.slice("epubcfi(".length, -")".length);
    const steps = path
        // Split the path into steps
        .split("/")
        .filter(Boolean)
        // Remove any bracketed assertions as required by the sorting rules
        // defined in the epub cfi spec
        // - https://idpf.org/epub/linking/cfi/#sec-sorting
        .map((step) => (step + " ").slice(0, step.indexOf("[")))
        // Remove the trailing "!" that denotes a reference to another document.
        // This logic assumes that references to other documents will be identical
        // across CFIs (in practice almost all document references will be
        // references from the spine to somewhere in a chapter)
        .map((step) => (step + " ").slice(0, step.indexOf("!")));
    // Strip away any temporal, spatial, or temporal-spatial offsets from the
    // final step
    let charOffset = undefined;
    let lastStep = steps[steps.length - 1];
    lastStep = (lastStep + " ").slice(0, lastStep.indexOf("~"));
    lastStep = (lastStep + " ").slice(0, lastStep.indexOf("@"));
    [lastStep, charOffset] = lastStep.split(":");
    steps[steps.length - 1] = lastStep;
    return {
        steps: steps.map((step) => parseInt(step, 10)),
        characterOffset: charOffset ? parseInt(charOffset, 10) : 0,
    };
}
/**
 * Determine whether a parsed CFI is valid for comparison
 */
function isValid({ steps, characterOffset: offset }) {
    if (!steps.length)
        return false;
    if (steps.some(isNaN))
        return false;
    if (offset && isNaN(offset))
        return false;
    return true;
}
/**
 * Compare two CFIs, returning a negative number if `a` comes first, a positive
 * number if `b` comes first, and 0 if `a` and `b` are equal
 *
 * NOTE:
 *   - Range CFIs are not supported
 *   - Temporal, spatial, and temporal-spatial offsets are not supported
 *   - References to other documents are assumed to be identical between CFIs
 */
function compareCfis(a, b) {
    const parsedA = parse(a);
    const parsedB = parse(b);
    const isAValid = isValid(parsedA);
    const isBValid = isValid(parsedB);
    // If both are invalid then treat them as equal
    if (!isAValid && !isBValid) {
        return 0;
    }
    // Sort "a" first if "b" is invalid
    if (isAValid && !isBValid) {
        return -1;
    }
    // Sort "b" first if "a" is invalid
    if (!isAValid && isBValid) {
        return 1;
    }
    // Walk through and compare each corresponding step
    for (let i = 0; i < Math.min(parsedA.steps.length, parsedB.steps.length); i++) {
        const order = parsedA.steps[i] - parsedB.steps[i];
        if (order !== 0) {
            return order;
        }
    }
    // If shared steps are equal but "a" has fewer steps, then "a" comes first
    if (parsedA.steps.length < parsedB.steps.length) {
        return -1;
    }
    // If shared steps are equal but "b" has fewer steps, then "b" comes first
    if (parsedB.steps.length < parsedA.steps.length) {
        return 1;
    }
    // If all steps are equal then fall back to character offset
    return parsedA.characterOffset - parsedB.characterOffset;
}
exports.compareCfis = compareCfis;
