"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetaPixelSubscriptionLifecycleEventId = void 0;
/**
 * Get an id that can be used to deduplicate subscription lifecycle analytics
 * events that are sent from both the client and the server
 *
 * NOTE: Facebook event ids don't need to be unique across time, since
 * deduplication only occurs during a 48hr window
 * - https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
 * - https://developers.facebook.com/docs/marketing-api/conversions-api/guides/end-to-end-implementation#add-parameters-for-dedup
 */
const getMetaPixelSubscriptionLifecycleEventId = ({ userId, subscriptionPlanId, eventName, }) => `${userId}-${subscriptionPlanId}-${eventName}`;
exports.getMetaPixelSubscriptionLifecycleEventId = getMetaPixelSubscriptionLifecycleEventId;
