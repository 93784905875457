import { Platform } from "react-native"

import Emittery from "emittery"
import {
  getNetworkStateAsync,
  NetworkState,
  NetworkStateType,
} from "expo-network"

import { logError } from "./logging"

// The expo-network lib is broken on Android SDK < 29 so we just disable it...
// these users will be stuck in the "unknown" network state permanently. See:
// https://github.com/expo/expo/issues/14527
const DISABLE_NETWORK_STATE = Platform.OS === "android" && Platform.Version < 29

const DEFAULT_STATE: NetworkState = {
  type: NetworkStateType.UNKNOWN,
  isConnected: undefined,
  isInternetReachable: undefined,
}

// This is used to power the addNetworkStateListener function. We have to setup
// our own emitter because the expo-network library doesn't support listening to
// network state changes.
const emittery = new Emittery()

// Cache the network state. The expo-network library doesn't support listening
// to network state changes, so we have to fetch the state every time we need
// it. To avoid potential overfetching we cache the state locally for
// CACHE_MAX_AGE milliseconds.
let networkState: NetworkState = DEFAULT_STATE
let lastFetched: number | undefined

/**
 * Determine if one network state is different than another
 */
export const networkStateNotEqual = (
  a: NetworkState,
  b: NetworkState,
): boolean =>
  a.type !== b.type ||
  a.isConnected !== b.isConnected ||
  a.isInternetReachable !== b.isInternetReachable

/**
 * Returns true if the network is in an offline state
 */
export const isOfflineState = (state: NetworkState): boolean =>
  state.isInternetReachable === false

/**
 * Get the current network state. A cached value may be returned if the
 * staleTime option is set and the cached value is no older than the number of
 * milliseconds passed via staleTime.
 */
export async function getNetworkState({
  staleTime,
}: { staleTime?: number } = {}): Promise<NetworkState> {
  try {
    const now = Date.now()
    if (
      !DISABLE_NETWORK_STATE &&
      (staleTime === undefined ||
        lastFetched === undefined ||
        lastFetched + staleTime < now)
    ) {
      lastFetched = now
      const nextNetworkState = await getNetworkStateAsync()
      if (networkStateNotEqual(nextNetworkState, networkState)) {
        networkState = nextNetworkState
        emittery.emit("NETWORK_STATE", networkState)
      }
    }
  } catch (error) {
    logError(error)
  }
  return networkState
}

/**
 * Immediately returns the cached network state. This may be significantly out
 * of date.
 */
export function getCachedNetworkState(): NetworkState {
  return networkState
}

/**
 * Setup a listener that will be called any time a new network state is detected
 * by getNetworkState. An unsubscribe function is returned.
 */
export function addNetworkStateListener(
  listener: (state: NetworkState) => void,
): () => void {
  return emittery.on("NETWORK_STATE", listener)
}
