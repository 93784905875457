import React from "react"
import { useTranslation } from "react-i18next"
import { Platform, View } from "react-native"

import getNameOfUser from "@treefort/lib/get-name-of-user"

import { ActionCard } from "../../../components/action-card"
import { ActionLink } from "../../../components/action-link"
import Column from "../../../components/column"
import Text from "../../../components/text"
import config from "../../../config"
import { useActiveProfileId } from "../../../hooks/use-active-profile-id"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useIsProfilesFeatureEnabled } from "../../../hooks/use-is-profiles-feature-enabled"
import { useProfile } from "../../../hooks/use-profile"
import useUserInfo, { resetUserInfoQuery } from "../../../hooks/use-user-info"
import authenticator from "../../../lib/authenticator"
import openUrl from "../../../lib/open-url"
import MenuLayout from "../../layouts/menu"

export function AccountScreen(): JSX.Element {
  const { t, i18n } = useTranslation()
  const isProfilesFeatureEnabled = useIsProfilesFeatureEnabled()
  const userInfo = useUserInfo({ refetchOnWindowFocus: true })
  const asyncViewProps = useAsyncViewPropsForQueries([userInfo])
  const profileId = useActiveProfileId()
  const profile = useProfile(profileId)
  const name = userInfo.data && getNameOfUser(userInfo.data)

  return (
    <MenuLayout asyncViewProps={asyncViewProps}>
      <Column alignItems="stretch" gap="medium">
        {userInfo.isSuccess ? (
          <ActionCard
            imageUrl={
              // Don't show the profile image here if we're already showing it
              // in the profiles card
              isProfilesFeatureEnabled
                ? undefined
                : profile.data?.imageMedia.original.url ||
                  userInfo.data.profilePic
            }
            title={name || userInfo.data.email}
            titleTextStyle="headingMedium"
            childrenGap="xsmall"
          >
            {name ? (
              <Text color="secondary" textStyle="strong" numberOfLines={1}>
                {userInfo.data.email}
              </Text>
            ) : null}
          </ActionCard>
        ) : null}
        <View>
          <ActionLink
            onPress={() =>
              openUrl(
                `${config.AUTH_ENDPOINT}/account/edit/?client_id=${config.CLIENT_ID}&locale=${i18n.language}`,
                { parentalGateway: false, openInApp: true },
              ).then(() => {
                // If we're on native then refresh the user info after the in-app browser closes
                if (Platform.OS !== "web") {
                  resetUserInfoQuery()
                }
              })
            }
          >
            {t("Manage account")}
          </ActionLink>
          <ActionLink to={{ type: "path", path: "/menu/account/delete" }}>
            {t("Delete account")}
          </ActionLink>
          <ActionLink onPress={() => authenticator.logout()}>
            {t("Sign out")}
          </ActionLink>
        </View>
      </Column>
    </MenuLayout>
  )
}
