import React from "react"
import Box from "./box"
import Row from "./row"
import Text from "./text"
import { useTokens } from "./tokens-provider"
import Column from "./column"

export default function SettingsRow({
  label,
  secondaryLabel,
  children,
  showBorder,
  ...rowProps
}: Parameters<typeof Row>[0] & {
  label?: string
  secondaryLabel?: string
  showBorder?: boolean
}): JSX.Element {
  const { tokens } = useTokens()
  return (
    <Box paddingHorizontal={tokens.settingsRow.padding.horizontal}>
      {label ? (
        <Row
          justifyContent="space-between"
          borderTopColor={showBorder ? "primary" : undefined}
          paddingVertical={tokens.settingsRow.padding.vertical}
          minHeight={tokens.settingsRow.minHeight}
          gap="medium"
          {...rowProps}
        >
          <Column gap="tiny" alignItems="stretch" flex={1}>
            <Text textStyle="body">{label}</Text>
            {secondaryLabel ? (
              <Text textStyle="caption" color="secondary">
                {secondaryLabel}
              </Text>
            ) : null}
          </Column>
          {children}
        </Row>
      ) : (
        <Row
          borderTopColor={showBorder ? "primary" : undefined}
          paddingVertical={tokens.settingsRow.padding.vertical}
          minHeight={tokens.settingsRow.minHeight}
          {...rowProps}
        >
          {children}
        </Row>
      )}
    </Box>
  )
}
