"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expandableInfo = void 0;
const base_1 = require("../base");
const icon_1 = require("./icon");
exports.expandableInfo = {
    expandableInfoContentContainer: {
        marginLeft: icon_1.icon.size.small,
        marginRight: base_1.spacing.xsmall,
        borderRadius: base_1.borderRadius.rounded,
        backgroundColor: base_1.colors.background.secondary,
        padding: base_1.spacing.xsmall,
    },
};
