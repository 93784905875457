import { Event, EventName, Parameter } from "@treefort/lib/analytics"
import isObject from "@treefort/lib/is-object"

import { logError } from "../../../logging"

type RudderStackParameterValue = string | number | boolean | undefined

type RudderStackParameters = Record<string, RudderStackParameterValue>

type RudderStackEvent =
  | {
      type: "screen"
      title: string
      parameters: RudderStackParameters
    }
  | {
      type: "track"
      name: string
      parameters: RudderStackParameters
    }

export function transformValue(
  value: string | number | boolean | null | undefined,
) {
  return value ?? undefined
}

export function transformParameters(
  parameters:
    | Record<string, string | number | boolean | null | undefined>
    | undefined,
) {
  return parameters
    ? Object.fromEntries(
        Object.entries(parameters).flatMap(([key, value]) => {
          switch (key) {
            // Ignore the user id which is handled separately
            case Parameter.UserId:
              return []
            default:
              return [[key, transformValue(value)]]
          }
        }),
      )
    : {}
}

export function transformError(error: unknown) {
  // Drop breadcrumbs from Sentry errors to make sure we scoot under
  // Rudderstack's 32kb event size limit.
  if (isObject(error) && "breadcrumbs" in error) {
    return { error: JSON.stringify({ ...error, breadcrumbs: undefined }) }
  }

  return { error: JSON.stringify(error) }
}

export function transformEvents(events: Array<Event>) {
  return events.flatMap<RudderStackEvent>((event) => {
    switch (event.name) {
      case EventName.ScreenView:
        return [
          {
            type: "screen",
            title: event.parameters[Parameter.ScreenTitle],
            parameters: transformParameters(event.parameters),
          },
        ]
      default:
        return [
          {
            type: "track",
            name: event.name,
            parameters: transformParameters(event.parameters),
          },
        ]
    }
  })
}

export function logRudderStackError(message: string) {
  return (cause: unknown) =>
    logError(new Error(`[RudderStack] ${message}`, { cause }))
}
