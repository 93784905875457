"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.billingIntervalToggle = void 0;
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
exports.billingIntervalToggle = {
    track: {
        backgroundColor: (0, helpers_1.displayModeToken)({
            light: base_1.colors.background.secondary.light,
            dark: base_1.colors.background.secondary.dark,
        }),
        borderRadius: base_1.borderRadius.rounded,
    },
    toggleOption: {
        boxShadow: {
            default: "none",
            selected: (0, helpers_1.displayModeToken)({
                light: "0 4px 20px rgba(0, 0, 0, 0.25)",
                dark: "none",
            }),
        },
        backgroundColor: {
            default: "transparent",
            selected: (0, helpers_1.displayModeToken)({
                light: base_1.colors.background.secondary.dark,
                dark: base_1.colors.background.secondary.light,
            }),
        },
        titleColor: {
            default: base_1.colors.text.primary,
            selected: (0, helpers_1.displayModeToken)({
                light: base_1.colors.white,
                dark: base_1.colors.black,
            }),
        },
    },
};
