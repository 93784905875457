import React from "react"
import styled from "styled-components/native"
import Box from "./box"
import Column from "./column"

const InnerContainer = styled(Box)<{ maxWidth?: number }>`
  max-width: ${(props) => props.maxWidth ?? props.theme.maxPageWidth}px;
`

export default function CenteredContent({
  children,
  maxWidth,
  paddingHorizontal = "pagePaddingHorizontal",
  ...boxProps
}: Parameters<typeof Box>[0] & { maxWidth?: number }): JSX.Element {
  return (
    <Column width="100%" paddingHorizontal={paddingHorizontal} {...boxProps}>
      <InnerContainer width="100%" maxWidth={maxWidth}>
        {children}
      </InnerContainer>
    </Column>
  )
}
