"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuth = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const authenticator_1 = require("./authenticator");
const noop = () => { };
const AuthContext = (0, react_1.createContext)({
    user: null,
    initialized: false,
    loading: true,
});
/**
 * The entire app should be wrapped in this provider. This is where all the OAuth
 * integration magic happens.
 */
function AuthProvider({ children, authenticator, initialUrl, onError, onSessionExpired = noop, }) {
    const [state, setState] = (0, react_1.useState)({ user: null, initialized: false, loading: true });
    (0, react_1.useEffect)(() => {
        const cleanupFunctions = [
            authenticator.on(authenticator_1.Event.Initialized, (user) => setState({ user, initialized: true, loading: false })),
            // Handle changes to the user after initialization
            authenticator.on(authenticator_1.Event.User, (user) => setState((state) => (state.initialized ? Object.assign(Object.assign({}, state), { user }) : state))),
            authenticator.on(authenticator_1.Event.AuthorizationCodeReceived, () => setState((state) => state.loading ? state : Object.assign(Object.assign({}, state), { loading: true }))),
            authenticator.on(authenticator_1.Event.Error, onError),
            authenticator.on(authenticator_1.Event.SessionExpired, onSessionExpired),
            // Initialize the authenticator last (after adding event listeners) to
            // ensure we don't miss any events.
            authenticator.initialize(initialUrl),
        ];
        return () => cleanupFunctions.forEach((cleanup) => cleanup());
    }, [authenticator, onError, onSessionExpired, initialUrl]);
    return (0, jsx_runtime_1.jsx)(AuthContext.Provider, Object.assign({ value: state }, { children: children }));
}
exports.default = AuthProvider;
/**
 * Use this to get access to the current user (if authenticated) and the
 * initialized state of the authenticator.
 */
const useAuth = () => (0, react_1.useContext)(AuthContext);
exports.useAuth = useAuth;
