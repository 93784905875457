import { DeepPartial } from "@treefort/lib/deep-partial"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"

import {
  MediaSession,
  MediaSessionConfig,
  MediaSessionData,
  SkipInterval,
} from "./base"

const MEDIA_SESSION_ARTWORK_SIZES = [96, 128, 192, 256, 384, 512]
const MEDIA_SESSION_AVAILABLE = "mediaSession" in navigator

/**
 * Sets an action handler if supported, otherwise does nothing.
 */
function safeSetActionHandler(
  ...args: Parameters<typeof navigator.mediaSession.setActionHandler>
) {
  try {
    navigator.mediaSession.setActionHandler(...args)
  } catch (_error) {
    // Action handler not supported. No worries.
  }
}
/**
 * Uses the web's built-in MediaSession API. See:
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaSession
 */
export default class MediaSessionWeb extends MediaSession {
  protected _updateData = (data: DeepPartial<MediaSessionData>): void => {
    const prevData = this.data
    if (prevData) {
      const track = { ...prevData.track, ...data.track }
      return this._activate({ ...prevData, track })
    }
  }

  protected _updateConfig = ({
    onSkipTrack,
    onPlay,
    onPause,
  }: MediaSessionConfig): void => {
    safeSetActionHandler(
      "previoustrack",
      onSkipTrack ? () => onSkipTrack(-1) : null,
    )
    safeSetActionHandler("nexttrack", onSkipTrack ? () => onSkipTrack(1) : null)
    safeSetActionHandler("play", onPlay || null)
    safeSetActionHandler("pause", onPause || null)
  }

  protected _activate = (data: MediaSessionData): void => {
    if (MEDIA_SESSION_AVAILABLE) {
      const artwork = data.track.artwork
      navigator.mediaSession.metadata = new MediaMetadata({
        title: data.track.title,
        artist: data.track.artist,
        album: data.track.album,
        artwork: artwork
          ? MEDIA_SESSION_ARTWORK_SIZES.map((size) => ({
              src: getOptimizedImageSource(artwork, size),
              type: `image/${artwork.split(".").pop()}`,
              sizes: `${size}x${size}`,
            }))
          : undefined,
      })
    }
  }

  public _deactivate = (): void => {
    if (MEDIA_SESSION_AVAILABLE) {
      navigator.mediaSession.metadata = null
    }
  }
}

export type { SkipInterval }
