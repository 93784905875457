import React from "react"
import styled from "styled-components/native"
import { ImageProps, View } from "react-native"
import { FadeTowards } from "./base"
import { hexToRgba } from "@treefort/lib/color"

export const getRotation = (fadeTowards: FadeTowards): number =>
  ({
    bottom: 0,
    left: 90,
    top: 180,
    right: 270,
  }[fadeTowards])

const StyledView = styled(View)<{
  fadeTowards: FadeTowards
  color: string
}>`
  background-image: linear-gradient(
    ${(props) => getRotation(props.fadeTowards)}deg,
    ${(props) => {
      const [r, g, b] = hexToRgba(props.color)
      return `rgba(${r}, ${g}, ${b}, 0)`
    }},
    60%,
    ${(props) => props.color}
  );
`

function LinearGradient({
  style,
  fadeTowards,
  color = "#000000",
}: Omit<ImageProps, "source"> & {
  fadeTowards: FadeTowards
  color?: string
}): JSX.Element {
  return (
    <StyledView
      pointerEvents="none"
      style={style}
      fadeTowards={fadeTowards}
      color={color}
    />
  )
}

export default LinearGradient
