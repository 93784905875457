"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorageStore = exports.StoreEvent = void 0;
/**
 * Key/value store based on LocalStorage.
 *
 * NOTE: Only available for the web.
 *
 * NOTE: The getMany API does not have any performance benefits over individual
 * calls to get. Use sparingly.
 *
 * NOTE: All actions are syncronous, but the async API is kept for consistency
 * with other stores. Syncronous methods are also exposed for convenience when
 * cross-platform consistency is not important.
 */
const base_1 = require("./base");
Object.defineProperty(exports, "StoreEvent", { enumerable: true, get: function () { return base_1.StoreEvent; } });
class LocalStorageStore extends base_1.Store {
    constructor() {
        super(...arguments);
        this.getAllKeys = () => {
            const keys = [];
            for (let i = 0; i < localStorage.length; i++) {
                keys.push(localStorage.key(i));
            }
            return keys.filter((key) => !!key);
        };
        this.parseValue = (value) => typeof value === "string" ? JSON.parse(value) : null;
        this._set = async (key, value) => localStorage.setItem(key, JSON.stringify(value));
        this._get = async (key) => this.parseValue(localStorage.getItem(key));
        this._remove = async (key) => localStorage.removeItem(key);
        this._keys = async () => this.getAllKeys();
        this._getMany = async (keys) => Promise.all(keys.map(this._get));
        this._removeMany = async (keys) => {
            await Promise.all(keys.map(this._remove));
        };
        this.setSync = (key, value) => {
            localStorage.setItem(this.prefixKey(key), JSON.stringify(value));
            this.emitter.emit(base_1.StoreEvent.ItemUpdated, { key, value: value !== null && value !== void 0 ? value : null });
        };
        this.getSync = (key) => this.parseValue(localStorage.getItem(this.prefixKey(key)));
        this.removeSync = (key) => {
            localStorage.removeItem(this.prefixKey(key));
            this.emitter.emit(base_1.StoreEvent.ItemUpdated, { key, value: null });
        };
        this.keysSync = () => this.getKeysWithoutPrefixes(this.getAllKeys());
    }
}
exports.LocalStorageStore = LocalStorageStore;
