import React from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { i18nKey } from "../types/i18next"
import Spacer from "./spacer"
import Text from "./text"

interface TitleGroupProps {
  titleNumberOfLines?: number
  titleColor?: string
  data?: {
    title: string
    subtitle?: string
    displayTypeStringKey?: i18nKey
  }
}

/**
 * Renders a square thumbnail with a title and subtitle(s) below it.
 */
export default function ThumbnailWithTitle({
  titleNumberOfLines,
  titleColor,
  data,
}: TitleGroupProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <View>
      <Text
        textStyle="headingSmall"
        numberOfLines={titleNumberOfLines || 1}
        loadingWidth={300}
        loading={data === undefined}
        color={titleColor}
      >
        {data?.title}
      </Text>
      {data === undefined || data?.subtitle ? (
        <>
          <Spacer size="xtiny" />
          <Text
            textStyle="captionStrong"
            color="secondary"
            loadingWidth={140}
            numberOfLines={1}
            loading={data === undefined}
          >
            {data?.subtitle}
          </Text>
        </>
      ) : null}
      {data === undefined || data?.displayTypeStringKey ? (
        <>
          <Spacer size="tiny" />
          <Text
            textStyle="caption"
            color="secondary"
            loading={data === undefined}
            loadingWidth={100}
          >
            {data?.displayTypeStringKey ? t(data?.displayTypeStringKey) : null}
          </Text>
        </>
      ) : null}
    </View>
  )
}
