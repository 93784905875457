import { useCallback, useState } from "react"

/**
 * Like setState, but instead of returning a generic set function it returns a
 * setTrue and a setFalse function. Helpful for managing common state patterns
 * like opened/closed, on/off, expanded/collapsed, etc.
 */
export function useBooleanState(
  defaultValue = false,
): [value: boolean, setTrue: () => void, setFalse: () => void] {
  const [value, setValue] = useState<boolean>(defaultValue)
  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  return [value, setTrue, setFalse]
}
