import { DefaultTheme } from "styled-components/native"
import tokens from "@treefort/tokens/app"

type BorderRadiusToken = keyof typeof tokens.borderRadius

export type BorderRadius = keyof typeof tokens.borderRadius | number

export const borderRadiusToNumber = <Value extends BorderRadius | undefined>(
  tokens: DefaultTheme,
  borderRadius: Value,
): Value extends undefined ? undefined : number =>
  (typeof borderRadius === "string" && borderRadius in tokens.borderRadius
    ? tokens.borderRadius[borderRadius as BorderRadiusToken]
    : typeof borderRadius === "number"
    ? borderRadius
    : undefined) as Value extends undefined ? undefined : number
