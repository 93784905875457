import { useCallback, useMemo } from "react"

import { useTokens } from "../../components/tokens-provider"
import {
  getHomeTab,
  getPathFromTab,
  Params,
  SEARCH_TAB,
} from "../../navigation/routes"
import useAppManifest from "../use-app-manifest"
import { useHistory } from "../use-history"
import { useNavigate } from "../use-navigate"

export function useSearch(): {
  open: (params?: Params) => void
  close: () => void
} {
  const manifest = useAppManifest()
  const history = useHistory()
  const navigate = useNavigate()
  const { tokens } = useTokens()

  const open = useCallback(
    (params?: Params) => {
      navigate(
        tokens.appHeader.mode === "desktop"
          ? getPathFromTab(SEARCH_TAB)
          : "/search",
        params,
      )
    },
    [navigate, tokens.appHeader.mode],
  )

  const close = useCallback(() => {
    if (history.canGoBack()) {
      history.goBack()
    } else {
      navigate(getPathFromTab(getHomeTab(manifest)))
    }
  }, [history, navigate, manifest])

  return useMemo(() => ({ open, close }), [open, close])
}
