import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import AsyncView, { AsyncViewProps } from "../../../components/async-view"
import { AsyncViewOfflineProvider } from "../../../components/async-view-offline-provider"
import Box from "../../../components/box"
import CenteredContent from "../../../components/centered-content"
import { Heading } from "../../../components/heading"
import Row from "../../../components/row"
import Spacer, { Spacing } from "../../../components/spacer"
import { useRoute } from "../../../hooks/use-route"
import useSafeAreaInsetAppHeader from "../../../hooks/use-safe-area-inset-app-header"
import { i18nKey } from "../../../types/i18next"
import { MENU_TAB, getPathFromTab } from "../../routes"
import PageLayout from "../page"
import { MenuBackButton } from "./back-button"
import { MENU_CONTENT_MAX_WIDTH_PX } from "./utils"

function MenuLayoutDesktop({
  children,
  asyncViewProps,
  backgroundColor = "tertiary",
  paddingHorizontal = "jumbo",
}: {
  children?: ReactNode
  asyncViewProps?: AsyncViewProps & { offlineStateDisabled?: boolean }
  backgroundColor?: string
  paddingHorizontal?: Spacing
}): JSX.Element {
  const headerHeight = useSafeAreaInsetAppHeader()
  const route = useRoute()
  const { t } = useTranslation()
  const title = route.title ? t(route.title as i18nKey) : undefined
  return (
    <PageLayout backgroundColor={backgroundColor}>
      <Spacer size={headerHeight} />
      <CenteredContent maxWidth={MENU_CONTENT_MAX_WIDTH_PX} padding="jumbo">
        <Box
          backgroundColor="primary"
          paddingTop="xlarge"
          paddingBottom="jumbo"
          paddingHorizontal={paddingHorizontal}
          borderRadius="roundedLarge"
        >
          {title ? (
            <Row
              paddingBottom="xlarge"
              alignItems="center"
              justifyContent="center"
              gap="medium"
            >
              {route.path !== getPathFromTab(MENU_TAB) ? (
                <StyledMenuBackButton />
              ) : null}
              <Heading level={2} textStyle="headingLarge">
                {title}
              </Heading>
            </Row>
          ) : (
            <Spacer size="small" />
          )}
          {asyncViewProps ? (
            <AsyncViewOfflineProvider {...asyncViewProps}>
              <AsyncView>{children}</AsyncView>
            </AsyncViewOfflineProvider>
          ) : (
            children
          )}
        </Box>
      </CenteredContent>
    </PageLayout>
  )
}

const StyledMenuBackButton = styled(MenuBackButton)`
  position: absolute;
  left: 0;
`

export default MenuLayoutDesktop
