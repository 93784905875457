import { useCallback, useEffect } from "react"
import { history } from "../../lib/history.web"

type History = { canGoBack: () => boolean; goBack: () => void }

let historyStackDepth = 0

export function useHistory(): History {
  const canGoBack = useCallback(() => historyStackDepth > 0, [])
  const goBack = useCallback(() => canGoBack() && history.goBack(), [canGoBack])

  useEffect(
    () =>
      history.listen((_location, action) => {
        if (action === "POP" && historyStackDepth > 0) {
          historyStackDepth--
        } else if (action === "PUSH") {
          historyStackDepth++
        }
      }),
    [],
  )

  return { canGoBack, goBack }
}
