import React from "react"

import { t } from "i18next"

import { AppLink } from "@treefort/api-spec"

import BannerItem, { getBannerItemHeight } from "../banner-item"
import Box from "../box"
import Spacer from "../spacer"
import TokensProvider, { ResolvedTokens, useTokens } from "../tokens-provider"
import { SliderModule, getSliderDimensions, SliderProps } from "./slider"

type FeatureProps = Pick<
  SliderProps,
  | "items"
  | "itemShape"
  | "showItemTitles"
  | "showItemDates"
  | "scrollRestorationKey"
> &
  Parameters<typeof BannerItem>[0]["bannerItem"] & {
    bannerItemHeight: number
  }

export { getBannerItemHeight }

export const getFeatureHeight = ({
  tokens,
  bannerItemHeight,
  itemShape,
  preferredShowItemTitles,
  preferredShowItemDates,
}: {
  tokens: ResolvedTokens
  bannerItemHeight: number
  itemShape?: FeatureProps["itemShape"]
  preferredShowItemTitles?: FeatureProps["showItemTitles"]
  preferredShowItemDates?: FeatureProps["showItemDates"]
}): number => {
  const { height: sliderHeight } = getSliderDimensions({
    tokens,
    itemShape,
    preferredShowItemTitles,
    preferredShowItemDates,
  })
  const sliderMarginVertical = tokens.feature.sliderMarginVertical * 2
  return bannerItemHeight + sliderHeight + sliderMarginVertical
}

export function FeatureModule({
  items,
  actionLink,
  itemShape = "square",
  showItemTitles,
  showItemDates,
  bannerItemHeight,
  scrollRestorationKey,
  ...bannerItem
}: FeatureProps): JSX.Element {
  const { tokens } = useTokens()

  // If items is a collection, videoSeries, or podcast, then create a default app link
  // for use in defaultActionLink.
  const defaultActionLinkAppLink: AppLink | undefined =
    items.type === "collection"
      ? {
          type: "collection",
          collectionId: items.collectionId,
        }
      : items.type === "videoSeries" || items.type === "podcast"
      ? {
          type: "content",
          contentType: items.type,
          contentId: items.contentId,
        }
      : undefined

  const defaultActionLink: FeatureProps["actionLink"] | undefined =
    defaultActionLinkAppLink
      ? {
          title: t("See All"),
          link: defaultActionLinkAppLink,
        }
      : undefined

  return (
    <TokensProvider displayMode="dark">
      <Box backgroundColor={tokens.feature.backgroundColor}>
        <BannerItem
          height={bannerItemHeight}
          bannerItem={{
            ...bannerItem,
            actionLink: actionLink || defaultActionLink,
          }}
          backgroundColor={tokens.feature.backgroundColor}
        />
        <Spacer size={tokens.feature.sliderMarginVertical} />
        <SliderModule
          scrollRestorationKey={scrollRestorationKey}
          items={items}
          itemShape={itemShape}
          showItemTitles={showItemTitles}
          showItemDates={showItemDates}
        />
        <Spacer size={tokens.feature.sliderMarginVertical} />
      </Box>
    </TokensProvider>
  )
}
