import { UseQueryOptions, useQuery } from "react-query"

import { GiftUpCheckoutLinkResponse } from "@treefort/api-spec"

import api from "../../lib/api"

export function useGiftUpCheckoutLink(
  options?: UseQueryOptions<
    string | null,
    unknown,
    string | null,
    ["gift-up-checkout-url"]
  >,
) {
  return useQuery(
    ["gift-up-checkout-url"],
    () =>
      api
        .get<GiftUpCheckoutLinkResponse>("/integrations/gift-up/checkout-link")
        .then((res) => res.data.checkoutLink),
    options,
  )
}
