import { Platform } from "react-native"

import axios from "axios"
import { stringify } from "query-string"

import {
  AppManifest,
  AppPage,
  AppModule,
  AppManifestStatus,
} from "@treefort/api-spec"
import { isPlatformsConditionMet } from "@treefort/lib/conditions"

import config from "../config"
import { debug } from "./logging"
import { Store } from "./store"

export type { AppManifest }

export const APP_MANIFEST_VERSION = "5"

const STORE_KEY_LOCAL = `local.${config.ENV}.${APP_MANIFEST_VERSION}`

// Bump this constant to reset the cache and ensure the manifest will be
// re-fetched accross all clients. Helpful when adding new fields to a manifest
// that new clients require.
const RESET_CACHE = 16

export const appManifestDebug = debug.extend("manifest")

const store = new Store({
  key: "appManifest",
  migrations: [
    {
      name: `reset:${RESET_CACHE}`,
      migrate: (store) => store.clear(),
    },
  ],
})

/**
 * Fetch the manifest from the server.
 */
export const getRemoteAppManifest = async <TStatus extends AppManifestStatus>({
  status,
  publishedAfter,
  appPreviewToken,
}: {
  status: TStatus
  publishedAfter?: TStatus extends "draft" ? never : string
  appPreviewToken?: TStatus extends "draft" ? string : never
}): Promise<
  TStatus extends "draft" ? AppManifest : AppManifest | undefined
> => {
  appManifestDebug("Fetching manifest from API")

  // NOTE: Don't use our `api` axios extension because we don't want manifest
  // requests to get caught up in our regular authentication logic as that could
  // unnecessarily slow them down.
  const { data: manifest } = await axios.get<
    TStatus extends "draft" ? AppManifest : AppManifest | undefined
  >(
    `/apps/${config.APP_ID}/manifest/${status}?${stringify({ publishedAfter })}`,
    {
      baseURL: config.API_ENDPOINT,
      headers: {
        "X-Treefort-Tenant": config.TENANT_ID,
        "X-Treefort-Version": config.API_VERSION,
        "X-Treefort-Client": config.CLIENT_ID,
        "X-Treefort-App-Version": config.APP_VERSION,
        ...(appPreviewToken
          ? { "X-Treefort-App-Preview-Token": appPreviewToken }
          : null),
      },
    },
  )

  return manifest
}

/**
 * Fetch the manifest from the local store.
 */
export const getLocalAppManifest = (): Promise<AppManifest | null> => {
  appManifestDebug("Getting manifest from local store")
  return store.get<AppManifest>(STORE_KEY_LOCAL)
}

/**
 * Save the manifest locally for fast access.
 */

export const setLocalAppManifest = (manifest: AppManifest): Promise<void> => {
  appManifestDebug("Saving manifest to local store")
  return store.set(STORE_KEY_LOCAL, manifest)
}

/**
 * Extract a page object from a manifest
 */
export const getPage = (
  pageId: number,
  manifest: AppManifest,
): AppPage | undefined => manifest.pages.find((page) => page.id === pageId)

/**
 * Get all the modules that should be rendered for a page
 */
export const getPageModules = (
  page: AppPage,
  manifest: AppManifest,
): AppModule[] =>
  page.moduleIds
    .map((moduleId) =>
      manifest.modules.find((module) => module.id === moduleId),
    )
    .filter((module?: AppModule): module is AppModule =>
      Boolean(
        module && isPlatformsConditionMet(module.conditions, Platform.OS),
      ),
    )
