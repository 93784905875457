import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import { SubscriptionPlanInterval } from "@treefort/api-spec"

import ButtonView from "./button-view"
import { SIGN_UP_CARD_MAX_WIDTH_PX } from "./sign-up-option-card"
import Text from "./text"
import { DisplayMode, useTokens } from "./tokens-provider"
import Touchable from "./touchable"

export type BillingInterval = SubscriptionPlanInterval | "all"

const Track = styled(Touchable)`
  background-color: ${({ theme }) =>
    theme.billingIntervalToggle.track.backgroundColor};
  width: 100%;
  max-width: ${SIGN_UP_CARD_MAX_WIDTH_PX}px;
  flex-direction: row;
  border-radius: ${({ theme }) =>
    theme.billingIntervalToggle.track.borderRadius}px;
`

const StyledButtonView = styled(ButtonView)<{
  isSelected: boolean
  displayMode: DisplayMode
}>`
  background-color: ${({ theme, isSelected }) =>
    theme.billingIntervalToggle.toggleOption.backgroundColor[
      isSelected ? "selected" : "default"
    ]};
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  box-shadow: ${({ theme, isSelected }) =>
    theme.billingIntervalToggle.toggleOption.boxShadow[
      isSelected ? "selected" : "default"
    ]};
`

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export function BillingIntervalToggle({
  value,
  onChange,
}: {
  value: "month" | "year"
  onChange: (value: BillingInterval) => unknown
}) {
  const { tokens, displayMode } = useTokens()
  const { t } = useTranslation()
  return (
    <Track onPress={() => onChange(value === "year" ? "month" : "year")}>
      <StyledButtonView
        isSelected={value === "month"}
        displayMode={displayMode}
      >
        <StyledText
          textStyle="button"
          color={
            tokens.billingIntervalToggle.toggleOption.titleColor[
              value === "month" ? "selected" : "default"
            ]
          }
        >
          {t("Monthly")}
        </StyledText>
      </StyledButtonView>
      <StyledButtonView isSelected={value === "year"} displayMode={displayMode}>
        <StyledText
          textStyle="button"
          color={
            tokens.billingIntervalToggle.toggleOption.titleColor[
              value === "year" ? "selected" : "default"
            ]
          }
        >
          {t("Yearly")}
        </StyledText>
      </StyledButtonView>
    </Track>
  )
}
