import kebabCase from "@treefort/lib/kebab-case"
import unitless from "@emotion/unitless"

// @emotion/unitless is meant for the web so we need to tweak it a tad for
// react-native
const reactNativeUnitless = { ...unitless }
delete reactNativeUnitless.lineHeight

/**
 * Turns an object of styles into a CSS-style string of styles. Probably not the
 * fastest thing in the world, so use sparingly.
 */
const styleObjectToString = (styles: Record<string, unknown>): string =>
  Object.keys(styles)
    .filter(
      (key) =>
        styles[key] !== undefined && styles[key] !== null && styles[key] !== "",
    )
    .map((key) => {
      const value = styles[key]
      const needsUnits =
        typeof value === "number" &&
        value !== 0 &&
        !(key in reactNativeUnitless)
      return `${kebabCase(key)}: ${value}${needsUnits ? "px" : ""}`
    })
    .join(";\n")

export default styleObjectToString
