import React from "react"

import { AppModuleAlbum } from "@treefort/api-spec"

import { AlbumModule } from "../../../../components/modules/album"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleAlbum["config"]

export class AlbumListViewModule
  implements ListViewModule<AppModuleAlbum, Item>
{
  readonly appModule: AppModuleAlbum
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = undefined
  readonly gapSize = 36
  readonly maxWidth = 1200

  constructor(appModule: AppModuleAlbum) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <AlbumModule
      {...args.item}
      paddingTop={args.safeAreaInsets.top}
      maxWidth={args.maxWidth}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id
}
