"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWillUnmount = void 0;
const react_1 = require("react");
// DEPRECATED: This is no longer needed for our use-cases. Don't use it, remove
// usage of it when you come across it.
function useWillUnmount() {
    const willUnmount = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        willUnmount.current = false;
        return () => {
            willUnmount.current = true;
        };
    }, []);
    return willUnmount;
}
exports.useWillUnmount = useWillUnmount;
