import React, { ReactNode } from "react"
import Box from "./box"
import Text from "./text"
import Column from "./column"

export default function SettingsFieldset({
  children,
  title,
  id,
}: {
  children: ReactNode
  title: string
  id?: string
}): JSX.Element {
  return (
    <Column id={id} gap="tiny" alignItems="stretch">
      <Text textStyle="headingSmall">{title}</Text>
      <Box backgroundColor="tertiary" borderRadius="rounded">
        {children}
      </Box>
    </Column>
  )
}
