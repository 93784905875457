import React from "react"
import { Result } from "@treefort/lib/result"
import { useRoute } from "../../hooks/use-route"
import useContent from "../../hooks/use-content"
import { PodcastListViewModule } from "../layouts/module/list-view-modules/podcast"
import { ModuleLayout } from "../layouts/module"
import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"

export function ContentPodcastScreen(): JSX.Element {
  const { params } = useRoute<"contentId">()

  if (!params.contentId) {
    throw new Error("Visited the podcast screen without a content id!")
  }

  const contentId = parseInt(params.contentId)

  const podcast = useContent(contentId, "podcast")

  const listViewModules = Result.mapSuccess(podcast, (podcast) =>
    Result.success([
      new PodcastListViewModule(
        { id: 0, type: "podcast", config: { contentId }, conditions: {} },
        podcast,
      ),
    ]),
  )

  const key = `podcast-page-${contentId}`

  return (
    <AsyncViewOfflineProvider
      key={key}
      {...useAsyncViewPropsForQueries(podcast, {
        forceLoading: listViewModules.isLoading,
      })}
    >
      <ModuleLayout pageKey={key} listViewModules={listViewModules} />
    </AsyncViewOfflineProvider>
  )
}
