import React, { ComponentProps } from "react"

import { Portal as GorhomPortal } from "@gorhom/portal"

/**
 * Wraps the Portal component from `@gorhom/portal` and uses "middleground" as the
 * default portal host
 */
export function Portal({
  hostName = "middleground",
  ...props
}: Omit<ComponentProps<typeof GorhomPortal>, "hostName"> & {
  hostName?: "foreground" | "middleground"
}) {
  return <GorhomPortal {...props} hostName={hostName} />
}
