/**
 * This is a stub that doesn't do anything. Deep link listening is a native-only
 * concept: on the web deep linking means just opening the app via a URL (in
 * which case react-native-web's implementation of Linking.getInitialURL is what
 * you want).
 */
const addDeepLinkListener = (_handler: (url: string) => void): (() => void) => {
  return () => {}
}

export default addDeepLinkListener
