import { createContext } from "react"

import { EventEmitter } from "@treefort/lib/event-emitter"
import isObject from "@treefort/lib/is-object"
import { Event } from "@treefort/lib/types/settings"

import { setCurrentProfileId } from "./logging"
import settings from "./settings"

export type ActiveProfile =
  | { state: "loading" }
  | { state: "unset" }
  | { state: "set"; id: string | null }

export type ProfilesOverlayScreen =
  | { name: "select" }
  | { name: "manage" }
  | { name: "add" }
  | { name: "edit"; params?: { profileId?: string } }

export enum ProfilesOverlayEvent {
  RequestOpen = "PROFILES_OVERLAY_OPEN",
  RequestClose = "PROFILES_OVERLAY_CLOSE",
  RequestBack = "PROFILES_OVERLAY_BACK",
  StateChanged = "PROFILES_OVERLAY_STATE_CHANGED",
}

interface ProfilesOverlayEventMap {
  [ProfilesOverlayEvent.RequestOpen]: { screen: ProfilesOverlayScreen }
  [ProfilesOverlayEvent.RequestClose]: undefined
  [ProfilesOverlayEvent.RequestBack]: undefined
  [ProfilesOverlayEvent.StateChanged]: { open: boolean }
}

const ACTIVE_PROFILE_SETTING_KEY = "activeProfile"

export const ActiveProfileContext = createContext<ActiveProfile>({
  state: "loading",
})

class ProfilesOverlay extends EventEmitter<ProfilesOverlayEventMap> {
  private state = { open: false }

  public requestOpen = (event: { screen: ProfilesOverlayScreen }) =>
    this.emitter.emit(ProfilesOverlayEvent.RequestOpen, event)

  public requestClose = () =>
    this.emitter.emit(ProfilesOverlayEvent.RequestClose)

  public requestBack = () => this.emitter.emit(ProfilesOverlayEvent.RequestBack)

  public setState = async (state: { open: boolean }) => {
    if (state.open !== this.state.open) {
      this.state.open = state.open
      await this.emitter.emit(ProfilesOverlayEvent.StateChanged, this.state)
    }
  }

  public getState = () => this.state
}

export const profilesOverlay = new ProfilesOverlay()

export async function getActiveProfile() {
  return settings
    .getLocal(ACTIVE_PROFILE_SETTING_KEY, { profileId: null })
    .then(getActiveProfileFromSetting)
}

export async function setActiveProfileId(profileId: string | null) {
  await settings.saveLocal(
    ACTIVE_PROFILE_SETTING_KEY,
    { id: profileId },
    { profileId: null },
  )
}

export async function clearActiveProfile() {
  await settings.clearLocal(ACTIVE_PROFILE_SETTING_KEY, { profileId: null })
}

/**
 * Listen for changes to the active profile. When the listener is added it will
 * be called right away with the current active profile, and then again any time
 * the active profile changes.
 */
export function addActiveProfileListener(
  callback: (profile: ActiveProfile) => unknown,
) {
  getActiveProfile().then(callback)
  return settings.on(Event.LocalSettingUpdated, (setting) => {
    if (setting.key === ACTIVE_PROFILE_SETTING_KEY) {
      callback(getActiveProfileFromSetting(setting))
    }
  })
}

function getActiveProfileFromSetting(setting: { value: unknown } | undefined) {
  return setting?.value &&
    isObject(setting.value) &&
    (typeof setting.value.id === "string" || setting.value.id === null)
    ? ({ state: "set", id: setting.value.id } as const)
    : ({ state: "unset" } as const)
}

// When the user profile changes, update Sentry context
addActiveProfileListener((profile) =>
  setCurrentProfileId(profile.state === "set" ? profile.id : null),
)
