import React, { ComponentProps } from "react"
import { KeyboardAvoidingView as RnKeyboardAvoidingView } from "react-native"

/**
 * Wraps React Native's KeyboardAvoidingView with the goal of helping us create
 * a more consistent cross-platform experience. On Android and the web children
 * are always rendered in a fragment since the keyboard avoiding functionality
 * is unecessary on those platforms
 */
export function KeyboardAvoidingView(
  props: Omit<ComponentProps<typeof RnKeyboardAvoidingView>, "behavior"> & {
    behavior: "height" | "position" | "padding" | "disabled"
    nested?: boolean
  },
) {
  return <>{props.children}</>
}
