import { MediaEpubSection } from "@treefort/api-spec"

/**
 * Turn nested table of contents data into a flat list of options for our Select
 * component.
 */
export function getTableOfContentsOptions(
  tableOfContents: MediaEpubSection[],
  depth = 0,
): Array<{ label: string; value: string }> {
  return tableOfContents.flatMap((section) => [
    {
      label: section.label,
      value: section.id,
      depth,
    },
    ...(section.subitems
      ? getTableOfContentsOptions(section.subitems, depth + 1)
      : []),
  ])
}

/**
 * Find a section by ID in nested table of contents data.
 */
export function findTableOfContentsSection(
  tableOfContents: MediaEpubSection[],
  sectionId: string | undefined,
) {
  return sectionId
    ? tableOfContents
        .flatMap((section) => [section, ...(section.subitems || [])])
        .find((section) => section.id === sectionId)
    : undefined
}
