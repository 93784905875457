import React, { useCallback, useState } from "react"

import styled from "styled-components/native"

import Image, { ImageProps as WebImageProps } from "../image/index.web"
import { FADE_DURATION, FADE_DELAY } from "./base"

export type ImageWithFadeProps = WebImageProps

const Container = styled.View`
  overflow: hidden;
`

const AnimatedImage = styled(Image)<{ loaded: boolean }>`
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity ${FADE_DURATION}ms ease;
  width: 100%;
  height: 100%;
`

/**
 * Render an image that will fade in when loaded.
 *
 * NOTE: This component will not automatically match the aspect ratio of the
 * source image. Apply width, height, or aspect ratio styles for best results.
 */
export function ImageWithFade({
  onLoad,
  style,
  ...props
}: ImageWithFadeProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)

  const onImageLoad = useCallback<NonNullable<ImageWithFadeProps["onLoad"]>>(
    (event) => {
      setTimeout(() => setLoaded(true), FADE_DELAY)
      onLoad?.(event)
    },
    [onLoad],
  )

  return (
    <Container style={style}>
      <AnimatedImage {...props} onLoad={onImageLoad} loaded={loaded} />
    </Container>
  )
}
