import { useAuth } from "@treefort/lib/auth-provider"
import { User } from "@treefort/lib/authenticator"
import { QueryKey } from "react-query"

export const getQueryKey = (user: User | null, key: unknown): QueryKey => {
  const roles = user?.roles ? [...user.roles].sort() : []
  return [user?.id, roles, key]
}

/**
 * Return a key that can be used to cache query results with react-query. The
 * value-add is that this automatically adds the current user's id and roles (if
 * authenticated) so that the query will update correctly when the user signs
 * in, signs out, subscribes, or loses their subscription access.
 */
const useQueryKey = (key: unknown): QueryKey => {
  const auth = useAuth()
  return getQueryKey(auth.user, key)
}

export default useQueryKey
