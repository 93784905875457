"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounceAnimationFrame = exports.triggerAndWaitForEvent = exports.addEventListeners = exports.addEventListener = exports.isEventTargetElement = void 0;
const trigger_and_wait_for_event_1 = require("./trigger-and-wait-for-event");
/**
 * Returns true if the provided event target is an element
 */
const isEventTargetElement = (target) => (target ? "tagName" in target : false);
exports.isEventTargetElement = isEventTargetElement;
/**
 * Add an event listener to an element and return a cleanup function.
 */
const addEventListener = (element, eventName, handler, options) => {
    element.addEventListener(eventName, handler, options);
    return () => element.removeEventListener(eventName, handler, options);
};
exports.addEventListener = addEventListener;
/**
 * Add multiple event listeners to an element using an object of handlers keyed
 * by event names. A cleanup function is returned.
 */
const addEventListeners = (element, handlers, options) => {
    const removeListenerFunctions = Object.keys(handlers).map((eventName) => (0, exports.addEventListener)(element, eventName, handlers[eventName], options));
    return () => removeListenerFunctions.forEach((removeListener) => removeListener());
};
exports.addEventListeners = addEventListeners;
/**
 * Run a "triggering" function that is expected to result in an event on an
 * element and then wait for that event to be fired. The event is returned. If
 * the event is never fired then this function will pend forever, so have a
 * cancellation strategy in place.
 */
function triggerAndWaitForEvent(trigger, element, eventName) {
    return (0, trigger_and_wait_for_event_1.triggerAndWaitForEvent)({
        trigger,
        addEventListener: (listener) => (0, exports.addEventListener)(element, eventName, listener),
    });
}
exports.triggerAndWaitForEvent = triggerAndWaitForEvent;
function debounceAnimationFrame(callback) {
    let animationFrame;
    const cancel = () => window.cancelAnimationFrame(animationFrame);
    const debounced = (...args) => {
        cancel();
        animationFrame = window.requestAnimationFrame(() => callback(...args));
    };
    debounced.cancel = cancel;
    return debounced;
}
exports.debounceAnimationFrame = debounceAnimationFrame;
