import i18next, { t } from "i18next"

import {
  StripePromoCodeResponse,
  WebPaymentPromoCodeResponse,
} from "@treefort/api-spec"
import { formatMoney, getCurrencyDivisor } from "@treefort/lib/money"

export const getPromoCodeDiscountMessage = (
  code: StripePromoCodeResponse | WebPaymentPromoCodeResponse,
): string => {
  const firstTimeOnly = code.type !== "invalid" && code.firstTimeSubscribersOnly
  switch (code.type) {
    case "percent":
      return t(
        firstTimeOnly
          ? "{{percentZeroToOne}} off at checkout for new subscribers"
          : "{{percentZeroToOne}} off at checkout",
        { percentZeroToOne: code.percentOff / 100 },
      )
    case "amount":
      return t(
        firstTimeOnly
          ? "{{formattedAmount}} off at checkout for new subscribers"
          : "{{formattedAmount}} off at checkout",
        {
          formattedAmount: formatMoney(
            {
              amount: code.amountOff,
              currency: code.currency,
              divisor: getCurrencyDivisor(code.currency),
            },
            i18next.language,
          ),
        },
      )
    case "invalid":
      return t("Invalid promo code.")
  }
}
