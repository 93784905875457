import { useCallback, useEffect, useState } from "react"

import { Store, StoreEvent } from "../lib/store"

type Options<T> = {
  store: Store
  key: string
  // This cannot be changed after the hook is first called.
  shouldUpdate?: (prevValue: T | null, nextValue: T | null) => boolean
  // This cannot be changed after the hook is first called.
  defaultValue?: T
}

type OptionsWithDefaultValue<T> = Omit<
  Options<T>,
  "shouldUpdate" | "defaultValue"
> & {
  shouldUpdate?: (prevValue: T, nextValue: T) => boolean
  defaultValue: T
}

type UseStoreItemHookReturn<T> = [T, (value: T | null) => Promise<void>]

/**
 * Subscribe to a value from a store. If undefined is returned then the value is
 * being loaded. If null is returned then the value is empty. If a value is
 * returned then the value was loaded _or_ a default value was provided.
 */
export function useStoreItem<T = unknown>(
  options: OptionsWithDefaultValue<T>,
): UseStoreItemHookReturn<T>
export function useStoreItem<T>(
  options: Options<T>,
): UseStoreItemHookReturn<T | null | undefined>
export function useStoreItem<T>({
  store,
  key,
  shouldUpdate,
  defaultValue,
}: Options<T>): UseStoreItemHookReturn<T | null | undefined> {
  const [localValue, setLocalValue] = useState<T | null | undefined>(
    defaultValue,
  )

  // Update the store
  const updateStoreValue = useCallback(
    (value: T | null | undefined) => store.set(key, value),
    [store, key],
  )

  // Update the local value when the store changes
  useEffect(
    () => {
      function updateLocalValue(nextValue: T | null) {
        setLocalValue((prevValue) => {
          if (
            !shouldUpdate ||
            shouldUpdate(
              prevValue ?? defaultValue ?? null,
              nextValue ?? defaultValue ?? null,
            )
          ) {
            return nextValue
          } else {
            return prevValue
          }
        })
      }

      const removeListener = store.on(StoreEvent.ItemUpdated, (item) => {
        if (item.key === key) {
          updateLocalValue(item.value as T | null)
        }
      })

      store.get<T>(key).then(updateLocalValue)

      return removeListener
    },
    // Omit shouldUpdate and defaultValue (see docs for those options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store, key],
  )

  return [
    defaultValue ? localValue ?? defaultValue : localValue,
    updateStoreValue,
  ]
}
