import React, { useEffect, useState, useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import { useAuth } from "@treefort/lib/auth-provider"
import { DisplayableError } from "@treefort/lib/displayable-error"
import getNameOfUser from "@treefort/lib/get-name-of-user"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

import { Button } from "../../../components/button"
import { ButtonType, ButtonViewProps } from "../../../components/button-view"
import TextInput, { TextInputRef } from "../../../components/form-text-input"
import { KeyboardAvoidingView } from "../../../components/keyboard-avoiding-view"
import Spacer from "../../../components/spacer"
import { TouchableProps } from "../../../components/touchable"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import useQueryKey from "../../../hooks/use-query-key"
import useUserInfo from "../../../hooks/use-user-info"
import { sendContactEmail, getContactMetadata } from "../../../lib/contact"
import { logError } from "../../../lib/logging"
import { toast } from "../../../lib/toaster"
import MenuLayout from "../../layouts/menu"

export type { ButtonType }

export type ButtonProps = Omit<TouchableProps, "feedback"> & ButtonViewProps

export default function ContactForm(): JSX.Element {
  const willUnmount = useWillUnmount()
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [address, setAddress] = useState("")
  const [name, setName] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const auth = useAuth()
  const userInfo = useUserInfo()
  const contactMetadata = useQuery(
    useQueryKey("contact-metadata"),
    () => getContactMetadata({ user: userInfo.data }),
    { enabled: !auth.user || userInfo.isSuccess },
  )

  const messageRef = useRef<TextInputRef & { clear: () => void }>(null)
  const subjectRef = useRef<TextInputRef & { clear: () => void }>(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (auth.user) {
      setAddress(auth.user.email)
    }
  }, [auth.user])

  useEffect(() => {
    if (userInfo.data) {
      setName(getNameOfUser(userInfo.data) || "")
    }
  }, [userInfo.data])

  const handleSubmit = useCallback(async () => {
    if (!address) {
      toast.error(t("Please enter your email address."))
    } else if (!auth.user && !address.includes("@")) {
      toast.error(t("Please enter a valid email address."))
    } else if (!name) {
      toast.error(t("Please enter your name."))
    } else if (!message) {
      toast.error(t("Please enter a message."))
    } else {
      setIsSuccess(false)
      setIsSending(true)
      try {
        // This shouldn't happen because the form isn't shown until metadata is
        // loaded.
        if (!contactMetadata.isSuccess) {
          throw new Error(
            "Tried to submit contact form but metadata wasn't loaded",
          )
        }
        await sendContactEmail({
          replyTo: address.trim(),
          subject: subject.trim() || "Contact",
          text: message.trim(),
          name: name.trim(),
          metadata: contactMetadata.data.trim(),
        })
        if (!willUnmount.current) {
          setIsSuccess(true)
        }
      } catch (error) {
        logError(
          new DisplayableError(
            t("Sorry, your message couldn't be sent."),
            error,
          ),
        )
      } finally {
        if (!willUnmount.current) {
          setIsSending(false)
        }
      }
    }
  }, [
    subject,
    message,
    address,
    auth.user,
    name,
    contactMetadata.data,
    contactMetadata.isSuccess,
    willUnmount,
    t,
  ])

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("Success! Your message has been sent."))
      subjectRef.current && subjectRef.current.clear()
      messageRef.current && messageRef.current.clear()
      setSubject("")
      setMessage("")
    }
  }, [isSuccess, t])

  const asyncViewProps = useAsyncViewPropsForQueries(
    [userInfo, contactMetadata],
    { ignoreErrors: true },
  )

  const showEmailInput = !auth.user

  const showNameInput = !userInfo.isSuccess || !getNameOfUser(userInfo.data)

  return (
    <KeyboardAvoidingView nested behavior="padding">
      <MenuLayout asyncViewProps={asyncViewProps}>
        {showEmailInput ? (
          <>
            <TextInput
              onChangeText={setAddress}
              autoCapitalize="none"
              placeholder={t("Your email address")}
            />
            <Spacer size="large" />
          </>
        ) : null}
        {showNameInput ? (
          <>
            <TextInput onChangeText={setName} placeholder={t("Your name")} />
            <Spacer size="large" />
          </>
        ) : null}
        <TextInput
          ref={subjectRef}
          onChangeText={setSubject}
          placeholder={t("Subject (optional)")}
          maxLength={78}
        />
        <Spacer size="large" />
        <TextInput
          ref={messageRef}
          multiline
          onChangeText={setMessage}
          minHeight={120}
          placeholder={t("Message")}
        />
        <Spacer size="large" />
        <Button
          onPress={handleSubmit}
          loading={isSending}
          disabled={isSending}
          type="primary"
        >
          {t("Send")}
        </Button>
        <Spacer size="medium" />
      </MenuLayout>
    </KeyboardAvoidingView>
  )
}
