const confirm = ({
  title,
  message,
}: {
  title: string
  message: string
}): Promise<boolean> =>
  new Promise((resolve) => {
    if (window.confirm(`${title}\n\n${message}`)) {
      resolve(true)
    } else {
      resolve(false)
    }
  })

export default confirm
