import React from "react"
import { useTranslation } from "react-i18next"
import { ViewProps, Platform } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import FastToaster from "react-native-toast-notifications"
import { ToastProps as FastToastProps } from "react-native-toast-notifications/lib/typescript/toast"

import styled from "styled-components/native"

import useAppManifest from "../hooks/use-app-manifest"
import { useBottomTabsHeight } from "../hooks/use-bottom-tabs-height"
import { ToastType, setToaster, getToaster } from "../lib/toaster"
import IconButton from "./icon-button"
import Text from "./text"
import { useTokens } from "./tokens-provider"

export default function Toaster(): JSX.Element {
  const { tokens } = useTokens()
  const insets = useSafeAreaInsets()
  const bottomTabsHeight = useBottomTabsHeight()
  return (
    <FastToaster
      ref={setToaster}
      placement="bottom"
      duration={tokens.toaster.toast.duration}
      animationDuration={tokens.toaster.toast.animationDuration}
      offset={insets.bottom + bottomTabsHeight + tokens.spacing.medium}
      renderType={{
        // eslint-disable-next-line react/display-name
        error: (props: FastToastProps) => (
          <Toast {...props} toastType="error" />
        ),
        // eslint-disable-next-line react/display-name
        success: (props: FastToastProps) => (
          <Toast {...props} toastType="success" />
        ),
      }}
    />
  )
}

const CloseButton = styled(IconButton)`
  /* The close button should overlap with the toast's padding */
  margin: -${({ theme }) => theme.toaster.toast.padding}px;
  margin-left: 12px;
`

const ToastWrapper = styled.View<{ toastType: ToastType }>`
  margin-top: ${({ theme }) => theme.toaster.toast.margin}px;
  margin-right: ${({ theme }) => theme.toaster.toast.margin}px;
  margin-left: ${({ theme }) => theme.toaster.toast.margin}px;
  background-color: ${({ theme, toastType: toastType }) =>
    theme.toaster.toast.backgroundColor[toastType]};
  border-radius: ${({ theme }) => theme.borderRadius.rounded}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: ${({ theme }) => theme.toaster.toast.padding}px;
`

function Toast({
  message,
  ...props
}: FastToastProps & ViewProps & { toastType: ToastType }) {
  const manifest = useAppManifest()
  const { tokens } = useTokens()
  const { t } = useTranslation()

  return (
    <ToastWrapper {...props}>
      <Text textStyle="body" color={tokens.toaster.toast.textColor}>
        {message}
      </Text>
      {Platform.OS === "web" && (
        <CloseButton
          label={t("Close")}
          source={manifest.icons.close}
          iconSize="small"
          color={tokens.toaster.toast.textColor}
          onPress={() => getToaster()?.hide(props.id)}
        />
      )}
    </ToastWrapper>
  )
}
