import React, { useRef } from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import { AppModule } from "@treefort/api-spec"
import { Result, ResultAsync } from "@treefort/lib/result"

import { ListViewRef } from "../../../components/list-view"
import Spacer from "../../../components/spacer"
import { useTokens } from "../../../components/tokens-provider"
import PageLayout from "../page"
import { ItemArgs, ListViewModule } from "./list-view-module"

type ListViewItem = {
  listViewModule: ListViewModule<AppModule, unknown>
  itemArgs: ItemArgs<unknown>
}

/**
 * Takes in two list view items and, if the items represent a break between
 * modules, returns the largest gapSize configured by the two modules.
 */
const getGapSize = (itemBefore: ListViewItem, itemAfter: ListViewItem) =>
  itemBefore.listViewModule === itemAfter.listViewModule
    ? 0
    : Math.max(
        itemBefore.listViewModule.gapSize,
        itemAfter.listViewModule.gapSize,
      )

export function ModuleLayout({
  pageKey,
  listViewModules,
  children,
}: {
  // A key that uniquely identifies the layout in the app
  pageKey: string
  listViewModules: ResultAsync<ListViewModule<AppModule, unknown>[]>
  children?: (props: {
    listView: React.RefObject<ListViewRef<ListViewItem>>
  }) => JSX.Element | null
}): JSX.Element {
  const listView = useRef<ListViewRef<ListViewItem>>(null)
  const { tokens, displayHeight, displayWidth } = useTokens()
  const safeAreaInsets = useSafeAreaInsets()

  // The max width of the layout is the largest max width of any individual
  // module
  const maxWidth = listViewModules.data
    ?.map((listViewModule) => listViewModule.maxWidth)
    .reduce(
      (result, maxWidth) =>
        result !== undefined && maxWidth !== undefined
          ? Math.min(result, maxWidth)
          : result || maxWidth,
      undefined,
    )

  const safeAreaInsetTop =
    safeAreaInsets.top +
    (listViewModules.data?.[0]?.appHeaderState === "collapsed"
      ? tokens.appHeader.minHeight
      : tokens.appHeader.maxHeight)
  const safeAreaInsetsHeadItem = {
    ...safeAreaInsets,
    top: safeAreaInsetTop,
    bottom: 0,
  }
  const safeAreaInsetsTailItem = { ...safeAreaInsets, top: 0, bottom: 0 }

  const virtualizeable = listViewModules.data?.every(
    (listViewModule) => listViewModule.getItemSize !== undefined,
  )

  const listViewItems = Result.mapSuccess(listViewModules, (listViewModules) =>
    Result.success(
      listViewModules
        .flatMap((listViewModule) =>
          listViewModule.getItems().map((item, indexInModule) => ({
            item,
            indexInModule,
            listViewModule,
          })),
        )
        .map(({ item, indexInModule, listViewModule }, indexInPage, items) => ({
          listViewModule,
          itemArgs: {
            item,
            indexInModule,
            indexInPage,
            pageKey,
            tokens,
            displayHeight,
            displayWidth,
            maxWidth,
            safeAreaInsets:
              indexInPage === 0
                ? safeAreaInsetsHeadItem
                : safeAreaInsetsTailItem,
            itemCount: items.length,
          },
        })),
    ),
  )

  const paddingEnd =
    listViewItems.data?.[listViewItems.data.length - 1]?.listViewModule
      .gapSize || 0

  return (
    <PageLayout
      listViewProps={
        listViewItems.isSuccess && virtualizeable
          ? {
              ref: listView,
              renderItem: ({ listViewModule, itemArgs }) =>
                listViewModule.renderItem(itemArgs),
              getItemSize: ({ listViewModule, itemArgs }) =>
                listViewModule.getItemSize?.(itemArgs) || 0,
              getItemKey: ({ listViewModule, itemArgs }) =>
                listViewModule.getItemKey(itemArgs),
              items: listViewItems.data,
              getGapSize,
              paddingEnd,
              scrollBehavior: "smooth",
            }
          : undefined
      }
      headerPresentation={listViewModules.data?.[0]?.appHeaderPresentation}
      headerState={listViewModules.data?.[0]?.appHeaderState}
      hideDesktopFooter={listViewModules.data?.[0]?.hideDesktopFooter}
    >
      {listViewItems.isSuccess && !virtualizeable
        ? listViewItems.data.map((item) => {
            const { listViewModule, itemArgs } = item
            const itemBefore = listViewItems.data[itemArgs.indexInPage - 1]
            const gap = itemBefore ? getGapSize(itemBefore, item) : 0
            return (
              <React.Fragment key={listViewModule.getItemKey(itemArgs)}>
                {gap ? <Spacer size={gap} /> : null}
                {listViewModule.renderItem(itemArgs)}
              </React.Fragment>
            )
          })
        : null}
      {paddingEnd && !virtualizeable ? <Spacer size={paddingEnd} /> : null}
      {children?.({ listView })}
    </PageLayout>
  )
}
