import { SubscriptionPlanResponse } from "@treefort/api-spec"

import { canPurchasePlanFromProvider } from "../providers"
import { AvailableSubscriptionPlan } from "./base"

export type { AvailableSubscriptionPlan }

// async for parity with the native version of this function
export async function getAvailableSubscriptionPlans(
  allSubscriptionPlans: SubscriptionPlanResponse[],
): Promise<SubscriptionPlanResponse[]> {
  return allSubscriptionPlans.filter(
    (plan): plan is AvailableSubscriptionPlan =>
      canPurchasePlanFromProvider(plan.provider) && !plan.archivedAt,
  )
}
