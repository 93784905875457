import React, { useContext, useEffect, useMemo } from "react"
import { Animated, Keyboard } from "react-native"

import debounce from "lodash/debounce"

import { addEventListener, debounceAnimationFrame } from "@treefort/lib/dom"

import AsyncView, { AsyncViewContext } from "../../../components/async-view"
import Box from "../../../components/box"
import ListView from "../../../components/list-view"
import { isBodyScrollLocked } from "../../../hooks/use-lock-body-scroll"
import { useRoute } from "../../../hooks/use-route"
import { useCurrentRouteScrollOffset } from "../../../hooks/use-route-scroll-offset"
import useWindowDimensions from "../../../hooks/use-window-dimensions"
import { useNativeDriver } from "../../../lib/animation-use-native-driver"
import { history } from "../../../lib/history.web"
import {
  PageContainer,
  HeaderContainer,
  FooterContainer,
  ListViewProps,
  ScrollableLayoutProps,
  DEFAULT_SCROLL_EVENT_THROTTLE,
  DEFAULT_RENDER_AHEAD_OFFSET,
} from "./base"

export type { ListViewProps }

export type { ScrollableLayoutProps }

function ScrollableLayout<ListViewItem = unknown>({
  children,
  header,
  footer,
  headerHeight = 0,
  footerHeight = 0,
  contentContainerStyle,
  keyboardDismissMode,
  backgroundColor = "primary",
  listViewProps,
}: ScrollableLayoutProps<ListViewItem>): JSX.Element {
  const scrollOffset = useCurrentRouteScrollOffset()
  const asyncViewContext = useContext(AsyncViewContext)
  const route = useRoute()
  const windowDimensions = useWindowDimensions()

  // This scroll handler mimics ScrollView features such as keyboardDismissMode
  // and passing an Animated event to onScroll, but with the body element which
  // exists outside of React-land.
  const onScroll = useMemo(
    () =>
      debounceAnimationFrame(() => {
        if (!isBodyScrollLocked()) {
          // A bit of jerry-rigging to attach an Animated.event to the body's
          // scroll listener. See the corresponding native code for an example of
          // how this normally looks...
          Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: scrollOffset,
                  },
                },
              },
            ],
            { useNativeDriver },
          )({
            nativeEvent: {
              contentOffset: { y: document.documentElement.scrollTop },
            },
          })
        }
      }),
    [scrollOffset],
  )

  // The minHeight here is necessary to avoid an empty space below the
  // footer on iOS 15. This'll pick up whatever the innerHeight value is
  // at the time we render, which is what we want to solve this issue:
  // https://stackoverflow.com/questions/69355787/mobile-safari-15-bottom-navigation-area-issue
  const minHeight = window.innerHeight
  const style = useMemo(
    () => [{ minHeight }, contentContainerStyle],
    [minHeight, contentContainerStyle],
  )

  useEffect(() => {
    const removeEventListener = addEventListener(document, "scroll", onScroll)
    return () => {
      removeEventListener()
      onScroll.cancel()
    }
  }, [onScroll])

  useEffect(
    () =>
      history.listen((_location, action) => {
        // Reset the body scroll on push or replace actions (new page, new me).
        if (action === "PUSH" || action === "REPLACE") {
          document.documentElement.scrollTop = 0
          scrollOffset.setValue(0)
        }
      }),
    [scrollOffset],
  )

  // Any time the route changes we want to make sure our cached scroll offset is
  // in sync with the browser's actual scroll offset. This takes care of the
  // scenario where the page's content has changed since we were last there and
  // the browser cant't restore the scroll to the same position that we have
  // cached.
  useEffect(
    () => {
      scrollOffset.setValue(document.documentElement.scrollTop)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [route],
  )

  useEffect(() => {
    // Close the keyboard if we're supposed to dismiss it when the user
    // drags (copied from react-native-web's guts).
    if (keyboardDismissMode === "on-drag") {
      return addEventListener(
        document,
        "touchmove",
        debounce(() => Keyboard.dismiss(), 1000, { leading: true }),
      )
    }
  }, [keyboardDismissMode])

  return (
    <PageContainer backgroundColor={backgroundColor}>
      <HeaderContainer>{header}</HeaderContainer>
      {asyncViewContext.props.state !== undefined ? (
        <AsyncView
          contentContainerStyle={style}
          paddingTop={listViewProps ? undefined : headerHeight}
          paddingBottom={listViewProps ? undefined : footerHeight}
          indicatorPosition="fixed"
        >
          {listViewProps ? (
            <ListView
              windowScroll
              viewSize={windowDimensions}
              scrollEventThrottle={DEFAULT_SCROLL_EVENT_THROTTLE}
              renderAheadOffset={DEFAULT_RENDER_AHEAD_OFFSET}
              key={window.location.pathname}
              {...listViewProps}
              paddingStart={headerHeight + (listViewProps.paddingStart || 0)}
              paddingEnd={footerHeight + (listViewProps.paddingEnd || 0)}
            />
          ) : null}
          {children}
        </AsyncView>
      ) : listViewProps ? (
        <>
          <ListView
            contentContainerStyle={style}
            windowScroll
            viewSize={windowDimensions}
            scrollEventThrottle={DEFAULT_SCROLL_EVENT_THROTTLE}
            renderAheadOffset={DEFAULT_RENDER_AHEAD_OFFSET}
            key={window.location.pathname}
            {...listViewProps}
            paddingStart={headerHeight + (listViewProps.paddingStart || 0)}
            paddingEnd={footerHeight + (listViewProps.paddingEnd || 0)}
          />
          {children}
        </>
      ) : (
        <Box
          style={style}
          paddingTop={headerHeight}
          paddingBottom={footerHeight}
        >
          {children}
        </Box>
      )}
      <FooterContainer>{footer}</FooterContainer>
    </PageContainer>
  )
}

export default ScrollableLayout
