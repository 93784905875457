import { useEffect, useRef, useState } from "react"
import { Animated, Easing } from "react-native"

import { useTokens } from "../components/tokens-provider"
import { useNativeDriver } from "../lib/animation-use-native-driver"
import { startAnimation } from "../lib/start-animation"

export type OpenState = "open" | "opening" | "closing" | "closed"

/**
 * Helps animate the opening/closing of an element. Takes in the open state as a
 * simple boolean and returns a more granular open state as an enum along with
 * an animated value with a range from 0 (closed) to 1 (open).
 */
export function useOpenAnimation({
  open,
  duration = 500,
  onCloseComplete,
}: {
  open: boolean
  duration?: number
  onCloseComplete?: () => void
}): [
  openValue: Animated.Value,
  openState: "open" | "opening" | "closing" | "closed",
] {
  const { tokens } = useTokens()
  const [openState, setOpenState] = useState<
    "open" | "opening" | "closing" | "closed"
  >(open ? "open" : "closed")
  const prevOpen = useRef(open)
  const openValue = useRef(new Animated.Value(open ? 1 : 0))

  useEffect(() => {
    if (open !== prevOpen.current) {
      setOpenState(open ? "opening" : "closing")
      const animation = Animated.timing(openValue.current, {
        useNativeDriver,
        toValue: open ? 1 : 0,
        duration,
        easing: Easing.out(Easing.exp),
      })
      prevOpen.current = open

      // Note that `startAnimation` returns a cleanup function, but we don't
      // return that from the effect because we don't want a re-render of the
      // component invoking this hook to cause the animation to stop halfway
      // through.
      startAnimation(animation, () => {
        setOpenState(open ? "open" : "closed")
        if (!open) onCloseComplete?.()
      })
    }
  }, [tokens, open, onCloseComplete, duration])

  return [openValue.current, openState]
}
