import React from "react"

import { AppModuleWebEmbed } from "@treefort/api-spec"

import { WebEmbedModule } from "../../../../components/modules/web-embed"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleWebEmbed["config"]

export class WebEmbedListViewModule
  implements ListViewModule<AppModuleWebEmbed, Item>
{
  readonly appModule: AppModuleWebEmbed
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = "collapsed"
  readonly maxWidth = 960
  readonly gapSize
  readonly hideDesktopFooter

  constructor(appModule: AppModuleWebEmbed) {
    this.appModule = appModule
    this.hideDesktopFooter = appModule.config.display !== "inline"
    this.gapSize = appModule.config.display === "inline" ? 36 : 0
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <WebEmbedModule
      {...args.item}
      paddingTop={args.safeAreaInsets.top}
      maxWidth={args.maxWidth}
      presentation={args.itemCount > 1 ? "listItem" : "solo"}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id
}
