import React, { useEffect, useState } from "react"

import { useProfiles } from "../hooks/use-profiles"
import {
  ActiveProfile,
  ActiveProfileContext,
  addActiveProfileListener,
  clearActiveProfile,
} from "../lib/profiles"

export function ActiveProfileProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [activeProfile, setActiveProfile] = useState<ActiveProfile>({
    state: "loading",
  })
  const profilesQuery = useProfiles()

  // Load the current active profile and stay up to date with future updates
  useEffect(() => addActiveProfileListener(setActiveProfile), [])

  // Make sure the active profile is still available. If it is not, clear the
  // profile selection.
  const activeProfileId =
    activeProfile.state === "set" ? activeProfile.id : null
  useEffect(() => {
    if (activeProfileId !== null && profilesQuery.data) {
      const isValidProfile = profilesQuery.data.profiles.some(
        (profile) => profile.id === activeProfileId,
      )
      if (!isValidProfile) {
        clearActiveProfile()
      }
    }
  }, [activeProfileId, profilesQuery.data])

  return (
    <ActiveProfileContext.Provider value={activeProfile}>
      {children}
    </ActiveProfileContext.Provider>
  )
}
