import { SettingStoreItem } from "@treefort/lib/settings"

import { settingsStore } from "../lib/settings"
import { Store } from "../lib/store"
import { useStoreItem } from "./use-store-item"

const ASK_BEFORE_USING_CELLULAR_KEY = "askBeforeUsingCellular"

const store = new Store({
  key: "networkSettings",
  migrations: [
    {
      name: "seedFromSettings",
      migrate: async (store) => {
        const setting = await settingsStore.get<SettingStoreItem<boolean>>(
          ASK_BEFORE_USING_CELLULAR_KEY,
        )
        if (setting) {
          await store.set(ASK_BEFORE_USING_CELLULAR_KEY, setting.value)
          await settingsStore.remove(ASK_BEFORE_USING_CELLULAR_KEY)
        }
      },
    },
  ],
})

export function useAskBeforeUsingCellular() {
  return useStoreItem<boolean>({
    store,
    key: ASK_BEFORE_USING_CELLULAR_KEY,
    defaultValue: true,
  })
}
