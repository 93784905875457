import React, { useCallback } from "react"
import { Animated, StyleProp, View, ViewStyle } from "react-native"
import { accessibilityHiddenProps } from "../../lib/accessibility-hidden-props"
import TokensProvider, { useTokens } from "../tokens-provider"
import { AppHeaderPresentation, AppHeaderState } from "./types"

export default function AnimatedItem({
  children,
  style,
  opacity,
  scale,
  containerStyle,
  presentation,
}: {
  opacity?: Animated.Value | Animated.AnimatedInterpolation<number>
  scale?: Animated.Value | Animated.AnimatedInterpolation<number>
  containerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  children: (props: {
    state: AppHeaderState
    color: string
  }) => JSX.Element | null
  presentation: AppHeaderPresentation
}): JSX.Element {
  const { tokens } = useTokens()
  const getItemColor = useCallback(
    (state: AppHeaderState) =>
      tokens.appHeader.itemColor[
        presentation === "transparent" && state === "expanded"
          ? "transparent"
          : "opaque"
      ],
    [tokens, presentation],
  )
  if (opacity || scale) {
    const expandedChildren = opacity ? (
      <Animated.View
        {...accessibilityHiddenProps}
        style={[
          style,
          {
            opacity: opacity.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 0],
            }),
          },
          scale ? { transform: [{ scale }] } : undefined,
        ]}
      >
        {children({
          state: "expanded",
          color: getItemColor("expanded"),
        })}
      </Animated.View>
    ) : null
    return (
      <View style={containerStyle}>
        {expandedChildren && presentation === "transparent" ? (
          <TokensProvider displayMode="dark">{expandedChildren}</TokensProvider>
        ) : (
          expandedChildren
        )}
        <Animated.View
          key={
            // Re-mounting an animated view when detaching or re-attaching an
            // animated value avoids odd rendering problems where styles get out
            // of sync with the animated value.
            `view-${Boolean(scale)}`
          }
          style={[
            style,
            {
              opacity,
              transform: scale ? [{ scale }] : undefined,
              position: opacity ? "absolute" : undefined,
            },
          ]}
        >
          {children({
            state: "collapsed",
            color: getItemColor("collapsed"),
          })}
        </Animated.View>
      </View>
    )
  } else {
    return (
      <View style={containerStyle}>
        <View style={style}>
          {children({
            state: "static",
            color: getItemColor("static"),
          })}
        </View>
      </View>
    )
  }
}
