import { ResultAsync, Result } from "@treefort/lib/result"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import {
  AppPage,
  CollectionResponse,
  ContentResponse,
} from "@treefort/api-spec"
import { t } from "i18next"
import useContent, { fetchContent } from "./use-content"
import useCollection, { fetchCollection } from "./use-collection"
import { useRoute } from "./use-route"
import {
  isContentRoute,
  isCollectionRoute,
  isInitialRouteInTab,
  getPathFromRoute,
  RouteWithParams,
  getTabFromRoute,
  getPageFromRoute,
  AnyTab,
} from "../navigation/routes"
import useAppManifest from "./use-app-manifest"
import config from "../config"
import { AppManifest } from "../lib/app-manifest"
import { i18nKey } from "../types/i18next"

const MAX_IMAGE_SIZE = 512

export type PageMetadata = {
  title: string
  url: string
  home: boolean
  image?: string
  description?: string
  content?: ContentResponse
  collection?: CollectionResponse
  page?: AppPage
  tab?: AnyTab
}

export type PageMetadataResult = ResultAsync<PageMetadata>

const fallbackImage = getOptimizedImageSource(
  config.SPLASH_SCREEN_FOREGROUND_IMAGE_URL,
  {
    backgroundColor: config.SPLASH_SCREEN_BACKGROUND_COLOR,
    width: MAX_IMAGE_SIZE,
    height: MAX_IMAGE_SIZE,
  },
)

/**
 * This returns metadata for a page based on the current route.
 */
export const usePageMetadata = (): PageMetadataResult => {
  const manifest = useAppManifest()
  const route = useRoute()

  const collectionQuery = useCollection(
    isCollectionRoute(route) ? parseInt(route.params.collectionId) : 0,
    { enabled: isCollectionRoute(route) },
  )
  const contentQuery = useContent(
    isContentRoute(route) ? parseInt(route.params.contentId) : 0,
    undefined,
    { enabled: isContentRoute(route) },
  )

  if (collectionQuery.isLoading || contentQuery.isLoading) {
    return Result.loading()
  } else if (collectionQuery.isError || contentQuery.isError) {
    return Result.error(collectionQuery.error || contentQuery.error)
  } else if (collectionQuery.isSuccess) {
    return Result.success(
      getPageMetadata({ route, manifest, collection: collectionQuery.data }),
    )
  } else if (contentQuery.isSuccess) {
    return Result.success(
      getPageMetadata({ route, manifest, content: contentQuery.data }),
    )
  } else {
    return Result.success(getPageMetadata({ route, manifest }))
  }
}

export async function fetchPageMetadata({
  route,
  manifest,
}: {
  route: RouteWithParams
  manifest: AppManifest
}): Promise<PageMetadata> {
  const content = isContentRoute(route)
    ? await fetchContent(parseInt(route.params.contentId))
    : undefined
  const collection = isCollectionRoute(route)
    ? await fetchCollection(parseInt(route.params.collectionId))
    : undefined
  return getPageMetadata({ content, collection, route, manifest })
}

function getPageMetadata({
  content,
  collection,
  route,
  manifest,
}: {
  content?: ContentResponse
  collection?: CollectionResponse
  route: RouteWithParams
  manifest: AppManifest
}): PageMetadata {
  const page = getPageFromRoute(route, manifest) ?? undefined
  const tab = getTabFromRoute(route, manifest)
  const initialRoute = isInitialRouteInTab(route, tab, manifest)
  const url = `https://${config.DOMAIN_NAME}${getPathFromRoute(route)}`
  const overrideTitle = initialRoute
    ? "title" in tab
      ? t(tab.title as i18nKey)
      : route.title
      ? t(route.title as i18nKey)
      : undefined
    : undefined
  const home = initialRoute && tab.id === manifest.tabs[0].id
  const podcastEpisode =
    content?.type === "podcast" && "episodeNumber" in route.params
      ? content.details.episodes.find(
          (episode) =>
            episode.episode === parseInt(route.params.episodeNumber || ""),
        )
      : undefined
  return {
    title:
      overrideTitle ||
      content?.title ||
      collection?.title ||
      page?.title ||
      config.APP_NAME,
    url,
    home,
    description: content?.description,
    image: podcastEpisode
      ? getOptimizedImageSource(
          podcastEpisode.artworkMedia?.url,
          MAX_IMAGE_SIZE,
        )
      : content
      ? getOptimizedImageSource(
          content?.artworkMedia?.original.url,
          MAX_IMAGE_SIZE,
        )
      : collection?.content[0]?.artworkMedia
      ? getOptimizedImageSource(
          collection.content[0].artworkMedia.original.url,
          MAX_IMAGE_SIZE,
        )
      : fallbackImage,
    content,
    collection,
    page,
    tab,
  }
}
