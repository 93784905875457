import React from "react"

import { AppModuleVideo } from "@treefort/api-spec"

import { VideoModule } from "../../../../components/modules/video"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleVideo["config"]

export class VideoListViewModule
  implements ListViewModule<AppModuleVideo, Item>
{
  readonly appModule: AppModuleVideo
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = "collapsed"
  readonly gapSize = 36
  readonly maxWidth = 960

  constructor(appModule: AppModuleVideo) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <VideoModule
      {...args.item}
      paddingTop={args.safeAreaInsets.top}
      maxWidth={args.maxWidth}
      presentation={args.itemCount > 1 ? "listItem" : "solo"}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id
}
