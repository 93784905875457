import React, { ReactNode } from "react"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import AppFooter from "../../../components/app-footer"
import AppHeader from "../../../components/app-header"
import {
  AppHeaderPresentation,
  AppHeaderState,
} from "../../../components/app-header/types"
import { DesktopPageFooter } from "../../../components/desktop-page-footer"
import { DisplayMode, useTokens } from "../../../components/tokens-provider"
import useSafeAreaInsetAppFooter from "../../../hooks/use-safe-area-inset-app-footer"
import { BackgroundColor } from "../../../lib/background-color"
import ScrollableLayout, {
  ListViewProps,
  ScrollableLayoutProps,
} from "../scrollable"

/**
 * When the page content doesn't overflow the screen these styles will pull the
 * content to the top of the screen and push the page footer to the bottom.
 */
const { containerWithPageFooterStyle } = StyleSheet.create({
  containerWithPageFooterStyle: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
})

const ContentWithPageFooter = styled.View`
  width: 100%;
  flex: auto;
`

function PageLayout<ListViewItem = unknown>({
  children,
  headerChildren,
  headerChildrenHeight,
  headerDropShadow,
  headerDisplayMode,
  headerPresentation,
  headerState,
  headerBackgroundColor,
  hideDesktopFooter,
  backgroundColor,
  listViewProps,
}: {
  children?: ReactNode
  headerChildren?: ReactNode
  headerChildrenHeight?: number
  headerDropShadow?: boolean
  headerDisplayMode?: DisplayMode
  headerPresentation?: AppHeaderPresentation
  headerState?: AppHeaderState
  headerBackgroundColor?: string
  hideDesktopFooter?: boolean
  backgroundColor?: BackgroundColor
  listViewProps?: ListViewProps<ListViewItem>
  // Only used on native
  contentContainerStyle?: ScrollableLayoutProps<ListViewItem>["contentContainerStyle"]
}): JSX.Element {
  const { tokens } = useTokens()
  const footerHeight = useSafeAreaInsetAppFooter()
  const desktopFooter = tokens.appFooter.mode === "desktop"
  return (
    <ScrollableLayout
      header={
        <AppHeader
          childrenHeight={headerChildrenHeight}
          dropShadow={headerDropShadow}
          displayMode={headerDisplayMode}
          presentation={headerPresentation}
          state={headerState}
          backgroundColor={headerBackgroundColor}
        >
          {headerChildren}
        </AppHeader>
      }
      footer={<AppFooter />}
      footerHeight={footerHeight}
      contentContainerStyle={
        desktopFooter ? containerWithPageFooterStyle : undefined
      }
      backgroundColor={backgroundColor}
      listViewProps={listViewProps}
    >
      {desktopFooter && !hideDesktopFooter ? (
        <>
          <ContentWithPageFooter>{children}</ContentWithPageFooter>
          <DesktopPageFooter />
        </>
      ) : (
        children
      )}
    </ScrollableLayout>
  )
}

export default PageLayout
