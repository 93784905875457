import axios, { AxiosRequestConfig, AxiosResponse } from "axios"

import addErrorResolutionInterceptor from "@treefort/lib/axios-error-resolution-interceptor"
import isAccessTokenError from "@treefort/lib/is-access-token-error"
import { isAxiosError } from "@treefort/lib/is-axios-error"

import config from "../config"
import authenticator from "./authenticator"
import { isAxiosNetworkError, logError } from "./logging"

const api = axios.create({
  baseURL: config.API_ENDPOINT,
  headers: {
    "X-Treefort-Tenant": config.TENANT_ID,
    "X-Treefort-Client": config.CLIENT_ID,
    "X-Treefort-Version": config.API_VERSION,
    "X-Treefort-App-Version": config.APP_VERSION,
  },
})

api.interceptors.request.use(addAuthToRequest)
api.interceptors.response.use(refreshAgingAccessToken)

addErrorResolutionInterceptor({
  axios: api,
  resolveError: async (error) => {
    if (await authenticator.canAttemptAccessTokenRefresh()) {
      return authenticator.refreshAccessToken()
    } else {
      throw error
    }
  },
  interceptError: isAccessTokenError,
  onSuccess: addAuthToRequest,
})

/**
 * Send cookies or an Authorization header depending on the kind of auth we're
 * using.
 */
async function addAuthToRequest(req: AxiosRequestConfig) {
  switch (authenticator.getType()) {
    case "bearer": {
      req.withCredentials = false
      const accessToken = await authenticator.getAccessToken()
      if (accessToken) {
        req.headers["Authorization"] = `Bearer ${accessToken}`
      }
      return req
    }
    case "cookie": {
      req.withCredentials = true
      delete req.headers["Authorization"]
      return req
    }
  }
}

/**
 * When an API request succeeds check if our access token could use a refresh.
 * Refreshing preemptively will help avoid 401 errors related to using an
 * expired access token (we can recover from those, but they clog up the logs
 * and add latency). Why run this logic when an API request succeeds? A. We know
 * the app is active (so we're not wasting bandwidth) and B. we know that we've
 * got a working network connection.
 */
async function refreshAgingAccessToken(res: AxiosResponse<unknown>) {
  if (await authenticator.shouldAttemptAccessTokenRefresh()) {
    // Don't await here - the refresh can safely happen in the background while
    // the app continues on as usual.
    authenticator.refreshAccessToken().catch((cause) => {
      // Don't log network errors or 400 (expired token) errors. These are both
      // just a fact of life.
      if (
        !isAxiosNetworkError(cause) &&
        (!isAxiosError(cause) || cause.response?.status !== 400)
      ) {
        logError(new Error("[Auth] Failed to refresh token", { cause }))
      }
    })
  }
  return res
}

export { api as default }
