import { useEffect, useRef, useState } from "react"

import { EventEmitter } from "@treefort/lib/event-emitter"

/**
 * This hook makes it easy to get the latest value from an event emitter. It
 * takes in the event emitter, the event to listen to, and a getter function to
 * provide the initial value. This hook ensures that no values are missed
 * between fetching the initial value and adding the event listener.
 */
export function useEventEmitterValue<
  EventMap,
  Event extends keyof EventMap,
  ReturnValue,
>(
  eventEmitter: EventEmitter<EventMap>,
  event: Event,
  getValue: () => ReturnValue,
): EventMap[Event] | ReturnValue {
  const initialValue = useRef(getValue())
  const [value, setValue] = useState<EventMap[Event] | ReturnValue>(
    initialValue.current,
  )
  useEffect(() => {
    const removeListener = eventEmitter.on(event, setValue)

    // Update our local state if we missed a value between the initial render
    // and binding the event listener
    const currentValue = getValue()
    if (initialValue.current !== currentValue) {
      setValue(currentValue)
    }

    return removeListener
  }, [getValue, eventEmitter, event])
  return value
}
