"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moneyToFloat = exports.formatMoney = exports.getCurrencyDivisor = exports.ANDROID_NANOS_CURRENCY_DIVISOR = exports.ANDROID_MICROS_CURRENCY_DIVISOR = exports.ZERO_DECIMAL_CURRENCIES = exports.THREE_DECIMAL_CURRENCIES = void 0;
// We use Stripe's strategy for handling currency fractions:
// https://stripe.com/docs/currencies
exports.THREE_DECIMAL_CURRENCIES = ["BHD", "JOD", "KWD", "OMR", "TND"];
exports.ZERO_DECIMAL_CURRENCIES = [
    "BIF",
    "CLP",
    "DJF",
    "GNF",
    "JPY",
    "KMF",
    "KRW",
    "MGA",
    "PYG",
    "RWF",
    "UGX",
    "VND",
    "VUV",
    "XAF",
    "XOF",
    "XPF",
];
exports.ANDROID_MICROS_CURRENCY_DIVISOR = 1000000;
exports.ANDROID_NANOS_CURRENCY_DIVISOR = 1000000000;
function getCurrencyDivisor(currency) {
    return exports.THREE_DECIMAL_CURRENCIES.includes(currency.toUpperCase())
        ? 1000
        : exports.ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())
            ? 1
            : 100;
}
exports.getCurrencyDivisor = getCurrencyDivisor;
function formatMoney(money, locale) {
    // Drop fractional digits if they'll all be zero. This results in a
    // friendlier display for round prices (e.g. $5/mo instead of $5.00/mo).
    const fractionDigits = areEqual(Math.round(money.amount) / money.divisor, Math.round(money.amount / money.divisor))
        ? 0
        : undefined;
    return new Intl.NumberFormat(locale === null || locale === void 0 ? void 0 : locale.replace("_", "-"), {
        style: "currency",
        currency: money.currency,
        maximumFractionDigits: fractionDigits,
        // Set this as well to avoid a crash on Android:
        // https://github.com/facebook/hermes/issues/990#issuecomment-1973995632
        minimumFractionDigits: fractionDigits,
    }).format(money.amount / money.divisor);
}
exports.formatMoney = formatMoney;
function moneyToFloat(money) {
    return parseFloat((money.amount / money.divisor).toFixed(Math.log10(money.divisor)));
}
exports.moneyToFloat = moneyToFloat;
function areEqual(a, b) {
    return Math.abs(a - b) < Number.EPSILON;
}
