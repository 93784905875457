import {
  DownloadManager,
  Download,
  DownloadInProgress,
  DownloadSuccessful,
  DownloadFailed,
  DownloadDeleted,
  Event,
  EventMap,
} from "./base"

export type {
  Download,
  DownloadInProgress,
  DownloadSuccessful,
  DownloadFailed,
  DownloadDeleted,
  EventMap,
}

export { Event }

/**
 * Not supported on web... yet! See: https://web.dev/pwa-with-offline-streaming/
 */
export class DownloadManagerWeb extends DownloadManager {
  getDownload = async (_key: string): Promise<undefined> => undefined

  getDownloads = async (_keys: string[]): Promise<Array<undefined>> => []

  requestDownload = async (
    _url: string,
    _options?: { key?: string; allowOverCellular?: boolean },
  ): Promise<void> => {}

  pauseDownload = async (_key: string): Promise<void> => {}

  deleteDownload = async (_key: string): Promise<void> => {}

  deleteAllDownloads = async (): Promise<void> => {}

  getTotalSizeOfDownloads = async (): Promise<number> => 0
}

export default new DownloadManagerWeb()
