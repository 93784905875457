import React, { useCallback, useEffect, useState } from "react"

import { VideoResponse } from "@treefort/api-spec"
import { ResultAsync, Result } from "@treefort/lib/result"

import { useActiveProfileId } from "../hooks/use-active-profile-id"
import { VideoConsumableContent } from "../lib/consumable-content"
import { playContentVideo } from "../lib/content-video"
import DownloadItem, { Event as DownloadItemEvent } from "../lib/download-item"
import { Event, VideoPlayer } from "../lib/video-player"
import VideoPlayerInline from "./video-player-inline"

/**
 * This generates a VideoPlayer instance from ConsumableContent and passes it to
 * the VideoPlayerInline component to render a video.
 */
export default function VideoPlayerForConsumableContent({
  consumableContent,
  loading,
  nextVideo,
}: {
  consumableContent?: VideoConsumableContent
  loading?: boolean
  nextVideo?: VideoResponse
}): JSX.Element {
  const profileId = useActiveProfileId()
  const isLocked =
    consumableContent?.content.details.videoMedia?.status === "notAvailable"
  const [videoPlayer, setVideoPlayer] = useState<ResultAsync<VideoPlayer>>(
    Result.loading(),
  )
  const watchVideo = useCallback(async () => {
    if (consumableContent && !isLocked) {
      const videoPlayer = await playContentVideo({
        consumableContent,
        profileId,
      })

      setVideoPlayer(videoPlayer ? Result.success(videoPlayer) : Result.idle())

      // When the video player is suspended we reset our local state. This
      // ensures that we don't hold on to outdated progress data (e.g. if the
      // video is playing in the audio player).
      videoPlayer?.on(Event.Suspended, (suspended) => {
        if (suspended) {
          setVideoPlayer(Result.idle())
          videoPlayer.clearListeners()
        }
      })
    }
  }, [consumableContent, isLocked, profileId])

  // Load the video on mount
  useEffect(() => {
    if (isLocked) {
      setVideoPlayer(Result.idle())
    } else {
      watchVideo()
    }
  }, [isLocked, watchVideo])

  // Reload the video when it finishes downloading and suspend it if the
  // download is about to be deleted
  useEffect(() => {
    if (consumableContent) {
      const downloadItem = new DownloadItem({ consumableContent })
      downloadItem.enableEvents()
      downloadItem.on(DownloadItemEvent.State, (state) => {
        if (state.type === "downloaded") {
          watchVideo()
        } else if (state.type === "notDownloaded") {
          videoPlayer.data?.suspend()
        }
      })
      return () => downloadItem.disableEvents()
    }
  }, [watchVideo, consumableContent, videoPlayer.data])

  return (
    <VideoPlayerInline
      videoPlayer={videoPlayer?.data}
      poster={consumableContent?.content.artworkMedia?.original.url}
      onPressPoster={isLocked ? undefined : watchVideo}
      autoPlay
      loading={loading || videoPlayer.isLoading}
      nextVideo={nextVideo}
    />
  )
}
