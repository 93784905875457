import { field, text } from "@nozbe/watermelondb/decorators"

import { DatabaseFieldsOf } from "../stores/types"
import { SourceAwareModel } from "./source-aware-model"

export type ProgressData = DatabaseFieldsOf<Progress> & { id: string }

export class Progress extends SourceAwareModel {
  static table = "Progress" as const

  toPlainObject() {
    return {
      id: this.id,
      userId: this.userId,
      profileId: this.profileId,
      data: this.data,
      createdAtDate: this.createdAtDate,
      updatedAtDate: this.updatedAtDate,
    }
  }

  @field("userId")
  userId!: string
  @text("data")
  data!: string
}
