import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import getNameOfUser from "@treefort/lib/get-name-of-user"

import { useAsyncViewPropsForQueries } from "../hooks/use-async-view-props-for-queries"
import { useProfiles } from "../hooks/use-profiles"
import useUserInfo from "../hooks/use-user-info"
import { profilesOverlay, setActiveProfileId } from "../lib/profiles"
import AsyncView from "./async-view"
import { Button } from "./button"
import Column from "./column"
import { ProfileButton } from "./profile-button"
import {
  PROFILES_ACTION_BUTTON_WIDTH,
  PROFILES_CONTENT_BOTTOM_PADDING,
  PROFILES_CONTENT_TOP_PADDING,
} from "./profiles-manage-screen"
import Row from "./row"
import Spacer from "./spacer"
import Text from "./text"

const StyledButton = styled(Button)`
  display: block;
  width: ${PROFILES_ACTION_BUTTON_WIDTH}px;
`

export function ProfilesSelectScreen() {
  const profiles = useProfiles()
  const userInfo = useUserInfo()
  const { t } = useTranslation()

  const asyncViewProps = useAsyncViewPropsForQueries([userInfo, profiles])

  return (
    <AsyncView {...asyncViewProps}>
      <Column>
        <Text textStyle="headingXLarge">{t("Choose your profile")}</Text>
        <Spacer size={PROFILES_CONTENT_TOP_PADDING} />
        <Row
          flexWrap="wrap"
          justifyContent="center"
          columnGap="large"
          rowGap="small"
          paddingHorizontal="large"
        >
          {userInfo.data ? (
            <ProfileButton
              key={userInfo.data.id}
              onPress={() => {
                setActiveProfileId(null)
                profilesOverlay.requestClose()
              }}
              text={userInfo.data.profileName || getNameOfUser(userInfo.data)}
              profileImage={userInfo.data.profilePic}
            />
          ) : null}
          {profiles.data?.profiles.map((profile) => (
            <ProfileButton
              key={profile.id}
              onPress={() => {
                setActiveProfileId(profile.id)
                profilesOverlay.requestClose()
              }}
              text={profile.name}
              profileImage={profile.imageMedia.original.url}
            />
          ))}
        </Row>
        <Spacer size={PROFILES_CONTENT_BOTTOM_PADDING} />
        <StyledButton
          type="secondary"
          onPress={() =>
            profilesOverlay.requestOpen({ screen: { name: "manage" } })
          }
        >
          {t("Manage profiles")}
        </StyledButton>
      </Column>
    </AsyncView>
  )
}
