import DownloadItem from "./download-item"
import { PodcastEpisodeProgressItem, ProgressItem } from "./progress-item"

export type LibraryItem = {
  contentId: number
  progressUpdatedAt?: number
  progressItem?: ProgressItem
  downloadItem?: DownloadItem
  entitled?: boolean
}

/**
 * Returns a unique key for a library item.
 */
export function getLibraryItemKey(libraryItem: LibraryItem) {
  return (
    libraryItem.progressItem?.getKey() || `content-${libraryItem.contentId}`
  )
}

/**
 * Sorts library items *by reference*. Returns the reference for convenience.
 */
export function sortLibraryItems(libraryItems: LibraryItem[]) {
  libraryItems.sort((a, b) => {
    const progressSort = (b.progressUpdatedAt || 0) - (a.progressUpdatedAt || 0)
    const downloadSort =
      (b.downloadItem?.getRequestedAt() || 0) -
      (a.downloadItem?.getRequestedAt() || 0)
    const idSort = b.contentId - a.contentId
    const episodeSort =
      a.progressItem instanceof PodcastEpisodeProgressItem &&
      b.progressItem instanceof PodcastEpisodeProgressItem
        ? b.progressItem.getPodcastEpisodeNumber() -
          a.progressItem.getPodcastEpisodeNumber()
        : 0
    return progressSort || downloadSort || idSort || episodeSort
  })
  return libraryItems
}
