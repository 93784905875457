"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.themeToken = exports.platformToken = exports.displayWidthToken = exports.displayModeToken = void 0;
/**
 * Creates a display mode tokens object.
 */
function displayModeToken(tokens) {
    return { ...tokens, _resolveWithDisplayMode: true };
}
exports.displayModeToken = displayModeToken;
/**
 * Creates a display mode tokens object.
 */
function displayWidthToken(tokens) {
    return { ...tokens, _resolveWithDisplayWidth: true };
}
exports.displayWidthToken = displayWidthToken;
/**
 * Creates a display mode tokens object.
 */
function platformToken(tokens) {
    return { ...tokens, _resolveWithPlatform: true };
}
exports.platformToken = platformToken;
/**
 * Creates a display mode tokens object.
 */
function themeToken(property) {
    return { property, _resolveWithTheme: true };
}
exports.themeToken = themeToken;
