import React from "react"
import { useTranslation } from "react-i18next"

import icons from "@treefort/tokens/app/icons"

import { AppLink } from "../../../../components/app-link"
import Icon from "../../../../components/icon"
import { useRoute } from "../../../../hooks/use-route"

export function MenuBackButton(props: Partial<Parameters<typeof AppLink>[0]>) {
  const { t } = useTranslation()
  const currentRoute = useRoute()
  const parentRoutePath = currentRoute.parents[0]
  return parentRoutePath ? (
    <AppLink
      to={{ type: "path", path: parentRoutePath }}
      aria-label={t("Go back")}
      {...props}
    >
      <Icon source={icons.chevronLeft} size="medium" />
    </AppLink>
  ) : null
}
