import { ColorValue } from "react-native"

import type { NavigationBarButtonStyle } from "expo-navigation-bar"

/**
 * Controls the system navigation bar.
 */
export function NavigationBar(_: {
  buttonStyle?: NavigationBarButtonStyle
  backgroundColor?: ColorValue

  /**
   * IMPORTANT: This function should be stabilized.
   */
  onPressBack?: () => unknown
}) {
  return null
}
