import { Q } from "@nozbe/watermelondb"

import { getClient } from "../db"
import { SourceAwareModel } from "../models/source-aware-model"
import { syncManager } from "../sync"
import { Deleter } from "./behaviors/deleter"
import { Finder } from "./behaviors/finder"
import { Updater } from "./behaviors/updater"
import { DatabaseFieldsOf, ISyncedDatastore } from "./types"

export abstract class BaseStore<TModel extends SourceAwareModel>
  implements ISyncedDatastore<TModel>
{
  private table: string

  private finder: Finder<TModel>
  private updater: Updater<TModel>
  private deleter: Deleter<TModel>

  constructor(t: typeof SourceAwareModel) {
    this.table = t.table

    this.finder = new Finder<TModel>(this.table)
    this.updater = new Updater<TModel>(this.table, this.finder)
    this.deleter = new Deleter<TModel>(this.finder)

    this.getAll = this.getAll.bind(this)
    this.find = this.find.bind(this)
    this.findBy = this.findBy.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
  }

  async getAll() {
    return await getClient().get<TModel>(this.table).query().fetch()
  }

  async find(id: string) {
    return await this.finder.find(id)
  }

  findBy(fields: Partial<DatabaseFieldsOf<TModel> & { id: string }>) {
    return getClient()
      .get<TModel>(this.table)
      .query(Object.entries(fields).map((field) => Q.where(field[0], field[1])))
  }

  async create(values: DatabaseFieldsOf<TModel>) {
    const database = getClient()
    const result = await database.write(async () => {
      const createDate = new Date().getTime()
      return await database.get<TModel>(this.table).create((model) => {
        Object.assign(model, values)
        model.createdAtDate = createDate
        model.updatedAtDate = createDate
        model.syncIgnore_createdLocally = true
      })
    })

    await syncManager.requestSync({ syncType: "user-initiated" })

    return result
  }

  async update(id: string, fields: Partial<DatabaseFieldsOf<TModel>>) {
    return await this.updater.update(id, fields)
  }

  async delete(id: string) {
    await this.deleter.delete(id)
  }
}
