import React, { ReactNode, useEffect, useRef } from "react"
import { initReactI18next, useTranslation } from "react-i18next"

import i18next from "i18next"

import mapValues from "@treefort/lib/map-values"
import { simpleHash } from "@treefort/lib/simple-hash"

import useAppManifest from "../hooks/use-app-manifest"
import { useLocaleSetting } from "../hooks/use-locale-setting"
import authenticator from "../lib/authenticator"
import "../lib/i18n/pluralrules-polyfill"
import { SplashScreen } from "../navigation/screens/splash"

const i18n = i18next.use(initReactI18next)

/**
 * Run i18next initialization logic for the app. This should be rendered below
 * the AppManifestProvider and above anything that renders translations.
 */
export function InitializeI18n({ children }: { children: ReactNode }) {
  const resources = mapValues(
    useAppManifest().i18n.locales,
    (locale) => locale?.strings,
  )
  const prevResources = useRef(resources)
  const [locale] = useLocaleSetting()

  // Initialization
  useEffect(() => {
    if (locale && !i18n.isInitialized) {
      i18n.init({ lng: locale, resources })
    }
    authenticator.setLocale(locale)
  }, [resources, locale])

  // Update when resources change after initializing
  useEffect(() => {
    if (
      locale &&
      i18n.isInitialized &&
      simpleHash(resources) !== simpleHash(prevResources.current)
    ) {
      Object.entries(resources).forEach(([locale, languageResources]) => {
        Object.entries(languageResources).forEach(
          ([namespace, resourceBundle]) =>
            i18n.addResourceBundle(
              locale,
              namespace,
              resourceBundle,
              true,
              true,
            ),
        )
      })
    }
  }, [resources, locale])

  return <WhenI18nReady>{children}</WhenI18nReady>
}

function WhenI18nReady({ children }: { children: ReactNode }) {
  const { ready } = useTranslation(undefined, { i18n, useSuspense: false })

  return ready ? <>{children}</> : <SplashScreen />
}
