import FastToaster from "react-native-toast-notifications"
import throttle from "lodash/throttle"
import tokens from "@treefort/tokens/app"

export type ToastType = keyof typeof tokens.toaster.toast.backgroundColor

const { duration, animationDuration } = tokens.toaster.toast

// This will be set when the the `Toaster` component renders.
let toaster: FastToaster | null = null

let popToastTimeout: unknown

/**
 * A helper function that the `toast` object uses to pop toast without spamming
 * the user. See [issue #146](https://gitlab.com/teamtreefort/treefort-ott/-/issues/146).
 */
const popToast = throttle(
  (message: string, type: ToastType) => {
    // Clear out any already popped or pending toast
    clearTimeout(popToastTimeout as number)
    getToaster()?.hideAll()
    // Wait for the hide animation to finish and then show the new toast
    popToastTimeout = setTimeout(
      () => getToaster()?.show(message, { type, duration, animationDuration }),
      animationDuration,
    )
  },
  2500,
  {
    leading: true,
    trailing: false,
  },
)

/**
 * An object with methods for popping toast anywhere in our app.
 * @method `error` Show the user some toast to let them know something went wrong.
 * @method `success` Show the user some toast to let thme know something worked.
 */
export const toast = {
  error: (message: string): void => popToast(message, "error"),
  success: (message: string): void => popToast(message, "success"),
}

export const setToaster = (instance: FastToaster | null): void => {
  if (instance) {
    toaster = instance
  }
}

export const getToaster = (): FastToaster | null => {
  return toaster
}
