import React from "react"

import styled from "styled-components/native"

import rawTokens from "@treefort/tokens/app"

import Icon, { IconProps, IconSize, IconState } from "./icon"
import Text from "./text"
import Touchable, { TouchableProps } from "./touchable"

export type IconButtonProps = {
  source: IconProps["source"]
  label: string
  state?: IconState
  iconSize?: IconSize
  color?: IconProps["color"]
  showLabel?: boolean
  // Set the minimum size (both width and height) of the icon button to either
  // the minimum recommended tap target size, the height of our regular button
  // (helpful when aligning an icon button with a regular button).
  minSize?: "tapTarget" | "buttonHeight"
  // This can be used to squash the icon button's height independent of the
  // minSize prop. This is helpful on tight mobile layouts, but should be used
  // sparingly to maintain sufficient tap target sizes.
  height?: number
  // This can be used to set a custom width independent of the minimum size
  width?: number
  role?: "button" | "link"
} & TouchableProps

const StyledTouchable = styled(Touchable)`
  align-items: center;
  justify-content: center;
`

const Container = styled.View<{
  minSize: number
  height?: number
  width?: number
}>`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  ${(props) =>
    props.width === undefined
      ? `min-width: ${props.minSize}px`
      : `width: ${props.width}px`};
  ${(props) =>
    props.height === undefined
      ? `min-height: ${props.minSize}px`
      : `height: ${props.height}px`};
`

const Label = styled(Text)<{
  state: IconState
  color?: string
}>`
  opacity: ${(props) => props.theme.icon.opacity[props.state]};
  margin-top: ${(props) => props.theme.iconButton.labelMargin}px;
`

export default function IconButton({
  source,
  label,
  color: colorProp,
  feedback = "opacity",
  state = "default",
  iconSize = "medium",
  showLabel = false,
  minSize: minSizeProp = "tapTarget",
  height,
  width,
  role = "button",
  ...extraProps
}: IconButtonProps): JSX.Element {
  const color = extraProps.disabled
    ? "disabled"
    : state === "active"
      ? "accent"
      : colorProp
  const minSize = Math.max(
    minSizeProp === "tapTarget"
      ? rawTokens.minTapTarget
      : rawTokens.button.height.medium,
    rawTokens.icon.size[iconSize],
  )
  return (
    <StyledTouchable
      {...extraProps}
      feedback={feedback}
      accessible
      role={role}
      accessibilityState={state === "active" ? { selected: true } : {}}
      aria-selected={state === "active" ? true : undefined}
      aria-label={label}
    >
      {showLabel ? (
        <Container minSize={minSize} height={height} width={width}>
          <Icon
            size={iconSize}
            source={source}
            state={state}
            color={color}
            key={color}
          />
          <Label
            textStyle="caption"
            numberOfLines={1}
            state={state}
            key="label"
          >
            {label}
          </Label>
        </Container>
      ) : (
        <Container minSize={minSize} height={height} width={width}>
          <Icon
            size={iconSize}
            source={source}
            state={state}
            color={color}
            key={color}
          />
        </Container>
      )}
    </StyledTouchable>
  )
}
