import { Platform } from "react-native"
import { useQuery } from "react-query"

import { stringify } from "query-string"

import { ContentBaseResponse } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"
import filter from "@treefort/lib/filter"

import { rudderstackAnalyticsPlugin } from "../lib/analytics/plugins/rudderstack"
import api from "../lib/api"
import { IS_BOT } from "../lib/is-bot"
import { useActiveProfileId } from "./use-active-profile-id"
import useAppPreview from "./use-app-preview"
import useQueryKey from "./use-query-key"

export const useRecommendations = ({
  enabled,
  ...options
}: {
  contentId?: number
  scenario?: string
  count: number
  personalized?: boolean
  enabled?: boolean
}) => {
  const auth = useAuth()
  const appPreview = useAppPreview()
  const appPreviewToken =
    appPreview.state === "on" ? appPreview.token : undefined
  const profileId = useActiveProfileId()
  return useQuery(
    useQueryKey(["recommendations", profileId, appPreviewToken, options]),
    async () => {
      if (IS_BOT) return { content: [], recommId: "" } // Don't waste recombee requests on bots
      const anonymousId = auth.user
        ? undefined
        : await rudderstackAnalyticsPlugin.getAnonymousId()
      const recommendations = await api.get<{
        content: ContentBaseResponse[]
        recommId?: string
      }>(
        `/integrations/recombee/recommendations?${stringify({
          ...(anonymousId ? { anonymousId } : {}),
          ...options,
          platform: Platform.OS,
        })}`,
        {
          headers: filter(
            {
              "X-Treefort-App-Preview-Token": appPreviewToken,
              "X-Treefort-Profile": profileId,
            },
            Boolean,
          ),
        },
      )
      return recommendations.data
    },
    {
      enabled: auth.initialized && enabled !== false,
    },
  )
}
