import { Platform } from "react-native"

import { IntegrationProvider, UserSubscription } from "@treefort/api-spec"

const PAYMENT_PROVIDERS = [
  "stripe",
  "appStore",
  "playStore",
  "webPayment",
] as const

export function canPurchasePlanFromProvider(
  provider: IntegrationProvider,
): boolean {
  switch (provider) {
    case "stripe":
    case "webPayment":
    case "groupMembership":
      return Platform.OS === "web"
    case "appStore":
      return Platform.OS === "ios"
    case "playStore":
      return Platform.OS === "android"
    default:
      return false
  }
}

export function canOpenBillingPortalForProvider(
  provider: IntegrationProvider,
  subscription: UserSubscription | undefined,
): boolean {
  switch (provider) {
    case "stripe":
      // The Stripe billing portal will only work if the user has an active
      // subscription
      return (
        Platform.OS === "web" &&
        subscription !== undefined &&
        !subscription.deactivatedAt
      )
    case "webPayment":
      return Platform.OS === "web"
    case "appStore":
      return Platform.OS === "ios" || Platform.OS === "web"
    case "playStore":
      return Platform.OS === "android" || Platform.OS === "web"
    default:
      return false
  }
}

export function canCancelPlanFromProvider(
  provider: IntegrationProvider,
): boolean {
  switch (provider) {
    case "stripe":
    case "webPayment":
      // Only allow cancellation of web plans on the web. We don't want to
      // encourage users to shoot themselves in the foot by moving to App Store
      // or Play Store subscriptions which are usually more expensive, harder to
      // cancel, harder to refund, and are just generally customer service
      // nightmares
      return Platform.OS === "web"
    case "playStore":
      // Cancelling Play Store subscriptions programatically via API has a
      // different effect than cancelling manually via the Play Store UI:
      // programatically canceled subscriptions cannot be uncanceled while
      // manually canceled subscriptions can via a "Resubscribe" button in the
      // Play Store UI. For that reason we push users towards the better "Manage
      // subscription" flow on Android instead of showing an in-app "Cancel
      // subscription" button. Everywhere else we show the cancel button as the
      // user is less likely to be signed into the Play Store's web UI with the
      // correct account and may have a hard time cancelling. Plus if the user
      // wants to resubscribe on another platform it probably won't be with a
      // Play Store subscription anyway.
      return Platform.OS !== "android"
    case "groupMembership":
      return true
    default:
      return false
  }
}

export function getPaymentProvidersForPlatform() {
  return PAYMENT_PROVIDERS.filter(canPurchasePlanFromProvider)
}
