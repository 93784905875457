import React from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet } from "react-native"

import styled from "styled-components/native"

import { Heading } from "../../components/heading"
import Spacer from "../../components/spacer"
import Text from "../../components/text"
import Touchable from "../../components/touchable"
import { useParentalGateway } from "../../hooks/use-parental-gateway"
import {
  APP_STORE_APP_PAGE_URL,
  PLAY_STORE_APP_PAGE_URL,
} from "../../lib/app-stores"
import openUrl from "../../lib/open-url"
import PageLayout from "../layouts/page"

// Center content vertically in the page layout
const { contentContainerStyle } = StyleSheet.create({
  contentContainerStyle: { flexGrow: 1, justifyContent: "center" },
})

const CenteredContent = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing.large}px;
`

export function UpdateScreen(): JSX.Element {
  const parentalGateway = useParentalGateway()
  const appStoreAppPageUrl = Platform.select({
    ios: APP_STORE_APP_PAGE_URL,
    android: PLAY_STORE_APP_PAGE_URL,
  })
  const { t } = useTranslation()

  return (
    <PageLayout contentContainerStyle={contentContainerStyle}>
      <CenteredContent>
        <Heading level={2} textStyle="headingMedium">
          {t("Update Required")}
        </Heading>
        <Spacer size="small" />
        <Text textStyle="body" alignment="center">
          {t("An update is required to view this content.")}{" "}
          {Platform.select({
            ios: "Make sure that you have the latest version installed from the App Store and then restart the app.",
            android:
              "Make sure that you have the latest version installed from the Play Store and then restart the app.",
            web: "Please refresh the page.",
          })}
        </Text>
        {appStoreAppPageUrl ? (
          <>
            <Spacer size="small" />
            <Touchable
              onPress={() =>
                openUrl(appStoreAppPageUrl, {
                  openInApp: false,
                  parentalGateway,
                })
              }
            >
              <Text color="accent" textStyle="body">
                {t("Open")}{" "}
                {Platform.select({
                  ios: "App Store",
                  android: "Play Store",
                })}
              </Text>
            </Touchable>
          </>
        ) : null}
      </CenteredContent>
    </PageLayout>
  )
}
