import React, { Ref, useState } from "react"
import { View } from "react-native"

import styled from "styled-components/native"

import { TextStyle } from "../../lib/text-style"
import { withUnits } from "../../lib/with-units"
import Text from "../text"
import BaseTextInput from "../text-input"
import { useTokens } from "../tokens-provider"
import { MAX_HEIGHT, SMALL_TEXT_STYLE, inputStyles } from "./styles"
import { Size, TextInputProps } from "./types"

export type { TextInputRef } from "./types"

const StyledTextInput = styled(BaseTextInput)<{
  size: Size
  height?: number | string
  minHeight?: number | string
  maxHeight?: number | string
  textStyle: TextStyle
  position?: "absolute" | "relative"
  width?: number | string
}>`
  position: ${({ position = "relative" }) => position};
  width: ${({ width = "auto" }) => withUnits(width)};
  ${inputStyles};
`

const HiddenTextContainer = styled.View<{
  size: Size
  textStyle: TextStyle
  maxHeight?: number | string
}>`
  visibility: hidden;
  ${inputStyles}
`

const AutoGrowingTextInput = React.forwardRef(function AutoGrowingTextInput(
  { size = "medium", ...props }: TextInputProps,
  ref: Ref<BaseTextInput> | null,
): JSX.Element {
  const { tokens } = useTokens()
  const [value, setValue] = useState(props.defaultValue)

  const textStyle = size === "medium" ? "body" : SMALL_TEXT_STYLE

  return (
    <View>
      <HiddenTextContainer
        size={size}
        textStyle={textStyle}
        maxHeight={MAX_HEIGHT}
      >
        <Text textStyle={textStyle}>
          {
            // Adding a trailing space to the hidden text mitigates some
            // layout issues on the web
            // - https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/#aa-this-is-the-weirdest-part
            value + " "
          }
        </Text>
      </HiddenTextContainer>
      <StyledTextInput
        {...props}
        ref={ref}
        size={size}
        multiline
        textStyle={textStyle}
        placeholderTextColor={tokens.textInput.placeholderTextColor}
        onChangeText={(value) => {
          setValue(value)
          props.onChangeText?.(value)
        }}
        position="absolute"
        maxHeight="100%"
        height="100%"
        width="100%"
      />
    </View>
  )
})

export default React.forwardRef(function TextInput(
  { size = "medium", autoGrow, ...props }: TextInputProps,
  ref: Ref<BaseTextInput> | null,
): JSX.Element {
  const { tokens } = useTokens()

  const multiline = autoGrow ? true : props.multiline

  return autoGrow ? (
    <AutoGrowingTextInput {...props} ref={ref} />
  ) : (
    <StyledTextInput
      ref={ref as Ref<BaseTextInput> | null}
      maxHeight={MAX_HEIGHT}
      size={size}
      multiline={multiline}
      textStyle={size === "medium" ? "body" : SMALL_TEXT_STYLE}
      placeholderTextColor={tokens.textInput.placeholderTextColor}
      {...props}
    />
  )
})
