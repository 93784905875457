import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import { useAuth } from "@treefort/lib/auth-provider"
import { isAvailable } from "@treefort/lib/availability"
import { joinContributorNames } from "@treefort/lib/contributor"
import icons from "@treefort/tokens/app/icons"

import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import { useBooleanState } from "../../hooks/use-boolean-state"
import useContent from "../../hooks/use-content"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import { useProgressItem } from "../../hooks/use-progress-item"
import { usePromptCreateFreeAccount } from "../../hooks/use-prompt-create-free-account"
import {
  canDownloadConsumableContent,
  EbookConsumableContent,
  getConsumableContentFromEbookResponse,
} from "../../lib/consumable-content"
import { readContentEbook } from "../../lib/content-ebook"
import { formatDate } from "../../lib/date"
import { syncManager } from "../../watermelon/sync"
import AddToLibraryButton, { canAddToLibrary } from "../add-to-library-button"
import { AsyncButton } from "../async-button"
import { useAsyncViewProps } from "../async-view"
import { BoxPadding } from "../box"
import DownloadButton from "../download-button"
import { EbookHighlightsSelect } from "../ebook-highlights-select"
import { EbookTableOfContentsSelect } from "../ebook-table-of-contents-select"
import { Heading } from "../heading"
import IconButton from "../icon-button"
import LockedContentButton from "../locked-content-button"
import { MetadataSpacer, MetadataText } from "../metadata"
import ModuleArtworkLayout from "../module-artwork-layout"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import Spacer from "../spacer"
import TextToggle from "../text-toggle"

const { buttonFlexContainerStyle } = StyleSheet.create({
  buttonFlexContainerStyle: { flex: 1 },
})

const ReadButton = styled(AsyncButton)`
  flex: ${(props) => (props.theme.ebookModule.readButtonFlex ? "1" : "none")};
`

const LockedReadButton = styled(LockedContentButton)`
  flex: ${(props) => (props.theme.ebookModule.readButtonFlex ? "1" : "none")};
`

function TableOfContentsSelect({
  open,
  onClose,
  consumableContent,
}: {
  open: boolean
  onClose: () => void
  consumableContent: EbookConsumableContent
}): JSX.Element | null {
  const profileId = useActiveProfileId()
  const progressItem = useProgressItem({
    consumableContent,
  })
  const { content } = consumableContent
  const { ebookMedia } = content.details

  // Bail if we don't have access to the media (where the table of contents is
  // stored)
  if (ebookMedia?.status !== "available") return null

  return (
    <EbookTableOfContentsSelect
      label={content.title}
      open={open}
      onClose={onClose}
      onChange={(section) => {
        onClose()
        readContentEbook({
          consumableContent,
          location: {
            type: "requested",
            location: { type: "href", href: section.href },
            metadata: { sectionId: section.id },
          },
          profileId,
        })
      }}
      sectionId={progressItem?.getLocation()?.metadata?.sectionId}
      tableOfContents={ebookMedia.data.tableOfContents}
    />
  )
}

export function EbookModule({
  contentId,
  maxWidth,
  paddingTop = "medium",
  presentation = "solo",
}: {
  contentId: number
  maxWidth?: number
  paddingTop?: BoxPadding
  presentation?: "solo" | "listItem"
}): JSX.Element {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [artworkReady, setArtworkReady] = useState(false)
  const [highlightsOpen, openHighlights, closeHighlights] =
    useBooleanState(false)
  const [tableOfContentsOpen, openTableOfContents, closeTableOfContents] =
    useBooleanState(false)
  const content = useContent(contentId, "ebook")
  const ebookMedia = content.data?.details.ebookMedia
  const title = content.data?.title
  const consumableContent = useMemo(
    () => content.data && getConsumableContentFromEbookResponse(content.data),
    [content.data],
  )
  const profileId = useActiveProfileId()
  const promptCreateFreeAccount = usePromptCreateFreeAccount()

  const openCheckoutPage = useOpenCheckoutPage({
    availability: ebookMedia,
    contentId,
  })

  const showBookmarks =
    consumableContent &&
    isAvailable(consumableContent.content.details.ebookMedia)
  const includeTableOfContents =
    consumableContent &&
    isAvailable(consumableContent.content.details.ebookMedia) &&
    consumableContent.content.details.ebookMedia.data.tableOfContents.length > 0
  const showDownloadButton =
    consumableContent && canDownloadConsumableContent(consumableContent)
  const showAddToLibraryButton = canAddToLibrary({
    manifest: useAppManifest(),
    consumableContent,
  })

  const openReader = useCallback(async () => {
    if (consumableContent) {
      await syncManager.requestSync({ syncType: "user-initiated" })
      await readContentEbook({ consumableContent, profileId })
    }
  }, [consumableContent, profileId])

  useAsyncViewProps(
    useAsyncViewPropsForQueries([content], {
      forceLoading: content.data?.artworkMedia ? !artworkReady : undefined,
    }),
  )

  const author = joinContributorNames(content.data?.contributors, "author")
  const publisher = joinContributorNames(
    content.data?.contributors,
    "publisher",
  )

  return (
    <ModuleArtworkLayout
      paddingTop={paddingTop}
      artwork={content.data?.artworkMedia?.original.url}
      onArtworkReady={setArtworkReady}
      maxWidth={maxWidth}
    >
      {({ layout }) => (
        <>
          <Heading
            level={1}
            textStyle="headingLarge"
            color="primary"
            maxWidth="title"
          >
            {title || ""}
          </Heading>
          <Spacer size="small" />
          <Row>
            {author ? <MetadataText>{author}</MetadataText> : null}
            {author && content.data?.publishedAt ? <MetadataSpacer /> : null}
            {content.data?.publishedAt ? (
              <MetadataText>
                {formatDate(new Date(content.data.publishedAt), {
                  strategy: "fullDate",
                  i18n,
                })}
              </MetadataText>
            ) : null}
            {
              <ProgressForConsumableContent
                consumableContent={consumableContent}
                includeProgressLabel
                includeFinishedBadge
                childrenBefore={
                  author || content.data?.publishedAt ? (
                    <MetadataSpacer />
                  ) : undefined
                }
              />
            }
          </Row>
          {publisher ? <MetadataText>{publisher}</MetadataText> : null}
          <ProgressForConsumableContent
            consumableContent={consumableContent}
            includeProgressBar
            childrenBefore={<Spacer size="small" />}
          />
          <Spacer size="medium" />
          <Row gap="large">
            {ebookMedia?.status === "notAvailable" ? (
              <LockedReadButton
                openCheckoutPage={openCheckoutPage}
                availability={ebookMedia}
                action="read"
                containerStyle={buttonFlexContainerStyle}
              />
            ) : (
              <ReadButton
                icon={icons.openBook}
                type="primary"
                onPress={openReader}
                disabled={!content.isSuccess}
                containerStyle={buttonFlexContainerStyle}
              >
                {t("Read")}
              </ReadButton>
            )}
            {includeTableOfContents ||
            showBookmarks ||
            showDownloadButton ||
            showAddToLibraryButton ? (
              <Row gap="small">
                {includeTableOfContents ? (
                  <IconButton
                    source={icons.menuStaggered}
                    onPress={openTableOfContents}
                    label={t("Table of Contents")}
                    color="secondary"
                    minSize="buttonHeight"
                  />
                ) : null}
                {showBookmarks ? (
                  <IconButton
                    source={icons.bookmark}
                    onPress={() =>
                      auth.user
                        ? openHighlights()
                        : promptCreateFreeAccount(
                            "You must have an account to highlight and take notes. Would you like to create one now?",
                          )
                    }
                    label={t("Highlights")}
                    color="secondary"
                    minSize="buttonHeight"
                  />
                ) : null}
                {showDownloadButton ? (
                  <DownloadButton consumableContent={consumableContent} />
                ) : null}
                {showAddToLibraryButton ? (
                  <AddToLibraryButton consumableContent={consumableContent} />
                ) : null}
              </Row>
            ) : null}
          </Row>
          {content.data?.description ? (
            <>
              <Spacer size="medium" />
              <TextToggle
                textStyle="body"
                numberOfLines={
                  layout === "portrait" && presentation === "listItem" ? 2 : 6
                }
                maxWidth="description"
                withLinks
              >
                {content.data.description}
              </TextToggle>
            </>
          ) : null}
          {includeTableOfContents ? (
            <TableOfContentsSelect
              consumableContent={consumableContent}
              open={tableOfContentsOpen}
              onClose={closeTableOfContents}
            />
          ) : null}
          {showBookmarks ? (
            <EbookHighlightsSelect
              open={highlightsOpen}
              onClose={closeHighlights}
              consumableContent={consumableContent}
            />
          ) : null}
        </>
      )}
    </ModuleArtworkLayout>
  )
}
