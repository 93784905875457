"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.slider = void 0;
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
exports.slider = {
    title: {
        textStyle: "headingMedium",
        paddingBottom: base_1.spacing.medium,
    },
    item: {
        shape: {
            square: {
                height: {
                    "1-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 148,
                            960: 148 * 1.4,
                            1200: 148 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 192,
                            960: 192 * 1.4,
                            1200: 192 * 1.8,
                        }),
                    },
                    "2-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 116,
                            960: 116 * 1.4,
                            1200: 116 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 148,
                            960: 148 * 1.4,
                            1200: 148 * 1.8,
                        }),
                    },
                    // height of individual items in the 2 x 2 grid in mixed layout
                    mixedBase: {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 92,
                            960: 92 * 1.4,
                            1200: 92 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 124,
                            960: 124 * 1.4,
                            1200: 124 * 1.8,
                        }),
                    },
                },
            },
            circle: {
                height: {
                    "1-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 148,
                            960: 148 * 1.4,
                            1200: 148 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 192,
                            960: 192 * 1.4,
                            1200: 192 * 1.8,
                        }),
                    },
                    "2-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 116,
                            960: 116 * 1.4,
                            1200: 116 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 148,
                            960: 148 * 1.4,
                            1200: 148 * 1.8,
                        }),
                    },
                },
            },
            tall: {
                height: {
                    "1-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 192,
                            960: 192 * 1.4,
                            1200: 192 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 252,
                            960: 252 * 1.4,
                            1200: 252 * 1.8,
                        }),
                    },
                    "2-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 148,
                            960: 148 * 1.4,
                            1200: 148 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 192,
                            960: 192 * 1.4,
                            1200: 192 * 1.8,
                        }),
                    },
                    // height of individual items in the 2 x 2 grid in mixed layout
                    mixedBase: {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 116,
                            960: 116 * 1.2,
                            1200: 116 * 1.4,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 148,
                            960: 148 * 1.2,
                            1200: 148 * 1.4,
                        }),
                    },
                },
            },
            wide: {
                height: {
                    "1-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 116,
                            960: 116 * 1.4,
                            1200: 116 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 164,
                            960: 164 * 1.4,
                            1200: 164 * 1.8,
                        }),
                    },
                    "2-row": {
                        medium: (0, helpers_1.displayWidthToken)({
                            0: 92,
                            960: 92 * 1.4,
                            1200: 92 * 1.8,
                        }),
                        large: (0, helpers_1.displayWidthToken)({
                            0: 124,
                            960: 124 * 1.4,
                            1200: 124 * 1.8,
                        }),
                    },
                },
            },
        },
    },
};
