import React from "react"

import styled from "styled-components/native"

import { Shape } from "@treefort/api-spec"
import { clamp } from "@treefort/lib/clamp"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"

import useWindowDimensions from "../hooks/use-window-dimensions"
import { Spacing, spacingToNumber } from "../lib/spacing"
import Box from "./box"
import CenteredContent from "./centered-content"
import Column from "./column"
import ImageContained from "./image-contained"
import Row from "./row"
import { useTokens } from "./tokens-provider"

const Artwork = styled(Box)`
  position: relative;
`

const Content = styled(Box)`
  ${(props) =>
    props.theme.artworkFullPage.layout === "portrait" ? "width: 100%" : ""};
  ${(props) =>
    props.theme.artworkFullPage.layout === "landscape" ? "flex: 1" : ""};
`

/**
 * Layout shared by the audiobook, ebook, and podcast episode modules
 */
export default function ModuleArtworkLayout({
  artwork,
  artworkShape,
  maxWidth,
  paddingTop = "medium",
  fullBleedContent,
  onArtworkReady,
  children,
}: {
  artwork?: string
  artworkShape?: Shape
  maxWidth?: number
  paddingTop?: Spacing
  /**
   * Set this to true to render content full-bleed (no horizontal padding) in
   * portrait mode.
   */
  fullBleedContent?: boolean
  onArtworkReady?: (ready: boolean) => unknown
  children:
    | React.ReactNode
    | ((props: { layout: "landscape" | "portrait" }) => JSX.Element)
}): JSX.Element {
  const { tokens } = useTokens()
  const {
    artworkFullPage: {
      layout: layoutToken,
      artworkMaxSize,
      artworkMinSize,
      artworkHeightPercentage,
      artworkWidthPercentage,
    },
    spacing: { pagePaddingHorizontal },
  } = tokens
  const layout =
    layoutToken === "portrait" ? ("portrait" as const) : ("landscape" as const)
  const windowDimensions = useWindowDimensions()

  // Get a rough estimate of the total space available in the viewport for the
  // artwork, multiply the space by the percentage of the space the artwork
  // should take up, and then constrain the result to the max size allowd.
  const artworkHeight = clamp(
    (windowDimensions.height - spacingToNumber(tokens, paddingTop)) *
      artworkHeightPercentage,
    artworkMinSize,
    artworkMaxSize,
  )
  const artworkWidth = clamp(
    (windowDimensions.width - pagePaddingHorizontal * 2) *
      artworkWidthPercentage,
    artworkMinSize,
    artworkMaxSize,
  )

  const artworkImage = artwork ? (
    <ImageContained
      uri={getOptimizedImageSource(artwork, artworkMaxSize)}
      borderRadius={artworkShape === "circle" ? "roundedFull" : "roundedLarge"}
      containerSize={{ height: artworkHeight, width: artworkWidth }}
      onReady={onArtworkReady}
      aspectRatio={artworkShape ? tokens.aspectRatios[artworkShape] : undefined}
    />
  ) : undefined

  return (
    <CenteredContent
      paddingTop={paddingTop}
      paddingHorizontal="none"
      maxWidth={maxWidth}
    >
      {layout === "portrait" ? (
        <Column gap="large">
          <Artwork paddingHorizontal="pagePaddingHorizontal">
            {artworkImage}
          </Artwork>
          <Content
            paddingHorizontal={
              fullBleedContent ? "none" : "pagePaddingHorizontal"
            }
          >
            {typeof children === "function" ? children({ layout }) : children}
          </Content>
        </Column>
      ) : (
        <Row
          alignItems="flex-start"
          paddingTop="xlarge"
          gap="jumbo"
          paddingHorizontal="pagePaddingHorizontal"
        >
          <Artwork>{artworkImage}</Artwork>
          <Content>
            {typeof children === "function" ? children({ layout }) : children}
          </Content>
        </Row>
      )}
    </CenteredContent>
  )
}
