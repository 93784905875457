import React from "react"

import { AppModulePodcastEpisode } from "@treefort/api-spec"

import { PodcastEpisodeModule } from "../../../../components/modules/podcast-episode"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModulePodcastEpisode["config"]

export class PodcastEpisodeListViewModule
  implements ListViewModule<AppModulePodcastEpisode, Item>
{
  readonly appModule: AppModulePodcastEpisode
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = undefined
  readonly gapSize = 36
  readonly maxWidth = 1200

  constructor(appModule: AppModulePodcastEpisode) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <PodcastEpisodeModule
      {...args.item}
      paddingTop={args.safeAreaInsets.top}
      maxWidth={args.maxWidth}
      presentation={args.itemCount > 1 ? "listItem" : "solo"}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id
}
