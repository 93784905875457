import React from "react"
import { useTokens } from "../tokens-provider"
import HorizontalListViewWithArrowsDesktop from "./horizontal-list-view-with-arrows-desktop"
import HorizontalListViewWithArrowsMobile from "./horizontal-list-view-with-arrows-mobile"
import { HorizontalListViewWithArrowsProps } from "./types"

/**
 * Renders a horizontal ListView. On the web at the desktop breakpoint it also
 * renders right and left arrows that appear when the list is hovered over. The
 * left arrow is rendered if the list is scrolled to the right such that the
 * first item is obscured, and the right arrow is rendered if the last item is
 * not wholly visible.
 */
export default function HorizontalListViewWithArrows<T>(
  props: HorizontalListViewWithArrowsProps<T>,
): JSX.Element {
  const { tokens } = useTokens()
  return tokens.horizontalListViewWithArrows.mode === "desktop" ? (
    <HorizontalListViewWithArrowsDesktop {...props} />
  ) : (
    <HorizontalListViewWithArrowsMobile {...props} />
  )
}
