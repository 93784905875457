"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressPersistence = void 0;
class ProgressPersistence {
    constructor(store) {
        // Any code checking for localOnly can treat this store
        // as if it is local only and we'll actually sync remote
        // in the background. Shh, don't tell them.
        this.localOnly = () => true;
        this.saveLocal = async (key, value, options) => {
            // insert into watermelon, trigger a scheduled sync
            this.store.set(key, JSON.stringify(value), options);
        };
        this.saveRemote = async (_key, _value, _options) => {
            // no-op: all progress is automatically synced via Watermelon
            // This intentionally does not pass-through to saveLocal
            // because every legacy place where this is called _also_
            // calls saveLocal and we don't need to do the work twice.
        };
        this.updateRemote = async (_key, _callback, _options) => {
            // TODO: https://gitlab.com/teamtreefort/treefort-ott/-/issues/1654
            // As of this writing, this is only called by use-onboarding-screens
            // and we may be able to simplify it away by migrating to Watermelon.
            throw new Error("Not supported.");
        };
        this.saveLocalAndRemote = async (key, value, options) => {
            return this.saveLocal(key, value, options);
        };
        this.getLocal = async (key, options) => {
            var _a;
            const value = await this.store.get(key, options);
            if (!value) {
                return {
                    value: null,
                    timestamp: undefined,
                    profileId: options.profileId,
                };
            }
            try {
                return {
                    value: (_a = JSON.parse(value)) !== null && _a !== void 0 ? _a : null,
                    timestamp: undefined,
                    profileId: options.profileId,
                };
            }
            catch (_b) {
                return {
                    value: null,
                    timestamp: undefined,
                    profileId: options.profileId,
                };
            }
        };
        this.getManyLocal = async (_keys, _options) => {
            // TODO: https://gitlab.com/teamtreefort/treefort-ott/-/issues/1654
            // Do we need this function at all for progress?
            throw new Error("Method not implemented.");
        };
        this.getLocalOrRemote = async (key, options) => {
            return this.getLocal(key, options);
        };
        this.getRemote = async (key, options) => {
            return this.getLocal(key, options);
        };
        this.clearLocal = async (key, options) => {
            await this.store.delete(key, options);
        };
        this.clearRemote = async (key, options) => {
            await this.clearLocal(key, options);
        };
        this.clearLocalAndRemote = async (key, options) => {
            await this.clearLocal(key, options);
        };
        this.store = store;
    }
}
exports.ProgressPersistence = ProgressPersistence;
