import React, { useMemo } from "react"
import { ViewProps } from "react-native"

import styled from "styled-components/native"

import {
  BackgroundColor,
  backgroundColorToString,
} from "../lib/background-color"
import { BorderColor, borderColorToString } from "../lib/border-color"
import { BorderRadius, borderRadiusToNumber } from "../lib/border-radius"
import { Spacing, spacingToNumber } from "../lib/spacing"
import styleObjectToString from "../lib/style-object-to-string"
import { useTokens } from "./tokens-provider"

export type BoxBorderColor = BorderColor
export type BoxBackgroundColor = BackgroundColor
export type BoxPadding = Spacing
export type BoxBorderRadius = BorderRadius

type StyleProps = {
  backgroundColor?: BoxBackgroundColor
  width?: string | number
  height?: string | number
  minWidth?: string | number
  minHeight?: string | number
  maxWidth?: string | number
  maxHeight?: string | number
  flex?: number
  borderRadius?: BoxBorderRadius
  borderColor?: BoxBorderColor
  borderTopColor?: BoxBorderColor
  borderRightColor?: BoxBorderColor
  borderBottomColor?: BoxBorderColor
  borderLeftColor?: BoxBorderColor
  padding?: BoxPadding
  paddingHorizontal?: BoxPadding
  paddingVertical?: BoxPadding
  paddingTop?: BoxPadding
  paddingBottom?: BoxPadding
  paddingLeft?: BoxPadding
  paddingRight?: BoxPadding
}

const StyledView = styled.View<{ boxStyle: string }>`
  ${(props) => props.boxStyle};
`

function Box({
  backgroundColor,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  flex,
  borderRadius,
  borderColor,
  borderTopColor,
  borderRightColor,
  borderBottomColor,
  borderLeftColor,
  padding,
  paddingHorizontal,
  paddingVertical,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  ...viewProps
}: ViewProps & StyleProps): JSX.Element {
  const { tokens } = useTokens()

  const boxStyle = useMemo(
    () =>
      styleObjectToString({
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        flex,
        backgroundColor: backgroundColorToString(tokens, backgroundColor),
        borderRadius: borderRadiusToNumber(tokens, borderRadius),
        borderWidth: borderColor !== undefined ? 2 : undefined,
        borderColor: borderColorToString(tokens, borderColor),
        borderTopColor: borderColorToString(tokens, borderTopColor),
        borderTopWidth: borderTopColor !== undefined ? 1 : undefined,
        borderRightColor: borderColorToString(tokens, borderRightColor),
        borderRightWidth: borderRightColor !== undefined ? 1 : undefined,
        borderBottomColor: borderColorToString(tokens, borderBottomColor),
        borderBottomWidth: borderBottomColor !== undefined ? 1 : undefined,
        borderLeftColor: borderColorToString(tokens, borderLeftColor),
        borderLeftWidth: borderLeftColor !== undefined ? 1 : undefined,
        padding: spacingToNumber(tokens, padding),
        paddingHorizontal: spacingToNumber(tokens, paddingHorizontal),
        paddingVertical: spacingToNumber(tokens, paddingVertical),
        paddingTop: spacingToNumber(tokens, paddingTop),
        paddingBottom: spacingToNumber(tokens, paddingBottom),
        paddingLeft: spacingToNumber(tokens, paddingLeft),
        paddingRight: spacingToNumber(tokens, paddingRight),
      }),
    [
      tokens,
      backgroundColor,
      width,
      height,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      flex,
      borderRadius,
      borderColor,
      borderTopColor,
      borderRightColor,
      borderBottomColor,
      borderLeftColor,
      padding,
      paddingHorizontal,
      paddingVertical,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    ],
  )

  return <StyledView {...viewProps} boxStyle={boxStyle} />
}

export default Box
