import { extract, parse, stringify } from "query-string"
import urlParse from "url-parse"

/**
 * Returns an object containing all string query parameters from a URL (or
 * undefined if the URL does not contain string parameters).
 */
export function getStringParamsFromUrl(url: string) {
  const allParams = parse(extract(url))
  const stringParams = Object.fromEntries(
    Object.entries(allParams).flatMap(([key, value]) =>
      typeof value === "string" ? [[key, value]] : [],
    ),
  )
  return Object.keys(stringParams).length ? stringParams : undefined
}

/**
 * Sets query parameters on a URL.
 */
export function setUrlParams(
  url: string,
  params: Record<string, unknown> | undefined,
) {
  if (!params) {
    return url
  }

  const paramEntries = Object.entries(params)
  if (!paramEntries.length) {
    return url
  }

  const parsedUrl = urlParse(url)
  parsedUrl.set("query", stringify({ ...parse(extract(url)), ...params }))
  return parsedUrl.toString()
}
