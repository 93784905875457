"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmark = void 0;
function benchmark(debugInstance) {
    return function (_target, propertyKey, descriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args) {
            debugInstance(`Entering ${propertyKey}`);
            const result = originalMethod.apply(this, args);
            debugInstance(`Exiting ${propertyKey}`);
            return result;
        };
        return descriptor;
    };
}
exports.benchmark = benchmark;
