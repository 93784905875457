import React from "react"
import styled from "styled-components/native"
import icons from "@treefort/tokens/app/icons"
import Text from "./text"
import Icon from "./icon"
import Spacer from "./spacer"

const Container = styled.View`
  flex-direction: row;
  align-items: center;
`

function UnobtrusiveButtonView({
  children,
}: {
  children: string
}): JSX.Element {
  return (
    <Container>
      <Text shadow textStyle="strong" color="translucent">
        {children}
      </Text>
      <Spacer size="tiny" horizontal />
      <Icon size="small" source={icons.chevronRight} color="translucent" />
    </Container>
  )
}

export default UnobtrusiveButtonView
