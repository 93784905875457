import type { TableSchemaSpec } from "@nozbe/watermelondb/Schema"

export const progressTable: TableSchemaSpec = {
  name: "Progress",
  columns: [
    { name: "userId", type: "string" },
    { name: "profileId", type: "string", isOptional: true },
    { name: "data", type: "string" },
    { name: "createdAtDate", type: "number" },
    { name: "updatedAtDate", type: "number" },
    { name: "archivedAtDate", type: "number", isOptional: true },
    { name: "syncIgnore_createdLocally", type: "boolean", isOptional: true },
  ],
}
