import styled from "styled-components/native"

import { spacingToNumber, Spacing } from "../lib/spacing"
import Box from "./box"

const Column = styled(Box)<{
  alignItems?: "flex-start" | "center" | "flex-end" | "baseline" | "stretch"
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
  gap?: Spacing
  columnGap?: Spacing
  rowGap?: Spacing
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse"
}>`
  flex-direction: column;
  align-items: ${({ alignItems = "center" }) => alignItems};
  justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  /* NOTE: using the longer interpolation pattern because
  styled-components/native doesn't seem to filter the empty gap properties
  correctly on Android, crashing the app if the property is undefined */
  ${({ theme, gap }) =>
    gap !== undefined ? `gap: ${spacingToNumber(theme, gap)}px;` : ``}
  ${({ theme, rowGap }) =>
    rowGap !== undefined ? `row-gap: ${spacingToNumber(theme, rowGap)}px;` : ``}
  ${({ theme, columnGap }) =>
    columnGap !== undefined
      ? `column-gap: ${spacingToNumber(theme, columnGap)}px;`
      : ``}
`

export default Column
