import { useContext, useEffect, useRef } from "react"
import { AppState, AppStateStatus } from "react-native"

import { useAuth } from "@treefort/lib/auth-provider"
import { Event } from "@treefort/lib/authenticator"

import audioPlayer, { PlaybackState } from "../lib/audio-player"
import authenticator from "../lib/authenticator"
import { Event as AudioPlayerEvent } from "../lib/av/types"
import {
  getCurrentAudioProgressKey,
  getLastPlayedContentAudio,
  loadContentAudio,
  stopAudioPlayer,
} from "../lib/content-audio"
import { logError } from "../lib/logging"
import { ActiveProfileContext } from "../lib/profiles"
import {
  getProgressItemFromSettingValue,
  PlayableProgressItemData,
} from "../lib/progress-item"
import settings from "../lib/settings"
import { syncManager } from "../watermelon/sync"

/**
 * Load the most recent progress (local or remote)
 */
async function loadLastPlayedContentAudio(args: { profileId: string | null }) {
  const lastPlayed = await getLastPlayedContentAudio(args)
  if (lastPlayed) {
    await loadContentAudio(lastPlayed)
  } else {
    await stopAudioPlayer(args)
  }
  return lastPlayed
}

export function InitializeAudioPlayer() {
  const previousAppState = useRef<AppStateStatus | undefined>(undefined)
  const profile = useContext(ActiveProfileContext)
  const auth = useAuth()

  useEffect(() => {
    // Wait to iniitalize until the active profile has been loaded
    if (profile.state === "loading") {
      return
    }

    const profileId = profile.state === "set" ? profile.id : null

    // If the app is moved into the foreground, the audio player is paused, and
    // there is newer remote progress for the content in the audio player, then
    // load the remote progress.
    const appStateSubscription = AppState.addEventListener(
      "change",
      async (appState: AppStateStatus) => {
        try {
          if (
            appState === "active" &&
            // iOS has an "inactive" state in addition to "active" and "background",
            // but we don't want to do anything when moving from "inactive" to
            // "active"
            previousAppState.current !== "inactive" &&
            authenticator.getInitialized() &&
            audioPlayer.getPlaybackState() === PlaybackState.Paused &&
            audioPlayer.getTracks().length
          ) {
            const currentAudioProgressKey = await getCurrentAudioProgressKey({
              profileId,
            })
            if (currentAudioProgressKey) {
              const [localOrRemote, local] = await Promise.all([
                settings
                  .getLocalOrRemote<PlayableProgressItemData>(
                    currentAudioProgressKey,
                    { profileId },
                  )
                  .then(
                    ({ value }) =>
                      value &&
                      getProgressItemFromSettingValue({ value, profileId }),
                  ),
                settings
                  .getLocal<PlayableProgressItemData>(currentAudioProgressKey, {
                    profileId,
                  })
                  .then(
                    ({ value }) =>
                      value &&
                      getProgressItemFromSettingValue({ value, profileId }),
                  ),
              ])
              if (
                localOrRemote &&
                // Make sure we're still paused
                audioPlayer.getPlaybackState() === PlaybackState.Paused
              ) {
                const localOrRemoteProgress = localOrRemote.getProgress()
                const localProgress = local?.getProgress()
                if (localOrRemoteProgress.index !== localProgress?.index) {
                  await audioPlayer.skipToTrack(localOrRemoteProgress.index, {
                    position: localOrRemoteProgress.position,
                  })
                }
              }
            }
          }
        } catch (error) {
          logError(error)
        } finally {
          previousAppState.current = appState
        }
      },
    )

    // Stop the audio player when signing out
    const cleanupLogoutComplete = authenticator.on(
      Event.ActionComplete,
      async (action) => {
        try {
          if (action === "logout") {
            await stopAudioPlayer({ profileId })
          }
        } catch (error) {
          logError(error)
        }
      },
    )

    return () => {
      cleanupLogoutComplete()
      appStateSubscription.remove()
    }
  }, [profile])

  // If the user or profile changes then pause the audio player and releaod the
  // last played content for that user/profile.
  useEffect(() => {
    if (auth.initialized && profile.state !== "loading") {
      const profileId = profile.state === "set" ? profile.id : null
      loadLastPlayedContentAudio({ profileId }).catch(logError)
    }
  }, [auth.initialized, auth.user, profile])

  useEffect(() => {
    const unsubscribeAudioPause = audioPlayer.on(
      AudioPlayerEvent.PlaybackState,
      (state) => {
        if (state === PlaybackState.Paused) {
          syncManager.requestSync({ syncType: "immediate" })
        }
      },
    )

    return unsubscribeAudioPause
  }, [])

  return null
}
