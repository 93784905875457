import { DefaultTheme } from "styled-components/native"
import tokens from "@treefort/tokens/app"

type SpacingToken = keyof typeof tokens.spacing

export type Spacing = SpacingToken | number

export const spacingToNumber = <Value extends Spacing | undefined>(
  tokens: DefaultTheme,
  spacing: Value,
): Value extends undefined ? undefined : number =>
  (typeof spacing === "string" && spacing in tokens.spacing
    ? tokens.spacing[spacing as SpacingToken]
    : typeof spacing === "number"
    ? spacing
    : undefined) as Value extends undefined ? undefined : number
