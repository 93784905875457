import { useCallback, useEffect, useState } from "react"
import { Platform } from "react-native"

import { User } from "@treefort/api-spec"
import {
  isAuthenticatedConditionMet,
  isPlatformsConditionMet,
  isSubscribedConditionMet,
} from "@treefort/lib/conditions"
import { SettingStoreItem } from "@treefort/lib/settings"
import unique from "@treefort/lib/unique"

import useAppManifest from "../hooks/use-app-manifest"
import settings, { settingsStore } from "../lib/settings"
import { Store } from "../lib/store"

const SEEN_SCREEN_IDS = "onboarding.seenScreensIds"

const SYNCED_REMOTELY = "onboarding.syncedRemotely"

// This local store holds IDs of the the screens that have been seen on the
// current device
const localStore = new Store({
  key: "onboardingScreens",
  migrations: [
    {
      name: "seedFromSettings",
      migrate: async (store) => {
        const setting =
          await settingsStore.get<SettingStoreItem<number[]>>(SEEN_SCREEN_IDS)
        if (setting?.value) {
          await store.set(SEEN_SCREEN_IDS, setting.value)
          await settingsStore.remove(SEEN_SCREEN_IDS)
        }
      },
    },
  ],
})

export function useOnboardingScreens({ user }: { user: User | null }) {
  const { onboardingScreens: allScreens } = useAppManifest()
  const enabled = allScreens.length > 0

  const [seenScreenIds, setSeenScreenIds] = useState<number[]>()
  const unseenScreens = seenScreenIds
    ? allScreens.filter((screen) => !seenScreenIds.includes(screen.id))
    : undefined
  const screensToShow = unseenScreens?.filter(({ conditions }) => {
    const authenticatedConditionMet = isAuthenticatedConditionMet(
      conditions,
      Boolean(user),
    )
    const platformConditionMet = isPlatformsConditionMet(
      conditions,
      Platform.OS,
    )
    const subscribedConditionMet = isSubscribedConditionMet(
      conditions,
      typeof user?.subscription?.subscribed === "number" ||
        Array.isArray(user?.subscription?.subscribed),
    )
    return (
      authenticatedConditionMet &&
      platformConditionMet &&
      subscribedConditionMet
    )
  })

  // Onboarding screens are managed at the level of device and account, not the
  // profile
  const profileId = null

  // Update the local setting for onboarding screens and clear the flag that
  // indicates the remote setting has been synced
  const updateLocalStore = useCallback(async (seenScreenIds: number[]) => {
    await localStore.set(SEEN_SCREEN_IDS, seenScreenIds)
    await settings.clearLocal(SYNCED_REMOTELY, { profileId })
  }, [])

  // Update the remote setting for onboarding screens if necessary. If we are in
  // local-only mode or have synced the setting already then do nothing.
  const updateRemoteSetting = useCallback(async (seenScreenIds: number[]) => {
    // Bail if there aren't any new screens to add
    if (!seenScreenIds.length) return

    // Bail if we're in local-only mode
    const localOnly = await settings.localOnly()
    if (localOnly) return

    // Bail if we've already synced remotely
    const syncedRemotely = await settings.getLocal<boolean>(SYNCED_REMOTELY, {
      profileId,
    })
    if (syncedRemotely.value) return

    await settings.updateRemote<number[]>(
      SEEN_SCREEN_IDS,
      (remoteSeenScreenIds) =>
        remoteSeenScreenIds
          ? unique([...remoteSeenScreenIds, ...seenScreenIds])
          : seenScreenIds,
      { profileId },
    )
    await settings.saveLocal(SYNCED_REMOTELY, true, { profileId })
  }, [])

  // When the user exits the onboarding flow update the setting with the screens
  // they have now seen.
  const markScreensAsSeen = useCallback(async () => {
    const nextSeenScreenIds = [
      ...(seenScreenIds || []),
      ...(screensToShow?.map((screen) => screen.id) || []),
    ]
    await updateLocalStore(nextSeenScreenIds)
    setSeenScreenIds(nextSeenScreenIds)
  }, [seenScreenIds, screensToShow, updateLocalStore])

  // Fetch the screens that the user has already seen from the local store and
  // from settings.
  useEffect(() => {
    if (enabled) {
      Promise.all([
        localStore.get<number[]>(SEEN_SCREEN_IDS),
        // We call the local-or-remote API here so that we don't get an error
        // when offline or not authenticated, but we only actually utilize the
        // remote value (local will always be null).
        settings.getLocalOrRemote<number[]>(SEEN_SCREEN_IDS, {
          profileId,
        }),
      ]).then(([localStoreValue, remoteSetting]) => {
        setSeenScreenIds(
          unique([...(localStoreValue || []), ...(remoteSetting.value || [])]),
        )
      })
    }
  }, [enabled])

  // When "seenScreenIds" changes sync the corresponding remote setting if
  // possible.
  useEffect(() => {
    if (seenScreenIds) {
      updateRemoteSetting(seenScreenIds)
    }
  }, [seenScreenIds, updateRemoteSetting])

  return screensToShow
    ? {
        screens: screensToShow,
        state: "loaded" as const,
        markScreensAsSeen,
      }
    : allScreens.length > 0
      ? { state: "loading" as const }
      : { state: "idle" as const }
}
