// react-native-gesture-handler's web componnets are garbage, use RN web's text
// input instead
import { TextInput as ReactNativeWebTextInput } from "react-native"
import styled from "styled-components/native"

// Use our own focus ring color instead of react-native-web's default goldenrod.
const TextInput = styled(ReactNativeWebTextInput)`
  outline-color: ${(props) => props.theme.colors.border.focus};
`
export default TextInput
