import { Route } from "../routes"
import { AppPageScreen } from "./app-page"
import { AppTabScreen } from "./app-tab"
import AuthLoginScreen from "./auth/login"
import AuthRedirectScreen from "./auth/redirect"
import AuthRegisterScreen from "./auth/register"
import CheckoutRedirectScreen from "./checkout/redirect"
import { CollectionScreen } from "./collection"
import { ContentAlbumScreen } from "./content-album"
import { ContentAudiobookScreen } from "./content-audiobook"
import { ContentEbookScreen } from "./content-ebook"
import { ContentPodcastScreen } from "./content-podcast"
import { ContentPodcastEpisodeScreen } from "./content-podcast-episode"
import { ContentVideoScreen } from "./content-video"
import { ContentVideoSeriesScreen } from "./content-video-series"
import { ContentWebEmbedScreen } from "./content-web-embed"
import { LibraryScreen } from "./library"
import { MenuScreen } from "./menu"
import AboutScreen from "./menu/about"
import { AccountScreen } from "./menu/account"
import { CancelSubscriptionScreen } from "./menu/cancel-subscription"
import CheckoutScreen from "./menu/checkout"
import ContactFormScreen from "./menu/contact-form"
import DeleteAccountScreen from "./menu/delete-account"
import { DownloadsScreen } from "./menu/downloads"
import SettingsScreen from "./menu/settings"
import { AdvancedSettingsScreen } from "./menu/settings/advanced"
import { SubscriptionScreen } from "./menu/subscription"
import VerifyEmailScreen from "./menu/verify-email"
import { NotFoundScreen } from "./not-found"
import SearchDesktopScreen from "./search/desktop"
import SearchMobileScreen from "./search/mobile"
import { UpdateScreen } from "./update"

const components = {
  SettingsScreen,
  AdvancedSettingsScreen,
  AuthLoginScreen,
  AuthRedirectScreen,
  AuthRegisterScreen,
  ContactFormScreen,
  AccountScreen,
  DeleteAccountScreen,
  SubscriptionScreen,
  AboutScreen,
  CancelSubscriptionScreen,
  CheckoutScreen,
  CheckoutRedirectScreen,
  VerifyEmailScreen,
  MenuScreen,
  DownloadsScreen,
  SearchMobileScreen,
  SearchDesktopScreen,
  AppPageScreen,
  CollectionScreen,
  ContentVideoSeriesScreen,
  ContentVideoScreen,
  ContentAlbumScreen,
  ContentAudiobookScreen,
  ContentEbookScreen,
  ContentPodcastScreen,
  ContentPodcastEpisodeScreen,
  ContentWebEmbedScreen,
  AppTabScreen,
  LibraryScreen,
  UpdateScreen,
  NotFoundScreen,
}

/**
 * Get the React component that should be rendered for a route. This is split
 * out from the routes.ts file to avoid circular dependencies.
 */
export function getScreenComponentFromRoute(
  route: Route,
): (props: unknown) => JSX.Element | null {
  const component = components[route.component as keyof typeof components]
  if (!component) {
    throw new Error("Component does not exist for route " + route.path)
  }
  return component
}
