import { useCallback } from "react"

import { useAuth } from "@treefort/lib/auth-provider"

import api from "../lib/api"
import { logError } from "../lib/logging"
import settings from "../lib/settings"
import useAppManifest from "./use-app-manifest"
import { SettingHookReturn, useSetting } from "./use-setting"

const LOCALE_SETTING_KEY = "locale"

export function useLocaleSetting(): SettingHookReturn<string | undefined> {
  const auth = useAuth()
  const manifest = useAppManifest()
  const [setting, setSetting] = useSetting<string>({
    key: LOCALE_SETTING_KEY,
    settings,
    strategy: "local",
    // The chosen locale is saved at the account level in FusionAuth so we can't
    // allow it to be per-profile.
    profileId: null,
  })
  const settingEmpty = setting === null
  const settingInvalid = setting && !(setting in manifest.i18n.locales)
  const userId = auth.user?.id
  const locale =
    settingEmpty || settingInvalid ? manifest.i18n.defaultLocale : setting
  const setLocale = useCallback(
    async (locale: string | undefined) => {
      if (userId) {
        api
          .patch(`/users/${userId}`, { locale })
          .catch((cause) =>
            logError(
              new Error(`[i18n] Failed to update user locale`, { cause }),
            ),
          )
      }
      await setSetting(locale)
    },
    [setSetting, userId],
  )
  return [locale, setLocale]
}
