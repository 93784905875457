import React from "react"
import { Platform, SwitchProps } from "react-native"
import { Switch as OriginalSwitch } from "react-native-gesture-handler"
import { colorWithAlpha, mixColors } from "@treefort/lib/color"
import { useTokens } from "./tokens-provider"

export default function Switch(props: SwitchProps): JSX.Element {
  const { tokens } = useTokens()
  const disabledState = props.disabled ? "disabled" : "enabled"
  const thumbColor = tokens.switch.thumbColor[disabledState]
  const trackColor = tokens.switch.trackColor[disabledState]
  const accentColor = props.disabled
    ? mixColors(tokens.switch.accentColor, tokens.colors.background.primary)
    : tokens.switch.accentColor
  const colorProps = Platform.select({
    web: {
      thumbColor,
      activeThumbColor: accentColor,
      trackColor: {
        true: colorWithAlpha(accentColor, 0.5),
        false: trackColor,
      },
    },
    android: {
      thumbColor: props.value ? accentColor : thumbColor,
      trackColor: {
        true: colorWithAlpha(accentColor, 0.5),
        false: trackColor,
      },
    },
    ios: {
      trackColor: {
        true: accentColor,
        false: trackColor,
      },
    },
  })
  return <OriginalSwitch {...props} {...colorProps} />
}
