import { EventEmitter } from "@treefort/lib/event-emitter"

import { EventMap, Event } from "./base"

class SplashScreen extends EventEmitter<EventMap> {
  private element: HTMLElement

  constructor() {
    super()

    // This element is hardcoded in index.html and should always exist
    const element = document.getElementById("splash")
    if (!element) {
      throw new Error("[SplashScreen] Couldn't find element")
    }
    this.element = element
  }

  public async hide() {
    this.element.style.display = "none"
    await this.emitter.emit(Event.Hidden)
  }
}

export const splashScreen = new SplashScreen()
export { Event }
