"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayableError = void 0;
/**
 * An error class that contains a human-friendly message that is indented to be
 * shown to the user. It's up to each app's error handling logic to determine
 * what to do with errors that are instances of this class, but it's helpful for
 * signaling that an error _should_ be displayed.
 */
class DisplayableError extends Error {
    constructor(message, cause) {
        super(message, { cause });
    }
}
exports.DisplayableError = DisplayableError;
