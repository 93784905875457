"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAvailableData = exports.isAvailable = void 0;
function isAvailable(source) {
    return (source === null || source === void 0 ? void 0 : source.status) === "available";
}
exports.isAvailable = isAvailable;
function getAvailableData(source) {
    return isAvailable(source) ? source.data : undefined;
}
exports.getAvailableData = getAvailableData;
