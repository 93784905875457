"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndexedDbStore = exports.StoreEvent = void 0;
/**
 * Key/value store based on Indexed DB (via idb-keyval).
 *
 * NOTE: Only available for the web.
 */
const idb_keyval_1 = require("idb-keyval");
const base_1 = require("./base");
Object.defineProperty(exports, "StoreEvent", { enumerable: true, get: function () { return base_1.StoreEvent; } });
const createKeyValStore = () => (0, idb_keyval_1.createStore)("keyval-store", "keyval");
class IndexedDbStore extends base_1.Store {
    constructor(options) {
        super(options);
        /**
         * Retry failed transactions to work around a bug in webkit
         * (https://bugs.webkit.org/show_bug.cgi?id=197050). This strategy is modeled
         * after Firebase's solution to the same bug
         * (https://github.com/firebase/firebase-js-sdk/pull/4059).
         */
        this.withRetry = (callback, attempts = 3) => callback().catch((error) => {
            const attemptsLeft = attempts - 1;
            if (attemptsLeft > 0) {
                this.store = createKeyValStore();
                return this.withRetry(callback, attemptsLeft);
            }
            else {
                throw error;
            }
        });
        this._get = async (key) => this.withRetry(() => (0, idb_keyval_1.get)(key, this.store));
        this._set = async (key, value) => this.withRetry(() => (0, idb_keyval_1.set)(key, value, this.store));
        this._remove = async (key) => this.withRetry(() => (0, idb_keyval_1.del)(key, this.store));
        this._keys = async () => this.withRetry(() => (0, idb_keyval_1.keys)(this.store));
        this._getMany = async (keys) => this.withRetry(() => (0, idb_keyval_1.getMany)(keys, this.store));
        this._removeMany = async (keys) => this.withRetry(() => (0, idb_keyval_1.delMany)(keys, this.store));
        this.store = createKeyValStore();
    }
}
exports.IndexedDbStore = IndexedDbStore;
