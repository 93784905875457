import { t } from "i18next"

import { prompt } from "./prompt"
import { toast } from "./toaster"

export const isParent = async () => {
  const input = await prompt({
    title: t("Ask your parents"),
    message: t("To continue please enter the year of your birth."),
  })
  const currentYear = new Date().getFullYear()
  const birthYear = input ? parseInt(input) : NaN
  const age = currentYear - birthYear
  if (!input?.trim()) {
    return
  } else if (isNaN(age) || age <= 12 || age >= 110) {
    toast.error(t("Please ask your parents for help."))
    return false
  } else {
    return true
  }
}
