import React, { useEffect, useState } from "react"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

const Components: Record<string, React.FunctionComponent | null | undefined> =
  {}

/**
 * This utility provides an easy way to lazy-load and then render a component.
 * An optional fallback component may be provided and will be rendered if the
 * `enabled` prop is false.
 */
export default function LazyLoadComponent<T extends Record<string, unknown>>({
  importKey,
  importComponent,
  enabled,
  props = {} as T,
  FallbackComponent,
}: {
  props?: T
  // A key for the imported preview component. Must be unique across the whole
  // app.
  importKey: string
  importComponent: () => Promise<{
    default: React.FunctionComponent<T>
  }>
  enabled: boolean
  FallbackComponent?: React.FunctionComponent<T>
}): JSX.Element | null {
  const Component = Components[importKey]
  const [_, refresh] = useState({})
  const willUnmount = useWillUnmount()

  useEffect(() => {
    if (enabled && !Component) {
      importComponent().then((module) => {
        Components[importKey] = module.default as React.FunctionComponent
        if (!willUnmount.current) {
          refresh({})
        }
      })
    }
  }, [enabled, importComponent, importKey, Component, willUnmount])

  if (enabled) {
    return Component ? <Component {...props} /> : null
  } else {
    return FallbackComponent ? <FallbackComponent {...props} /> : null
  }
}
