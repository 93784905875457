/**
 * Utilities to play HTTP Live Streaming media on web clients that don't support
 * it natively.
 */

import type { ErrorData } from "hls.js"
type HlsImport = typeof import("hls.js")
type HlsClass = HlsImport["default"]

export { isHlsUrl } from "./hls.native"

let hlsImport: Promise<HlsImport> | undefined

/**
 * Returns true if the client has good native support for HLS. Unfortunately
 * there's not a great way to do feature detection for this since some clients
 * will _say_ they support it but then fall over when you actually ask them to
 * (looking at you, Chrome on Android). The best we can do is check to see if
 * we're in an Apple client - if so native HLS is safe to use, otherwise we
 * should use hls.js to take care of any potential incompatibilities.
 */
export const isHlsSupportedNatively =
  typeof navigator !== undefined && navigator.vendor.includes("Apple")

/**
 * This lazy-loads hls.js and returns the default export. Don't call this on
 * platforms that support HLS natively (as determined by
 * `isHlsSupportedNatively`. It is safe to call this function more than once -
 * only one copy of hls.js will be imported.
 */
export async function importHlsJs(): Promise<HlsClass> {
  if (!hlsImport) {
    hlsImport = import("hls.js")
  }
  return (await hlsImport).default
}

/**
 * This creates an instance of hls.js, attaches it to a video or audio element,
 * waits until the instance is attached, and then returns the instance. hls.js
 * will be imported automatically if it has not been already. If you know in
 * advance that this function will be called then you can speed things up by
 * calling `importHlsJs` ahead of time.
 */
export async function attachHlsJs(
  elem: HTMLMediaElement,
  onError: (error: ErrorData) => unknown,
): Promise<InstanceType<HlsClass>> {
  const Hls = await importHlsJs()
  const hls = new Hls()
  hls.attachMedia(elem)
  hls.on(Hls.Events.ERROR, function (_event, error) {
    if (error.fatal) {
      switch (error.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          return hls.startLoad()
        case Hls.ErrorTypes.MEDIA_ERROR:
          return hls.recoverMediaError()
        default:
          return onError(error)
      }
    }
  })
  await new Promise((resolve) => hls.on(Hls.Events.MEDIA_ATTACHED, resolve))
  return hls
}
