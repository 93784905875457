import React from "react"

import { t } from "i18next"
import styled from "styled-components/native"

import {
  SubscriptionPlanResponse,
  UserSubscriptionsResponse,
} from "@treefort/api-spec"
import icons from "@treefort/tokens/app/icons"

import { useSubscriptionPlan } from "../hooks/subscriptions"
import { getCurrentSubscription } from "../lib/subscriptions"
import { TextStyle } from "../lib/text-style"
import Icon, { IconProps } from "./icon"
import Row from "./row"
import Text, { TextColor } from "./text"

function getSubscriptionStatusSummary({
  userSubscriptions,
  plan,
  willRenewWithPlan,
  showPlanName,
}: {
  userSubscriptions: UserSubscriptionsResponse
  plan: SubscriptionPlanResponse
  willRenewWithPlan?: SubscriptionPlanResponse
  showPlanName?: boolean
}): {
  primaryLabel: string
  secondaryLabel?: string
  icon?: IconProps["source"]
  iconColor?: IconProps["color"]
} {
  const subscription = getCurrentSubscription(userSubscriptions)
  const currentPeriodEndsAt = subscription?.currentPeriodEndsAt
    ? new Date(subscription.currentPeriodEndsAt)
    : null

  // Redundant subscriptions
  if (userSubscriptions.redundantSubscriptionIds.length) {
    return {
      primaryLabel: t("Multiple subscriptions"),
      icon: icons.alert,
      iconColor: "danger",
    }
  }

  // Group memberships
  if (plan.provider === "groupMembership") {
    if (subscription && !subscription?.deactivatedAt) {
      return {
        primaryLabel: showPlanName ? plan.name : t("Subscribed"),
        icon: icons.checkCircle.fill,
        iconColor: "accent",
      }
    } else {
      return { primaryLabel: t("Not subscribed") }
    }
  }

  switch (subscription?.status) {
    case "canceledWithTimeLeft":
      return {
        primaryLabel: t("Canceled"),
        secondaryLabel: currentPeriodEndsAt
          ? t("Valid until {{date}}", { date: currentPeriodEndsAt })
          : undefined,
        icon: icons.closeCircle.fill,
        iconColor: "danger",
      }

    case "freeTrial":
    case "subscribed": {
      const freeTrial =
        subscription.trialEndsAt &&
        new Date(subscription.trialEndsAt) > new Date()
      const primaryLabel = freeTrial
        ? t("Free Trial")
        : showPlanName
          ? plan.name
          : t("Subscribed")
      const secondaryLabel =
        currentPeriodEndsAt && willRenewWithPlan
          ? t("Renews {{date}} as {{planName}}", {
              date: currentPeriodEndsAt,
              planName: willRenewWithPlan.name,
            })
          : currentPeriodEndsAt && freeTrial
            ? t("Billing starts {{date}}", { date: currentPeriodEndsAt })
            : currentPeriodEndsAt
              ? t("Renews {{date}}", { date: currentPeriodEndsAt })
              : undefined
      return {
        primaryLabel,
        secondaryLabel,
        icon: icons.checkCircle.fill,
        iconColor: "accent",
      }
    }

    case "paymentPastDue":
      return {
        primaryLabel: t("Payment past due"),
        icon: icons.alert,
        iconColor: "danger",
      }

    case "lapsed":
    case "canceled":
      return {
        primaryLabel: t("Expired"),
        secondaryLabel: subscription.deactivatedAt
          ? t("Deactivated {{date}}", {
              date: new Date(subscription.deactivatedAt),
            })
          : undefined,
        icon: icons.closeCircle.fill,
        iconColor: "danger",
      }
  }

  return { primaryLabel: t("Not subscribed") }
}

const SubscriptionStatusLabel = styled(Text)`
  flex: 1;
`

export function SubscriptionStatusSummary({
  userSubscriptions,
  subscriptionPlan,
  textStyle = "body",
  textColor,
  showPlanName,
  showSecondaryLabel,
}: {
  userSubscriptions: UserSubscriptionsResponse
  subscriptionPlan: SubscriptionPlanResponse | undefined
  textStyle?: TextStyle
  textColor?: TextColor
  showPlanName?: boolean
  showSecondaryLabel?: boolean
}) {
  const subscription = getCurrentSubscription(userSubscriptions)
  const willRenewWithPlan = useSubscriptionPlan(
    subscription?.willRenewWithSubscriptionPlanId,
  )

  if (!subscription || !subscriptionPlan) {
    return null
  }

  const { primaryLabel, secondaryLabel, icon, iconColor } =
    getSubscriptionStatusSummary({
      userSubscriptions,
      willRenewWithPlan: willRenewWithPlan.data,
      plan: subscriptionPlan,
      showPlanName,
    })

  return (
    <>
      <Row alignItems="center" gap="tiny">
        {icon ? <Icon size="small" color={iconColor} source={icon} /> : null}
        <SubscriptionStatusLabel color={textColor} textStyle={textStyle}>
          {primaryLabel}
        </SubscriptionStatusLabel>
      </Row>
      {secondaryLabel && showSecondaryLabel ? (
        <SubscriptionStatusLabel color={textColor} textStyle={textStyle}>
          {secondaryLabel}
        </SubscriptionStatusLabel>
      ) : null}
    </>
  )
}
