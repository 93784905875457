"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openSansRegular = void 0;
exports.openSansRegular = {
    familyName: "Open Sans",
    weight: "400",
    style: "normal",
    ttfName: "OpenSans-Regular",
    ttfFile: "open-sans-regular.ttf",
    woff2File: "open-sans-regular.woff2",
};
