import React, { useCallback, useMemo } from "react"
import { ScrollView } from "react-native-gesture-handler"
import { EdgeInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import {
  AppOnboardingScreen,
  AppOnboardingScreenAction,
} from "@treefort/api-spec"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"

import useAppManifest from "../hooks/use-app-manifest"
import authenticator from "../lib/authenticator"
import { restoreAllInAppPurchases } from "../lib/in-app-purchases"
import openUrl from "../lib/open-url"
import { AsyncButton } from "./async-button"
import Box from "./box"
import { Heading } from "./heading"
import Image from "./image"
import ImageContained from "./image-contained"
import Spacer from "./spacer"
import Text from "./text"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

export const ONBOARDING_SCREEN_MAX_WIDTH = 600

// Unlocks a scroll view during body scroll locking on the web
const WEB_SCROLL_UNLOCK = { webScrollUnlock: true }

const Container = styled.View`
  position: relative;
  overflow: hidden;
  height: 100%;
`

const StyledScrollView = styled(ScrollView)<{ width: number }>`
  height: 100%;
  width: ${(props) => props.width}px;
`

const ContentBox = styled(Box)`
  align-items: center;
  justify-content: center;
`

const ImageView = styled.View`
  align-items: center;
  flex: 1;
  min-height: 128px;
`

const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const Background = styled.View<{ color?: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${(props) => props.color && `background-color: ${props.color}`};
`

const SecondaryAction = styled(Touchable)`
  height: ${(props) => props.theme.minTapTarget}px;
  flex-direction: row;
  align-items: center;
`

export default function OnboardingScreen({
  screen,
  width,
  insets,
  onRequestClose,
}: {
  screen: AppOnboardingScreen
  width: number
  onRequestClose: (
    closeCompleteCallback?: () => unknown,
  ) => Promise<void> | void
  insets?: EdgeInsets
}): JSX.Element {
  const { tokens } = useTokens()
  const {
    features: { parentalGateway },
  } = useAppManifest()

  const getActor = useCallback(
    (action: AppOnboardingScreenAction): (() => Promise<void> | void) => {
      switch (action.type) {
        case "login":
          return () => onRequestClose(authenticator.login)

        case "register":
          return () => onRequestClose(authenticator.register)

        case "restorePurchases":
          return async () => {
            await restoreAllInAppPurchases()
          }
        case "exit":
          return () => onRequestClose()
        case "openUrl":
          return () => openUrl(action.url, { parentalGateway })
      }
    },
    [onRequestClose, parentalGateway],
  )

  const contentContainerStyle = useMemo(
    () => ({
      minHeight: "100%" as const,
      paddingTop: insets?.top,
      paddingBottom: insets?.bottom,
      paddingLeft: insets?.left,
      paddingRight: insets?.right,
    }),
    [insets],
  )

  // A bit hacky, but to keep the bottom padding exactly xlarge we have to
  // account for the bottom padding that is conditionally rendered below
  const titleSpacing = tokens.spacing.xlarge
  const messageSpacing = tokens.spacing.large
  const primaryActionSpacing = tokens.spacing.xsmall
  const secondaryActionSpacing = 0
  const contentPaddingBottom =
    tokens.spacing.xlarge -
    (screen.secondaryAction
      ? secondaryActionSpacing
      : screen.primaryAction
        ? primaryActionSpacing
        : screen.message
          ? messageSpacing
          : screen.title
            ? titleSpacing
            : 0)

  return (
    <Container>
      {screen.background?.type === "image" ? (
        <Background color={tokens.colors.background.primary}>
          <BackgroundImage
            source={getOptimizedImageSource(
              { uri: screen.background.url },
              { width: ONBOARDING_SCREEN_MAX_WIDTH },
            )}
            resizeMode="cover"
          />
        </Background>
      ) : screen.background?.type === "color" ? (
        <Background color={screen.background.color} />
      ) : null}
      <StyledScrollView
        width={width}
        contentContainerStyle={contentContainerStyle}
        dataSet={WEB_SCROLL_UNLOCK}
      >
        <Spacer size="large" />
        <ImageView>
          <ImageContained
            containerStyle={{
              flex: 1,
              width: screen.imageWidth,
            }}
            uri={getOptimizedImageSource(screen.image, {
              width: screen.imageWidth,
            })}
          />
        </ImageView>
        <ContentBox
          paddingHorizontal="pagePaddingHorizontal"
          paddingTop="large"
          paddingBottom={contentPaddingBottom}
        >
          {screen.title ? (
            <>
              <Heading
                alignment="center"
                level={2}
                textStyle="headingLarge"
                color="highContrast"
              >
                {screen.title}
              </Heading>
              <Spacer size={titleSpacing} />
            </>
          ) : null}
          {screen.message ? (
            <>
              <Text alignment="center" textStyle="body" color="highContrast">
                {screen.message}
              </Text>
              <Spacer size={messageSpacing} />
            </>
          ) : null}
          {screen.primaryAction ? (
            <>
              <AsyncButton
                onPress={getActor(screen.primaryAction.action)}
                type="primary"
              >
                {screen.primaryAction.title}
              </AsyncButton>
              <Spacer size={primaryActionSpacing} />
            </>
          ) : null}
          {screen.secondaryAction ? (
            <SecondaryAction
              feedback="opacity"
              onPress={getActor(screen.secondaryAction.action)}
              role="link"
              aria-label={screen.secondaryAction.title}
            >
              <Text textStyle="body" color="highContrast">
                {screen.secondaryAction.title}
              </Text>
            </SecondaryAction>
          ) : null}
        </ContentBox>
      </StyledScrollView>
    </Container>
  )
}
