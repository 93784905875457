import React from "react"

import styled from "styled-components/native"

import { AppModuleLink } from "@treefort/api-spec"
import icons from "@treefort/tokens/app/icons"

import { getAbsoluteLineHeight } from "../../lib/text-style"
import { AppLink } from "../app-link"
import Box, { BoxPadding } from "../box"
import Column from "../column"
import { Heading } from "../heading"
import Icon from "../icon"
import Text from "../text"
import { ResolvedTokens, useTokens } from "../tokens-provider"

type LinkModuleProps = AppModuleLink["config"] & {
  maxWidth?: number
  paddingTop?: BoxPadding
}

const LINK_TEXT_STYLE = "headingSmall"

const Title = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: ${(props) => props.theme.links.title.paddingBottom}px;
  border-bottom-width: 1px;
  border-bottom-color: ${(props) => props.theme.colors.border.primary};
`

const LinksListContainer = styled.View<{
  height: number
}>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height}px;
  flex-wrap: wrap;
`

const LinkContainer = styled.View<{
  isInFirstColumn: boolean
  isInLastColumn: boolean
  numberOfColumns: number
}>`
  width: 100%;
  max-width: ${(props) => 100 / props.numberOfColumns}%;
  height: ${({ theme }) => getLinkHeight(theme)}px;
  ${({ theme, isInFirstColumn }) =>
    isInFirstColumn
      ? ""
      : `padding-left: ${theme.links.column.gutterWidth / 2}px`};
  ${({ theme, isInLastColumn }) =>
    isInLastColumn
      ? ""
      : `padding-right: ${theme.links.column.gutterWidth / 2}px`};
`

const LinkInnerWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
  padding-left: ${(props) => props.theme.links.paddingLeft}px;
  padding-right: ${(props) => props.theme.links.paddingLeft}px;
`

const StyledLink = styled(AppLink)`
  height: 100%;
`

const StyledText = styled(Text)`
  flex-shrink: 1;
  margin-right: 6px;
`

const getLinkHeight = (tokens: ResolvedTokens) =>
  getAbsoluteLineHeight(LINK_TEXT_STYLE, tokens) + tokens.links.row.gap

/**
 * This returns the actual maximum possible number of columns less than or equal
 * to maxCount, given the constraint that a new column can only be created if
 * all the columns before it are full.
 */
const getColumnCount = ({
  tokens,
  links,
}: {
  tokens: ResolvedTokens
  links: LinkModuleProps["links"]
}): number => {
  const maxColumnCount = tokens.links.column.maxCount
  return Math.min(
    Math.ceil(links.length / 2),
    links.length % Math.ceil(links.length / maxColumnCount) === 0
      ? links.length / Math.ceil(links.length / maxColumnCount)
      : maxColumnCount,
  )
}

const getRowCount = ({
  tokens,
  links,
}: {
  tokens: ResolvedTokens
  links: LinkModuleProps["links"]
}): number => Math.ceil(links.length / getColumnCount({ tokens, links }))

export const getLinkModuleContentHeight = ({
  tokens,
  links,
}: Pick<LinkModuleProps, "links"> & {
  tokens: ResolvedTokens
}): number => {
  const rowCount = getRowCount({ tokens, links })
  return getLinkHeight(tokens) * rowCount
}

export const getLinkModuleHeight = ({
  tokens,
  title,
  contentHeight,
}: Pick<LinkModuleProps, "title"> & {
  tokens: ResolvedTokens
  contentHeight: number
}): number => {
  const titleBorderHeight = title ? 1 : 0
  const titleHeight = title
    ? getAbsoluteLineHeight(tokens.links.title.textStyle, tokens) +
      tokens.spacing.medium
    : 0
  return titleHeight + titleBorderHeight + contentHeight
}

export function LinkModule({
  title,
  links,
  actionLink,
  paddingTop,
  maxWidth,
}: LinkModuleProps): JSX.Element {
  const { tokens } = useTokens()
  const columnCount = getColumnCount({ tokens, links })
  const rowCount = getRowCount({ tokens, links })
  const lastColumnRowCount = links.length % rowCount || rowCount

  return (
    <Column
      paddingHorizontal="pagePaddingHorizontal"
      paddingTop={paddingTop}
      alignItems="center"
    >
      <Box width="100%" maxWidth={maxWidth}>
        <Title>
          <Heading
            level={3}
            numberOfLines={1}
            textStyle={tokens.links.title.textStyle}
          >
            {title}
          </Heading>
          {actionLink && (
            <AppLink to={actionLink.link} aria-label={actionLink.title}>
              <Text textStyle="body">{actionLink.title}</Text>
            </AppLink>
          )}
        </Title>
        <LinksListContainer
          height={getLinkModuleContentHeight({ tokens, links })}
        >
          {links.map(({ title, link }, i) => (
            <LinkContainer
              key={`${title}-${i}`}
              isInFirstColumn={i < rowCount}
              isInLastColumn={i >= links.length - lastColumnRowCount}
              numberOfColumns={columnCount}
            >
              <Box borderBottomColor="primary" height="100%">
                <StyledLink
                  to={link}
                  feedback="ripple-or-highlight"
                  arial-label={title}
                >
                  <LinkInnerWrapper>
                    <StyledText
                      textStyle={LINK_TEXT_STYLE}
                      numberOfLines={1}
                      color="secondary"
                    >
                      {title}
                    </StyledText>
                    <Icon
                      source={icons.chevronRight}
                      size="small"
                      color="secondary"
                    />
                  </LinkInnerWrapper>
                </StyledLink>
              </Box>
            </LinkContainer>
          ))}
        </LinksListContainer>
      </Box>
    </Column>
  )
}
