import styled from "styled-components/native"

import tokens from "@treefort/tokens/app"

export type Spacing = keyof typeof tokens.spacing | number

/**
 * @deprecated Use the gap prop on a Column or Row component
 */
const Spacer = styled.View<{ size: Spacing; horizontal?: boolean }>`
  flex-shrink: 0;
  ${(props) => (props.horizontal ? "width" : "height")}: ${(props) =>
    typeof props.size === "number"
      ? props.size
      : props.theme.spacing[props.size]}px;
`

export default Spacer
