import { Platform } from "react-native"

import { ContactPostData, User } from "@treefort/api-spec"
import { INTEGRATION_PROVIDER_METADATA } from "@treefort/constants"
import { getSubscriptionStatusLabel } from "@treefort/lib/subscription-status"

import config from "../config"
import analytics from "./analytics"
import api from "./api"
import { getSubscriptionPlan } from "./subscription-plans"

export type MessageType = "Contact" | "Feedback" | "Question"

const platformName = Platform.select({
  web: "Web",
  android: "Android",
  ios: "iOS",
})

/**
 * Returns a string containing metadata that should be sent with any feedback
 * requests. This string can be passed via the `ContactPostData.platform`
 * field.
 */
export const getContactMetadata = async ({
  user,
}: {
  user?: User | null
}): Promise<string> => {
  // Along with the feedback we send version data for the app, browser (if on
  // the web), and device.
  const appInfo = `App: ${platformName} ${config.APP_VERSION}`
  let operatingSystemInfo: undefined | string
  let browserInfo: undefined | string

  // On the web we lazy-load ua-parser-js and use that to get specific browser
  // and operating system estimates because react-native-web's Platform
  // implementation doesn't tell us much (just "web").
  if (Platform.OS === "web") {
    const { default: parseUserAgent } = await import("ua-parser-js")
    const userAgent = parseUserAgent()
    operatingSystemInfo = `Operating System: ${userAgent.os.name} ${userAgent.os.version}`
    browserInfo = `Browser: ${userAgent.browser.name} ${userAgent.browser.version}`
  } else {
    operatingSystemInfo = `Operating System: ${platformName} ${Platform.Version}`
  }

  // Include info about the user's account and subscription status
  const userInfo = user ? `User ID: ${user.id}` : "User ID: Not signed in"
  const subscriptionStatusInfo = `Subscription Status: ${getSubscriptionStatusLabel(
    user?.subscription.subscriptionStatus,
  )}`

  // Include the names of all of the user's subscription plans
  const planIds =
    typeof user?.subscription.subscribed === "number"
      ? [user.subscription.subscribed]
      : user?.subscription.subscribed &&
          Array.isArray(user.subscription.subscribed)
        ? user.subscription.subscribed
        : []
  let planNames: string[] = []
  if (planIds.length) {
    const plans = await Promise.all(
      planIds.map((planId) => getSubscriptionPlan(planId)),
    )
    planNames = planIds.map((planId) => {
      const plan = plans.find((plan) => plan.id === planId)
      return plan
        ? `${plan.name} (${INTEGRATION_PROVIDER_METADATA[plan.provider].title})`
        : `#${planId}`
    })
  }
  const subscriptionPlanInfo = planNames.length
    ? `Subscription Plan: ${planNames.join(", ")}`
    : null

  return [
    userInfo,
    subscriptionStatusInfo,
    subscriptionPlanInfo,
    appInfo,
    operatingSystemInfo,
    browserInfo,
  ]
    .filter(Boolean)
    .join("\n")
}

export const sendContactEmail = async (
  data: ContactPostData,
): Promise<void> => {
  analytics.logContact()
  await api.post(`/apps/${config.APP_ID}/contact`, data)
}
