import React from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import AppHeader from "../../../components/app-header"
import {
  SearchProvider,
  SearchInput,
  SearchResults,
} from "../../../components/search"
import { useTokens } from "../../../components/tokens-provider"
import useSafeAreaInsetAppHeader from "../../../hooks/use-safe-area-inset-app-header"
import ScrollableLayout from "../../layouts/scrollable"

export default function SearchScreen(): JSX.Element {
  const { tokens } = useTokens()
  const insets = useSafeAreaInsets()
  const appHeaderHeight = useSafeAreaInsetAppHeader()
  return (
    <SearchProvider>
      <ScrollableLayout
        header={
          <AppHeader
            childrenHeight={
              tokens.searchScreen.textInput.height -
              tokens.spacing.medium +
              tokens.searchScreen.textInput.underlineHeight
            }
            dropShadow={false}
          >
            <SearchInput />
          </AppHeader>
        }
        headerHeight={
          appHeaderHeight +
          tokens.searchScreen.textInput.height -
          tokens.spacing.medium +
          tokens.searchScreen.textInput.underlineHeight
        }
        footerHeight={insets.bottom}
        keyboardDismissMode="on-drag"
      >
        <SearchResults />
      </ScrollableLayout>
    </SearchProvider>
  )
}
