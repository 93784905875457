import React, { useEffect } from "react"
import { LocalStorageStore } from "@treefort/lib/store/local-storage"
import { SplashScreen } from "../../splash"
import { history } from "../../../../lib/history.web"

const STORE_KEY = "location"

const store = new LocalStorageStore({ key: "authRedirect" })

/**
 * Save a location in the store in order to navigate back to it after signing in
 * or signing up.
 */
const saveLocation = (location: { pathname: string }): void => {
  // Ignore locations starting with /auth
  if (!/^\/auth/.test(location.pathname)) {
    store.setSync(STORE_KEY, location)
  }
}

// Save the user's location when the page is loaded and any time the URL
// changes.
saveLocation(window.location)
history.listen(saveLocation)

/**
 * This screen is mounted when our auth service redirects back to the app.
 */
export default function AuthRedirectScreen(): JSX.Element | null {
  useEffect(() => {
    // Extract the last saved location from the store (or default to the root
    // path), clear the store, and navigate to the location
    const location = store.getSync<Location | string>(STORE_KEY) || "/"
    store.removeSync(STORE_KEY)
    history.replace(location)
  }, [])

  return <SplashScreen />
}
