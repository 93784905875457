"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openSansBold = void 0;
exports.openSansBold = {
    familyName: "Open Sans",
    weight: "700",
    style: "normal",
    ttfName: "OpenSans-Bold",
    ttfFile: "open-sans-bold.ttf",
    woff2File: "open-sans-bold.woff2",
};
