"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uniqueId = void 0;
let counter = 0;
/**
 * Generate an identifier that's unique to the current runtime. The identifier
 * is _not_ universally unique.
 */
function uniqueId(prefix = "") {
    const id = ++counter;
    return `${prefix}${id}`;
}
exports.uniqueId = uniqueId;
