import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import queryString from "query-string"

import { LocalStorageStore } from "@treefort/lib/store/local-storage"

import analytics from "../../lib/analytics"
import authenticator from "../../lib/authenticator"
import { history } from "../../lib/history.web"
import { logError } from "../../lib/logging"
import { AppPreviewContext } from "./base"

const APP_PREVIEW_TOKEN_KEY = "appPreviewToken"

const store = new LocalStorageStore({ key: "appPreview" })

/**
 * Extract the app preview token from the query string or from the store. The
 * presence of this token indicates whether the app is in preview mode or not.
 * If the token is set it can be passed to the API to subscribe to preview
 * events (see lib/app-preview.ts).
 */
const getToken = (): string | undefined => {
  const queryStringToken = queryString.parse(window.location.search)[
    APP_PREVIEW_TOKEN_KEY
  ]
  if (typeof queryStringToken === "string") {
    store.setSync(APP_PREVIEW_TOKEN_KEY, queryStringToken)
    history.replace(window.location.pathname)
    return queryStringToken
  } else {
    return store.getSync(APP_PREVIEW_TOKEN_KEY) ?? undefined
  }
}

/**
 * Update global configuration based on whether we're in app preview mode or
 * not.
 */
const updateGlobalConfig = (token: string | undefined): void => {
  // Disable analytics in preview mode so we don't add noise to our
  // production analytics.
  analytics.setEnabled(!token).catch(logError)

  // Use "bearer" auth in preview mode instead of "cookie" auth. Secure,
  // same-site cookies are not allowed from iframes.
  authenticator.setType(token ? "bearer" : "cookie")
}

/**
 * Provide all components in the app with access to the app preview state and,
 * if preview mode is on, the preview token and a function to exit preview mode.
 */
export default function AppPreviewProvider({
  children,
}: {
  children?: ReactNode
}): JSX.Element {
  const initialToken = useMemo(getToken, [])
  const [token, setToken] = useState<string | undefined>(initialToken)
  const exit = useCallback(async () => {
    store.removeSync(APP_PREVIEW_TOKEN_KEY)
    setToken(undefined)
  }, [])
  useEffect(() => {
    updateGlobalConfig(token)
  }, [token])
  return (
    <AppPreviewContext.Provider
      value={token ? { state: "on", token, exit } : { state: "off" }}
    >
      {children}
    </AppPreviewContext.Provider>
  )
}

// Update config as soon as the app mounts. This is particularly important for
// things like the auth type, which needs to be set before the app attempts to
// initialize authentication.
updateGlobalConfig(getToken())
