import React, { useContext, useEffect, useRef, useReducer } from "react"
import { Animated, Platform } from "react-native"

import styled from "styled-components/native"

import { useLockBodyScroll } from "../hooks/use-lock-body-scroll"
import { useProfiles } from "../hooks/use-profiles"
import { useNativeDriver } from "../lib/animation-use-native-driver"
import { ebookReader } from "../lib/ebook-reader"
import {
  ActiveProfileContext,
  ProfilesOverlayEvent,
  ProfilesOverlayScreen,
  profilesOverlay,
} from "../lib/profiles"
import { startAnimation } from "../lib/start-animation"
import { Portal } from "./portal"
import { ProfilesAddScreen } from "./profiles-add-screen"
import { ProfilesEditScreen } from "./profiles-edit-screen"
import { ProfilesManageScreen } from "./profiles-manage-screen"
import { ProfilesSelectScreen } from "./profiles-select-screen"

type ScreenTransition =
  | { type: "fadeOverlayIn" }
  | { type: "fadeCurrentScreenOut"; nextScreen: ProfilesOverlayScreen }
  | { type: "fadeNextScreenIn" }
  | { type: "fadeOverlayOut" }

const TRANSITION_DURATION_MS = 250

const Container = styled(Animated.View)`
  position: ${Platform.OS === "web" ? "fixed" : "absolute"};
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background.primary};
`

const Content = styled(Animated.View)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

// A reducer to juggle screen transitions for the profiles overlay
const reducer = (
  state: {
    previousScreens: ProfilesOverlayScreen[]
    screen: ProfilesOverlayScreen | undefined
    transition: ScreenTransition | undefined
  },
  action:
    | { type: "show"; screen: ProfilesOverlayScreen }
    | { type: "back" }
    | { type: "finishTransition"; transition: ScreenTransition }
    | { type: "close" },
) => {
  switch (action.type) {
    case "show":
      if (!state.screen) {
        // The profiles overlay is starting from the closed state so fade it in
        return {
          ...state,
          screen: action.screen,
          transition: { type: "fadeOverlayIn" as const },
        }
      } else {
        // We're already showing the overlay, so fade the current screen out
        // (the finishTransition action will fade the next screen in once the
        // first animation finishes)
        return {
          ...state,
          previousScreens: state.screen
            ? [...state.previousScreens, state.screen]
            : state.previousScreens,
          transition: {
            type: "fadeCurrentScreenOut" as const,
            nextScreen: action.screen,
          },
        }
      }
    case "back":
      if (state.screen) {
        if (state.previousScreens.length) {
          // Start the transition to fade the current screen out
          return {
            ...state,
            previousScreens: state.previousScreens.slice(0, -1),
            transition: {
              type: "fadeCurrentScreenOut" as const,
              nextScreen: state.previousScreens.at(-1) as ProfilesOverlayScreen,
            },
          }
        } else {
          // There's no previous screen, so start the transition to close the
          // overlay
          return {
            ...state,
            transition: { type: "fadeOverlayOut" as const },
          }
        }
      } else {
        return state
      }
    case "close":
      // Start the transition to close the overlay
      return { ...state, transition: { type: "fadeOverlayOut" as const } }
    case "finishTransition":
      if (action.transition.type === "fadeCurrentScreenOut") {
        // We're transitioning between screens inside the overlay and we've
        // finished fading the previous screen out so fade the next screen in
        return {
          ...state,
          screen: action.transition.nextScreen,
          transition: { type: "fadeNextScreenIn" as const },
        }
      } else if (action.transition.type === "fadeOverlayOut") {
        // We just finished fading the entire overlay out. The overlay is
        // closed, so reset the state
        return {
          previousScreens: [],
          screen: undefined,
          transition: undefined,
        }
      } else {
        return { ...state, transition: undefined }
      }
  }
}

export function ProfilesOverlay() {
  const activeProfile = useContext(ActiveProfileContext)
  const profiles = useProfiles()
  const [{ screen, transition }, dispatch] = useReducer(reducer, {
    previousScreens: [],
    screen: undefined,
    transition: undefined,
  })

  const overlayOpacity = useRef(new Animated.Value(0))
  const contentOpacity = useRef(new Animated.Value(1))

  // Listen for a request to open the overlay
  useEffect(
    () =>
      profilesOverlay.on(ProfilesOverlayEvent.RequestOpen, (event) => {
        if (profiles.data?.status === "available") {
          dispatch({
            type: "show",
            screen: event.screen,
          })
        }
      }),
    [profiles.data],
  )

  // Listen for a request to close the overlay
  useEffect(() =>
    profilesOverlay.on(ProfilesOverlayEvent.RequestClose, () =>
      dispatch({ type: "close" }),
    ),
  )

  // Listen for a request to go back to the previous screen
  useEffect(
    () =>
      profilesOverlay.on(ProfilesOverlayEvent.RequestBack, () =>
        dispatch({ type: "back" }),
      ),
    [],
  )

  // Keep the profilesOverlay instance apprised of the current screen
  useEffect(() => {
    profilesOverlay.setState({ open: Boolean(screen) })
  }, [screen])
  useEffect(() => {
    return () => {
      profilesOverlay.setState({ open: false })
    }
  }, [])

  // Close the ebook reader (if it's open) after the profiles overlay finishes
  // animating in (waiting until after the profiles overlay has animated results
  // in a smoother experience visually).
  useEffect(() => {
    if (screen && !transition) {
      ebookReader.requestClose()
    }
  }, [screen, transition])

  // Lock body scroll when we're open
  useLockBodyScroll(screen !== undefined)

  // If the user has multiple profiles, they haven't selected one on this
  // device, and the overlay isn't already open, then show the profiles select
  // screen
  useEffect(() => {
    if (
      activeProfile.state === "unset" &&
      profiles.data?.profiles.length &&
      !screen
    ) {
      profilesOverlay.requestOpen({ screen: { name: "select" } })
    }
  }, [activeProfile.state, profiles.data, screen])

  // Close the profiles overlay if the browser forward or back button is used to
  // navigate away from the page where the overlay was opened (we offer a nicer
  // UX for the hardware back button on Android but that logic has to live in
  // the RootNavigator component in /app/src/navigation/index.tsx)
  useEffect(() => {
    if (Platform.OS === "web" && screen) {
      const handleState = () => {
        dispatch({ type: "close" })
      }
      window.addEventListener("popstate", handleState)
      return () => window.removeEventListener("popstate", handleState)
    }
  }, [screen])

  // Fade the whole overlay in or out when it's opened or closed
  useEffect(() => {
    if (
      transition?.type === "fadeOverlayIn" ||
      transition?.type === "fadeOverlayOut"
    ) {
      return startAnimation(
        Animated.timing(overlayOpacity.current, {
          useNativeDriver,
          toValue: transition.type === "fadeOverlayIn" ? 1 : 0,
          duration: TRANSITION_DURATION_MS,
        }),
        () => {
          dispatch({ type: "finishTransition", transition })
        },
      )
    }
  }, [transition])

  // Fade the current screen content out and the next screen content in when
  // transitioning between screens inside the overlay
  useEffect(() => {
    if (
      transition?.type === "fadeCurrentScreenOut" ||
      transition?.type === "fadeNextScreenIn"
    ) {
      return startAnimation(
        Animated.timing(contentOpacity.current, {
          useNativeDriver,
          toValue: transition.type === "fadeCurrentScreenOut" ? 0 : 1,
          duration: TRANSITION_DURATION_MS / 2,
        }),
        () => {
          dispatch({ type: "finishTransition", transition })
        },
      )
    }
  }, [transition])

  return (
    <Portal>
      {screen ? (
        <Container
          style={{
            opacity: overlayOpacity.current,
          }}
        >
          <Content
            style={{
              opacity: contentOpacity.current,
            }}
          >
            {screen.name === "select" ? (
              <ProfilesSelectScreen />
            ) : screen.name === "add" ? (
              <ProfilesAddScreen />
            ) : screen.name === "edit" ? (
              <ProfilesEditScreen profileId={screen.params?.profileId} />
            ) : (
              <ProfilesManageScreen />
            )}
          </Content>
        </Container>
      ) : undefined}
    </Portal>
  )
}
