import { FirebaseMessagingTokenPostRequest } from "@treefort/api-spec"
import {
  NotificationsError,
  NotificationsPermissionErrorStatus,
} from "@treefort/lib/errors"

import config from "../../config"
import api from "../api"
import { getFirebaseApp } from "../firebase"

/**
 * - authorized = full permissions granted by user
 * - limited = limited permissions granted by system
 */
type NotificationsPermissionSuccessStatus = "authorized" | "limited"

export type PermissionStatus =
  | NotificationsPermissionErrorStatus
  | NotificationsPermissionSuccessStatus

/**
 * Utilities for managing push notifications. Backed by Firebase Messaging.
 */
export abstract class Notifications {
  /**
   * Get the current permission status for notifications. This will vary based
   * on whether we've asked for permission to send notifications and whether the
   * user has granted, partially granted, or denied our request.
   */
  abstract getPermissionStatus: () => Promise<PermissionStatus>

  /**
   * Tokens are used to identify each device that can receive notifications.
   * Call this to ensure the current device has a token. This may return null if
   * we don't have permission to send notifications (and can't get permission)
   * or if notifications are not supported. This should be called semi-regularly
   * (e.g. once per day) after the auth system is initialized.
   */
  abstract getToken: (args: {
    /**
     * Set this to true to request permission to send notifications if
     * permission has not already been granted.
     */
    requestPermission: boolean
  }) => Promise<string>

  /**
   * Resolves to true if notifications are supported.
   */
  abstract isSupported: () => Promise<boolean>

  /**
   * Post the device's notification token to the server. This helps us track the
   * freshness of tokens and ensure that each token's topic subscriptions are
   * up-to-date. This should be called semi-regularly (e.g. once per day) after
   * the auth system is initialized. The `optOut` flag can be set to true or
   * false to disable or enable notifications for the token within our systems
   * (independent of whether the user has granted us permission via the client).
   */
  postToken = async ({
    token,
    optOut,
  }: {
    token: string
    /**
     * Set this to true or false to explicitly opt the token in or out of
     * receiving notifications.
     */
    optOut?: boolean
  }): Promise<boolean> => {
    const senderId = getFirebaseApp()?.options.messagingSenderId
    if (!senderId) {
      throw new NotificationsError("Failed to get sender ID")
    }

    const data: FirebaseMessagingTokenPostRequest = {
      token,
      clientId: config.CLIENT_ID,
      senderId,
      optOut,
    }
    try {
      await api.post("/integrations/firebase-messaging/tokens", data)
    } catch (cause) {
      throw new NotificationsError("Failed to post token", { cause })
    }

    return true
  }
}
