import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { UseQueryResult } from "react-query"

import styled from "styled-components/native"

import { ContentType, VideoSeriesResponse } from "@treefort/api-spec"
import { joinContributorNames } from "@treefort/lib/contributor"
import icons from "@treefort/tokens/app/icons"

import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useContent from "../../hooks/use-content"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import { shouldShowLockIcon } from "../../lib/content"
import { formatDate } from "../../lib/date"
import { spacingToNumber } from "../../lib/spacing"
import { AppLink } from "../app-link"
import { useAsyncViewProps } from "../async-view"
import { getBannerHeight } from "../banner"
import BannerImageContainer from "../banner-image-container"
import Box, { BoxPadding } from "../box"
import ButtonView from "../button-view"
import { Heading } from "../heading"
import LockedContentButton from "../locked-content-button"
import { MetadataText } from "../metadata"
import { SliderModule } from "../modules/slider"
import Spacer from "../spacer"
import TextToggle from "../text-toggle"
import TokensProvider, { useTokens } from "../tokens-provider"

const PlayButton = styled(AppLink)`
  flex-shrink: 0;
  align-self: ${(props) =>
    props.theme.videoSeriesModule.playButtonFlex
      ? "stretch"
      : props.theme.videoSeriesModule.layout === "portrait"
        ? "flex-start"
        : "flex-end"};
`

const StyledLockedContentButton = styled(LockedContentButton)`
  flex-shrink: 0;
  width: ${(props) =>
    props.theme.videoSeriesModule.playButtonFlex ? "100%" : "auto"};
`

const LandscapeContent = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`

const LandscapeText = styled(Box)`
  flex: 1;
`

function getFirstEpisode(
  videoSeries: UseQueryResult<VideoSeriesResponse> | undefined,
) {
  return videoSeries?.isSuccess
    ? videoSeries.data.details.seasons[
        videoSeries.data.details.seasons.length - 1
      ]?.episodes[0]
    : undefined
}

export function VideoSeriesModule({
  contentId,
  paddingTop = "none",
}: {
  contentId: number
  paddingTop?: BoxPadding
}): JSX.Element | null {
  const { tokens, displayHeight } = useTokens()
  const { layout } = tokens.videoSeriesModule
  const [descriptionReady, setDescriptionReady] = useState(false)
  const { t, i18n } = useTranslation()
  const videoSeries = useContent(contentId, "videoSeries")
  const firstEpisode = getFirstEpisode(videoSeries)
  const openCheckoutPage = useOpenCheckoutPage({
    availability: firstEpisode?.availability,
    contentId,
  })

  useAsyncViewProps(
    useAsyncViewPropsForQueries(videoSeries, {
      forceLoading:
        Boolean(videoSeries.data?.description) &&
        !descriptionReady &&
        layout === "portrait",
    }),
  )

  if (videoSeries.isSuccess) {
    const playButton =
      firstEpisode?.availability.status === "notAvailable" ? (
        <StyledLockedContentButton
          openCheckoutPage={openCheckoutPage}
          availability={firstEpisode.availability}
          action="watch"
        />
      ) : firstEpisode?.availability.status === "available" ? (
        <PlayButton
          to={{
            type: "content",
            contentType: "video" as ContentType,
            contentId: firstEpisode.id,
          }}
          feedback="ripple-or-opacity"
          aria-label={t("Play now")}
        >
          <ButtonView icon={icons.play} type="primary">
            {t("Play now")}
          </ButtonView>
        </PlayButton>
      ) : null

    const publisher = joinContributorNames(
      videoSeries.data.contributors,
      "publisher",
    )

    const title = (
      <>
        <Heading
          textStyle="headingXLarge"
          shadow
          level={1}
          color="highContrast"
          maxWidth="title"
        >
          {videoSeries.data.title}
        </Heading>
        {publisher ? <MetadataText>{publisher}</MetadataText> : null}
      </>
    )

    const paddingTopNumeric = spacingToNumber(tokens, paddingTop)
    const bannerImageHeight =
      paddingTopNumeric +
      getBannerHeight({
        tokens,
        viewportHeight: displayHeight - paddingTopNumeric,
        size: "medium",
      })

    return (
      <Box>
        <TokensProvider displayMode="dark">
          <BannerImageContainer
            source={{
              uri:
                videoSeries.data.details.backgroundMedia?.original.url ||
                videoSeries.data.artworkMedia?.original.url,
            }}
            paddingBottom={layout === "landscape" ? "large" : "medium"}
            height={bannerImageHeight}
            gradientOffset={tokens.videoSeriesModule.banner.gradientOffset}
            gradientCoverage={tokens.videoSeriesModule.banner.gradientCoverage}
            backgroundColor={tokens.videoSeriesModule.banner.backgroundColor}
          >
            {layout === "portrait" ? (
              title
            ) : (
              <LandscapeContent>
                <LandscapeText paddingRight="large">
                  {title}
                  {videoSeries.data.description ? (
                    <>
                      <Spacer size="medium" />
                      <TextToggle
                        textStyle="body"
                        numberOfLines={6}
                        maxWidth="description"
                        type="modal"
                        title={videoSeries.data.title}
                        withLinks
                      >
                        {videoSeries.data.description}
                      </TextToggle>
                    </>
                  ) : null}
                </LandscapeText>
                {playButton}
              </LandscapeContent>
            )}
          </BannerImageContainer>
          {layout === "portrait" ? (
            <Box
              paddingHorizontal="pagePaddingHorizontal"
              backgroundColor="primary"
              paddingBottom="medium"
            >
              {playButton}
              {videoSeries.data.description ? (
                <>
                  <Spacer size="medium" />
                  <TextToggle
                    textStyle="body"
                    numberOfLines={2}
                    onReady={setDescriptionReady}
                    withLinks
                  >
                    {videoSeries.data.description}
                  </TextToggle>
                </>
              ) : null}
            </Box>
          ) : null}
        </TokensProvider>
        {videoSeries.data?.details.seasons
          .slice()
          .reverse()
          .map((season) => {
            return (
              <SliderModule
                key={season.id}
                paddingTop="xlarge"
                title={season.title}
                showItemTitles
                showItemDates="fullDate"
                itemShape="wide"
                size="large"
                scrollRestorationKey={`video-series-season-${season.id}`}
                items={{
                  type: "custom",
                  items: season.episodes.map((episode) => ({
                    title: episode.title,
                    subtitle: episode.publishedAt
                      ? formatDate(new Date(episode.publishedAt), {
                          strategy: "fullDate",
                          i18n,
                        })
                      : undefined,
                    showProgressBar:
                      episode.availability.status === "available",
                    showLockIcon: shouldShowLockIcon(episode.availability),
                    link: {
                      type: "content",
                      contentId: episode.id,
                      contentType: "video" as ContentType,
                    },
                    imageUrl: episode.artworkMedia?.original.url,
                  })),
                }}
              />
            )
          })}
      </Box>
    )
  } else {
    return null
  }
}
