import { Platform } from "react-native"
import { CollectionResponse } from "@treefort/api-spec"
import api from "./api"

export const getCollection = async (
  id: number,
): Promise<CollectionResponse> => {
  const result = await api.get<CollectionResponse>(
    `/collections/${id}?platform=${Platform.OS}`,
  )
  return result.data
}
