import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import { isIos } from "@braintree/browser-detection"
import styled from "styled-components/native"

import EpubViewer, {
  EpubViewerRef,
  Event,
  ErrorCode,
  getGutterWidth,
} from "@treefort/epub-viewer"
import epubViewerApp from "@treefort/epub-viewer/app.html"
import { useAuth } from "@treefort/lib/auth-provider"
import { simpleHash } from "@treefort/lib/simple-hash"

import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import useAppManifest from "../../hooks/use-app-manifest"
import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import { useHotkeys } from "../../hooks/use-hotkeys"
import useWindowDimensions from "../../hooks/use-window-dimensions"
import {
  Ebook,
  EbookReader,
  ebookReader,
  EbookReaderEvent,
  EbookReaderLocation,
} from "../../lib/ebook-reader"
import openUrl from "../../lib/open-url"
import AsyncView from "../async-view"
import { useTokens } from "../tokens-provider"
import { HighlightDetail, highlightDetail } from "./highlight-detail"
import { Hud, getHudTopHeight, getHudBottomHeight } from "./hud"

type State =
  | { status: "loading"; targetLocation?: EbookReaderLocation["location"] }
  | { status: "resizing" }
  | { status: "rendered" }
  | { status: "error"; errorCode: ErrorCode }

const MULTI_SPREAD_BREAKPOINT = 1200
const RESIZE_DEBOUNCE_INTERVAL = 500
const WINDOW_DIMENSIONS_TIMEOUT = 500

const EpubViewerContainer = styled.View`
  position: relative;
  flex: 1;
  overflow: hidden;
`

/**
 * This renders an instance of our @treefort/epub-viewer app (in charge of
 * actually loading/rendering epubs), connects that to our instance of
 * EbookReader (the controller that manages state and actions for ebooks) and
 * renders a HUD on top of it all.
 */
export function ViewerWithHud({
  animating,
  ebook,
}: {
  animating: boolean
  ebook: Ebook
}): JSX.Element {
  const { t } = useTranslation()
  const { displayMode } = useTokens()
  const auth = useAuth()
  const profileId = useActiveProfileId()
  const { parentalGateway } = useAppManifest().features
  const viewer = useRef<EpubViewerRef>(null)
  const windowDimensions = useWindowDimensions({
    // Ignoring height changes on iOS Safari prevents an issue where the ebook
    // gets squished when the soft keyboard pops up
    updateOnHeightChange: !(Platform.OS === "web" && isIos()),
    wait: WINDOW_DIMENSIONS_TIMEOUT,
  })
  const safeAreaInsets = useSafeAreaInsets()
  const ebookReaderTheme = useEventEmitterValue(
    ebookReader,
    EbookReaderEvent.ThemeChanged,
    ebookReader.getTheme,
  )
  const [viewerState, setViewerState] = useState<State>({
    status: "loading",
    targetLocation: ebookReader.getLocation()?.location,
  })
  const resizingTimeout = useRef<NodeJS.Timeout>()
  const width =
    windowDimensions.width - safeAreaInsets.left - safeAreaInsets.right
  const height = windowDimensions.height
  const viewerStyle = useMemo(() => ({ width, height }), [width, height])
  const pageMarginTop = safeAreaInsets.top + getHudTopHeight(width)
  const pageMarginBottom = safeAreaInsets.bottom + getHudBottomHeight(width)
  const gutter = getGutterWidth(width)
  const spreadCount = width >= MULTI_SPREAD_BREAKPOINT ? 2 : 1
  const theme = useMemo(
    () =>
      EbookReader.getEpubViewerTheme({
        ebookReaderTheme,
        displayMode,
        pageMarginTop,
        pageMarginBottom,
      }),
    [displayMode, ebookReaderTheme, pageMarginTop, pageMarginBottom],
  )

  // Mount the viewer only when the layout is settled (no animation, navigation
  // bar hidden, not resizing) to avoid layout jitters and performance issues.
  const mountViewer =
    !animating &&
    viewerState.status !== "resizing" &&
    viewerState.status !== "error"

  // Handle hotkeys. Note that we need to listen for events on the top level
  // document as well as events from the epub viewer iframe (see everywhere this
  // function is called below).
  const handleHotkey = useCallback((key: KeyboardEvent["key"]) => {
    switch (key) {
      case " ":
      case "ArrowRight": {
        viewer.current?.triggerAction({ type: "move", offset: 1 })
        return
      }
      case "ArrowLeft": {
        viewer.current?.triggerAction({ type: "move", offset: -1 })
        return
      }
    }
  }, [])

  // Handle events triggered by the viewer
  const handleViewerEvent = useCallback(
    (event: Event) => {
      switch (event.type) {
        case "ready": {
          viewer.current?.triggerAction({
            type: "loadBook",
            url: ebook.media.url,
            headers: ebook.media.headers,
            query: ebook.media.query,
            cors: ebook.media.cors,
            locations: ebook.media.locations,
            spreadCount,
            theme,
          })
          viewer.current?.triggerAction({
            type: "render",
            location:
              viewerState.status === "loading"
                ? viewerState.targetLocation
                : undefined,
          })
          break
        }

        case "locationChanged": {
          if (
            // Update the ebookReader location when the epub viewer reports a
            // new rendered location
            simpleHash(event.location) !==
              simpleHash(ebookReader.getLocation()?.location) ||
            // Update the ebookReader location if the epub viewer reports
            // different metadata (for instance, after the viewer has been
            // resized)
            simpleHash(event.metadata) !==
              simpleHash(ebookReader.getLocation()?.metadata) ||
            // Update the ebookReader location if it's still in a "requested"
            // state
            ebookReader.getLocation()?.type !== "rendered"
          ) {
            // Update the reader's cached location
            ebookReader.setLocation({
              type: "rendered",
              location: event.location,
              metadata: event.metadata,
            })
            // Show the controls if we're at the start of the book
            // or the end of the book
            ebookReader.setShowControls(
              event.metadata.startOfBookVisible ||
                event.metadata.endOfBookVisible
                ? true
                : false,
            )
          }
          setViewerState((state) =>
            state.status === "rendered" ? state : { status: "rendered" },
          )
          break
        }

        case "tap": {
          ebookReader.emitTap(event)
          // Page to the next/prev page when the user taps the edge of the
          // screen, unless they may have been tapping to close the highlight
          // modal
          if (!highlightDetail.getIsModalOpen()) {
            if (event.region === "left") {
              viewer.current?.triggerAction({ type: "move", offset: -1 })
            } else if (event.region === "right") {
              viewer.current?.triggerAction({ type: "move", offset: 1 })
            }
          }
          // Toggle control visibility when the user taps the
          // center region of the reader.
          if (
            event.region === "top" ||
            event.region === "bottom" ||
            event.region === "center"
          ) {
            ebookReader.setShowControls(!ebookReader.getShowControls())
          }
          break
        }

        case "swipe": {
          if (event.direction === "left") {
            viewer.current?.triggerAction({ type: "move", offset: 1 })
          } else if (event.direction === "right") {
            viewer.current?.triggerAction({ type: "move", offset: -1 })
          }
          break
        }

        case "keyDown": {
          handleHotkey(event.key)
          break
        }

        case "linkRequest": {
          openUrl(event.url, { parentalGateway })
          break
        }

        case "textSelection":
          ebookReader.emitTextSelection(event)
          break

        case "currentTextSelectionChangeStarted":
          ebookReader.emitCurrentTextSelectionChangeStarted()
          break

        case "newTextSelectionStarted":
          ebookReader.emitNewTextSelectionStarted()
          break

        case "textSelectionCleared":
          ebookReader.emitTextSelectionCleared()
          break

        case "tapHighlight":
          ebookReader.emitTapHighlight(event.highlightId)
          break

        case "error": {
          setViewerState({
            status: "error",
            errorCode: event.errorCode,
          })
          break
        }
      }
    },
    [
      ebook.media.url,
      ebook.media.headers,
      ebook.media.query,
      ebook.media.cors,
      ebook.media.locations,
      spreadCount,
      theme,
      viewerState,
      handleHotkey,
      parentalGateway,
    ],
  )

  // Update viewerState when the window is resized
  useEffect(() => {
    clearInterval(resizingTimeout.current)

    resizingTimeout.current = setTimeout(() => {
      setViewerState({
        status: "loading",
        targetLocation: ebookReader.getLocation()?.location,
      })
    }, RESIZE_DEBOUNCE_INTERVAL)

    setViewerState({ status: "resizing" })
  }, [windowDimensions.width])

  // Pass the theme to the epub viewer any time it changes
  useEffect(() => {
    viewer.current?.triggerAction({ type: "updateTheme", theme })
  }, [theme])

  // Listen for hotkeys when the iframe is _not_ focused (e.g. when a HUD
  // element is focused).
  useHotkeys([" ", "ArrowRight", "ArrowLeft"], (event) =>
    handleHotkey(event.key),
  )

  // If the location change was requested through the ebookReader (e.g. the user
  // picked a chapter from our table of contents popover) then load that
  // location in the ebook
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.LocationChanged, (event) => {
        if (event.current?.type === "requested") {
          viewer.current?.triggerAction({
            type: "render",
            location: event.current.location,
          })
        }
      }),
    [],
  )

  // Mark all highlights when requested through the ebookReader
  useEffect(() => {
    return ebookReader.on(
      EbookReaderEvent.MarkAllHighlightsRequested,
      (data) => {
        viewer.current?.triggerAction({ type: "markAllHighlights", ...data })
      },
    )
  }, [])

  return (
    <>
      <AsyncView
        state={
          viewerState.status === "rendered"
            ? "success"
            : viewerState.status === "error"
              ? "error"
              : "loading"
        }
        message={t(
          "An error occurred. Please contact us if the issue persists.",
        )}
        primaryAction={{
          label: t("Refresh"),
          onPress: () =>
            setViewerState({
              status: "loading",
              // If we ended up here because of an error when attempting to
              // render a particular location then try reloading at the
              // beginning of the book instead of at the requested location.
              targetLocation:
                viewerState.status === "error" &&
                viewerState.errorCode === "EV501"
                  ? undefined
                  : ebookReader.getLocation()?.location,
            }),
        }}
        textColor={theme.textColor}
        backgroundColor={theme.backgroundColor}
      >
        {mountViewer ? (
          <EpubViewerContainer>
            <EpubViewer
              ref={viewer}
              app={epubViewerApp}
              onEvent={handleViewerEvent}
              style={viewerStyle}
            />
          </EpubViewerContainer>
        ) : null}
      </AsyncView>
      <Hud
        gutter={gutter}
        width={width}
        theme={theme}
        ebook={ebook}
        safeAreaInsets={safeAreaInsets}
      />
      <HighlightDetail
        contentId={ebook.extra.consumableContent.content.id}
        profileId={profileId}
        userId={auth.user?.id}
        viewerStatus={viewerState.status}
      />
    </>
  )
}
