"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermission = exports.TREEFORT_API_ROLES = exports.TREEFORT_API_PERMISSIONS = exports.TREEFORT_API_LATEST_VERSION = exports.TREEFORT_API_ENTITY_ID = void 0;
const constants_1 = require("@treefort/constants");
exports.TREEFORT_API_ENTITY_ID = "de24cc4d-765c-4649-bc41-925bc5d9eee0"; // Constant everywhere for convenience
exports.TREEFORT_API_LATEST_VERSION = "2023-06-10";
// The full list of possible API permissions
exports.TREEFORT_API_PERMISSIONS = [
    "account:usage:write",
    "account:usage:read",
    "analytics:read",
    "apps:publish",
    "apps:read",
    "apps:write",
    "collections:read",
    "collections:write",
    "content:read",
    "content:write",
    "entitlements:read",
    "entitlements:write",
    "group-membership-codes:read",
    "group-membership-codes:write",
    "integrations:algolia:read",
    "integrations:algolia:write",
    "integrations:app-store:read",
    "integrations:firebase-messaging:write",
    "integrations:google-analytics:write",
    "integrations:intercom:read",
    "integrations:play-store:read",
    "integrations:stripe:read",
    "integrations:read",
    "integrations:vimeo:read",
    "integrations:write",
    "jobs:read",
    "jobs:write",
    "metadata:read",
    "offerings:read",
    "offerings:write",
    "subscription-plans:read",
    "subscription-plans:write",
    "root",
    "settings:read",
    "settings:write",
    "uploads:write",
    "users:read",
    "users:write",
    "webhooks:read",
    "webhooks:write",
    "regions:read",
];
// The API permissions granted by each possible role
exports.TREEFORT_API_ROLES = {
    adm: [
        "account:usage:read",
        "analytics:read",
        "apps:publish",
        "apps:read",
        "apps:write",
        "collections:read",
        "collections:write",
        "content:read",
        "content:write",
        "entitlements:read",
        "entitlements:write",
        "group-membership-codes:read",
        "group-membership-codes:write",
        "integrations:read",
        "integrations:vimeo:read",
        "integrations:intercom:read",
        "integrations:write",
        "metadata:read",
        "offerings:read",
        "offerings:write",
        "settings:read",
        "settings:write",
        "subscription-plans:read",
        "subscription-plans:write",
        "uploads:write",
        "users:read",
        "webhooks:read",
        "webhooks:write",
        "regions:read",
    ],
    "treefort-script": [
        "integrations:algolia:read",
        "integrations:algolia:write",
        "integrations:firebase-messaging:write",
        "integrations:google-analytics:write",
        "integrations:app-store:read",
        "integrations:play-store:read",
        "integrations:stripe:read",
        "jobs:read",
        "jobs:write",
        "account:usage:write",
        "account:usage:read",
        "root",
    ],
    "treefort-test": ["integrations:firebase-messaging:write", "root"],
};
/**
 * Returns true if a token grants the provided permission(s). The token MUST be
 * authenticated before being passed to this function (which does not do any
 * validation).
 */
function hasPermission(token, ...permissions) {
    return (
    // Permissions should only be granted to tokens belonging to the API's
    // tenant.
    (token === null || token === void 0 ? void 0 : token.tid) === constants_1.TREEFORT_TENANT_ID &&
        // Hunt for every permission in either the permissions field (populated for
        // entities) or the roles field (populated for users)
        permissions.every((permission) => {
            var _a, _b;
            return ((_a = token === null || token === void 0 ? void 0 : token.permissions) === null || _a === void 0 ? void 0 : _a[exports.TREEFORT_API_ENTITY_ID].includes(permission)) ||
                ((_b = token === null || token === void 0 ? void 0 : token.roles) === null || _b === void 0 ? void 0 : _b.some((role) => { var _a; return (_a = exports.TREEFORT_API_ROLES[role]) === null || _a === void 0 ? void 0 : _a.includes(permission); }));
        }));
}
exports.hasPermission = hasPermission;
