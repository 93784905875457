"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSvgDataUriColor = exports.isSvgDataUri = exports.isSvgString = void 0;
const isSvgString = (source) => typeof source === "string" && source.includes("<svg");
exports.isSvgString = isSvgString;
const isSvgDataUri = (source) => typeof source === "string" && source.startsWith("data:image/svg+xml");
exports.isSvgDataUri = isSvgDataUri;
const setSvgDataUriColor = (source, color) => source
    .replace(/(['"])(#000|#000000|black)(['"])/g, `$1${color}$3`)
    .replace(/(stroke|fill):\s*(#000|#000000|black)/g, `$1: ${color}`);
exports.setSvgDataUriColor = setSvgDataUriColor;
