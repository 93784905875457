import { Database } from "@nozbe/watermelondb"

import { adapter } from "./adapter"
import { Highlight } from "./models/highlight"
import { Progress } from "./models/progress"

let offlineDb: Database

const getClient = () => {
  if (offlineDb) {
    return offlineDb
  }

  offlineDb = new Database({
    adapter,
    modelClasses: [Highlight, Progress],
  })

  return offlineDb
}

export { getClient }
