import React from "react"
import Indicator from "./indicator"
import { IconSize } from "../icon"
import { TextColor } from "../text"
import { useTokens } from "../tokens-provider"

export default function ActivityIndicator({
  size,
  color = "primary",
}: {
  color?: TextColor
  size: IconSize
}): JSX.Element {
  const { tokens } = useTokens()
  const colorValue =
    color in tokens.colors.text
      ? tokens.colors.text[color as keyof typeof tokens.colors.text]
      : color
  const sizeValue = tokens.icon.size[size]
  return (
    <Indicator
      containerSize={sizeValue}
      size={sizeValue * tokens.activityIndicator.indicatorScale}
      color={colorValue}
    />
  )
}
