import {
  schemaMigrations,
  createTable,
} from "@nozbe/watermelondb/Schema/migrations"

import { highlightTable } from "./migrations/02-highlight-table"
import { progressTable } from "./migrations/03-progress-table"

// When adding a new migration, follow the steps at
// https://watermelondb.dev/docs/Advanced/Migrations
//
// Brief summary of the above instructions:
// 1. Define the migration in a new file in the `migrations` directory
//    - name the file starting with the new schema number, e.g. 03-your-migration-name.ts
//    - Don't change schema.ts yet
// 2. Add the new migration here with an appropriate `toVersion` value
// 3. Verify the app shows an error "Migrations can't be newer than schema"
// 4. Make matching changes in schema.ts, importing the newly-defined migration there
// 5. Refresh the app, verify same "Migrations can't be newer" error is shown
// 6. Bump the `version` in schema to version + 1
// 7. If adding a table or adding a column to an existing table, implement changes
//    in sync-pull-handlers to backfill records on out-of-date devices when they
//    update to incorporate the new database changes.
// 8. Test migrations:
//   - install previous version of app, sync, upgrade, ensure migrations run successfully
//   - verify fresh install of app works correctly
//
// Other notes:
// * Make sure when testing that you run an app that has previously
//   successfully synced (as opposed to just initializing the database
//   with the old migrations and then immediately applying new ones)
//   so that lastPulledAt gets populated and sent to the server to better
//   simulate real-world usage.
// * If you're adding a new model, don't forget to add it to the modelClasses
//   property in the Database constructor.
export default schemaMigrations({
  migrations: [
    {
      toVersion: 2,
      steps: [createTable(highlightTable)],
    },
    {
      toVersion: 3,
      steps: [createTable(progressTable)],
    },
  ],
})
