import { Model } from "@nozbe/watermelondb"

type ExcludeModelFields<T> = {
  [K in keyof T as K extends keyof Model ? never : K]: T[K]
}

type ExcludeMethods<T> = {
  // Disable @typescript-eslint/ban-types because in this rare case we
  // actually _want_ the Function type to apply to any function-like thing.
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T as T[K] extends Function ? never : K]: T[K]
}

// Remove fields from the type if the field name starts with `syncIgnore_`
type ExcludeLocalFields<T> = {
  [K in keyof T as K extends `syncIgnore_${string}` ? never : K]: T[K]
}

export type DatabaseFieldsOf<T> = ExcludeLocalFields<
  ExcludeMethods<ExcludeModelFields<T>>
>

export interface ISyncedDatastore<T extends Model> {
  getAll: () => Promise<T[]>
  find: (id: string) => Promise<T | null>
  create: (values: DatabaseFieldsOf<T>) => Promise<T>
  update: (id: string, fields: Partial<DatabaseFieldsOf<T>>) => Promise<void>
  delete: (id: string) => Promise<void>
}

// WatermelonDB field names starting with this pattern will not be
// synced to the server, and any fields starting with this prefix
// will be stripped off the server pull response before passing it
// to the Watermelon sync logic.
export const SYNC_IGNORE_PREFIX = "syncIgnore_"
