import { Platform } from "react-native"
import {
  Subscription as IapSubscription,
  getSubscriptions as getIapSubscriptions,
} from "react-native-iap"

import PQueue from "p-queue"

import { SubscriptionPlanResponse } from "@treefort/api-spec"
import {
  getCurrencyDivisor,
  ANDROID_MICROS_CURRENCY_DIVISOR,
} from "@treefort/lib/money"

// Alias "Subscriptions" from react-native-iap as "Products" to avoid conflicts
// with our own terminology
type SubscriptionProduct = IapSubscription

export type { SubscriptionProduct }

export { restoreAllInAppPurchases } from "./restore-all-in-app-purchases"

export { restoreInAppPurchases } from "./restore-in-app-purchases"

export { handleInAppPurchaseError } from "./handle-in-app-purchase-error"

// getIapSubscriptions can only handle one request at a time, otherwise it will
// fail with "Previous request was cancelled due to a new request"
const getIapSubscriptionsQueue = new PQueue({ concurrency: 1 })

export function getSubscriptionProducts(plans: SubscriptionPlanResponse[]) {
  return getIapSubscriptionsQueue.add(() =>
    getIapSubscriptions({
      skus: plans.flatMap((plan) =>
        ((plan.provider === "appStore" && Platform.OS === "ios") ||
          (plan.provider === "playStore" && Platform.OS === "android")) &&
        plan.productId &&
        !plan.archivedAt
          ? plan.productId
          : [],
      ),
    }),
  )
}

export function getAppStorePrice(
  plan: SubscriptionPlanResponse,
  products: SubscriptionProduct[],
) {
  const product = products.find(
    (product) =>
      plan.provider === "appStore" && product.productId === plan.productId,
  )
  if (product?.platform === "ios") {
    const currency = product.currency
    const divisor = getCurrencyDivisor(currency)
    return {
      amount: parseFloat(product.price) * divisor,
      currency,
      divisor,
    }
  }
}

/**
 * Returns the best formatted price for a Play Store plan. What do we mean by
 * the "best" price? The Play Store supports a feature called "price phases".
 * This feature is virtually undocumented, but it is presumably the reason that
 * we have to fetch the price out of an array called "pricingPhaseList".
 * However, based on the Play Console UI it seems that multiple pricing phases
 * are not allowed on the default offer (the offer with a missing offerId
 * field). Given that it feels safe enough to just grab the first price,
 * although only time will tell.
 */
export function getBestPlayStorePrice(
  plan: SubscriptionPlanResponse,
  products: SubscriptionProduct[],
) {
  const offers = getPlayStoreOffers(plan, products)
  const defaultOffer = offers.find((offer) => !offer.offerId)
  const pricingPhase = defaultOffer?.pricingPhases.pricingPhaseList[0]
  if (pricingPhase) {
    return {
      // Android uses 6 decimals for all currencies, but we use Stripe's
      // strategy of varying the decimal amount per currency. This converts
      // from Anroid's strategy to ours.
      amount: parseInt(pricingPhase.priceAmountMicros),
      currency: pricingPhase.priceCurrencyCode,
      divisor: ANDROID_MICROS_CURRENCY_DIVISOR,
    }
  }
}

/**
 * Returns the first trial offer token it finds associated with a plan, or the
 * default offer token associated with a plan if a trial hasn't been configured.
 * An offer token is required for a subscription purchase on Android. Offer
 * tokens are included in the return value of react-native-iap's
 * getSubscriptions function. See:
 * https://github.com/dooboolab-community/react-native-iap/discussions/2563#discussioncomment-7243951
 */
export function getBestPlayStoreOfferToken(
  plan: SubscriptionPlanResponse,
  products: SubscriptionProduct[],
) {
  const offers = getPlayStoreOffers(plan, products)
  // The id of the default offer associated with a baseplan will be null, so the
  // first offer *with* an id will be some kind of trial configured in the Play
  // Console. See:
  // https://developer.android.com/reference/com/android/billingclient/api/ProductDetails.SubscriptionOfferDetails#getOfferId()
  const trialOffer = offers.find((offer) => offer.offerId !== null)
  if (trialOffer) {
    return trialOffer.offerToken
  } else {
    return offers[0]?.offerToken
  }
}

/**
 * Returns all the Play Store subscription offers associated with a plan.
 */
function getPlayStoreOffers(
  plan: SubscriptionPlanResponse,
  products: SubscriptionProduct[],
) {
  return plan.provider === "playStore" && plan.playStoreBasePlanId
    ? products.flatMap((product) =>
        product.platform === "android" && product.productId === plan.productId
          ? product.subscriptionOfferDetails.filter(
              (details) => details.basePlanId === plan.playStoreBasePlanId,
            )
          : [],
      )
    : []
}
