"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.videoRequestMatchesVideoMedia = exports.getBestMediaSourceForDownloadingAudio = exports.getBestMediaSourceForStreamingAudio = exports.getBestMediaSourceForDownloadingVideo = exports.getBestMediaSourceForStreamingVideo = void 0;
const getBestMediaSourceForStreamingVideo = (media) => {
    var _a, _b, _c, _d, _e, _f;
    switch (media.type) {
        case "videoFile":
            return (((_a = media.processed) === null || _a === void 0 ? void 0 : _a.find((item) => item.url.startsWith("file://"))) ||
                ((_b = media.processed) === null || _b === void 0 ? void 0 : _b.find((item) => item.format === "hls")) ||
                ((_c = media.processed) === null || _c === void 0 ? void 0 : _c.find((item) => item.format === "mp4")) ||
                (media.original.format.match(/^(hls|mp4)$/)
                    ? media.original
                    : undefined));
        case "vimeoVideo":
            return (((_d = media.processed) === null || _d === void 0 ? void 0 : _d.find((item) => item.url.startsWith("file://"))) ||
                ((_e = media.processed) === null || _e === void 0 ? void 0 : _e.find((item) => item.format === "hls")) ||
                ((_f = media.processed) === null || _f === void 0 ? void 0 : _f.find((item) => item.format === "mp4")));
        default:
            return undefined;
    }
};
exports.getBestMediaSourceForStreamingVideo = getBestMediaSourceForStreamingVideo;
const getBestMediaSourceForDownloadingVideo = (media) => {
    var _a, _b;
    switch (media.type) {
        case "videoFile": {
            return (((_a = media.processed) === null || _a === void 0 ? void 0 : _a.find((item) => item.format === "mp4")) ||
                (media.original.format === "mp4" && media.original) ||
                undefined);
        }
        case "vimeoVideo":
            return (_b = media.processed) === null || _b === void 0 ? void 0 : _b.find((item) => item.format === "mp4");
        default:
            return undefined;
    }
};
exports.getBestMediaSourceForDownloadingVideo = getBestMediaSourceForDownloadingVideo;
const getBestMediaSourceForStreamingAudio = (media) => {
    var _a, _b, _c, _d;
    switch (media.type) {
        case "audioFile":
            return (((_a = media.processed) === null || _a === void 0 ? void 0 : _a.find((item) => item.url.startsWith("file://"))) ||
                ((_b = media.processed) === null || _b === void 0 ? void 0 : _b.find((item) => item.format === "hls")) ||
                ((_c = media.processed) === null || _c === void 0 ? void 0 : _c.find((item) => item.format === "mp4")) ||
                ((_d = media.processed) === null || _d === void 0 ? void 0 : _d.find((item) => item.format === "mp3")) ||
                (media.original.format.match(/^(hls|mp4|mp3)$/)
                    ? media.original
                    : undefined));
        default:
            return undefined;
    }
};
exports.getBestMediaSourceForStreamingAudio = getBestMediaSourceForStreamingAudio;
const getBestMediaSourceForDownloadingAudio = (media) => {
    var _a, _b;
    switch (media.type) {
        case "audioFile":
            return (((_a = media.processed) === null || _a === void 0 ? void 0 : _a.find((item) => item.format === "mp4")) ||
                ((_b = media.processed) === null || _b === void 0 ? void 0 : _b.find((item) => item.format === "mp3")) ||
                (media.original.format.match(/^(mp4|mp3)$/)
                    ? media.original
                    : undefined));
        default:
            return undefined;
    }
};
exports.getBestMediaSourceForDownloadingAudio = getBestMediaSourceForDownloadingAudio;
const videoRequestMatchesVideoMedia = (request, media) => {
    switch (media === null || media === void 0 ? void 0 : media.type) {
        case "videoFile":
            return request.type === "url" && media.original.url === request.url;
        case "vimeoVideo":
            return (request.type === "vimeo" &&
                media.integrationId === request.integrationId &&
                media.videoId === request.videoId);
        default:
            return false;
    }
};
exports.videoRequestMatchesVideoMedia = videoRequestMatchesVideoMedia;
