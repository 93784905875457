import { SyntheticEvent } from "react"
import isObject from "@treefort/lib/is-object"

/**
 * Returns true if an event is appears to be one of React's synthetic events.
 * These events are a "legacy" feature of React, but are still around in the
 * latest version of react native (0.70 at the time of writing). See:
 * https://reactjs.org/docs/legacy-event-pooling.html
 */
export const isSyntheticEvent = (event: unknown): event is SyntheticEvent =>
  isObject(event) && "persist" in event
