import { Platform } from "react-native"

import { EventEmitter } from "@treefort/lib/event-emitter"

import audioPlayer from "./audio-player"
import { EventMap } from "./av/types"
import { cancelPlayContentAudio } from "./content-audio"
import { Event, PlaybackState, VideoPlayer } from "./video-player"

/**
 * This helper will execute a callback any time the player either signals an
 * intent to play, starts buffering before playback, or actually begins playing.
 */
const onAnyPlayEvent = (
  player: EventEmitter<EventMap>,
  callback: () => void,
): (() => void) => {
  const listeners: Array<() => void> = []
  listeners.push(player.on(Event.PlayIntent, callback))
  listeners.push(
    player.on(Event.PlaybackState, (state) => {
      if (
        state === PlaybackState.Playing ||
        state === PlaybackState.Buffering
      ) {
        callback()
      }
    }),
  )
  return () => listeners.forEach((removeListener) => removeListener())
}

/**
 * Helps coordinate AV actions across the app.
 */
class VideoPlayerManager {
  private videoPlayers: Map<VideoPlayer, () => void> = new Map()

  constructor() {
    // Suspend all video players when the audio player is about to play
    onAnyPlayEvent(audioPlayer, () =>
      this.videoPlayers.forEach((_, player) => player.suspend()),
    )
  }

  registerVideoPlayer = (videoPlayer: VideoPlayer) => {
    this.videoPlayers.set(
      videoPlayer,
      onAnyPlayEvent(videoPlayer, () => {
        // Suspend all other video players
        this.videoPlayers.forEach((_, player) => {
          if (player !== videoPlayer) {
            player.suspend()
          }
        })

        // Cancel any audio content that's being loaded and pause the audio
        // player.
        cancelPlayContentAudio()
        audioPlayer.pause()

        // If the same content is loaded into the audio player, stop the audio
        // player completely.
        if (
          audioPlayer.getTrack()?.url === videoPlayer.getTrack()?.url ||
          // HACK: On Android we stop the audio player to prevent audio player
          // controls from being shown on the lock screen while video is
          // playing. On Android the video player doesn't support lock screen
          // controls, and it would be less confusing to show no controls than
          // to show the audio player controls.
          Platform.OS === "android"
        ) {
          audioPlayer.stop()
        }
      }),
    )
  }

  unregisterVideoPlayer = (videoPlayer: VideoPlayer) => {
    this.videoPlayers.get(videoPlayer)?.()
    this.videoPlayers.delete(videoPlayer)
  }
}

export const videoPlayerManager = new VideoPlayerManager()
