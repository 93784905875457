import React from "react"
import { AppModuleSlider } from "@treefort/api-spec"
import {
  SliderModule,
  getSliderDimensions,
} from "../../../../components/modules/slider"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleSlider["config"]

type Options = { key?: string }

export class SliderListViewModule
  implements ListViewModule<AppModuleSlider, Item>
{
  readonly appModule: AppModuleSlider
  readonly options: Options
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = undefined
  readonly gapSize = 36
  readonly maxWidth = undefined

  constructor(appModule: AppModuleSlider, options: Options = {}) {
    this.appModule = appModule
    this.options = options
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => {
    const key = this.getItemKey(args)
    return (
      <SliderModule
        {...args.item}
        paddingTop={args.safeAreaInsets.top}
        scrollRestorationKey={key}
        // Key the slider component. This forces a remount of the slider when
        // RecyclerListView recycles a view that already had a slider in it.
        // This is not as performant, but avoids incorrect/outdated slider items
        // briefly flashing in the UI.
        key={key}
      />
    )
  }

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id

  getItemSize = (args: ItemArgs<Item>) =>
    getSliderDimensions({
      tokens: args.tokens,
      preferredLayout: args.item.layout,
      preferredShowItemTitles: args.item.showItemTitles,
      preferredShowItemDates: args.item.showItemDates,
      ...args.item,
    }).height + args.safeAreaInsets.top
}
