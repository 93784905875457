"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInvertedPromise = void 0;
/**
 * Returns a promise that can be resolved or rejected from the outside
 */
const getInvertedPromise = () => {
    let resolveOutside;
    let rejectOutside;
    const invertedPromise = new Promise((resolve, reject) => {
        resolveOutside = resolve;
        rejectOutside = reject;
    });
    if (resolveOutside && rejectOutside) {
        invertedPromise.resolveOutside = resolveOutside;
        invertedPromise.rejectOutside = rejectOutside;
    }
    else {
        // This will never happen in any ordinary environments.
        throw new Error("The callback passed to Promise.constructor was not executed syncronously");
    }
    return invertedPromise;
};
exports.getInvertedPromise = getInvertedPromise;
