import React from "react"

import useAppPreview from "../../hooks/use-app-preview"
import LazyLoadComponent from "../lazy-load-component"

/**
 * Run initialization logic for the app preview mode. This should be rendered
 * exactly once below the AppManifestProvider.
 */
export function InitializeAppPreview(): JSX.Element {
  const appPreview = useAppPreview()
  return (
    <LazyLoadComponent
      importKey="InitializeAppPreview"
      importComponent={() => import("./init")}
      enabled={appPreview.state === "on"}
      props={{ token: appPreview.state === "on" ? appPreview.token : "" }}
    />
  )
}
