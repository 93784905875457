import { Platform } from "react-native"

import { t } from "i18next"

import { PROGRESS_VERSION } from "@treefort/constants"
import { runCancellable } from "@treefort/lib/cancellable"
import { DisplayableError } from "@treefort/lib/displayable-error"

import { getClient } from "../watermelon/db"
import { progressStore } from "../watermelon/stores/progress"
import analytics from "./analytics"
import audioPlayer, { PlaybackState, Event } from "./audio-player"
import { MILLISECONDS_FROM_END_TO_COUNT_AS_FINISHED } from "./av/constants"
import { LoopMode, Track } from "./av/types"
import {
  getConsumableContentFromContentResponse,
  getTracksFromConsumableContent,
  isConsumableContentAvailable,
  PlayableContent,
} from "./consumable-content"
import { getContent } from "./content"
import {
  syncSettingsWithProgressItem,
  cancelSaveProgress,
  flushSaveProgress,
} from "./content-audio-sync-progress"
import DownloadItem, { Event as DownloadItemEvent } from "./download-item"
import { logError } from "./logging"
import { getNetworkState, isOfflineState } from "./network-state"
import { PlayProgressTracker } from "./play-progress-tracker"
import {
  PlayableProgressItemData,
  PlayableProgressItem,
  getProgressItemFromConsumableContent,
  PodcastEpisodeProgressItem,
  VideoProgressItem,
  ProgressItem,
  fetchProgressItemFromSettings,
} from "./progress-item"
import settings from "./settings"

// This represents the data needed to load or play content audio
type ContentAudioData = {
  consumableContent: PlayableContent
  profileId: string | null
  trackIndex?: number
}

const CURRENT_AUDIO_PROGRESS_KEY = `currentProgressKey.${PROGRESS_VERSION}`

const LOOP_MODE_KEY = "audioPlayer.loopMode"

const noop = (): void => {}
// A variable to store listeners so we can remove them before adding new ones.
export let removeListeners: () => void = noop
let cancelLoadAndSyncAudio: () => void = noop
export let cancelPlayContentAudio: () => void = noop
let cancelStopAudioPlayerIfCurrent: () => void = noop
let cancelStopAudioPlayer: () => void = noop

/**
 * Returns true if any downloaded files are loaded into the audio player.
 */
function audioPlayerContainsDownload(): boolean {
  return audioPlayer
    .getTracks()
    .some((track) => track.url.startsWith("file://"))
}

/**
 * Adds listeners for changes in progress, playback rate, and download status
 * for a particular piece of content/progress item. Returns a function to remove
 * the listeners.
 */
function addListeners({
  data,
  tracks,
  downloadItem,
  progressItem,
}: {
  data: ContentAudioData
  tracks: Track[]
  downloadItem: DownloadItem
  progressItem: PlayableProgressItem
}): () => void {
  const playProgressTracker = new PlayProgressTracker({
    playbackState: PlaybackState.Idle,
    playbackRate: progressItem.getPlaybackRate(),
  })

  // On Android progress is synced to settings via the rntp-playback-service
  const removeSyncSettingsWithProgressItemListener =
    Platform.OS === "android"
      ? noop
      : syncSettingsWithProgressItem({
          progressItem,
          tracks,
          playProgressTracker,
        })

  const removeAudioPlayerProgressListener = audioPlayer.on(
    Event.Progress,
    ({ position, duration, index }) => {
      // Ignore events where duration = 0 (we can't save progress if duration
      // hasn't loaded yet) or where position = 0 (there's no progress to
      // save...)
      if (duration === 0 || position === 0) return

      // Update the duration for podcast episodes which is not pre-cached
      // anywhere
      if (
        progressItem instanceof PodcastEpisodeProgressItem &&
        !progressItem.getPodcastEpisodeDuration()
      ) {
        progressItem.setPodcastEpisodeDuration(duration)
      }

      playProgressTracker.logProgress()

      // Update the position within the current track
      progressItem.updateProgress({ position })

      // If we're within MILLISECONDS_FROM_END_TO_COUNT_AS_FINISHED from the
      // end of a track then consider it as "finished"
      const finished =
        position > 0 &&
        index === audioPlayer.getTracks().length - 1 &&
        duration - position <= MILLISECONDS_FROM_END_TO_COUNT_AS_FINISHED
      if (progressItem.getProgress().finished !== finished) {
        progressItem.updateProgress({ finished })
      }
    },
  )

  // Reset the "save progress" functions after a seek so that the next reported
  // progress is saved immediately.
  const removeSeekedListener = audioPlayer.on(Event.Seeked, cancelSaveProgress)

  const removePlaybackStateListener = audioPlayer.on(
    Event.PlaybackState,
    (state) => {
      // Save progress right away when the user pauses
      if (state === PlaybackState.Paused) {
        flushSaveProgress()
      }

      playProgressTracker.setPlaybackState(state)
    },
  )

  // Save progress when the user stops the audio player
  const removeWillStopListener = audioPlayer.on(Event.WillStop, () => {
    const state = audioPlayer.getPlaybackState()
    if (state === PlaybackState.Playing || state === PlaybackState.Buffering) {
      flushSaveProgress()
    }
  })

  const removeTrackIndexListener = audioPlayer.on(Event.TrackIndex, (index) =>
    progressItem.updateProgress({ index: index ?? 0 }),
  )

  const removePlaybackRateListener = audioPlayer.on(
    Event.PlaybackRate,
    (rate) => {
      progressItem.setPlaybackRate(rate)
      playProgressTracker.setPlaybackRate(rate)
    },
  )

  const removeFinishedListener = audioPlayer.on(Event.Finished, async () => {
    try {
      await audioPlayer.pause()
      if (audioPlayer.getTracks().length > 1) {
        await audioPlayer.skipToTrack(0)
      } else {
        await audioPlayer.skipToPosition(0)
      }
      progressItem.updateProgress({ index: 0, position: 0, finished: true })
    } catch (e) {
      logError(e)
    }
  })

  const removeLoopModeListener = audioPlayer.on(Event.LoopMode, (loopMode) =>
    getClient().localStorage.set(LOOP_MODE_KEY, loopMode),
  )

  const disableDownloadItemEvents = downloadItem.enableEvents()

  // When the content in the player finishes downloading, reload the player to
  // use the downloaded files
  const removeDownloadItemStateListener = downloadItem.on(
    DownloadItemEvent.State,
    async (state) => {
      if (state.type === "downloaded") {
        const playbackState = audioPlayer.getPlaybackState()
        await loadContentAudio(data)
        if (
          playbackState === PlaybackState.Playing ||
          playbackState === PlaybackState.Buffering
        ) {
          audioPlayer.play()
        }
      }
    },
  )

  // When the content in the player is about to be deleted, stop the player
  const removeDownloadItemWillDeleteListener = downloadItem.on(
    DownloadItemEvent.WillDelete,
    async () => {
      if (audioPlayerContainsDownload()) {
        await stopAudioPlayer(data)
      }
    },
  )

  return () => {
    flushSaveProgress()
    removeSyncSettingsWithProgressItemListener()
    removeAudioPlayerProgressListener()
    removeSeekedListener()
    removePlaybackStateListener()
    removeWillStopListener()
    removeTrackIndexListener()
    removePlaybackRateListener()
    removeFinishedListener()
    removeLoopModeListener()
    disableDownloadItemEvents()
    removeDownloadItemStateListener()
    removeDownloadItemWillDeleteListener()
  }
}

/**
 * Gets the current progress item.
 */
export async function getLastPlayedContentAudio({
  profileId,
}: {
  profileId: string | null
}): Promise<ContentAudioData | null> {
  try {
    const currentAudioProgressKey = await getCurrentAudioProgressKey({
      profileId,
    })
    const setting = currentAudioProgressKey
      ? await settings.getLocalOrRemote<PlayableProgressItemData>(
          currentAudioProgressKey,
          { profileId },
        )
      : undefined
    if (setting?.value) {
      const podcastEpisodeNumber =
        setting.value.contentType === "podcast"
          ? setting.value.podcastEpisode
          : undefined
      const content = await getContent(setting.value.contentId)
      const consumableContent =
        content?.type === "podcast" ||
        content?.type === "book" ||
        content?.type === "video" ||
        content?.type === "album"
          ? getConsumableContentFromContentResponse({
              content,
              podcastEpisodeNumber,
            })
          : undefined
      return consumableContent &&
        isConsumableContentAvailable(consumableContent)
        ? { consumableContent, profileId }
        : null
    }
  } catch (error) {
    logError(error)
  }
  return null
}

/**
 * Load audio from content and sync progress but don't start playing.
 */
async function* loadContentAudioGenerator(
  data: ContentAudioData,
): AsyncGenerator<unknown, PlayableProgressItem> {
  const { consumableContent, trackIndex, profileId } = data
  const downloadItem = new DownloadItem({ consumableContent })
  const [networkState, downloadItemState] = await Promise.all([
    getNetworkState(),
    downloadItem.getState(),
  ])
  yield // Allow cancellation after fetching network state and download item state
  if (isOfflineState(networkState) && downloadItemState.type !== "downloaded") {
    throw new DisplayableError(t("Cannot play audio - no network connection."))
  }

  const { progressItem } = await fetchProgressItemFromSettings({
    consumableContent,
    profileId,
    strategy: "localOrRemote",
  })
  if (progressItem instanceof VideoProgressItem) {
    progressItem.setPlayMode("listen")
  }
  if (trackIndex !== undefined) {
    progressItem.updateProgress({ index: trackIndex, position: 0 })
  }
  yield // Allow cancellation loading progress

  // Use content from the download item for offline playback
  const tracks = getTracksFromConsumableContent({
    consumableContent: await downloadItem.getOfflineConsumableContent(),
    profileId,
  })
  yield // Allow cancellation after fetching downloaded content

  yield audioPlayer.pause()
  if (tracks.length === 0) {
    throw new DisplayableError(
      t("Please update your app to access this content."),
      `[Content] No compatible tracks found for content "${consumableContent.content.id}".`,
    )
  }
  removeListeners()
  const { index, position, finished } = progressItem.getProgress()
  yield audioPlayer.setTracks(tracks, {
    index: finished ? 0 : index,
    position: finished ? 0 : position,
    playbackRate:
      consumableContent.type === "album" ? 1 : progressItem.getPlaybackRate(),
    skipMode: consumableContent.type === "album" ? "track" : "position",
    loopMode:
      consumableContent.type === "album"
        ? (await getClient().localStorage.get<LoopMode>(LOOP_MODE_KEY)) || "off"
        : "off",
  })
  removeListeners = addListeners({ data, tracks, downloadItem, progressItem })
  // Ensure that the local current progress key matches what's loaded into the
  // player.
  saveCurrentAudioProgressKey({ key: progressItem.getKey(), profileId })
  return progressItem
}

export const loadContentAudio = (
  data: ContentAudioData,
): Promise<PlayableProgressItem | undefined> => {
  cancelLoadAndSyncAudio()
  const { cancel, promise } = runCancellable(loadContentAudioGenerator(data))
  cancelLoadAndSyncAudio = cancel
  return promise
}

/**
 * Load audio from content and sync progress and start playing. Does nothing if
 * the content is already playing.
 */
async function* playContentAudioGenerator(
  data: ContentAudioData,
): AsyncGenerator {
  try {
    audioPlayer.publishPlayIntent()
    const tracks = getTracksFromConsumableContent(data)
    const audioState = audioPlayer.getPlaybackState()
    const progressKey = getProgressItemFromConsumableContent(data).getKey()
    // Skip if the requested content is already playing
    if (
      (audioState === PlaybackState.Playing ||
        audioState === PlaybackState.Buffering) &&
      data.trackIndex === undefined &&
      progressKey === (await getCurrentAudioProgressKey(data))
    ) {
      return
    }
    // If no compatible tracks were found then assume an update is required
    if (tracks.length === 0) {
      throw new DisplayableError(
        t("Please update your app to access this content."),
        `[Content] No compatible tracks found for content "${data.consumableContent.content.id}". Client is likely outdated.`,
      )
    }

    // If we're playing an album and our last saved loop mode was set to "one"
    // then revert to "all". The thinking is that if a user is switching tracks
    // he's likely wanting to move out of loop-one mode.
    if (data.consumableContent.type === "album") {
      const loopMode =
        await getClient().localStorage.get<LoopMode>(LOOP_MODE_KEY)
      yield
      if (loopMode === "one") {
        yield getClient().localStorage.set(LOOP_MODE_KEY, "all")
      }
    }

    const progressItem = await loadContentAudio(data)

    if (progressItem) {
      yield audioPlayer.play()
      yield analytics.logPlayRequest({ tracks, progressItem })
    }
  } catch (error) {
    logError(error)
  }
}

export function playContentAudio(data: ContentAudioData): Promise<void> {
  cancelPlayContentAudio()
  const { cancel, promise } = runCancellable(playContentAudioGenerator(data))
  cancelPlayContentAudio = cancel
  return promise.catch(logError)
}

/**
 * Unloads whatever is currently loaded in the audio player.
 */
function* stopAudioPlayerGenerator({
  profileId,
}: {
  profileId: string | null
}): Generator {
  yield audioPlayer.stop()
  removeListeners()
  yield clearCurrentAudioProgressKey({ profileId })
}

export const stopAudioPlayer = ({
  profileId,
}: {
  profileId: string | null
}): Promise<unknown> => {
  cancelStopAudioPlayer()
  const { cancel, promise } = runCancellable(
    stopAudioPlayerGenerator({ profileId }),
  )
  cancelStopAudioPlayer = cancel
  return promise.catch(logError)
}

/**
 * Clears the audio player if `progressKey` corresponds to the currently loaded
 * audio and clears `CURRENT_AUDIO_PROGRESS_KEY` locally and remotely.
 */
async function* stopAudioPlayerIfCurrentGenerator(
  progressItem: ProgressItem,
): AsyncGenerator {
  const profileId = progressItem.getProfileId()
  const currentAudioProgressKey = await getCurrentAudioProgressKey({
    profileId,
  })
  yield
  if (progressItem.getKey() === currentAudioProgressKey) {
    yield stopAudioPlayer({ profileId })
  }
}

export const stopAudioPlayerIfCurrent = (
  progressItem: ProgressItem,
): Promise<unknown> => {
  cancelStopAudioPlayerIfCurrent()
  const { cancel, promise } = runCancellable(
    stopAudioPlayerIfCurrentGenerator(progressItem),
  )
  cancelStopAudioPlayerIfCurrent = cancel
  return promise.catch(logError)
}

/**
 * Returns the ProgressItem key of the audio that is currently loaded into the
 * audio player (or should be currently loaded).
 */
export function getCurrentAudioProgressKey({
  profileId,
}: {
  profileId: string | null
}) {
  return settings
    .getLocal<string>(CURRENT_AUDIO_PROGRESS_KEY, { profileId })
    .then((setting) => setting.value)
    .catch((error) => {
      logError(error)
      return null
    })
}

/**
 * Save the ProgressItem key of the audio that is currently loaded into the
 * audio player so that we can re-load it on app startup.
 */
const saveCurrentAudioProgressKey = async ({
  key,
  profileId,
}: {
  key: string
  profileId: string | null
}): Promise<void> => {
  await Promise.all([
    progressStore
      .saveCurrentAudioProgress({ profileId, value: key })
      .catch(logError),
    settings
      .saveLocal<string>(CURRENT_AUDIO_PROGRESS_KEY, key, { profileId })
      .catch(logError),
  ])
}

/**
 * Clear the ProgressItem key of the audio that is currently loaded into the
 * audio player. This will make the app "forget" whatever was in the audio
 * player.
 */
const clearCurrentAudioProgressKey = async ({
  profileId,
}: {
  profileId: string | null
}): Promise<void> => {
  await Promise.all([
    progressStore.clearCurrentAudioProgress({ profileId }).catch(logError),
    settings
      .clearLocal(CURRENT_AUDIO_PROGRESS_KEY, { profileId })
      .catch(logError),
  ])
}
