import React from "react"

import { AppModuleAudiobook } from "@treefort/api-spec"

import { AudiobookModule } from "../../../../components/modules/audiobook"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleAudiobook["config"]

export class AudiobookListViewModule
  implements ListViewModule<AppModuleAudiobook, Item>
{
  readonly appModule: AppModuleAudiobook
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = undefined
  readonly gapSize = 36
  readonly maxWidth = 1200

  constructor(appModule: AppModuleAudiobook) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <AudiobookModule
      {...args.item}
      paddingTop={args.safeAreaInsets.top}
      maxWidth={args.maxWidth}
      presentation={args.itemCount > 1 ? "listItem" : "solo"}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id
}
