import React, { useState } from "react"
import { Pressable, View } from "react-native"
import styled from "styled-components/native"
import icons from "@treefort/tokens/admin/icons"
import Icon from "./icon"
import Spacer from "./spacer"
import { useTokens } from "./tokens-provider"

const StyledPressable = styled(Pressable)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ExpandableContentContainer = styled.View`
  margin-left: ${({ theme }) =>
    theme.expandableInfo.expandableInfoContentContainer.marginLeft}px;
  border-radius: ${({ theme }) =>
    theme.expandableInfo.expandableInfoContentContainer.borderRadius}px;
  background-color: ${({ theme }) =>
    theme.expandableInfo.expandableInfoContentContainer.backgroundColor};
  padding: ${({ theme }) =>
    theme.expandableInfo.expandableInfoContentContainer.padding}px;
  margin-right: ${({ theme }) =>
    theme.expandableInfo.expandableInfoContentContainer.marginRight}px;
`

export default function ExpandableInfo({
  headerContent,
  children,
}: {
  headerContent: JSX.Element
  children: React.ReactNode
}): JSX.Element {
  const { tokens } = useTokens()
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <View>
      <StyledPressable onPress={() => setIsExpanded(!isExpanded)}>
        <Icon
          size="small"
          source={isExpanded ? icons.chevronDown : icons.chevronRight}
          color={tokens.colors.text.secondary}
        />
        {headerContent}
      </StyledPressable>
      {isExpanded ? (
        <>
          <Spacer size="xsmall" />
          <ExpandableContentContainer>{children}</ExpandableContentContainer>
          <Spacer size="tiny" />
        </>
      ) : null}
    </View>
  )
}
