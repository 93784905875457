import React from "react"
import { useTokens } from "../tokens-provider"
import AppFooterMobile from "./footer-mobile"
import AppFooterDesktop from "./footer-desktop"
import BottomTabBar from "../bottom-tab-bar"

export default function AppFooter(
  props: Omit<Parameters<typeof BottomTabBar>[0], "height" | "safeAreaInsets">,
): JSX.Element {
  const { tokens } = useTokens()
  return tokens.appFooter.mode === "desktop" ? (
    <AppFooterDesktop {...props} />
  ) : (
    <AppFooterMobile {...props} />
  )
}
