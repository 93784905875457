import React, { useCallback } from "react"
import { Animated, DimensionValue } from "react-native"

import { AppBannerItem } from "@treefort/api-spec"

import Banner, { getBannerHeight } from "../banner"
import BannerItem from "../banner-item"
import TokensProvider, { useTokens } from "../tokens-provider"

export { getBannerHeight }

export function BannerModule({
  items,
  height,
  scrollRestorationKey,
  autoplay = false,
}: {
  items: AppBannerItem[]
  height: Exclude<DimensionValue, null | Animated.AnimatedNode>
  scrollRestorationKey?: string
  autoplay?: boolean
}): JSX.Element {
  const { tokens, displayWidth } = useTokens()
  const intervals = items?.length || 1
  const { backgroundColor, gradientOffset, gradientCoverage, scrollIndicator } =
    tokens.bannerModule

  // Make room for the scroll indicator when it's present
  const itemPaddingBottom =
    intervals > 1 ? scrollIndicator.container.height : tokens.spacing.large

  const renderItems = useCallback(
    (itemWidth: number) =>
      items.map((item, index) => (
        <BannerItem
          height={height}
          style={{ width: itemWidth, height }}
          bannerItem={item}
          key={index}
          paddingBottom={itemPaddingBottom}
          backgroundColor={backgroundColor}
          gradientOffset={gradientOffset}
          gradientCoverage={gradientCoverage}
          touchableArea="view"
        />
      )) || [],
    [
      backgroundColor,
      gradientCoverage,
      gradientOffset,
      height,
      itemPaddingBottom,
      items,
    ],
  )

  return (
    <TokensProvider displayMode="dark">
      <Banner
        renderItems={renderItems}
        autoplay={autoplay}
        height={height}
        width={displayWidth}
        showArrows={
          tokens.bannerModule.scrollIndicator.arrows.show as Parameters<
            typeof Banner
          >[0]["showArrows"]
        }
        scrollRestorationKey={scrollRestorationKey}
      />
    </TokensProvider>
  )
}
