export function canShareUrl() {
  return "share" in navigator
}

/**
 * Open the system share dialog for a URL. Promise resolves to true if the user
 * followed through with the share, false if the user canceled, and null if
 * sharing is unavailable.
 */
export async function shareUrl(data: { url: string; title?: string }) {
  if (!canShareUrl()) return null
  return navigator
    .share(data)
    .then(() => true)
    .catch(() => false)
}
