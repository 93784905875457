import React, { ReactNode } from "react"
import { DisplayMode, useTokens } from "../tokens-provider"
import AppHeaderMobile from "./header-mobile"
import AppHeaderDesktop from "./header-desktop"
import { AppHeaderPresentation, AppHeaderState } from "./types"

export default function AppHeader(props: {
  children?: ReactNode
  childrenHeight?: number
  dropShadow?: boolean
  presentation?: AppHeaderPresentation
  state?: AppHeaderState
  displayMode?: DisplayMode
  backgroundColor?: string
}): JSX.Element {
  const { tokens } = useTokens()
  return tokens.appHeader.mode === "desktop" ? (
    <AppHeaderDesktop {...props} />
  ) : (
    <AppHeaderMobile {...props} />
  )
}
