import unique from "@treefort/lib/unique"

import { Track } from "../av/types"
import settings from "../settings"
import { BaseProgressItem } from "./base"

export type ProgressPlayable = {
  position: number
  index: number
  finished: boolean
}

export enum PlayableProgressItemEvent {
  ProgressUpdated = "PROGRESS_UPDATED",
  PlaybackRateChanged = "PLAYBACK_RATE_CHANGED",
}

interface PlayableProgressItemEventMap {
  [PlayableProgressItemEvent.ProgressUpdated]: {
    updatedFields: Array<keyof ProgressPlayable>
  }
  [PlayableProgressItemEvent.PlaybackRateChanged]: number
}

export type VideoProgressItemData = {
  contentType: "video"
  contentId: number
  playbackRate?: number
  progress?: ProgressPlayable
  playMode?: "watch" | "listen"
}

export type AudiobookProgressItemData = {
  contentType: "book"
  contentId: number
  playbackRate?: number
  progress?: ProgressPlayable
}

export type AlbumProgressItemData = {
  contentType: "album"
  contentId: number
  progress?: ProgressPlayable
  playbackRate?: number
}

export type PodcastEpisodeProgressItemData = {
  contentType: "podcast"
  contentId: number
  podcastEpisode?: number
  podcastEpisodeDuration?: number
  playbackRate?: number
  progress?: ProgressPlayable
  // DEPRECATED: This is legacy for podcasts. Now content and progress are
  // separate, but progress still has to store podcastEpisodeDuration
  // somehow since we don't calculate that server side. Previously this
  // wasn't stored separately from tracks, so for the sake of old progress
  // item data, we need to allow tracks to be passed when creating progress
  // items for podcasts, (so we can get the episode duration out if it's set
  // on the track).
  tracks?: {
    title: string
    artwork: string
    duration?: number
    artist?: string
    album?: string
  }[]
}

export type PlayableProgressItemData =
  | AudiobookProgressItemData
  | VideoProgressItemData
  | PodcastEpisodeProgressItemData
  | AlbumProgressItemData

/**
 * Functionality that progress item for "playable" content types (audio or
 * video) must implement.
 */
export class PlayableProgressItem<
  Data extends PlayableProgressItemData = PlayableProgressItemData,
> extends BaseProgressItem<
  Data,
  ProgressPlayable,
  PlayableProgressItemEventMap
> {
  /**
   * Set the default playback rate that should be used for the progress item's
   * content
   */
  private setDefaultPlaybackRate = (playbackRate: number) =>
    this.data.contentType === "podcast"
      ? settings.saveLocal(
          `playback.rate.content.${this.data.contentId}`,
          playbackRate,
          { profileId: this.profileId },
        )
      : undefined

  /**
   * Get the default playback rate that should be used for the progress item's
   * content
   */
  getDefaultPlaybackRate = () =>
    this.data.contentType === "podcast"
      ? settings
          .getLocal<number>(`playback.rate.content.${this.data.contentId}`, {
            profileId: this.profileId,
          })
          .then((setting) => setting.value)
      : null

  getKeySuffix = (): string =>
    `${this.data.contentType}.${this.data.contentId}${
      this.data.contentType === "podcast"
        ? `.episode.${this.data.podcastEpisode || 1}`
        : ""
    }`

  /**
   * Returns the total duration in milliseconds by adding up the duration of
   * each track. Returns undefined if the total duration is not available.
   *
   * NOTE: This may not be exact and so shouldn't be used for important
   * calculations.
   */
  getTotalDuration = (tracks: Track[]): number | undefined => {
    if (this.data.contentType === "podcast") {
      return this.data.podcastEpisodeDuration
    } else {
      let duration = 0
      for (const track of tracks) {
        if (track.duration) {
          duration += track.duration
        } else {
          return undefined
        }
      }
      return duration
    }
  }

  /**
   * Gets overall postion in milliseconds by summing the duration of each track
   * before the current track and position within the current track.
   *
   * NOTE: This may not be exact and so shouldn't be used for important
   * calculations.
   */
  getOverallPosition = (tracks: Track[]): number | undefined => {
    const progress = this.getProgress()
    // If the index is no longer valid (can happen if an audiobook is edited)
    // then don't return anything.
    if (!tracks[progress.index]) {
      return undefined
    } else if (tracks.length === 1 || progress.index === 0) {
      return progress.position
    } else {
      let overallPosition = 0
      for (let i = 0; i < progress.index; i++) {
        const { duration } = tracks[i]
        if (duration) {
          overallPosition += duration
        } else {
          return undefined
        }
      }
      overallPosition += progress.position
      return overallPosition
    }
  }

  getPlaybackRate = () => this.data.playbackRate || 1

  setPlaybackRate = (playbackRate: number) => {
    if (playbackRate !== this.data.playbackRate) {
      this.data.playbackRate = playbackRate
      this.emitter.emit(
        PlayableProgressItemEvent.PlaybackRateChanged,
        playbackRate,
      )
      this.setDefaultPlaybackRate(this.data.playbackRate)
    }
  }

  /**
   * Returns true if a playback rate is explictly set. This can't be inferred
   * from getPlaybackRate as that returns the default rate of `1` if no rate is
   * explicitly set.
   */
  hasPlaybackRate = () => Boolean(this.data.playbackRate)

  getProgress = (): ProgressPlayable => ({
    position: this.data.progress?.position || 0,
    index: this.data.progress?.index || 0,
    finished: this.data.progress?.finished || false,
  })

  updateProgress = (progress: Partial<ProgressPlayable>): void => {
    // Don't mark albums as "finished", that doesn't make sense
    if (this.data.contentType === "album" && "finished" in progress) {
      delete progress.finished
    }

    // Generate the next progress object and determine which fields were updated
    const nextProgress = { ...this.data.progress, ...progress }
    const fields = unique(Object.keys(this.getProgress())) as Array<
      keyof ProgressPlayable
    >
    const updatedFields = fields.filter(
      (key) => nextProgress[key] !== this.data.progress?.[key],
    )

    // If at least one field changed then replace our internal progress object
    // and emit a ProgressUpdated event
    if (updatedFields.length > 0) {
      this.data = {
        ...this.data,
        progress: nextProgress as typeof this.data.progress,
      }
      this.emitter.emit(PlayableProgressItemEvent.ProgressUpdated, {
        updatedFields,
      })
    }
  }
}

export class VideoProgressItem extends PlayableProgressItem<VideoProgressItemData> {
  getPlayMode = (): NonNullable<VideoProgressItemData["playMode"]> =>
    this.data.playMode || "watch"

  setPlayMode = (playMode: NonNullable<VideoProgressItemData["playMode"]>) =>
    (this.data.playMode = playMode)
}

export class AudiobookProgressItem extends PlayableProgressItem<AudiobookProgressItemData> {}

export class AlbumProgressItem extends PlayableProgressItem<AlbumProgressItemData> {}

export class PodcastEpisodeProgressItem extends PlayableProgressItem<PodcastEpisodeProgressItemData> {
  getPodcastEpisodeNumber = (): number => this.data.podcastEpisode || 1

  setPodcastEpisodeDuration = (duration: number): number =>
    (this.data.podcastEpisodeDuration = duration)

  getPodcastEpisodeDuration = (): number | undefined =>
    this.data.podcastEpisodeDuration
}
