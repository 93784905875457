import { MediaEpub } from "@treefort/api-spec"
import { LocationChangedEvent, TapRegion, Theme } from "@treefort/epub-viewer"
import { clamp } from "@treefort/lib/clamp"
import { EventEmitter } from "@treefort/lib/event-emitter"
import { simpleHash } from "@treefort/lib/simple-hash"

import { EbookConsumableContent } from "./consumable-content"
import { DisplayMode } from "./display-mode"

type Highlight = {
  id: string
  colorPresetId: number
  startCfi: string
  endCfi: string
}

export type ColorScheme = (typeof COLOR_SCHEMES)[number]

export type EbookReaderTheme = {
  colorScheme: { light: ColorScheme; dark: ColorScheme }
  fontSizeMultiplier: number
  lineHeightMultiplier: number
  invertImagesInDarkMode: boolean
}

export type EbookReaderLocation =
  | {
      type: "rendered"
      location: LocationChangedEvent["location"]
      metadata: LocationChangedEvent["metadata"]
    }
  | {
      type: "requested"
      location: LocationChangedEvent["location"]
      metadata: { sectionId?: string }
    }

export type Ebook = {
  media: MediaEpub
  title: string
  extra: Record<string, unknown> & {
    consumableContent: EbookConsumableContent
  }
}

export enum EbookReaderEvent {
  EbookWillChange = "EBOOK_WILL_CHANGE",
  EbookChanged = "EBOOK_CHANGED",
  LocationChanged = "LOCATION_CHANGED",
  ThemeChanged = "THEME_CHANGED",
  ShowControlsChanged = "SHOW_CONTROLS_CHANGED",
  Tap = "TAP_EVENT",
  TextSelection = "TEXT_SELECTION",
  CurrentTextSelectionChangeStarted = "CURRENT_TEXT_SELECTION_CHANGE_START",
  NewTextSelectionStarted = "NEW_TEXT_SELECTION_STARTED",
  TextSelectionCleared = "TEXT_SELECTION_CLEARED",
  TapHighlight = "TAP_HIGHLIGHT",
  MoveRequested = "MOVE_REQUESTED",
  CloseRequested = "CLOSE_REQUESTED",
  MarkAllHighlightsRequested = "MARK_ALL_HIGHLIGHTS_REQUESTED",
}

export interface EbookReaderEventMap {
  [EbookReaderEvent.EbookWillChange]: Ebook | null
  [EbookReaderEvent.EbookChanged]: Ebook | null
  [EbookReaderEvent.LocationChanged]: {
    previous: EbookReaderLocation | null
    current: EbookReaderLocation | null
  }
  [EbookReaderEvent.ThemeChanged]: EbookReaderTheme
  [EbookReaderEvent.ShowControlsChanged]: boolean
  [EbookReaderEvent.Tap]: { region: TapRegion }
  [EbookReaderEvent.MoveRequested]: number
  [EbookReaderEvent.CloseRequested]: undefined
  [EbookReaderEvent.MarkAllHighlightsRequested]: {
    highlights: Highlight[]
  }
  [EbookReaderEvent.TextSelection]: {
    startCfi: string
    endCfi: string
    selectedText: string
  }
  [EbookReaderEvent.CurrentTextSelectionChangeStarted]: null
  [EbookReaderEvent.NewTextSelectionStarted]: null
  [EbookReaderEvent.TextSelectionCleared]: null
  [EbookReaderEvent.TapHighlight]: { id: string }
}

export const COLOR_SCHEMES = [
  {
    textColor: "#000000",
    textColorSubdued: "#717171",
    borderColor: "#A3A3A3",
    backgroundColor: "#ffffff",
    linkColor: "#0d99ff",
    displayMode: "light",
  },
  {
    textColor: "#5F5847",
    textColorSubdued: "#8A816A",
    borderColor: "#ADA388",
    backgroundColor: "#F2EEE3",
    linkColor: "#4396d3",
    displayMode: "light",
  },
  {
    textColor: "#E8E0CD",
    textColorSubdued: "#B7AE98",
    borderColor: "#8C846F",
    backgroundColor: "#3E3C37",
    linkColor: "#3490d2",
    displayMode: "dark",
  },
  {
    textColor: "#ffffff",
    textColorSubdued: "#A5A5A5",
    borderColor: "#7D7D7D",
    backgroundColor: "#000000",
    linkColor: "#0d99ff",
    displayMode: "dark",
  },
] as const

const BASE_FONT_SIZE = 18

const BASE_LINE_HEIGHT = 1.35

const MULTIPLIER_STEP = 0.1

const LINE_HEIGHT_MULTIPLIER_RANGE = [0.8, 3]

const FONT_SIZE_MULTIPLIER_RANGE = [0.6, 3.5]

export const DEFAULT_EBOOK_READER_THEME = {
  colorScheme: { light: COLOR_SCHEMES[0], dark: COLOR_SCHEMES[3] },
  fontSizeMultiplier: 1,
  lineHeightMultiplier: 1,
  invertImagesInDarkMode: false,
}

export const HIGHLIGHT_COLOR_PRESETS = [
  {
    id: 1,
    fill: "#EED91C",
    fillOpacity: 0.3,
  },
  {
    id: 2,
    fill: "#F48D2E",
    fillOpacity: 0.3,
  },
  {
    id: 3,
    fill: "#EE3434",
    fillOpacity: 0.3,
  },
  {
    id: 4,
    fill: "#58D03A",
    fillOpacity: 0.3,
  },
  {
    id: 5,
    fill: "#145DEA",
    fillOpacity: 0.3,
  },
]

export class EbookReader extends EventEmitter<EbookReaderEventMap> {
  private ebook: Ebook | null = null
  private location: EbookReaderLocation | null = null
  private theme: EbookReaderTheme = DEFAULT_EBOOK_READER_THEME
  private showControls = false

  static getEpubViewerTheme = ({
    ebookReaderTheme,
    displayMode,
    pageMarginTop,
    pageMarginBottom,
  }: {
    ebookReaderTheme: EbookReaderTheme
    displayMode: DisplayMode
    pageMarginTop: number
    pageMarginBottom: number
  }): Theme => {
    const colorScheme = ebookReaderTheme.colorScheme[displayMode]
    return {
      textColor: colorScheme.textColor,
      textColorSubdued: colorScheme.textColorSubdued,
      backgroundColor: colorScheme.backgroundColor,
      linkColor: colorScheme.linkColor,
      borderColor: colorScheme.borderColor,
      fontSize: BASE_FONT_SIZE * ebookReaderTheme.fontSizeMultiplier,
      lineHeight: BASE_LINE_HEIGHT * ebookReaderTheme.lineHeightMultiplier,
      invertImages:
        ebookReaderTheme.invertImagesInDarkMode &&
        colorScheme.displayMode === "dark",
      pageMarginTop,
      pageMarginBottom,
      highlightColorPresets: HIGHLIGHT_COLOR_PRESETS,
    }
  }

  static clamp = (
    type: "fontSizeMultiplier" | "lineHeightMultiplier",
    multiplier: number,
  ) =>
    clamp(
      multiplier,
      ...{
        fontSizeMultiplier: FONT_SIZE_MULTIPLIER_RANGE,
        lineHeightMultiplier: LINE_HEIGHT_MULTIPLIER_RANGE,
      }[type],
    )

  static increment =
    (type: "fontSizeMultiplier" | "lineHeightMultiplier") =>
    (theme: EbookReaderTheme) => ({
      ...theme,
      [type]: EbookReader.clamp(type, theme[type] + MULTIPLIER_STEP),
    })

  static decrement =
    (type: "fontSizeMultiplier" | "lineHeightMultiplier") =>
    (theme: EbookReaderTheme) => ({
      ...theme,
      [type]: EbookReader.clamp(type, theme[type] - MULTIPLIER_STEP),
    })

  setEbook = async (
    ebook: Ebook | null,
    options?: {
      theme?: EbookReaderTheme | null
      location?: EbookReaderLocation | null
    },
  ): Promise<void> => {
    await this.emitter.emit(EbookReaderEvent.EbookWillChange, ebook)
    this.ebook = ebook
    this.location = (ebook && options?.location) || null
    this.setShowControls(true)
    this.updateTheme(() => options?.theme || DEFAULT_EBOOK_READER_THEME)
    this.emitter.emit(EbookReaderEvent.EbookChanged, this.ebook)
  }

  setLocation = (location: EbookReaderLocation | null) => {
    const previousLocation = this.location
    if (
      previousLocation?.type === "rendered" &&
      location?.type === "requested" &&
      simpleHash(previousLocation.location) === simpleHash(location.location)
    ) {
      // If the current rendered location is requested then there's nothing the
      // ebook needs to do, so imediately publish the requested location as
      // rendered
      this.emitter.emit(EbookReaderEvent.LocationChanged, {
        previous: previousLocation,
        current: previousLocation,
      })
    } else {
      this.location = location
      this.emitter.emit(EbookReaderEvent.LocationChanged, {
        previous: previousLocation,
        current: location,
      })
    }
  }

  setShowControls = (showControls: boolean) => {
    if (this.showControls !== showControls) {
      this.showControls = showControls
      this.emitter.emit(EbookReaderEvent.ShowControlsChanged, showControls)
    }
  }

  updateTheme = (updater: (theme: EbookReaderTheme) => EbookReaderTheme) => {
    const nextTheme = updater(this.theme)
    if (simpleHash(nextTheme) !== simpleHash(this.theme)) {
      this.theme = nextTheme
      this.emitter.emit(EbookReaderEvent.ThemeChanged, nextTheme)
    }
  }

  markAllHighlights = (highlights: Highlight[]) =>
    this.emitter.emit(EbookReaderEvent.MarkAllHighlightsRequested, {
      highlights,
    })

  requestMove = (offset: number) => {
    if (this.ebook) {
      this.emitter.emit(EbookReaderEvent.MoveRequested, offset)
    }
  }

  emitTap = (tap: { region: TapRegion }) => {
    this.emitter.emit(EbookReaderEvent.Tap, tap)
  }

  emitNewTextSelectionStarted = () =>
    this.emitter.emit(EbookReaderEvent.NewTextSelectionStarted, null)

  emitTextSelection = (selection: {
    startCfi: string
    endCfi: string
    selectedText: string
  }) => this.emitter.emit(EbookReaderEvent.TextSelection, selection)

  emitCurrentTextSelectionChangeStarted = () =>
    this.emitter.emit(EbookReaderEvent.CurrentTextSelectionChangeStarted, null)

  emitTextSelectionCleared = () =>
    this.emitter.emit(EbookReaderEvent.TextSelectionCleared, null)

  emitTapHighlight = (id: string) =>
    this.emitter.emit(EbookReaderEvent.TapHighlight, { id })

  requestClose = () => {
    if (this.ebook) {
      this.emitter.emit(EbookReaderEvent.CloseRequested)
    }
  }

  getShowControls = () => this.showControls

  getEbook = () => this.ebook

  getLocation = () => this.location

  getTheme = () => this.theme
}

export const ebookReader = new EbookReader()
