import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import { getDuration, formatDuration } from "@treefort/lib/duration"

import { i18nKey } from "../types/i18next"
import Text, { TextProps } from "./text"

const StyledText = styled(Text)`
  flex-shrink: 0;
`

/**
 * Takes as its child a duration in milliseconds which will be displayed as a
 * formatted duration. Will be displayed as hh:mm:ss, leaving off levels that
 * are not needed. If units is true, the time will instead be formatted
 * as 5h 1m or 5m and fullDuration will be ignored.
 */
export default function Time({
  children = 0,
  countdown = false,
  units = false,
  stringKey,
  ...textProps
}: Omit<TextProps, "numberOfLines"> & {
  children?: number
  countdown?: boolean
  units?: boolean
  stringKey?: i18nKey
}): JSX.Element {
  const milliseconds = isNaN(children) ? 0 : children
  const { t } = useTranslation()
  const time = formatDuration({
    countdown,
    units,
    duration: getDuration(milliseconds),
  })
  return (
    <StyledText {...textProps} numberOfLines={1}>
      {stringKey ? t(stringKey, { time }) : time}
    </StyledText>
  )
}
