import { useAuth } from "@treefort/lib/auth-provider"

import useAppManifest from "./use-app-manifest"
import { useProfiles } from "./use-profiles"

export function useIsProfilesFeatureEnabled() {
  const auth = useAuth()
  const manifest = useAppManifest()
  const profiles = useProfiles()

  return Boolean(
    auth.user &&
      manifest.features.profiles?.enabled &&
      profiles.data &&
      (profiles.data.status === "available" ||
        profiles.data.reasonCode === "offeringRequired"),
  )
}
