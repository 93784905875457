import {
  AnalyticsLogger,
  AnalyticsPlugin,
  CommonPlayParameters,
  CommonReadParameters,
  EventName,
  Parameter,
  PlayableContentParameters,
  ReadableContentParameters,
} from "@treefort/lib/analytics"
import { Event as AuthEvent } from "@treefort/lib/authenticator"
import { moneyToFloat } from "@treefort/lib/money"

import config from "../../config"
import { fetchPageMetadata } from "../../hooks/use-page-metadata"
import { fetchUserInfo } from "../../hooks/use-user-info"
import { RouteWithParams, getPathFromRoute } from "../../navigation/routes"
import { AppManifest } from "../app-manifest"
import authenticator from "../authenticator"
import { Track } from "../av/types"
import { CheckoutSession } from "../checkout"
import {
  ReadableContent,
  getConsumableContentKeyFromTrack,
  getKeyFromConsumableContent,
} from "../consumable-content"
import { logError } from "../logging"
import { debug as appDebug } from "../logging"
import { PlayProgressTracker } from "../play-progress-tracker"
import { addActiveProfileListener } from "../profiles"
import {
  PlayableProgressItem,
  ReadableProgressItem,
  VideoProgressItem,
} from "../progress-item"
import { Store } from "../store"
import { firebaseAnalyticsPlugin } from "./plugins/firebase"
import { metaPixelAnalyticsPlugin } from "./plugins/meta-pixel"
import { rudderstackAnalyticsPlugin } from "./plugins/rudderstack"
import { singularAnalyticsPlugin } from "./plugins/singular"

/**
 * TYPES
 */

// We log progress for playable items in a local store so that network
// connection issues don't cause us to lose the data.
type PlayProgressStoreItem = {
  totalPlayTime: number
  maxOverallPosition?: number
  totalDuration?: number
  eventParameters: CommonPlayParameters & PlayableContentParameters
}

// We log progress for readable items in a local store so that network
// connection issues don't cause us to lose the data.
type ReadProgressStoreItem = {
  maxReadPercent?: number
  eventParameters: CommonReadParameters & ReadableContentParameters
}

/**
 * CONSTANTS
 */

// We'll cap engagement time at this reading speed. If a user appears to be
// reading any slower than this it's likely they actually just put their book
// down.
const READ_ENGAGEMENT_WORDS_PER_MINUTE_MINIMUM = 100

// Don't log engagement if the user spent less than 2 seconds on the page. This
// prevents us from spamming analytics when a user rapidly pages through a book.
const READ_ENGAGEMENT_TIME_MINIMUM = 2000

// The percent milestones in playable or readable content that we want to log
const PERCENT_MILESTONES = [10, 25, 50, 75, 90] as const

/**
 * HELPERS
 */

class AnalyticsError extends Error {
  constructor(message: string, options?: { cause?: unknown }) {
    super(`[Analytics] ${message}`, options)
  }
}

const analyticsDebug = appDebug.extend("analytics")
const playMilestoneStore = new Store({ key: "analytics.milestones.play" })
const readMilestoneStore = new Store({ key: "analytics.milestones.read" })

function getPlayableContentParameters({
  progressItem,
  tracks,
}: {
  progressItem: PlayableProgressItem
  tracks: Track[]
}): CommonPlayParameters & PlayableContentParameters {
  const index = progressItem.getProgress().index
  const track = tracks[index]
  if (!track) {
    throw new Error(
      `No track found at index '${index}' for ${progressItem.getKey()}`,
    )
  }
  const overallPosition = progressItem.getOverallPosition(tracks)
  const totalDuration = progressItem.getTotalDuration(tracks)
  const progressPercent =
    overallPosition !== undefined && totalDuration
      ? (overallPosition / totalDuration) * 100
      : undefined
  return {
    [Parameter.PlayMode]:
      progressItem instanceof VideoProgressItem
        ? progressItem.getPlayMode()
        : "listen",
    [Parameter.PlayTrack]: index,
    [Parameter.PlayPosition]: overallPosition,
    [Parameter.Duration]: totalDuration,
    [Parameter.ProgressPercent]: progressPercent,
    [Parameter.PlaybackRate]: progressItem
      .getPlaybackRate()
      .toFixed(config.PLAYBACK_RATE_DECIMALS),
    [Parameter.ContentId]: track.extra.contentId.toString(),
    [Parameter.ContentTitle]: track.extra.contentTitle,
    [Parameter.ContentSku]: track.extra.contentSku,
    [Parameter.ContentType]: track.extra.contentType,
    ...(track.extra.contentType === "podcast"
      ? {
          [Parameter.ContentPodcastEpisode]: track.extra.podcastEpisodeNumber,
        }
      : null),
  }
}

function getReadableContentParameters({
  progressItem,
  consumableContent,
}: {
  progressItem: ReadableProgressItem
  consumableContent: ReadableContent
}): CommonReadParameters & ReadableContentParameters {
  return {
    [Parameter.ProgressPercent]:
      // Round to the nearest hundreth of a percent
      Math.round(progressItem.getProgress().percent * 10000) / 100,
    [Parameter.ContentId]: consumableContent.content.id.toString(),
    [Parameter.ContentType]: consumableContent.content.type,
    [Parameter.ContentTitle]: consumableContent.content.title,
    [Parameter.ContentSku]: consumableContent.content.sku ?? undefined,
  }
}

function getCheckoutParameters(checkoutSession: CheckoutSession) {
  switch (checkoutSession.type) {
    case "paidPlan":
    case "paidPlanResubscription":
    case "paidPlanWithProrationPreview":
      return {
        [Parameter.SubscriptionPlanId]: checkoutSession.plan.id.toString(),
        [Parameter.SubscriptionPlanProvider]: checkoutSession.plan.provider,
        [Parameter.SubscriptionFreeTrial]:
          checkoutSession.type === "paidPlan" && checkoutSession.isFreeTrial,
        [Parameter.Currency]: checkoutSession.plan.price.currency,
        [Parameter.Price]: moneyToFloat(checkoutSession.plan.price),
        [Parameter.CheckoutCode]: checkoutSession.promoCode,
        [Parameter.ContentId]: checkoutSession.contentId?.toString(),
        [Parameter.RecommId]: checkoutSession.recommId,
      }
    case "groupMembership":
      return {
        [Parameter.SubscriptionPlanId]:
          checkoutSession.subscriptionPlanId.toString(),
        [Parameter.SubscriptionPlanProvider]: "groupMembership",
        [Parameter.CheckoutCode]: checkoutSession.membershipCode,
        [Parameter.ContentId]: checkoutSession.contentId?.toString(),
        [Parameter.RecommId]: checkoutSession.recommId,
      }
  }
}

const getPercentMilestones = ({
  prev,
  next,
  total,
}: {
  prev: number
  next: number
  total: number
}) => {
  const prevPercent = total > 0 ? 100 * (prev / total) : 0
  const nextPercent = total > 0 ? 100 * (next / total) : 0
  return PERCENT_MILESTONES.filter(
    (milestone) => prevPercent < milestone && nextPercent >= milestone,
  )
}

/**
 * MAIN
 */

class Analytics extends AnalyticsLogger {
  private userId: string | null | undefined
  private profileId: string | null | undefined
  private previousRoutePath?: string
  private previousSearch?: {
    query: string
    resultsCount: number
    status: "active" | "inactive" | "abandoned"
  }

  constructor({ plugins }: { plugins: AnalyticsPlugin[] }) {
    super({
      plugins,
      logError,
      logEvent: (event) =>
        analyticsDebug("Logging %s", event.name, event.parameters),
    })

    // Listen for changes to the current user
    authenticator.on(AuthEvent.User, (user) => {
      this.userId = user?.id ?? null
      this.handleIdentityUpdate()
    })
    authenticator.on(AuthEvent.Initialized, (user) => {
      if (!user) {
        this.userId = null
        this.handleIdentityUpdate()
      }
    })

    // Listen for login/register events
    authenticator.on(AuthEvent.ActionComplete, (action) => {
      const user = authenticator.getUser()
      if (user && action === "login") {
        this.logUserAuthenticated(user.id)
      }
      if (user && action === "register") {
        this.logUserRegistered(user.id)
      }
    })

    // Listen for changes to the current profile
    addActiveProfileListener((profile) => {
      this.profileId = profile.state === "set" ? profile.id : null
      this.handleIdentityUpdate()
    })
  }

  /**
   * Log a screen view.
   */
  logScreenView = async (
    route: RouteWithParams,
    manifest: AppManifest,
  ): Promise<void> => {
    const path = getPathFromRoute(route)
    if (path !== this.previousRoutePath) {
      // If the user has a pending search query then log it when they navigate
      // away
      if (this.previousSearch?.status === "active") {
        this.previousSearch.status = "abandoned"
        await this.logEvent({
          name: EventName.Search,
          parameters: {
            [Parameter.SearchOutcome]: "abandoned",
            [Parameter.SearchQuery]: this.previousSearch.query,
            [Parameter.SearchResultsCount]: this.previousSearch.resultsCount,
          },
        })
      }

      this.previousRoutePath = path
      const metadata = await fetchPageMetadata({ route, manifest })
      this.logEvent({
        name: EventName.ScreenView,
        parameters: {
          [Parameter.ScreenPath]: path,
          [Parameter.ScreenPageId]: metadata.page?.id.toString(),
          [Parameter.ScreenTabId]: metadata.tab?.id.toString(),
          [Parameter.ScreenTitle]: metadata.title,
          [Parameter.ContentId]: metadata.content?.id.toString(),
          [Parameter.ContentType]: metadata.content?.type,
          [Parameter.ContentSku]: metadata.content?.sku ?? undefined,
          [Parameter.ContentTitle]: metadata.content?.title,
          [Parameter.CollectionId]: metadata.collection?.id.toString(),
          [Parameter.CollectionTitle]: metadata.collection?.title,
          [Parameter.RecommId]: route.params.recommId,
        },
      })
    }
  }

  /**
   * Log a share
   */
  logShare = async (
    route: RouteWithParams,
    manifest: AppManifest,
  ): Promise<void> => {
    const metadata = await fetchPageMetadata({ route, manifest })
    this.logEvent({
      name: EventName.Share,
      parameters: {
        [Parameter.ScreenPath]: getPathFromRoute(route),
        [Parameter.ScreenPageId]: metadata.page?.id.toString(),
        [Parameter.ScreenTabId]: metadata.tab?.id.toString(),
        [Parameter.ScreenTitle]: metadata.title,
        [Parameter.ContentId]: metadata.content?.id.toString(),
        [Parameter.ContentSku]: metadata.content?.sku ?? undefined,
        [Parameter.ContentTitle]: metadata.content?.title,
        [Parameter.CollectionId]: metadata.collection?.id.toString(),
        [Parameter.CollectionTitle]: metadata.collection?.title,
      },
    })
  }

  /**
   * Log when the user requests to play content from anywhere within the app
   * _except_ the players themselves.
   */
  logPlayRequest = async ({
    tracks,
    progressItem,
  }: {
    tracks: Track[]
    progressItem: PlayableProgressItem
  }): Promise<void> => {
    try {
      const parameters = getPlayableContentParameters({ progressItem, tracks })
      await this.logEvent({ name: EventName.PlayRequest, parameters })
    } catch (cause) {
      logError(new AnalyticsError("Failed to log play request", { cause }))
    }
  }

  /**
   * Log that the user played a particular piece of content for a certain
   * duration. This does not necessarily represent the total duration of the
   * user's play session - the event may be logged multiple times during a
   * single session and summed to come up with the total duration of the
   * session.
   */
  logPlayProgress = async ({
    tracks,
    progressItem,
    playProgressTracker,
    maxLoggableEnagementTime,
    minLoggableEnagementTime = 3000,
  }: {
    tracks: Track[]
    progressItem: PlayableProgressItem
    playProgressTracker: PlayProgressTracker
    // HACK: We've had some unexpected instances of extremely large duration
    // numbers (e.g. 19 years instead of 60 seconds). These cases are extremely
    // rare (less than 1 in 1 million), but unfortunately once they are logged
    // in GA there's no getting rid of them and they are a huge pain. We've
    // since moved from Date.now to performance.now to attempt to address the
    // issue, but to protect our analytics from getting screwed up we've added
    // this param.
    maxLoggableEnagementTime: number
    // Wait to log until we have at least this many milliseconds to log. This
    // prevents things like buffering issues or users mashing play/pause from
    // sending an overwhelming amount of events to the analytics service. Note
    // that if we skip logging we also skip resetting the duration tracker,
    // ensuring that the duration fragment is preserved in the tracker and is
    // still counted the next time logPlayProgress is called.
    minLoggableEnagementTime?: number
  }) => {
    try {
      const engagementTime = playProgressTracker.getEngagementTime()
      const playTime = playProgressTracker.getPlayTime()
      if (engagementTime > maxLoggableEnagementTime) {
        throw new Error(
          `Unexpectedly large engagementTime value for content ${progressItem.getData().contentId}: ${engagementTime}`,
        )
        playProgressTracker.resetTime()
      } else if (engagementTime >= minLoggableEnagementTime) {
        // Extract the current track from the tracklist
        const index = progressItem.getProgress().index
        const track = tracks[index]
        if (!track) {
          throw new Error(
            `No track found at index '${index}' for ${progressItem.getKey()}`,
          )
        }

        const eventParameters = getPlayableContentParameters({
          progressItem,
          tracks,
        })

        // Log the PlayProgress event which allows us to tally up total play
        // engagementTime for all users from within the analytics system.
        this.logEvent({
          name: EventName.PlayProgress,
          parameters: {
            ...eventParameters,
            [Parameter.EngagementTime]: Math.round(engagementTime),
            [Parameter.PlayTime]: Math.round(playTime),
          },
        })

        // Reset the engagementTime tracker once we've logged the PlayProgress
        // event
        playProgressTracker.resetTime()

        // Log newly reached milestones
        const userId = authenticator.getUser()?.id
        const profileId = progressItem.getProfileId()
        const key = `${
          userId && profileId ? userId + ":" + profileId : userId || "anonymous"
        }-${getConsumableContentKeyFromTrack(track)}`
        const totalDuration = progressItem.getTotalDuration(tracks)
        const overallPosition = progressItem.getOverallPosition(tracks)
        await playMilestoneStore
          .get<PlayProgressStoreItem>(key)
          .then(async (prevData) => {
            const prevTotalDuration = prevData?.totalDuration || 0
            const nextTotalDuration = Math.round(
              totalDuration || prevTotalDuration,
            )
            const prevTotalPlayTime = prevData?.totalPlayTime || 0
            const nextTotalPlayTime = prevTotalPlayTime + playTime
            const prevMaxOverallPosition = prevData?.maxOverallPosition || 0
            const nextMaxOveralPosition = Math.max(
              Math.round(overallPosition || 0),
              prevMaxOverallPosition,
            )

            const logTimeMilestones = getPercentMilestones({
              prev: prevTotalPlayTime,
              next: nextTotalPlayTime,
              total: nextTotalDuration,
            }).map((milestone) =>
              this.logEvent({
                name: EventName.PlayTimeMilestone,
                parameters: {
                  ...eventParameters,
                  [Parameter.MilestonePercent]: milestone,
                },
              }),
            )

            const logPositionMilestones = getPercentMilestones({
              prev: prevMaxOverallPosition,
              next: nextMaxOveralPosition,
              total: nextTotalDuration,
            }).map((milestone) =>
              this.logEvent({
                name: EventName.PlayPositionMilestone,
                parameters: {
                  ...eventParameters,
                  [Parameter.MilestonePercent]: milestone,
                },
              }),
            )

            const updateStore = playMilestoneStore.set(key, {
              totalPlayTime: nextTotalPlayTime,
              maxOverallPosition: nextMaxOveralPosition,
              totalDuration: nextTotalDuration,
              eventParameters,
            })

            return Promise.all([
              updateStore,
              ...logTimeMilestones,
              ...logPositionMilestones,
            ])
          })
      }
    } catch (cause) {
      logError(new AnalyticsError("Failed to log play progress", { cause }))
    }
  }

  /**
   * Log when the user requests to read content.
   */
  logReadRequest = async ({
    consumableContent,
    progressItem,
  }: {
    consumableContent: ReadableContent
    progressItem: ReadableProgressItem
  }): Promise<void> => {
    await this.logEvent({
      name: EventName.ReadRequest,
      parameters: getReadableContentParameters({
        progressItem,
        consumableContent,
      }),
    })
  }

  /**
   * Log when the user makes progress in readable content
   */
  logReadProgress = async ({
    consumableContent,
    progressItem,
    engagementTime: rawEngagementTime,
    wordsVisible,
  }: {
    consumableContent: ReadableContent
    progressItem: ReadableProgressItem
    engagementTime: number
    wordsVisible: number
  }) => {
    try {
      // Skip if the engagement time is too small
      if (rawEngagementTime < READ_ENGAGEMENT_TIME_MINIMUM) return

      // Limit engagement time to a reasonable amount (1 minute + the time it
      // would take to read the page at READ_ENGAGEMENT_WORDS_PER_MINUTE_MINIMUM).
      // This avoids logging excessive engagement times in scenarios where the
      // user's device might be on but they're not actively using it.
      const maxEngagementTime =
        (wordsVisible / READ_ENGAGEMENT_WORDS_PER_MINUTE_MINIMUM) * 60000 +
        60000
      const engagementTime = Math.min(rawEngagementTime, maxEngagementTime)

      // Log the page
      const sharedEventParameters = getReadableContentParameters({
        progressItem,
        consumableContent,
      })
      this.logEvent({
        name: EventName.ReadProgress,
        parameters: {
          ...sharedEventParameters,
          [Parameter.EngagementTime]: Math.round(engagementTime),
          [Parameter.WordsVisible]: wordsVisible,
        },
      })

      // Log newly reached milestones
      const key = `${
        authenticator.getUser()?.id || "anonymous"
      }-${getKeyFromConsumableContent(consumableContent)}`
      await readMilestoneStore
        .get<ReadProgressStoreItem>(key)
        .then(async (prevData) => {
          const prevMaxReadPercent = prevData?.maxReadPercent || 0
          const nextMaxReadPercent = Math.max(
            prevMaxReadPercent,
            progressItem.getProgress().percent,
          )

          const logMilestones = getPercentMilestones({
            prev: prevMaxReadPercent,
            next: nextMaxReadPercent,
            total: 1,
          }).map((milestone) =>
            this.logEvent({
              name: EventName.ReadPositionMilestone,
              parameters: {
                ...sharedEventParameters,
                [Parameter.MilestonePercent]: milestone,
              },
            }),
          )

          const updateStore = readMilestoneStore.set(key, {
            maxReadPercent: nextMaxReadPercent,
            eventParameters: sharedEventParameters,
          })

          return Promise.all([updateStore, ...logMilestones])
        })
    } catch (cause) {
      logError(new AnalyticsError("Failed to log read progress", { cause }))
    }
  }

  /**
   * Log when a search query is returned to the user
   */
  logSearch = async ({
    query,
    resultsCount,
  }: {
    query: string
    resultsCount: number
  }) => {
    const previousSearch = this.previousSearch
    const nextSearch = { query, resultsCount, status: "active" as const }
    this.previousSearch = nextSearch

    // If the new search query is not building on the previous search query then
    // log the previous search query as abondoned (no result chosen)
    if (
      previousSearch?.status === "active" &&
      !nextSearch.query.startsWith(previousSearch.query)
    ) {
      await this.logEvent({
        name: EventName.Search,
        parameters: {
          [Parameter.SearchOutcome]: "abandoned",
          [Parameter.SearchQuery]: previousSearch.query,
          [Parameter.SearchResultsCount]: previousSearch.resultsCount,
        },
      })
    }
  }

  /**
   * Log when the user chooses a search query result
   */
  logSearchResultChosen = async ({
    resultId,
    resultType,
  }: {
    resultId: string
    resultType: string
  }) => {
    if (this.previousSearch) {
      this.previousSearch.status = "inactive"
      await this.logEvent({
        name: EventName.Search,
        parameters: {
          [Parameter.SearchOutcome]: "success",
          [Parameter.SearchQuery]: this.previousSearch.query,
          [Parameter.SearchResultsCount]: this.previousSearch.resultsCount,
          [Parameter.SearchResultId]: resultId,
          [Parameter.SearchResultType]: resultType,
        },
      })
    }
  }

  /**
   * Log a contact event
   */
  logContact = async (): Promise<void> => {
    await this.logEvent({ name: EventName.Contact, parameters: undefined })
  }

  /**
   * Log when a checkout session starts
   */
  logCheckoutSessionStart = async (
    checkoutSession: CheckoutSession,
  ): Promise<void> =>
    this.logEvent({
      name: EventName.CheckoutStart,
      parameters: getCheckoutParameters(checkoutSession),
    })

  /**
   * Log when checkout session completes
   */
  logCheckoutSessionComplete = async (
    checkoutSession: CheckoutSession,
  ): Promise<void> =>
    this.logEvent({
      name: EventName.CheckoutComplete,
      parameters: getCheckoutParameters(checkoutSession),
    })

  /**
   * Log when a user creates a new account
   */
  logUserRegistered = async (userId: string) => {
    this.logEvent({
      name: EventName.UserRegistered,
      parameters: { [Parameter.UserId]: userId },
    })
  }

  /**
   * Log when a user signs in with an existing account
   */
  logUserAuthenticated = async (userId: string) => {
    this.logEvent({
      name: EventName.UserAuthenticated,
      parameters: { [Parameter.UserId]: userId },
    })
  }

  /**
   * Update user id and properties
   */
  private handleIdentityUpdate = async () => {
    // Bail if we haven't loaded both user and profile
    if (this.userId === undefined || this.profileId === undefined) {
      return
    }

    if (this.userId) {
      const userInfo = await fetchUserInfo().catch(() => null)
      const subscriptionPlanId =
        typeof userInfo?.subscription.subscribed === "number"
          ? userInfo.subscription.subscribed
          : Array.isArray(userInfo?.subscription.subscribed)
            ? userInfo?.subscription.subscribed[0]
            : undefined
      this.identifyUser({
        [Parameter.UserId]: this.userId,
        [Parameter.ProfileId]: this.profileId,
        [Parameter.UserAuthenticated]: true,
        [Parameter.SubscriptionPlanId]: subscriptionPlanId?.toString(),
      })
    } else {
      this.identifyUser({
        [Parameter.UserId]: null,
        [Parameter.ProfileId]: null,
        [Parameter.UserAuthenticated]: false,
      })
    }
  }
}

const analytics = new Analytics({
  plugins: [
    rudderstackAnalyticsPlugin,
    firebaseAnalyticsPlugin,
    metaPixelAnalyticsPlugin,
    singularAnalyticsPlugin,
  ],
})

export default analytics
