import { AppDisplayMode } from "@treefort/api-spec"
import { SettingStoreItem } from "@treefort/lib/settings"

import { DISPLAY_MODE_SETTING_KEY } from "../lib/display-mode"
import { settingsStore } from "../lib/settings"
import { Store } from "../lib/store"
import useAppManifest from "./use-app-manifest"
import { useStoreItem } from "./use-store-item"

const store = new Store({
  key: "display",
  migrations: [
    {
      name: "seedFromSettings",
      migrate: async (store) => {
        const setting = await settingsStore.get<
          SettingStoreItem<AppDisplayMode>
        >(DISPLAY_MODE_SETTING_KEY)
        if (setting?.value) {
          await store.set(DISPLAY_MODE_SETTING_KEY, setting.value)
          await settingsStore.remove(DISPLAY_MODE_SETTING_KEY)
        }
      },
    },
  ],
})

export function useDisplayModeSetting() {
  const manifest = useAppManifest()
  const [displayMode, setDisplayMode] = useStoreItem<AppDisplayMode>({
    key: DISPLAY_MODE_SETTING_KEY,
    store,
    shouldUpdate: () => manifest.features.userDisplayMode,
  })
  return [
    // If the setting is unset or if the user is not allowed to change the
    // setting then fall back to the default display mode.
    displayMode === null || !manifest.features.userDisplayMode
      ? manifest.theme.defaultDisplayMode
      : displayMode,
    setDisplayMode,
  ] as const
}
