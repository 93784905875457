import { DisplayableError } from "@treefort/lib/displayable-error"
import { getMessageFromAxiosError } from "@treefort/lib/errors"
import isObject from "@treefort/lib/is-object"

import {
  getInvalidGroupMembershipCodeMessage,
  signUpForGroupMembership,
} from "../group-membership"
import {
  checkoutSessionManager,
  CheckoutSessionGroupMembership,
} from "./session"

export const requestGroupMembership = async ({
  checkoutSession,
}: {
  checkoutSession: CheckoutSessionGroupMembership
}) => {
  try {
    await signUpForGroupMembership(checkoutSession)
  } catch (e) {
    await checkoutSessionManager.endSession({ complete: false })
    if (
      isObject(e) &&
      isObject(e.response) &&
      isObject(e.response.data) &&
      typeof e.response.data.reason === "string"
    ) {
      throw new DisplayableError(
        getInvalidGroupMembershipCodeMessage(
          e.response.data as { reason: string },
        ),
        e,
      )
    } else {
      const message = getMessageFromAxiosError(e)
      if (message) {
        throw new DisplayableError(message, e)
      } else {
        throw e
      }
    }
  }
}
