import { useContext } from "react"
import { AppManifestContext } from "../components/app-manifest-provider"

/**
 * Returns a function that can be used to refresh the app manifest. This will
 * throw if called outside AppManifestProvider.
 */
const useRefreshAppManifest = (): (() => Promise<void>) => {
  const context = useContext(AppManifestContext)
  if (context === null) {
    throw new Error(
      "useRefreshAppManifest hook was called but no context was provided. Did you wrap the app in AppManifestProvider?",
    )
  }
  return context.refreshManifest
}

export default useRefreshAppManifest
