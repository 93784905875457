import React from "react"
import { View, ViewProps } from "react-native"
import styled from "styled-components/native"
import { getTextStyleObject, TextStyle } from "../lib/text-style"

const Container = styled(View)<{
  textStyle: TextStyle
  maxWidth?: number
}>`
  ${(props) =>
    `height: ${getTextStyleObject(props.textStyle, props.theme).lineHeight}px`};
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : "")}
`

const FauxText = styled(View)<{ textStyle: TextStyle }>`
  ${(props) =>
    `height: ${getTextStyleObject(props.textStyle, props.theme).fontSize}px`};
  flex: 1;
  background-color: ${(props) => props.theme.colors.loading.text};
  border-radius: ${(props) => props.theme.borderRadius.rounded}px;
`

function TextPlaceholder(
  props: ViewProps & { textStyle: TextStyle; maxWidth?: number },
): JSX.Element {
  return (
    <Container {...props}>
      <FauxText textStyle={props.textStyle} />
    </Container>
  )
}
export default TextPlaceholder
