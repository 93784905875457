import { PixelRatio, Platform } from "react-native"
import { ResolvedTokens } from "../components/tokens-provider"
import styleObjectToString from "./style-object-to-string"

type FontFamilies = ResolvedTokens["fontFamilies"]

type TextStyles = FontFamilies[keyof FontFamilies]

export type TextStyle = keyof TextStyles

export type FontWeight = TextStyles[TextStyle]["font"]["weight"]

export type TextTransform = TextStyles[TextStyle]["textTransform"]

// The text styles that should use the "display" font family
const DISPLAY_TEXT_STYLES = ["headingXLarge", "headingLarge", "headingMedium"]

/**
 * Return platform-friendly font details given a family and weight. This is
 * necessary because Android references font families by font file name
 * (bizarre, but c'est la vie) while everyone else does it the normal way (font
 * = family + weight).
 */
export const getTextStyleObject = (
  textStyle: TextStyle,
  tokens: ResolvedTokens,
): {
  fontFamily: string
  fontSize: number
  lineHeight: number
  fontWeight?: FontWeight
  letterSpacing?: number
  textTransform?: TextTransform
} => {
  const style = getTextStyleTokens(textStyle, tokens)
  const props = {
    fontFamily:
      Platform.select({
        android: style.font.ttfFile.replace(".ttf", ""),
        ios: style.font.ttfName,
      }) || style.font.familyName,
    fontWeight: Platform.select({
      web: style.font.weight,
      ios: style.font.weight,
    }),
    fontSize: style.fontSize,
    lineHeight: style.lineHeight,
    letterSpacing: style.letterSpacing,
    textTransform: style.textTransform,
  }

  // Android really, really doesn't like fontWeight
  if (Platform.OS === "android") {
    delete props.fontWeight
  }

  return props
}

export const getTextStyleString = (
  ...args: Parameters<typeof getTextStyleObject>
): string => styleObjectToString(getTextStyleObject(...args))

export const getAbsoluteLineHeight = (
  textStyle: TextStyle,
  tokens: ResolvedTokens,
): number => {
  const style = getTextStyleTokens(textStyle, tokens)
  const fontScale = PixelRatio.getFontScale()
  return style.lineHeight * fontScale
}

const getTextStyleTokens = (textStyle: TextStyle, tokens: ResolvedTokens) => {
  const fontFamily = DISPLAY_TEXT_STYLES.includes(textStyle)
    ? tokens.fontFamilies.display
    : tokens.fontFamilies.text
  return fontFamily[textStyle]
}
