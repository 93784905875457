import { UseQueryOptions, useQuery } from "react-query"

import { ProfileListResponse } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import api from "../lib/api"
import useAppManifest from "./use-app-manifest"
import useQueryKey from "./use-query-key"

export const PROFILES_QUERY_KEY = "user-profiles"

export function useProfiles<Result = ProfileListResponse>(
  options?: UseQueryOptions<ProfileListResponse, unknown, Result>,
) {
  const manifest = useAppManifest()
  const auth = useAuth()
  const queryKey = useQueryKey(PROFILES_QUERY_KEY)

  const enabled =
    manifest.features.profiles?.enabled && auth.user ? options?.enabled : false

  return useQuery<ProfileListResponse, unknown, Result>(
    queryKey,
    async () => {
      const res = await api.get<ProfileListResponse>(
        `/users/${auth.user?.id}/profiles`,
      )
      return res.data
    },
    { ...options, enabled },
  )
}
