import { useCallback } from "react"

import { isOfflineState } from "../lib/network-state"
import { useNetworkState } from "./use-network-state"

/**
 * A wrapper around useNetworkState hook to simplify checking if the app is
 * offline. The API matches the useNetworkState hook except that the state is a
 * boolean (true = offline, false = online). If network info is not yet
 * available then this gives the network the benefit of the doubt and assumes an
 * online state.
 */
export function useOfflineState(): [boolean, () => Promise<boolean>] {
  const [state, refreshState] = useNetworkState({
    shouldUpdate: (a, b) => a.isInternetReachable !== b.isInternetReachable,
  })
  const refreshOfflineState = useCallback(
    () => refreshState().then(isOfflineState),
    [refreshState],
  )
  return [isOfflineState(state), refreshOfflineState]
}
