import React from "react"
import { useTranslation } from "react-i18next"

import { ProfileListResponse, ProfileResponse } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import { PROFILES_QUERY_KEY } from "../hooks/use-profiles"
import { getQueryKey } from "../hooks/use-query-key"
import api from "../lib/api"
import { profilesOverlay } from "../lib/profiles"
import { queryClient } from "../lib/query-client"
import { toast } from "../lib/toaster"
import { ProfileDetail } from "./profile-detail"

export function ProfilesAddScreen() {
  const auth = useAuth()
  const { t } = useTranslation()

  const createProfile = async (data: { name: string; image: string }) => {
    if (auth.user) {
      const {
        data: { profile },
      } = await api.post<ProfileResponse>(
        `/users/${auth.user.id}/profiles`,
        data,
      )
      queryClient.setQueryData<ProfileListResponse | undefined>(
        getQueryKey(auth.user, PROFILES_QUERY_KEY),
        (data) =>
          data ? { ...data, profiles: [...data.profiles, profile] } : data,
      )
    }
  }

  return (
    <ProfileDetail
      title={t("Add profile")}
      footerSecondary={{
        text: t("Cancel"),
        onPress: async () => profilesOverlay.requestBack(),
      }}
      footerPrimary={{
        text: t("Done"),
        onPress: async ({ name, image }) => {
          if (!name?.trim()) {
            toast.error(t("Please enter your name."))
          } else if (!image) {
            toast.error(t("Please select a profile image."))
          } else {
            await createProfile({ name: name.trim(), image })
            profilesOverlay.requestBack()
          }
        },
      }}
    />
  )
}
