import { useContext } from "react"
import { AppManifestContext } from "../components/app-manifest-provider"
import { AppManifest } from "../lib/app-manifest"

/**
 * Grab the app manifest. This will throw if called outside AppManifestProvider.
 */
const useAppManifest = (): AppManifest => {
  const context = useContext(AppManifestContext)
  if (context === null) {
    throw new Error(
      "useAppManifest hook was called but no context was provided. Did you wrap the app in AppManifestProvider?",
    )
  }
  return context.manifest
}

export default useAppManifest
