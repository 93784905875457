import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import { AsyncViewProvider } from "../../components/async-view"
import { Heading } from "../../components/heading"
import Spacer from "../../components/spacer"
import Text from "../../components/text"
import useAppManifest from "../../hooks/use-app-manifest"
import { useNavigate } from "../../hooks/use-navigate"
import useRefreshAppManifest from "../../hooks/use-refresh-app-manifest"
import { useRoute } from "../../hooks/use-route"
import { logError } from "../../lib/logging"
import PageLayout from "../layouts/page"
import { getRouteFromPath } from "../routes"

// Center content vertically in the page layout
const { contentContainerStyle } = StyleSheet.create({
  contentContainerStyle: { flexGrow: 1, justifyContent: "center" },
})

const CenteredContent = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing.large}px;
`

export function NotFoundScreen(): JSX.Element {
  const [state, setState] = useState<"mounted" | "manifestRefreshed" | "done">(
    "mounted",
  )
  const refreshManifest = useRefreshAppManifest()

  const route = useRoute()
  const manifest = useAppManifest()
  const navigate = useNavigate()
  const { t } = useTranslation()

  // Attempt to refresh the app manifest any time the user ends up on a 404
  // page. This will help resolve the 404 if the user ended up here because they
  // are missing pages that were recently added to the manifest.
  useEffect(() => {
    refreshManifest()
      .then(() => setState("manifestRefreshed"))
      .catch((error) => {
        logError(error)
        setState("done")
      })
  }, [refreshManifest])

  // If we were provided the path that triggered the 404 via a route parameter
  // then check if the path is valid any time the manifest is refreshed. If the
  // path becomes valid after refreshing the manifest then we navigate to it.
  //
  // NOTE: This technique is only used on native where a 404 results in the user
  // actually being redirected to the /not-found/:path route. On the web we can
  // actually leave the user on the missing path (e.g. /this-path-is-missing) so
  // that if refreshing the manifest makes the path valid the NotFoundScreen
  // component is automatically yanked and the newly-found route's component is
  // be rendered.
  useEffect(() => {
    if (state === "manifestRefreshed") {
      // Parse the path of the missing page
      const path = route.params.path && decodeURIComponent(route.params.path)
      const found =
        path && getRouteFromPath(path, manifest).component !== "NotFoundScreen"
      // If the path resolves after refreshing the manifest, navigate to it
      if (path && found) {
        navigate(path)
      }
      // Otherwise update the state to "done" so the not found message is shown.
      else {
        setState("done")
      }
    }
  }, [state, manifest, route.params.path, navigate])

  return (
    <AsyncViewProvider state={state === "done" ? "success" : "loading"}>
      <PageLayout contentContainerStyle={contentContainerStyle}>
        <CenteredContent>
          <Heading level={2} textStyle="headingMedium">
            {t("Not Found")}
          </Heading>
          <Spacer size="small" />
          <Text textStyle="body">{t("Sorry, that page doesn't exist!")}</Text>
        </CenteredContent>
      </PageLayout>
    </AsyncViewProvider>
  )
}
