import { Animated } from "react-native"

/**
 * This utility makes it easier to start an animation, register a callback that
 * will only run if the animation finishes, and receive a cleanup function that
 * will stop the animation.
 */
export function startAnimation(
  animation: Animated.CompositeAnimation,
  finishedCallback?: () => void,
): () => void {
  animation.start(
    finishedCallback
      ? ({ finished }) => {
          if (finished) {
            finishedCallback()
          }
        }
      : undefined,
  )
  return () => {
    animation.stop()
  }
}
