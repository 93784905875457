import React from "react"
import { StyleSheet, View } from "react-native"
import { TouchableWithoutFeedback } from "react-native-gesture-handler"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import icons from "@treefort/tokens/app/icons"

import Icon from "../icon"
import { ImageWithFade, ImageWithFadeProps } from "../image-with-fade"

const MAX_IMAGE_HEIGHT = 1080

const { containerStyle } = StyleSheet.create({
  containerStyle: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
})

const PosterImage = styled(ImageWithFade)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const PlayIconBackdrop = styled.View`
  position: absolute;
  border-radius: ${(props) => props.theme.borderRadius.roundedFull}px;
  background-color: ${(props) => props.theme.colors.black};
  width: ${(props) => props.theme.videoPlayer.playIconBackdropSize}px;
  height: ${(props) => props.theme.videoPlayer.playIconBackdropSize}px;
  opacity: ${(props) => props.theme.videoPlayer.playIconBackdropOpacity};
`

export default function Poster({
  source,
  onPress,
}: {
  source?: ImageWithFadeProps["source"]
  onPress?: () => void
}): JSX.Element | null {
  return source && onPress ? (
    <TouchableWithoutFeedback
      containerStyle={containerStyle}
      style={containerStyle}
      onPress={onPress}
    >
      <PosterImage
        source={getOptimizedImageSource(source, { height: MAX_IMAGE_HEIGHT })}
        resizeMode="contain"
      />
      <PlayIconBackdrop />
      <Icon size="jumbo" source={icons.play} color="highContrast" />
    </TouchableWithoutFeedback>
  ) : source ? (
    <View style={containerStyle}>
      <PosterImage
        source={getOptimizedImageSource(source, { height: MAX_IMAGE_HEIGHT })}
        resizeMode="contain"
      />
    </View>
  ) : null
}
