import React, { useState, useCallback, useRef } from "react"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"
import IconButton, { IconButtonProps } from "./icon-button"

const FORCE_LOADING_DISPLAY_DURATION = 600

export default function AsyncIconButton(props: IconButtonProps): JSX.Element {
  const action = props.onPress
  const forceLoadingDisplayTimeout = useRef<unknown>()
  const [loading, setLoading] = useState(false)
  const [forceLoadingDisplay, setForceLoadingDisplay] = useState(false)
  const willUnmount = useWillUnmount()

  const onPress = useCallback(async () => {
    if (action) {
      clearTimeout(forceLoadingDisplayTimeout.current as number)
      setLoading(true)
      setForceLoadingDisplay(true)
      forceLoadingDisplayTimeout.current = setTimeout(
        () => !willUnmount.current && setForceLoadingDisplay(false),
        FORCE_LOADING_DISPLAY_DURATION,
      )
      Promise.resolve(action()).finally(() => {
        if (!willUnmount.current) {
          setLoading(false)
        }
      })
    }
  }, [action, willUnmount])

  return (
    <IconButton
      {...props}
      state={loading || forceLoadingDisplay ? "loading" : props.state}
      disabled={loading || forceLoadingDisplay || props.disabled}
      onPress={onPress}
    />
  )
}
