"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLockBodyScroll = exports.isBodyScrollLocked = void 0;
const react_1 = require("react");
const dom_1 = require("./dom");
let lockState;
const stopPropagation = (event) => event.stopImmediatePropagation();
function isBodyScrollLocked() {
    return lockState !== undefined;
}
exports.isBodyScrollLocked = isBodyScrollLocked;
/**
 * Prevent document-level scrolling. Helpful when scrolling would be annoying to
 * the user (e.g. when a modal is open and we don't want the page behind the
 * modal to inadvertently scroll).
 *
 * IMPORTANT: This requires that both the html and body tags have height set to
 * 100%.
 *
 * IMPORTANT: Any scrollable view that should remain scrollable when this hook
 * is on should have a data-web-scroll-unlock attribute set.
 *
 * NOTE: It's virtually impossible to block all body scrolling. For example on
 * iOS a swipe down will cause collapsed browser UI to expand no matter what. On
 * certain versions of iOS two fingers can be used to scroll even if scrolling
 * is blocked. There are libraries out there (e.g.
 * https://www.npmjs.com/package/body-scroll-lock) that throw even more
 * JavaScript at the wall than this one does but the resulting side-effects seem
 * to be worse than the illness
 * (https://github.com/willmcpo/body-scroll-lock/issues/237). The purpose of
 * this hook is not to stop body scrolling at all costs but to improve the user
 * experience significantly with a few practical measures.
 */
function useLockBodyScroll(lock) {
    (0, react_1.useEffect)(() => {
        if (lock) {
            if (!lockState) {
                // The lock state holds values that we'll need to release the lock.
                lockState = {
                    lockCount: 1,
                    // Remember the scroll position before locking. This will be used to
                    // preserve that position while locked as some browsers attempt to
                    // reset the scroll position when setting "overflowY" to "hidden" on
                    // the body.
                    scrollTop: document.documentElement.scrollTop,
                    // Remember the various style properties of the document element so
                    // that we can restore them when the lock is released.
                    overflowY: document.documentElement.style.overflowY,
                    overscrollBehaviorY: document.documentElement.style.overscrollBehaviorY,
                    paddingBottom: document.documentElement.style.paddingBottom,
                    // Add an event listener that blocks touch move events on mobile (iOS
                    // Safari ignores overflow settings on the body so this is the only
                    // way to reliably block scrolling there).
                    removeTouchBlocker: (0, dom_1.addEventListener)(document, "touchmove", (event) => {
                        if (!event.target ||
                            !event.target.closest("*[data-web-scroll-unlock]")) {
                            event.preventDefault();
                            return false;
                        }
                    }, { passive: false }),
                };
                // Prevent any scroll events from being handled while we're supposed to
                // be locked. This ensures that the scroll thrashing below doesn't break
                // things like virtualized lists that carefullly watch the body's scroll
                // position.
                document.addEventListener("scroll", stopPropagation);
                // Disable overflow on the body element to prevent scrolling. At the
                // same time we force an empty scrollbar on the document element. This
                // prevents the layout from shifting on platforms that reserve
                // horizontal space for the scrollbar.
                document.documentElement.style.overflowY = "scroll";
                document.documentElement.style.overscrollBehaviorY = "none";
                document.body.style.overflowY = "hidden";
                // HACK (well, even hackier than the rest of this utility): Add 1px of
                // scroll to the document element in order to disable the
                // pull-to-refresh feature on iOS Safari. The pull-to-refresh feature
                // scrolls/resizes the page when activated which can be extremely
                // annoying to the user (e.g. when swiping horizontally through an
                // ebook). This feature will be active unless overscroll behavior is set
                // to "none" on the document element _and_ the document element has some
                // overflow. Targeting touch devices seems like the best way to apply
                // this as a) it doesn't involve finicky user agent sniffing and b)
                // touch devices are the least likely to have always-visible scrollbars
                // (which is the downside to this hack - a scrollbar may be shown in the
                // background for the 1px of overflow).
                if (window.matchMedia("(pointer: coarse)").matches) {
                    document.documentElement.style.paddingBottom = "1px";
                }
                // Restore the body's scroll top in case the browser reset it.
                document.body.scrollTop = lockState.scrollTop;
            }
            else {
                lockState.lockCount++;
            }
            return () => {
                // If we're back down to one lock then release it
                if ((lockState === null || lockState === void 0 ? void 0 : lockState.lockCount) === 1) {
                    // Enable scrolling on the body
                    document.body.style.overflowY = "scroll";
                    // Put the documentElement back into its original state and reset the
                    // lock state
                    if (lockState) {
                        lockState.removeTouchBlocker();
                        document.documentElement.style.overflowY = lockState.overflowY;
                        document.documentElement.style.overscrollBehaviorY =
                            lockState.overscrollBehaviorY;
                        document.documentElement.style.paddingBottom =
                            lockState.paddingBottom;
                        document.documentElement.scrollTop = lockState.scrollTop;
                        lockState = undefined;
                    }
                    // Allow scroll events to be handled again
                    document.removeEventListener("scroll", stopPropagation);
                }
                // If we have more than one lock then decrement the count
                else if (lockState) {
                    lockState.lockCount--;
                }
            };
        }
    }, [lock]);
}
exports.useLockBodyScroll = useLockBodyScroll;
