import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSafeAreaInsets, EdgeInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { User } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import useAppManifest from "../hooks/use-app-manifest"
import { useOnboardingScreens } from "../hooks/use-onboarding-screens"
import useUserInfo from "../hooks/use-user-info"
import IconButton from "./icon-button"
import Modal, { ModalType } from "./modal"
import OnboardingBanner from "./onboarding-banner"
import { ONBOARDING_SCREEN_MAX_WIDTH } from "./onboarding-screen"
import TokensProvider from "./tokens-provider"

const CloseOnboardingModalButtonContainer = styled.View<{
  type: ModalType
  insets: EdgeInsets
}>`
  position: absolute;
  top: ${(props) =>
    (props.type === "fullscreen" ? props.insets.top : 0) +
    props.theme.spacing.xsmall}px;
  right: ${(props) =>
    (props.type === "fullscreen" ? props.insets.right : 0) +
    props.theme.spacing.xsmall}px;
`

export default function Onboarding(): JSX.Element | null {
  const manifest = useAppManifest()
  const enabled = manifest.onboardingScreens.length > 0
  const auth = useAuth()
  const user = useUserInfo({ enabled })
  const showModal =
    enabled && auth.initialized && (!auth.user || user.isSuccess)

  // Render the onboarding modal once auth is initialized and we have user info
  // (if a user is signed in)
  return showModal ? <OnboardingModal user={user.data ?? null} /> : null
}

function OnboardingModal({ user }: { user: User | null }): JSX.Element | null {
  const manifest = useAppManifest()
  const [modalOpen, setModalOpen] = useState<boolean>()
  const insets = useSafeAreaInsets()
  const onboardingScreens = useOnboardingScreens({ user })
  const closeCompleteCallback = useRef<() => unknown>()
  const { t } = useTranslation()

  const handleCloseRequest = useCallback(
    (callback?: () => unknown) => {
      closeCompleteCallback.current = callback
      setModalOpen(false)
    },
    [setModalOpen],
  )

  const handleCloseComplete = useCallback(async () => {
    const callback = closeCompleteCallback.current
    closeCompleteCallback.current = undefined

    await onboardingScreens.markScreensAsSeen?.()
    await callback?.()
  }, [onboardingScreens])

  // Open the onboarding modal if there are screens to show and we haven't shown
  // the modal yet (we only show once per app open to avoid overwhelming the
  // user).
  const modalHasBeenShown = modalOpen !== undefined
  useEffect(() => {
    if (
      onboardingScreens.state === "loaded" &&
      onboardingScreens.screens.length > 0 &&
      !modalHasBeenShown
    ) {
      setModalOpen(true)
    }
  }, [onboardingScreens, modalHasBeenShown, setModalOpen])

  return (
    <Modal
      open={modalOpen ?? false}
      type="fullscreen"
      maxWidth={ONBOARDING_SCREEN_MAX_WIDTH}
      showBorder={false}
      onCloseComplete={handleCloseComplete}
    >
      {(type) => (
        <TokensProvider displayMode="dark">
          {onboardingScreens.state === "loaded" ? (
            <OnboardingBanner
              insets={insets}
              type={type}
              onRequestClose={handleCloseRequest}
              screens={onboardingScreens.screens}
            />
          ) : null}
          <CloseOnboardingModalButtonContainer insets={insets} type={type}>
            <IconButton
              source={manifest.icons.close}
              label={t("Skip")}
              onPress={() => handleCloseRequest()}
              color="highContrast"
            />
          </CloseOnboardingModalButtonContainer>
        </TokensProvider>
      )}
    </Modal>
  )
}
