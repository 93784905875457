import queryString, { StringifiableRecord } from "query-string"

import { isParent } from "../parental-gateway"

/**
 * Open a URL. Defaults to opening in a new tab. Prefer using an actual a tag if
 * possible.
 */
const openUrl = async (
  url: string,
  options: {
    params?: StringifiableRecord
    openInApp?: boolean
    parentalGateway: boolean
  },
): Promise<void> => {
  if (options.parentalGateway && !(await isParent())) return

  const { params, openInApp = false } = options || {}
  const href =
    url + (params === undefined ? "" : `?${queryString.stringify(params)}`)
  // We can't open links in the web app, but we can keep the user in the same
  // tab which is a simlar experience
  if (openInApp) {
    window.location.href = href
  } else {
    window.open(href, "_blank", "noopener")
  }
}

export default openUrl
