"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.horizontalListViewWithArrows = void 0;
const color_1 = require("@treefort/lib/color");
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
exports.horizontalListViewWithArrows = {
    mode: (0, helpers_1.platformToken)({
        default: "mobile",
        web: (0, helpers_1.displayWidthToken)({
            0: "mobile",
            [base_1.breakpoints.desktop]: "desktop",
        }),
    }),
    arrowButton: {
        width: base_1.spacing.jumbo,
        backgroundColor: (0, color_1.colorWithAlpha)(base_1.colors.black, 0.5),
        iconColor: base_1.colors.white,
    },
};
