import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import styled from "styled-components/native"

import { DisplayableError } from "@treefort/lib/displayable-error"

import useAppManifest from "../hooks/use-app-manifest"
import useWindowDimensions from "../hooks/use-window-dimensions"
import { getTreefortMessageFromError } from "../lib/get-treefort-message-from-error"
import { logError } from "../lib/logging"
import { Button } from "./button"
import Column from "./column"
import TextInput from "./form-text-input"
import HorizontalListViewWithArrows from "./horizontal-list-view-with-arrows"
import {
  PROFILE_ACCENT_RING_OUTER_OFFSET,
  PROFILE_IMAGE_SIZE_SMALL,
  ProfileButton,
} from "./profile-button"
import Row from "./row"
import Spacer from "./spacer"
import Text from "./text"
import { useTokens } from "./tokens-provider"

const CONTENT_MAX_WIDTH = 416
const IMAGE_SELECTOR_HEIGHT = 104

const FullWidthColumn = styled(Column)`
  width: 100%;
`

const MaxWidthView = styled.View`
  width: 100%;
  max-width: ${({ theme }) => CONTENT_MAX_WIDTH + theme.spacing.large * 2}px;
  padding: 0 ${({ theme }) => theme.spacing.large}px;
`

const ImagesContainer = styled.View<{ isNarrow: boolean }>`
  width: 100%;
  height: ${IMAGE_SELECTOR_HEIGHT}px;
  max-width: ${({ isNarrow }) =>
    isNarrow ? "100%" : CONTENT_MAX_WIDTH + "px"};
  border-radius: ${({ isNarrow, theme }) =>
    isNarrow ? 0 : theme.borderRadius.rounded}px;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  overflow: hidden;
`

export function ProfileDetail({
  title,
  name: nameProp,
  image: imageProp,
  footerSecondary,
  footerPrimary,
}: {
  title: string
  name?: string
  image?: string
  footerSecondary: {
    text: string
    onPress?: () => Promise<void>
  }
  footerPrimary: {
    text: string
    onPress: (data: { name?: string; image?: string }) => Promise<void>
  }
}) {
  const { t } = useTranslation()
  const { tokens } = useTokens()
  const manifest = useAppManifest()
  const dimensions = useWindowDimensions()
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false)
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false)
  const [name, setName] = useState(nameProp || "")
  const [profileImage, setProfileImage] = useState(imageProp)

  // We don't show this screen if the profiles feature isn't enabled, and if it
  // is enabled then we guarantee the user will have images to choose from
  const images = manifest.features.profiles?.profileImages || []
  const profileImageOptions =
    // If the user's current profile image isnt' included in the list of options
    // from the manifest, then add it as the first option
    imageProp && !images.includes(imageProp) ? [imageProp, ...images] : images
  const iconSize =
    PROFILE_IMAGE_SIZE_SMALL + PROFILE_ACCENT_RING_OUTER_OFFSET * 2
  const isNarrow =
    dimensions.width <= CONTENT_MAX_WIDTH + tokens.spacing.large * 2

  const onPressSecondary = async () => {
    if (!isSecondaryLoading) {
      try {
        setIsSecondaryLoading(true)
        await footerSecondary.onPress?.()
      } catch (e) {
        logError(new DisplayableError(getTreefortMessageFromError(e), e))
      } finally {
        setIsSecondaryLoading(false)
      }
    }
  }

  const onPressPrimary = async () => {
    if (!isPrimaryLoading) {
      try {
        setIsPrimaryLoading(true)
        await footerPrimary.onPress({ name, image: profileImage })
      } catch (e) {
        logError(new DisplayableError(getTreefortMessageFromError(e), e))
      } finally {
        setIsPrimaryLoading(false)
      }
    }
  }

  return (
    <FullWidthColumn>
      <Text textStyle="headingXLarge">{title}</Text>
      <Spacer size="xlarge" />
      <MaxWidthView>
        <Text textStyle="headingSmall">{t("Name")}</Text>
        <Spacer size="xsmall" />
        <TextInput
          maxLength={255}
          value={name}
          onChangeText={setName}
          returnKeyType="done"
          placeholder={t("Name")}
        />
        <Spacer size="large" />
        <Text textStyle="headingSmall">{t("Image")}</Text>
      </MaxWidthView>
      <Spacer size="xsmall" />
      <ImagesContainer isNarrow={isNarrow}>
        {profileImageOptions ? (
          <HorizontalListViewWithArrows
            items={profileImageOptions}
            getItemSize={() => iconSize}
            viewSize={{
              width: Math.min(CONTENT_MAX_WIDTH, dimensions.width),
              height: IMAGE_SELECTOR_HEIGHT,
            }}
            renderItem={(image) => (
              <Column>
                <Spacer size={(IMAGE_SELECTOR_HEIGHT - iconSize) / 2} />
                <ProfileButton
                  size="small"
                  isHighlighted={image === profileImage}
                  profileImage={image}
                  onPress={() => {
                    setProfileImage(image)
                  }}
                />
              </Column>
            )}
            getItemKey={(image) => image}
            arrowButtonHeight={IMAGE_SELECTOR_HEIGHT}
            getGapSize={() => tokens.spacing.small}
            paddingEnd={isNarrow ? tokens.spacing.medium : tokens.spacing.large}
            paddingStart={
              isNarrow ? tokens.spacing.medium : tokens.spacing.large
            }
          />
        ) : null}
      </ImagesContainer>
      <Spacer size="xlarge" />
      <Row
        gap="large"
        paddingHorizontal="large"
        width="100%"
        maxWidth={CONTENT_MAX_WIDTH}
        alignItems="center"
      >
        {footerSecondary.onPress ? (
          <Button
            // HACK: containerStyle + style necessary to get the correct flex
            // behavior on native and web with our button component
            containerStyle={{ flex: 1 }}
            style={Platform.OS === "web" ? { flex: 1 } : undefined}
            onPress={onPressSecondary}
            loading={isSecondaryLoading}
          >
            {footerSecondary.text}
          </Button>
        ) : (
          <Row flex={1} justifyContent="center">
            <Text textStyle="button">{footerSecondary.text}</Text>
          </Row>
        )}
        <Button
          // HACK: containerStyle + style necessary to get the correct flex
          // behavior on native and web with our button component
          containerStyle={{ flex: 1 }}
          style={Platform.OS === "web" ? { flex: 1 } : undefined}
          type="primary"
          onPress={onPressPrimary}
          loading={isPrimaryLoading}
        >
          {footerPrimary.text}
        </Button>
      </Row>
    </FullWidthColumn>
  )
}
