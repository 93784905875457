"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Settings {
    constructor({ store, api, localOnly = () => false, onRemoteError = (e) => {
        throw e;
    }, }) {
        /**
         * Saves a value locally. Store items are timestamped internally so that
         * `getLocalOrRemote` can return the most recent value.
         */
        this.saveLocal = async (key, value, options) => {
            await this.store.set(this.getStoreKey(key, options), this.withTimestamp(value, options));
        };
        /**
         * Saves a value remotely. Store items are timestamped internally so that
         * `getLocalOrRemote` can return the most recent value.
         */
        this.saveRemote = async (key, value, options) => {
            await this.api
                .put(`/settings/${key}`, this.withTimestamp(value, options), this.getApiConfig(options))
                .catch((options === null || options === void 0 ? void 0 : options.onRemoteError) || this.onRemoteError);
        };
        /**
         * Updates the remote value via a callback that receives the current value and
         * returns the next desired value.
         */
        this.updateRemote = async (key, callback, options) => {
            var _a;
            const currentValue = await this.getRemote(key, options);
            const nextValue = await callback((_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.value) !== null && _a !== void 0 ? _a : null);
            await this.saveRemote(key, nextValue, options);
            return nextValue;
        };
        /**
         * Same as `saveLocal` except it saves items to the server as well.
         *
         * @param onRemoteError An optional callback to deal with an http error if the api
         * call fails for some reason. Overrides `this.onRemoteError` which rethrows
         * http errors by default.
         */
        this.saveLocalAndRemote = async (key, value, options) => {
            if (await this.localOnly()) {
                await this.saveLocal(key, value, options);
            }
            else {
                await Promise.all([
                    this.saveLocal(key, value, options),
                    this.saveRemote(key, value, options),
                ]);
            }
        };
        /**
         * Retrieves only the locally saved version of a value.
         */
        this.getLocal = async (key, options) => {
            var _a;
            const item = await this.store.get(this.getStoreKey(key, options));
            return {
                value: (_a = item === null || item === void 0 ? void 0 : item.value) !== null && _a !== void 0 ? _a : null,
                timestamp: item === null || item === void 0 ? void 0 : item.timestamp,
                profileId: options.profileId,
            };
        };
        /**
         * Retrieves only the locally saved version of a value.
         */
        this.getManyLocal = async (keys, options) => {
            const items = await this.store.getMany(keys.map((key) => this.getStoreKey(key, options)));
            return items.map((item) => {
                var _a;
                return ({
                    value: (_a = item === null || item === void 0 ? void 0 : item.value) !== null && _a !== void 0 ? _a : null,
                    timestamp: item === null || item === void 0 ? void 0 : item.timestamp,
                    profileId: options.profileId,
                });
            });
        };
        /**
         * Retrieves only the remotely saved version of a value.
         *
         * **NOTE:** This method will fail with a 401 if the user is not
         * authenticated.
         *
         * * @param onRemoteError An optional callback to deal with an http error if
         *   the api call fails for some reason. Overrides `this.onRemoteError` which
         *   rethrows http errors by default.
         */
        this.getRemote = async (key, options) => {
            var _a;
            const res = await this.api
                .get(`/settings/${key}`, this.getApiConfig(options))
                .catch((options === null || options === void 0 ? void 0 : options.onRemoteError) || this.onRemoteError);
            const remote = res && res.data;
            return {
                value: (_a = remote === null || remote === void 0 ? void 0 : remote.value) !== null && _a !== void 0 ? _a : null,
                timestamp: remote === null || remote === void 0 ? void 0 : remote.timestamp,
                profileId: options.profileId,
            };
        };
        /**
         * Uses internal timestamps to fetch the most recently saved value available,
         * either from local storage or the server.
         *
         * * @param onRemoteError An optional callback to deal with an http error if the api
         * call fails for some reason. Overrides `this.onRemoteError` which rethrows
         * http errors by default.
         */
        this.getLocalOrRemote = async (key, options) => {
            if (await this.localOnly()) {
                return this.getLocal(key, options);
            }
            else {
                const [local, remote] = await Promise.all([
                    this.getLocal(key, options),
                    this.getRemote(key, options),
                ]);
                const isRemoteMoreRecent = (remote === null || remote === void 0 ? void 0 : remote.timestamp) !== undefined &&
                    (local === null || local === void 0 ? void 0 : local.timestamp) !== undefined &&
                    remote.timestamp > local.timestamp;
                // If we found a remote value that's newer than the local value then
                // update the local value. If we don't do this and the user goes offline
                // then the setting will revert to the older local value causing
                // unexpected behavior.
                if (isRemoteMoreRecent) {
                    await this.saveLocal(key, remote.value, Object.assign(Object.assign({}, options), { timestamp: remote.timestamp }));
                }
                return isRemoteMoreRecent ? remote : (local === null || local === void 0 ? void 0 : local.value) ? local : remote;
            }
        };
        /**
         * Clears an item from the local store.
         */
        this.clearLocal = async (key, options) => {
            await this.store.remove(this.getStoreKey(key, options));
        };
        /**
         * Clears an item from the server.
         */
        this.clearRemote = async (key, options) => {
            try {
                await this.api.delete(`/settings/${key}`, this.getApiConfig(options));
            }
            catch (error) {
                const onRemoteError = (options === null || options === void 0 ? void 0 : options.onRemoteError) || this.onRemoteError;
                onRemoteError(error);
            }
        };
        /**
         * Deletes an item from local storage and from the server. Will throw an error
         * if either action fails.
         *
         * **NOTE:** This method will fail with a 401 if the user is not
         * authenticated.
         */
        this.clearLocalAndRemote = async (key, options) => {
            await Promise.all([
                this.clearLocal(key, options),
                this.clearRemote(key, options),
            ]);
        };
        this.store = store;
        this.api = api;
        this.localOnly = localOnly;
        this.onRemoteError = onRemoteError;
        this.onRemoteError.bind(this);
    }
    static getUnixTimestampSeconds() {
        return Math.floor(Date.now() / 1000);
    }
    getStoreKey(key, options) {
        return (options === null || options === void 0 ? void 0 : options.profileId) ? `profile.${options.profileId}.${key}` : key;
    }
    getApiConfig(options) {
        return (options === null || options === void 0 ? void 0 : options.profileId)
            ? { headers: { "X-Treefort-Profile": options.profileId } }
            : undefined;
    }
    withTimestamp(value, options) {
        return {
            value,
            timestamp: (options === null || options === void 0 ? void 0 : options.timestamp) || Settings.getUnixTimestampSeconds(),
        };
    }
}
exports.default = Settings;
