import queryString from "query-string"

import { runCancellable } from "@treefort/lib/cancellable"
import { clamp } from "@treefort/lib/clamp"
import { AudioError } from "@treefort/lib/errors"
import { EventEmitter } from "@treefort/lib/event-emitter"

import { PROGRESS_UPDATE_INTERVAL } from "../av/constants"
import { SkipInterval } from "../av/media-session"
import {
  Track,
  Event,
  EventMap,
  Progress,
  PlaybackState,
  SkipMode,
  LoopMode,
} from "../av/types"
import { logWarning } from "../logging"

// The number of milliseconds to skip backward and forward. Only 15s and 30s are
// supported due to OS constraints and the fact we've only got skip 15 and skip 30
// icons.
export const SKIP_BACKWARD_INTERVAL: SkipInterval = 10000
export const SKIP_FORWARD_INTERVAL: SkipInterval = 30000
export { PROGRESS_UPDATE_INTERVAL }

export type { Track, Progress }
export { PlaybackState, Event }

/**
 * This abstract class is designed to provide a consistent, cross-platform API
 * for any number of audio players based on different audio engines.
 */
export abstract class AudioPlayer extends EventEmitter<EventMap> {
  private cancelLoadPosition: () => void | Promise<void> = () => {}
  private cancelLoadTrack: () => void | Promise<void> = () => {}

  private tracks: Track[] = []
  private playbackState: PlaybackState = PlaybackState.Idle
  private playbackRate = 1
  private seekable = false
  private index?: number
  private frozenPlaybackState?: PlaybackState
  private frozenProgress?: Progress
  private skipMode: SkipMode = "position"
  private loopMode: LoopMode = "off"

  /**
   * PRIVATE METHODS
   */

  private triggerPlaySafe = () =>
    this.triggerPlay().catch((cause) =>
      this.handleError(new AudioError("Failed to start playback", { cause })),
    )

  /**
   * PROTECTED METHODS
   */

  protected cancelPendingTasks = () => {
    this.cancelLoadPosition()
    this.cancelLoadTrack()
  }

  protected handleError = (error: unknown): void => {
    logWarning(error)
    this.emitter.emit(Event.Error, error)
    this.setPlaybackState(PlaybackState.Error)
  }

  protected setIndex = (nextIndex: number): void => {
    if (this.index !== nextIndex) {
      this.index = nextIndex
      const index = this.getIndex()
      const track = this.getTrack()
      if (index !== undefined && track) {
        this.emitter.emit(Event.TrackIndex, index)
        this.emitter.emit(Event.Track, track)
      }
    }
  }

  protected setSeekable = (seekable: boolean): void => {
    if (seekable !== this.seekable) {
      this.seekable = seekable
      this.emitter.emit(Event.Seekable, this.seekable)
    }
  }

  protected setPlaybackState = (playbackState: PlaybackState): void => {
    if (playbackState !== this.playbackState) {
      this.playbackState = playbackState
      if (!this.frozenPlaybackState) {
        this.emitter.emit(Event.PlaybackState, this.playbackState)
      }
    }
  }

  /**
   * This can be used to the freeze playback state at a particular value until
   * thawPlaybackState is called. This is helpful in stabilizing noise from
   * underlying audio libraries that often emit random/jittery playback state
   * events during actions like skipping tracks or seeking within a track.
   */
  protected freezePlaybackState = (
    state: PlaybackState = this.playbackState,
  ): void => {
    this.frozenPlaybackState = state
    if (this.frozenPlaybackState !== this.playbackState) {
      this.emitter.emit(Event.PlaybackState, this.frozenPlaybackState)
    }
  }

  /**
   * "Thaw" the playback state "frozen" by freezePlaybackState. Calls to
   * setPlaybackState will begin emitting playback stateevents again. The latest
   * playback state will be emitted immediately if different than the frozen
   * state.
   */
  protected thawPlaybackState = (): void => {
    if (
      this.frozenPlaybackState &&
      this.frozenPlaybackState !== this.playbackState
    ) {
      this.emitter.emit(Event.PlaybackState, this.playbackState)
    }
    this.frozenPlaybackState = undefined
  }

  protected publishFinished = (): void => {
    this.emitter.emit(Event.Finished)
  }

  protected publishProgress = (
    progress: Progress = this.getProgress(),
  ): void => {
    if (!this.frozenProgress) {
      this.emitter.emit(Event.Progress, progress)
    }
  }

  /**
   * This can be used to pause progress events until thawProgress is called.
   * Like freezePlaybackState, this is helpful in stabilizing noise from
   * underlying audio libraries that often emit random/jittery progress events
   * during actions like skipping tracks or seeking within a track.
   */
  protected freezeProgress = (
    progress: Progress = this.getProgress(),
  ): void => {
    this.frozenProgress = undefined
    this.publishProgress(progress)
    this.frozenProgress = progress
  }

  /**
   * "Thaws" progress "frozen" by freezeProgress. Immediately emits the current
   * progress allow publishProgress to start emitting events again.
   */
  protected thawProgress = (): void => {
    if (this.frozenProgress !== undefined) {
      this.frozenProgress = undefined
      this.publishProgress()
    }
  }

  /**
   * Returns true if the track was loaded, false if no attempt was made (e.g. no
   * track is selected), or undefined if the attempt was canceled or errored
   * out.
   */
  protected loadTrack = async (args: {
    track: Track
    index: number
  }): Promise<boolean | undefined> => {
    this.cancelLoadTrack()
    const { cancel, promise } = runCancellable(
      this.loadTrackGenerator(args),
      this.loadTrackCleanup,
    )
    this.cancelLoadTrack = cancel
    return promise.catch((cause) => {
      this.handleError(new AudioError("Failed to load track", { cause }))
      return undefined
    })
  }

  /**
   * Returns true if the position was loaded, false if no attempt was made (e.g.
   * no track is selected), or undefined if the attempt was canceled or errored
   * out.
   */
  protected loadPosition = async (
    position: number,
  ): Promise<boolean | undefined> => {
    this.cancelLoadPosition()
    const { cancel, promise } = runCancellable(
      this.loadPositionGenerator(position),
      this.loadPositionCleanup,
    )
    this.cancelLoadPosition = cancel
    return promise.catch((cause) => {
      this.handleError(new AudioError("Failed to load position", { cause }))
      return undefined
    })
  }

  /**
   * ABSTRACT METHODS
   */

  /**
   * This should return the current position of the current track.
   */
  protected abstract getTrackPosition(): number

  /**
   * This should return the duration of the current track, or 0 if the duration
   * is not loaded or is not known.
   */
  protected abstract getTrackDuration(): number

  /**
   * This should update the audio player to use the specified playback rate.
   */
  protected abstract loadPlaybackRate(playbackRate: number): Promise<void>

  /**
   * This should run any logic necessary to load the tracks from the `tracks`
   * property into the audio player (e.g. clear out old tracks, reset caches,
   * etc.).
   */
  protected abstract loadTracks(tracks: Track[]): Promise<void>

  /**
   * This should load the track returned from the `getTrack` method into the
   * audio player. This should be implemented as a generator and should yield
   * after any asyncronous actions so that it can be canceled partway through
   * execution. This should return true IFF the track was successfully loaded.
   */
  protected abstract loadTrackGenerator(args: {
    track: Track
    index: number
  }): Generator<unknown, boolean> | AsyncGenerator<unknown, boolean>

  /**
   * This should perform any cleanup that might be necessary if
   * loadTrackGenerator is canceled partway through.
   */
  protected abstract loadTrackCleanup?(): void | Promise<void>

  /**
   * This should skip to the specified position in the current track. This
   * should be implemented as a generator and should yield after any asyncronous
   * actions so that it can be canceled partway through execution. This should
   * return true IFF the position was successfully loaded.
   */
  protected abstract loadPositionGenerator(
    position: number,
  ): Generator<unknown, boolean> | AsyncGenerator<unknown, boolean>

  /**
   * This should perform any cleanup that might be necessary if
   * loadPositionGenerator is canceled partway through.
   */
  protected abstract loadPositionCleanup?(): void | Promise<void>

  /**
   * This should start playback.
   */
  protected abstract triggerPlay(): Promise<void>

  /**
   * This should pause playback.
   */
  protected abstract triggerPause(): Promise<void>

  /**
   * PUBLIC METHODS
   */

  /**
   * Replace the list of tracks loaded into the audio player with a new list. An
   * empty list may be supplied to clear the audio player. If you intend to skip
   * to a particular track, skip to a particular position, and/or start playback
   * immediately then do so by setting the corresponding options via the second
   * argument to this function. This is preferable to calling skipToTrack,
   * skipToPosition, or play immediately after calling setTracks because it
   * allows setTracks to better coordinate playback and progress events during
   * the entire sequence of events.
   */
  setTracks = async (
    tracks: Track[],
    options?: {
      index?: number
      position?: number
      playbackRate?: number
      autoPlay?: boolean
      skipMode?: SkipMode
      loopMode?: LoopMode
    },
  ): Promise<void> => {
    this.cancelPendingTasks()
    await this.pause()

    this.tracks = tracks.map((track) => {
      // HACK: SoundCloud sends a 302 response with an incorrect cache header
      // for podcast media forcing us to bust the cache manually here.
      if (track.url.startsWith("https://feeds.soundcloud.com")) {
        return {
          ...track,
          url: queryString.stringifyUrl({
            url: track.url,
            query: { [new Date().getTime()]: null },
          }),
        }
      } else {
        return track
      }
    })

    this.index = options?.index

    const index = this.getIndex()

    // Set seekable to false and freeze playback state and progress (so we don't
    // report all the glitchy state updates that ocur when loading new tracks)
    this.freezeProgress({ position: 0, duration: 0, index })
    this.freezePlaybackState(
      this.tracks.length && options?.autoPlay
        ? PlaybackState.Buffering
        : this.tracks.length
          ? PlaybackState.Loading
          : PlaybackState.Idle,
    )

    // Apply the new tracks
    await this.loadTracks(this.tracks)
    this.emitter.emit(Event.Tracks, this.getTracks())

    // Apply the plaback rate if one was passed in
    if (options?.playbackRate) {
      await this.setPlaybackRate(options.playbackRate)
    }

    // Apply the skip mode if one was passed in
    if (options?.skipMode) {
      await this.setSkipMode(options.skipMode)
    }

    // Skip to the provided track if an index was set
    if (index !== undefined) {
      await this.skipToTrack(index, options)
    }

    // Start reporting state updates again
    this.thawPlaybackState()
    this.thawProgress()
  }

  /**
   * Skip to a particular track that has already been loaded via the `setTracks`
   * method. If you intend to skip to a particular position and/or start
   * playback immediately then do so by setting the corresponding options via
   * the second argument to this function. This is preferable to calling
   * skipToPosition or play after calling skipToTrack because it allows
   * skipToTrack to better coordinate playback and progress events during the
   * entire sequence of events.
   */
  skipToTrack = async (
    nextIndex: number,
    options?: { position?: number; autoPlay?: boolean; loopMode?: LoopMode },
  ): Promise<number> => {
    this.index = nextIndex

    const track = this.getTrack()
    const index = this.getIndex()
    if (index !== undefined && track) {
      this.cancelPendingTasks()

      // Set seekable to false and freeze playback state and progress (so we don't
      // report all the glitchy state updates that ocur when loading new tracks)
      // Mark the player as not seekable immediately
      this.setSeekable(false)
      this.freezeProgress({ position: 0, duration: 0, index })
      this.freezePlaybackState(
        options?.autoPlay ? PlaybackState.Buffering : PlaybackState.Loading,
      )

      // Optimistically notify everyone of the new track
      this.emitter.emit(Event.Track, track)
      this.emitter.emit(Event.TrackIndex, index)

      const loaded = await this.loadTrack({ track, index })
      if (loaded && options?.position) {
        await this.skipToPosition(options?.position)
      }
      if (loaded && options?.autoPlay) {
        await this.triggerPlaySafe()
      }

      // Apply the loop mode if one was passed in. If no loop mode was specified
      // and the current loop mode is "one" then revert to "all".
      const loopMode =
        options?.loopMode || (this.getLoopMode() === "one" ? "all" : undefined)
      if (loopMode) {
        await this.setLoopMode(loopMode)
      }

      // Thaw playback and progress whether the track was loaded or not
      this.thawPlaybackState()
      this.thawProgress()
    }

    return nextIndex
  }

  /**
   * Skip to a particular position within the current track. Position must be
   * provided as milliseconds.
   */
  skipToPosition = async (
    position: number,
    options?: { autoPlay?: boolean },
  ): Promise<void> => {
    const index = this.getIndex()
    if (index !== undefined) {
      this.cancelLoadPosition()

      // Freeze the playback state as loading and optimistically update progress
      // to the new position
      const playbackState = this.getPlaybackState()
      this.freezePlaybackState(
        playbackState === PlaybackState.Playing ||
          playbackState === PlaybackState.Buffering
          ? PlaybackState.Buffering
          : PlaybackState.Loading,
      )
      this.freezeProgress({ ...this.getProgress(), position })

      const loaded = await this.loadPosition(position)
      if (loaded) {
        this.emitter.emit(Event.Seeked, position)
      } else {
        this.emitter.emit(Event.SeekCancelled)
      }

      if (loaded && options?.autoPlay) {
        await this.triggerPlaySafe()
      }

      // Thaw playback and progress whether the track was loaded or not
      this.thawPlaybackState()
      this.thawProgress()
    }
  }

  /**
   * Skips forward (positive number) or backward (negative number) within the
   * current track by the number of milliseconds provided.
   */
  skipPosition = async (interval: number): Promise<number> => {
    const { position, duration } = this.getProgress()
    const nextPosition = clamp(position + interval, 0, duration)
    await this.skipToPosition(nextPosition)
    return nextPosition
  }

  /**
   * Skips forward (positive number) or backward (negative number) by the number
   * of tracks provided.
   */
  skipTrack = async (
    interval: number,
    options?: Parameters<typeof this.skipToTrack>[1],
  ): Promise<number> => {
    const index = this.getIndex() || 0
    const nextIndex = clamp(index + interval, 0, this.tracks.length - 1)
    return this.skipToTrack(nextIndex, options)
  }

  /**
   * To the beginning of the current track or to the previous track.
   */
  skipBackward = async (): Promise<number> => {
    const index = this.getIndex() || 0

    // If we're on the first track or if we're more than 2 seconds into the
    // track then skip to the start of the track instead of to the previous
    // track.
    const { position } = this.getProgress()
    if (position > 2000 || index === 0) {
      await this.skipToPosition(0)
      return index
    }

    // Skip to the previous track
    return this.skipTrack(-1, { autoPlay: true })
  }

  /**
   * To the next track or to the beginning of the queue if we're already on the
   * last track.
   */
  skipForward = async (): Promise<number> => {
    const index = this.getIndex() || 0

    // If we're skipping forward and we're already on the last track then skip
    // to the first track. Autoplay if looping is enabled, otherwise stop
    // playback.
    if (index === this.tracks.length - 1) {
      return this.skipToTrack(0, { autoPlay: this.getLoopMode() !== "off" })
    }

    // Skip the number of requested tracks
    return this.skipTrack(1, { autoPlay: true })
  }

  play = async (): Promise<void> => {
    if (this.getTrack()) {
      // If playback was requested but we're in an error state then attempt to
      // reload the current track.
      if (this.getPlaybackState() === PlaybackState.Error) {
        await this.skipToTrack(this.getIndex() || 0, {
          position: this.getProgress().position,
          autoPlay: true,
        })
      } else {
        await this.triggerPlaySafe()
      }
    }
  }

  pause = async (): Promise<void> => {
    await this.triggerPause().catch((cause) =>
      this.handleError(new AudioError("Failed to pause playback", { cause })),
    )
  }

  /**
   * Returns the current track's index (within the list of tracks passed to
   * setTracks), position (milliseconds), and duration (milliseconds). Duration
   * will be 0 if the track's metadata has not been loaded yet or if the
   * duration cannot be known (e.g. for a live stream).
   */
  getProgress = (): Progress =>
    this.frozenProgress || {
      position: this.getTrackPosition(),
      duration: this.getTrackDuration(),
      index: this.getIndex(),
    }

  /**
   * Returns true if the audio player can accept seek commands for the current
   * track.
   */
  getSeekable = (): boolean => this.seekable

  getPlaybackState = (): PlaybackState =>
    this.frozenPlaybackState || this.playbackState

  /**
   * Returns the current track's index within the list of tracks passed to
   * setTracks.
   */
  getIndex = (): number | undefined =>
    this.index !== undefined && this.tracks[this.index] ? this.index : undefined

  /**
   * Returns the exact array passed to setTracks.
   */
  getTracks = (): Track[] => this.tracks

  getTrack = (index = this.getIndex()): Track | null =>
    index !== undefined && this.tracks[index] ? this.tracks[index] : null

  setPlaybackRate = async (rate: number) => {
    if (rate !== this.playbackRate) {
      this.playbackRate = rate
      await this.loadPlaybackRate(this.playbackRate).catch((cause) =>
        this.handleError(
          new AudioError("Failed to load playback rate", { cause }),
        ),
      )
      this.emitter.emit(Event.PlaybackRate, this.playbackRate)
    }
  }

  getPlaybackRate = () => this.playbackRate

  setSkipMode = async (skipMode: SkipMode) => {
    if (skipMode !== this.skipMode) {
      this.skipMode = skipMode
      this.emitter.emit(Event.SkipMode, this.skipMode)
    }
  }

  setLoopMode = async (loopMode: LoopMode) => {
    if (loopMode !== this.loopMode) {
      this.loopMode = loopMode
      this.emitter.emit(Event.LoopMode, this.loopMode)
    }
  }

  toggleLoopMode = async () => {
    switch (this.loopMode) {
      case "off":
        return this.setLoopMode("all")
      case "all":
        return this.setLoopMode("one")
      default:
        return this.setLoopMode("off")
    }
  }

  getSkipMode = () => this.skipMode

  getLoopMode = () => this.loopMode

  /**
   * Stops playback and clears all tracks from the audio player.
   */
  stop = async (): Promise<void> => {
    await this.emitter.emit(Event.WillStop)
    await this.setTracks([])
  }

  /**
   * This should be called as soon as we know that we're about to play a video.
   */
  publishPlayIntent = (): void => {
    this.emitter.emit(Event.PlayIntent)
  }
}
