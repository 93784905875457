import { useContext } from "react"

import { ActiveProfileContext } from "../lib/profiles"

export function useActiveProfileId() {
  const context = useContext(ActiveProfileContext)

  if (!context) {
    throw new Error(
      "[Profiles] 'useProfileId' called outside of ProfileProvider",
    )
  }

  return context.state === "set" ? context.id : null
}
