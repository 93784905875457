import React, { useCallback } from "react"
import styled from "styled-components/native"
import Box from "../components/box"
import chunk from "lodash/chunk"
import Spacer, { Spacing } from "./spacer"

type GridProps = {
  items: JSX.Element[]
  itemsPerRow: number
  gutterSize?: Spacing
} & Omit<Parameters<typeof Box>[0], "children">

const ItemRow = styled.View`
  display: flex;
  flex-direction: row;
`

const FillItem = styled.View`
  flex: 1;
`

export default function Grid({
  items,
  itemsPerRow,
  gutterSize = "medium",
  ...boxProps
}: GridProps): JSX.Element {
  const rows: JSX.Element[][] = chunk(items, itemsPerRow)

  const padRow = useCallback(function padRowFn(
    row: JSX.Element[],
    padding: number,
  ): JSX.Element[] {
    return row.concat(
      new Array(padding)
        .fill(null)
        .map((_, i) => <FillItem key={`fill-${i}`} />),
    )
  },
  [])

  return (
    <Box {...boxProps}>
      {rows.map((row, rowIndex) => {
        const rowKey = `${rowIndex}-row-${items
          .map((item) => item.key)
          .slice(rowIndex * itemsPerRow, rowIndex * itemsPerRow + itemsPerRow)
          .join("-")}`
        const isLastRow = rowIndex === rows.length - 1
        return (
          <React.Fragment key={rowKey}>
            <ItemRow>
              {(row.length < itemsPerRow
                ? padRow(row, itemsPerRow - row.length)
                : row
              ).map((item, rowItemIndex) => {
                const isLastItemInRow = rowItemIndex === itemsPerRow - 1
                return (
                  <React.Fragment key={item.key}>
                    {item}
                    {!isLastItemInRow && gutterSize ? (
                      <Spacer horizontal size={gutterSize} />
                    ) : null}
                  </React.Fragment>
                )
              })}
            </ItemRow>
            {!isLastRow && gutterSize ? <Spacer size={gutterSize} /> : null}
          </React.Fragment>
        )
      })}
    </Box>
  )
}
