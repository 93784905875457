import React from "react"
import { AppModuleCard } from "@treefort/api-spec"
import { CardModule } from "../../../../components/modules/card"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleCard["config"]

export class CardListViewModule implements ListViewModule<AppModuleCard, Item> {
  readonly appModule: AppModuleCard
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = undefined
  readonly gapSize = 0
  readonly maxWidth = undefined

  constructor(appModule: AppModuleCard) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <CardModule
      {...args.item}
      paddingTop={args.safeAreaInsets.top || undefined}
      maxWidth={args.maxWidth}
      displayWidth={args.displayWidth}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id
}
