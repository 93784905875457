"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.triggerAndWaitForEvent = void 0;
/**
 * Run a "triggering" function that is expected to result in an event. Wait for
 * that event to be fired. Once fired, the event is returned. If the event is
 * never fired then this function will pend forever.
 */
async function triggerAndWaitForEvent({ trigger, addEventListener, shouldAcceptEvent, }) {
    const eventTriggered = new Promise((resolve) => {
        const removeListener = addEventListener((event) => {
            if (!shouldAcceptEvent || shouldAcceptEvent(event)) {
                removeListener();
                resolve(event);
            }
        });
    });
    await trigger();
    return eventTriggered;
}
exports.triggerAndWaitForEvent = triggerAndWaitForEvent;
