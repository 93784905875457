import { PROGRESS_VERSION, PROGRESS_KEY_PREFIX } from "@treefort/constants"
import { EventEmitter } from "@treefort/lib/event-emitter"

import settings from "../settings"

/**
 * The base functionality that all progress items must implement
 */
export abstract class BaseProgressItem<
  Data,
  Progress extends Record<string, unknown>,
  EventMap,
> extends EventEmitter<EventMap> {
  protected data: Data
  protected profileId: string | null

  constructor({ data, profileId }: { data: Data; profileId: string | null }) {
    super()
    this.data = data
    this.profileId = profileId
  }

  abstract getKeySuffix: () => string

  abstract getProgress: () => Progress

  abstract updateProgress: (progress: Partial<Progress>) => void

  getKey = () =>
    `${PROGRESS_KEY_PREFIX}.${PROGRESS_VERSION}.${this.getKeySuffix()}`

  getData = () => this.data

  getProfileId = () => this.profileId

  removeAllListeners = () => this.emitter.clearListeners()

  saveLocalAndRemote = () =>
    settings.saveLocalAndRemote(this.getKey(), this.getData(), {
      profileId: this.getProfileId(),
    })

  saveLocal = () =>
    settings.saveLocal(this.getKey(), this.getData(), {
      profileId: this.getProfileId(),
    })

  clearLocalAndRemote = () =>
    settings.clearLocalAndRemote(this.getKey(), {
      profileId: this.getProfileId(),
    })
}
