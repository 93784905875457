import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import { ScrollView } from "react-native-gesture-handler"

import config from "../config"
import { usePlaybackRate } from "../hooks/audio"
import audioPlayer from "../lib/audio-player"
import { Button } from "./button"
import Modal from "./modal"
import Row from "./row"
import Spacer from "./spacer"

// Unlocks a scroll view during body scroll locking on the web
const WEB_SCROLL_UNLOCK = { webScrollUnlock: true }

export const playbackRateToString = (rate: number): string =>
  rate.toFixed(config.PLAYBACK_RATE_DECIMALS).replace(/(\d)0+/, "$1") + "x"

export default function AudioPlayerRatePicker({
  open,
  onRequestClose,
}: {
  open: boolean
  onRequestClose: () => void
}): JSX.Element {
  const playbackRate = usePlaybackRate()

  const { t } = useTranslation()

  return (
    <Modal
      title={t("Playback Rate")}
      open={open}
      onPressCloseButton={onRequestClose}
      onPressOutside={onRequestClose}
      type="sheet"
      maxWidth={640}
    >
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        dataSet={WEB_SCROLL_UNLOCK}
      >
        <Row paddingHorizontal="medium" paddingVertical="large">
          {config.PLAYBACK_RATES.map((rate, index) => {
            return (
              <React.Fragment key={rate}>
                {index !== 0 ? <Spacer size="tiny" horizontal /> : null}
                <Button
                  onPress={() => audioPlayer.setPlaybackRate(rate)}
                  type={
                    rate.toFixed(config.PLAYBACK_RATE_DECIMALS) ===
                    playbackRate.toFixed(config.PLAYBACK_RATE_DECIMALS)
                      ? "primary"
                      : "secondary"
                  }
                >
                  {playbackRateToString(rate)}
                </Button>
              </React.Fragment>
            )
          })}
        </Row>
      </ScrollView>
    </Modal>
  )
}
