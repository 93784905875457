import React from "react"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"

import config from "../config"
import useAppManifest from "../hooks/use-app-manifest"
import Image, { ImageProps } from "./image"
import { useTokens } from "./tokens-provider"

const LOGO_MAX_SIZE = 512

export const useTenantLogo = (): { dark: string; light: string } => {
  const {
    theme: { logoDark, logoLight },
  } = useAppManifest()
  return {
    dark: getOptimizedImageSource(logoDark, LOGO_MAX_SIZE),
    light: getOptimizedImageSource(logoLight, LOGO_MAX_SIZE),
  }
}

export default function TenantLogo({
  style,
}: {
  style?: ImageProps["style"]
}): JSX.Element {
  const { light, dark } = useTenantLogo()
  const { displayMode } = useTokens()
  return (
    <Image
      source={{ uri: displayMode === "light" ? light : dark }}
      style={style}
      aria-label={config.APP_NAME}
      resizeMode="contain"
    />
  )
}
