import React, { useRef, useEffect, ReactNode } from "react"
import {
  PanGestureHandler as OriginalPanGestureHandler,
  PanGestureHandlerProps,
} from "react-native-gesture-handler"

const stopPropagation = (event: PointerEvent) => event.stopPropagation()

/**
 * This is a thin wrapper around react-native-gesture-handler's
 * PanGestureHandler that prevents propagation on the "pointerdown" event to
 * stop other gesture handlers up the tree from also activating. This is a
 * workaround for a bug in react-native-gesture-handler and should be removed
 * once that bug is fixed: https://gitlab.com/teamtreefort/treefort-ott/-/issues/39
 */
function PanGestureHandler(
  props: PanGestureHandlerProps & { children: ReactNode },
): JSX.Element {
  const handlerRef = useRef<{ viewTag: HTMLElement }>()
  useEffect(() => {
    const handler = handlerRef.current
    handler?.viewTag.addEventListener("pointerdown", stopPropagation)
    return () =>
      handler?.viewTag.removeEventListener("pointerdown", stopPropagation)
  }, [])
  return <OriginalPanGestureHandler {...props} ref={handlerRef} />
}

export default PanGestureHandler
