import React from "react"
import { View } from "react-native"
import ActivityIndicator from "../activity-indicator"
import { useTokens } from "../tokens-provider"
import IconImage from "./icon-image"
import { IconImageProps, IconSource, IconState, IconSize } from "./base"

export type IconProps = Partial<IconImageProps> & {
  source: IconSource
  touchable?: boolean
}

export default function Icon({
  state = "default",
  size = "medium",
  touchable = false,
  color = "primary",
  // Source can be a standard image source _or_ raw svg data
  source,
  style,
}: IconProps): JSX.Element {
  const { tokens } = useTokens()
  const colorValue =
    color in tokens.colors.text
      ? tokens.colors.text[color as keyof typeof tokens.colors.text]
      : color
  const icon =
    state === "loading" ? (
      <ActivityIndicator size={size} color={colorValue} />
    ) : (
      <IconImage
        style={style}
        source={source}
        size={size}
        state={state}
        color={colorValue}
      />
    )
  return touchable ? icon : <View pointerEvents="none">{icon}</View>
}

export type { IconState, IconSize, IconSource }
