import { getDefaultProfileName } from "@treefort/lib/profiles"

import { useActiveProfileId } from "./use-active-profile-id"
import { useProfiles } from "./use-profiles"
import useUserInfo from "./use-user-info"

export function useActiveProfileInfo() {
  const activeProfileId = useActiveProfileId()

  const defaultProfile = useUserInfo({
    enabled: activeProfileId === null,
    select: (data) => ({
      id: null,
      name: getDefaultProfileName(data),
      image: data.profilePic,
    }),
  })

  const profile = useProfiles({
    enabled: activeProfileId !== null,
    select: (result) =>
      result.profiles
        .filter((profile) => profile.id === activeProfileId)
        .map((profile) => ({
          id: profile.id,
          name: profile.name,
          image: profile.imageMedia.original.url,
        }))[0],
  })

  return activeProfileId === null || (profile.isSuccess && !profile.data)
    ? defaultProfile
    : profile
}
