import React, { ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import useAppManifest from "../../hooks/use-app-manifest"
import { useAppTab } from "../../hooks/use-app-tab"
import { useMenu } from "../../hooks/use-menu"
import { useOfflineState } from "../../hooks/use-offline-state"
import { usePageMetadata } from "../../hooks/use-page-metadata"
import { useRoute } from "../../hooks/use-route"
import { useSearch } from "../../hooks/use-search"
import analytics from "../../lib/analytics"
import { canShareUrl, shareUrl } from "../../lib/share-url"
import { getAbsoluteLineHeight } from "../../lib/text-style"
import {
  getHomeTab,
  isCollectionRoute,
  isContentRoute,
  isInitialRouteInTab,
  isLibraryTab,
  isPageRoute,
  SEARCH_TAB,
} from "../../navigation/routes"
import { Heading } from "../heading"
import HtmlHead from "../html-head"
import IconButton from "../icon-button"
import TenantLogo from "../tenant-logo"
import { DisplayMode, useTokens } from "../tokens-provider"
import AnimatedItem from "./animated-item"
import Container from "./container"
import { AppHeaderPresentation, AppHeaderState } from "./types"

const TEXT_STYLE_TITLE = "headingLarge"

const CenterItem = styled(AnimatedItem)`
  width: 100%;
  justify-content: center;
  align-items: center;
`

const CenterItemPlaceholder = styled.View`
  flex: 1;
`

const IconPlaceholder = styled.View`
  width: ${(props) => props.theme.minTapTarget}px;
  height: ${(props) => props.theme.minTapTarget}px;
`

const StyledTenantLogo = styled(TenantLogo)`
  width: ${(props) => props.theme.appHeader.logoWidth}px;
  height: ${(props) => props.theme.appHeader.logoHeight}px;
`

export default function AppHeaderMobile({
  children,
  childrenHeight,
  dropShadow,
  presentation,
  state,
  displayMode,
  backgroundColor,
}: {
  children?: ReactNode
  childrenHeight?: number
  dropShadow?: boolean
  presentation?: AppHeaderPresentation
  state?: AppHeaderState
  displayMode?: DisplayMode
  backgroundColor?: string
}): JSX.Element {
  const { tokens } = useTokens()
  const manifest = useAppManifest()
  const tab = useAppTab()
  const route = useRoute()
  const pageMetadata = usePageMetadata()
  const search = useSearch()
  const [offline] = useOfflineState()
  const menu = useMenu()

  const pageRoute = isPageRoute(route)
  const initialRouteInTab = isInitialRouteInTab(route, tab, manifest)
  const collectionRoute = isCollectionRoute(route)
  const showLogo =
    !offline &&
    !isLibraryTab(tab) &&
    isInitialRouteInTab(route, getHomeTab(manifest), manifest)
  const showTitle =
    !showLogo && (pageRoute || collectionRoute || initialRouteInTab)
  const isSearchRoute = tab.id === SEARCH_TAB.id || route.path === "/search"
  const showSearch = !isSearchRoute && (pageRoute || initialRouteInTab)
  const showMenu = !isSearchRoute && initialRouteInTab
  const showShare =
    !showSearch && (collectionRoute || isContentRoute(route)) && canShareUrl()

  const titleContainerStyle = useMemo(
    () =>
      StyleSheet.create({
        titleContainerStyle: {
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          height: showTitle
            ? getAbsoluteLineHeight(TEXT_STYLE_TITLE, tokens)
            : undefined,
        },
      }).titleContainerStyle,
    [showTitle, tokens],
  )

  const { t } = useTranslation()

  return (
    <>
      <HtmlHead pageMetadata={pageMetadata} />
      <Container
        extraChildren={children}
        extraChildrenHeight={childrenHeight}
        dropShadow={dropShadow}
        presentation={presentation}
        state={state}
        displayMode={displayMode}
        backgroundColor={backgroundColor}
      >
        {({
          goBack,
          scrollInterpolation,
          heightInterpolation,
          presentation,
        }) => {
          return (
            <>
              <AnimatedItem
                presentation={presentation}
                opacity={
                  presentation === "transparent"
                    ? scrollInterpolation
                    : undefined
                }
              >
                {({ color }) =>
                  showMenu ? (
                    <IconButton
                      color={color}
                      label={t("Open menu")}
                      iconSize="large"
                      source={manifest.icons.menu}
                      onPress={() => menu.open()}
                    />
                  ) : (
                    <IconButton
                      color={color}
                      label={t("Go back")}
                      iconSize="large"
                      source={manifest.icons.back}
                      onPress={goBack}
                    />
                  )
                }
              </AnimatedItem>
              {showLogo || showTitle ? (
                <CenterItem
                  presentation={presentation}
                  opacity={
                    presentation === "transparent" || showLogo
                      ? scrollInterpolation
                      : undefined
                  }
                  containerStyle={titleContainerStyle}
                  scale={
                    showLogo
                      ? heightInterpolation.interpolate({
                          inputRange: [0, 1],
                          outputRange: [1, 0.8],
                        })
                      : undefined
                  }
                >
                  {({ color }) =>
                    showLogo ? (
                      <StyledTenantLogo />
                    ) : pageMetadata.data?.title ? (
                      <Heading
                        level={1}
                        textStyle={TEXT_STYLE_TITLE}
                        color={color}
                        numberOfLines={1}
                      >
                        {pageMetadata.data.title}
                      </Heading>
                    ) : null
                  }
                </CenterItem>
              ) : (
                <CenterItemPlaceholder />
              )}
              {showSearch ? (
                <AnimatedItem
                  presentation={presentation}
                  opacity={
                    presentation === "transparent"
                      ? scrollInterpolation
                      : undefined
                  }
                >
                  {({ color }) => (
                    <IconButton
                      label={t("Search")}
                      iconSize="large"
                      source={manifest.icons.search}
                      color={color}
                      onPress={search.open}
                      disabled={offline}
                    />
                  )}
                </AnimatedItem>
              ) : showShare ? (
                <AnimatedItem
                  presentation={presentation}
                  opacity={
                    presentation === "transparent"
                      ? scrollInterpolation
                      : undefined
                  }
                >
                  {({ color }) => (
                    <IconButton
                      label={t("Share")}
                      iconSize="large"
                      source={manifest.icons.share}
                      color={color}
                      onPress={() => {
                        if (pageMetadata.isSuccess) {
                          shareUrl({ url: pageMetadata.data.url }).then(
                            (success) => {
                              if (success) {
                                analytics.logShare(route, manifest)
                              }
                            },
                          )
                        }
                      }}
                      disabled={offline}
                    />
                  )}
                </AnimatedItem>
              ) : (
                <IconPlaceholder />
              )}
            </>
          )
        }}
      </Container>
    </>
  )
}
