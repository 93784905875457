import React from "react"

import { AppModule } from "@treefort/api-spec"
import { Result } from "@treefort/lib/result"

import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import LazyLoadComponent from "../../components/lazy-load-component"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAppPage } from "../../hooks/use-app-page"
import useAppPreview from "../../hooks/use-app-preview"
import { getPageModules } from "../../lib/app-manifest"
import { ModuleLayout } from "../layouts/module"
import { ListViewModule } from "../layouts/module/list-view-module"
import { BannerListViewModule } from "../layouts/module/list-view-modules/banner"
import { FeatureListViewModule } from "../layouts/module/list-view-modules/feature"
import { LinkListViewModule } from "../layouts/module/list-view-modules/link"
import { SliderListViewModule } from "../layouts/module/list-view-modules/slider"
import { NotFoundScreen } from "./not-found"

/**
 * Convert an app module to an array of list view module(s). The array will be
 * empty if there is no list view module that corresponds to the provided app
 * module. AppModules that correspond to content are ignored.
 */
export function appModuleToListViewModule(
  appModule: AppModule,
): ListViewModule<AppModule, unknown>[] {
  switch (appModule.type) {
    case "link":
      return [new LinkListViewModule(appModule)]
    case "slider":
      return [new SliderListViewModule(appModule)]
    case "banner":
      return [new BannerListViewModule(appModule)]
    case "feature":
      return [new FeatureListViewModule(appModule)]
    default:
      return []
  }
}

export function AppPageScreen(): JSX.Element {
  const manifest = useAppManifest()
  const page = useAppPage()
  const appPreview = useAppPreview()
  const key = `custom-page-${page?.id}`

  return page ? (
    <AsyncViewOfflineProvider state="success" key={key}>
      <ModuleLayout
        pageKey={key}
        listViewModules={Result.success(
          getPageModules(page, manifest).flatMap(appModuleToListViewModule),
        )}
      >
        {({ listView }) => (
          <LazyLoadComponent
            importKey="appPreviewFocusModule"
            importComponent={() =>
              import("../../components/app-preview-focus-module")
            }
            enabled={appPreview.state === "on"}
            props={{ listView }}
          />
        )}
      </ModuleLayout>
    </AsyncViewOfflineProvider>
  ) : (
    <NotFoundScreen />
  )
}
