import React from "react"
import { useTranslation } from "react-i18next"

import { useAuth } from "@treefort/lib/auth-provider"

import { AsyncButton } from "../../../components/async-button"
import Spacer from "../../../components/spacer"
import Text from "../../../components/text"
import { useUserSubscriptions } from "../../../hooks/subscriptions"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import useUserInfo from "../../../hooks/use-user-info"
import api from "../../../lib/api"
import authenticator from "../../../lib/authenticator"
import confirm from "../../../lib/confirm"
import {
  getCurrentSubscription,
  subscriptionWillRenew,
} from "../../../lib/subscriptions"
import { toast } from "../../../lib/toaster"
import MenuLayout from "../../layouts/menu"

function DeleteAccountScreen(): JSX.Element {
  const auth = useAuth()
  const userSubscriptions = useUserSubscriptions()
  const subscription = getCurrentSubscription(userSubscriptions.data)
  const userInfo = useUserInfo()
  const { t } = useTranslation()
  const userId = auth.user?.id

  async function deleteAccount() {
    // Bail if noone is signed in (should never happen)
    if (!userId) {
      return
    }

    try {
      const confirmDelete = await confirm({
        title: t("Delete account?"),
        message: t(
          "Are you sure you want to delete your account? This is permanent and cannot be undone.",
        ),
        confirmLabel: t("Delete account"),
        cancelLabel: t("Cancel"),
        danger: true,
      })
      if (confirmDelete) {
        await api.delete(`/users/${userId}`)
        // Revoking the user's token will fail because we've already deleted them,
        // but this'll reset local state and boot the user back to the main account
        // page
        await authenticator.logout()
        toast.success(t("Account deleted."))
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again."))
    }
  }

  const asyncViewProps = useAsyncViewPropsForQueries([
    userSubscriptions,
    userInfo,
  ])

  return (
    <MenuLayout asyncViewProps={asyncViewProps}>
      {subscriptionWillRenew(subscription) ? (
        <Text textStyle="body">
          {t(
            "We are not able to delete your account because you have an active subscription. Please cancel your subscription first.",
          )}
        </Text>
      ) : (
        <>
          <Text textStyle="body">
            {t(
              "Press the button below to delete your account. All of your data will be removed immediately. This is permanent and cannot be undone.",
            )}
          </Text>
          <Spacer size="large" />
          <AsyncButton type="danger" onPress={deleteAccount}>
            {t("Delete account")}
          </AsyncButton>
        </>
      )}
    </MenuLayout>
  )
}

export default DeleteAccountScreen
