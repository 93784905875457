import React from "react"
import { useTranslation } from "react-i18next"

import { ProfileListResponse, ProfileResponse, User } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"
import { getDefaultProfileName } from "@treefort/lib/profiles"

import { useAsyncViewPropsForQueries } from "../hooks/use-async-view-props-for-queries"
import { useProfile } from "../hooks/use-profile"
import { PROFILES_QUERY_KEY } from "../hooks/use-profiles"
import { getQueryKey } from "../hooks/use-query-key"
import useUserInfo, { USER_INFO_QUERY_KEY } from "../hooks/use-user-info"
import api from "../lib/api"
import confirm from "../lib/confirm"
import { profilesOverlay } from "../lib/profiles"
import { queryClient } from "../lib/query-client"
import { toast } from "../lib/toaster"
import AsyncView from "./async-view"
import { ProfileDetail } from "./profile-detail"

export function ProfilesEditScreen({
  profileId,
}: {
  // No profile id means we're editing the default user properties
  profileId?: string
}) {
  const { t } = useTranslation()
  const auth = useAuth()
  const profile = useProfile(profileId)
  const userInfo = useUserInfo()
  const asyncViewProps = useAsyncViewPropsForQueries([
    profileId ? profile : userInfo,
  ])

  const previousName = profileId
    ? profile.data?.name
    : userInfo.data
    ? getDefaultProfileName(userInfo.data)
    : undefined
  const previousImage = profileId
    ? profile.data?.imageMedia.original.url
    : userInfo.data?.profilePic

  const deleteProfile = async () => {
    if (profileId && userInfo.data) {
      await api.delete(`/users/${userInfo.data.id}/profiles/${profileId}`)
      queryClient.setQueryData<ProfileListResponse | undefined>(
        getQueryKey(auth.user, PROFILES_QUERY_KEY),
        (data) =>
          data
            ? {
                ...data,
                profiles:
                  data?.profiles.filter(
                    (profile) => profile.id !== profileId,
                  ) || [],
              }
            : undefined,
      )
    }
  }

  const updateProfile = async ({
    name,
    image,
  }: {
    name: string
    image: string
  }) => {
    if (userInfo.data) {
      if (image !== previousImage || name !== previousName) {
        if (profileId) {
          const {
            data: { profile: updated },
          } = await api.patch<ProfileResponse>(
            `/users/${userInfo.data.id}/profiles/${profileId}`,
            { name, image },
          )
          queryClient.setQueryData<ProfileListResponse | undefined>(
            getQueryKey(auth.user, PROFILES_QUERY_KEY),
            (data) =>
              data
                ? {
                    ...data,
                    profiles: data.profiles.map((profile) =>
                      profile.id === profileId ? updated : profile,
                    ),
                  }
                : undefined,
          )
        } else {
          await api.patch(`/users/${userInfo.data.id}`, {
            profileName: name,
            profilePic: image,
          })
          queryClient.setQueryData<User | undefined>(
            getQueryKey(auth.user, USER_INFO_QUERY_KEY),
            (data) =>
              data
                ? { ...data, profileName: name, profilePic: image }
                : undefined,
          )
        }
      }
    }
  }

  return (
    <AsyncView {...asyncViewProps}>
      <ProfileDetail
        title={t("Edit profile")}
        name={previousName}
        image={previousImage}
        footerSecondary={{
          text: profileId ? t("Delete") : t("Default profile"),
          onPress: profileId
            ? async () => {
                if (
                  await confirm({
                    danger: true,
                    title: t("Are you sure?"),
                    message: t("Deleting a profile cannot be undone."),
                    confirmLabel: t("Delete"),
                    cancelLabel: t("Cancel"),
                  })
                ) {
                  await deleteProfile()
                  profilesOverlay.requestBack()
                }
              }
            : undefined,
        }}
        footerPrimary={{
          text: t("Done"),
          onPress: async ({ name, image }) => {
            if (!name?.trim()) {
              toast.error(t("Please enter your name."))
            } else if (!image) {
              toast.error(t("Please select a profile image."))
            } else {
              await updateProfile({ name: name.trim(), image })
              profilesOverlay.requestBack()
            }
          },
        }}
      />
    </AsyncView>
  )
}
