import React from "react"
import { AppModuleLink } from "@treefort/api-spec"
import {
  LinkModule,
  getLinkModuleContentHeight,
  getLinkModuleHeight,
} from "../../../../components/modules/link"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleLink["config"]

export class LinkListViewModule implements ListViewModule<AppModuleLink, Item> {
  readonly appModule: AppModuleLink
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = undefined
  readonly gapSize = 36
  readonly maxWidth = undefined

  constructor(appModule: AppModuleLink) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <LinkModule
      {...args.item}
      paddingTop={args.safeAreaInsets.top}
      maxWidth={args.maxWidth}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id

  getItemSize = (args: ItemArgs<Item>) =>
    getLinkModuleHeight({
      tokens: args.tokens,
      title: args.item.title,
      contentHeight: getLinkModuleContentHeight({
        tokens: args.tokens,
        links: args.item.links,
      }),
    }) + args.safeAreaInsets.top
}
