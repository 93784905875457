"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coalesceUndefined = void 0;
/**
 * This is like the nullish coalescing operator (`??`) but treats nulls as
 * usable values, returning the first non-undefined argument
 */
function coalesceUndefined(...args) {
    for (const operand of args) {
        if (operand !== undefined) {
            return operand;
        }
    }
}
exports.coalesceUndefined = coalesceUndefined;
