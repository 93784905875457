import { PROGRESS_UPDATE_INTERVAL } from "./av/constants"
import { PlaybackState } from "./av/types"

/**
 * This class helps track engagement time for playable content. The logProgress
 * method should be called at least once every logProgressInterval milliseconds
 * while the content is playing. The setPlaybackState/Rate methods should be
 * called any time the playback state/rate changes. The tracker will use the
 * information from these calls to keep track of how long the user is "engaged"
 * with the playable content. This is more of an art than a science, but until
 * we have lower-level tracking across all platforms this'll have to do.
 */
export class PlayProgressTracker {
  private engagementTime: number
  private playTime: number
  private playbackState: PlaybackState
  private playbackRate: number
  private progressLoggedAt?: number

  constructor({
    playbackRate,
    playbackState,
  }: {
    playbackRate: number
    playbackState: PlaybackState
  }) {
    this.engagementTime = this.playTime = 0
    this.playbackState = playbackState
    this.playbackRate = playbackRate
  }

  logProgress = () => {
    if (this.progressLoggedAt && this.playbackState === PlaybackState.Playing) {
      // Cap the time at twice what we expect it to be. This allows for timing
      // variations due to slow devices or hiccups in the av engine while doing
      // a reasonable job of filtering out true tracking errors.
      const time = Math.min(
        performance.now() - this.progressLoggedAt,
        PROGRESS_UPDATE_INTERVAL * 2,
      )
      this.engagementTime += time
      this.playTime += this.playbackRate * time
    }
    this.progressLoggedAt = performance.now()
  }

  setPlaybackState = (playbackState: PlaybackState) => {
    // Log any outstanding progress before making changes
    this.logProgress()
    this.playbackState = playbackState
  }

  setPlaybackRate = (playbackRate: number) => {
    // Log any outstanding progress before making changes
    this.logProgress()
    this.playbackRate = playbackRate
  }

  getEngagementTime = () => {
    return this.engagementTime
  }

  getPlayTime = () => {
    return this.playTime
  }

  resetTime = () => {
    this.engagementTime = this.playTime = 0
  }
}
