import React, { ReactNode } from "react"
import { Platform, StyleProp, ViewStyle } from "react-native"

import styled from "styled-components/native"

import { colorWithAlpha } from "@treefort/lib/color"
import tokens from "@treefort/tokens/app"

import { BorderRadius } from "../lib/border-radius"
import ActivityIndicator from "./activity-indicator"
import Icon, { IconProps } from "./icon"
import Text from "./text"
import { useTokens } from "./tokens-provider"

export type ButtonType = keyof typeof tokens.button.color

export type ButtonSize = keyof typeof tokens.button.height

export type ButtonViewProps = {
  children: ReactNode
  type?: ButtonType
  size?: ButtonSize
  disabled?: boolean
  icon?: IconProps["source"]
  iconSize?: "small" | "medium"
  style?: StyleProp<ViewStyle>
  loading?: boolean
  borderRadius?: BorderRadius
  className?: string
}

const StyledView = styled.View<{
  type: ButtonType
  size: ButtonSize
  disabled?: boolean
  borderRadius: BorderRadius
}>`
  background-color: ${(props) =>
    props.disabled
      ? colorWithAlpha(props.theme.button.backgroundColor[props.type], 0.55)
      : props.theme.button.backgroundColor[props.type]};
  border-radius: ${({ theme, borderRadius }) =>
    typeof borderRadius === "number"
      ? borderRadius
      : theme.borderRadius[borderRadius]}px;
  padding-horizontal: ${(props) =>
    props.theme.button.paddingHorizontal[props.size]}px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.theme.button.height[props.size]}px;
  ${(props) =>
    Platform.OS === "web" && props.disabled ? "cursor: not-allowed" : ""};
  max-width: 100%;
`

const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.spacing.xsmall}px;
`

function ButtonView({
  children,
  type = "secondary",
  size = "medium",
  disabled,
  icon,
  style,
  loading,
  borderRadius = "rounded",
  iconSize = "small",
}: ButtonViewProps): JSX.Element {
  const { tokens } = useTokens()
  const hasOnlyStringChildren =
    typeof children === "string" ||
    (Array.isArray(children) &&
      children.every((child) => typeof child === "string"))
  return (
    <StyledView
      disabled={disabled}
      type={type}
      size={size}
      style={style}
      borderRadius={borderRadius}
    >
      {icon && !loading ? (
        <StyledIcon
          size={iconSize}
          source={icon}
          color={tokens.button.color[type]}
          touchable
        />
      ) : null}
      {loading ? (
        <ActivityIndicator size="medium" color={tokens.button.color[type]} />
      ) : hasOnlyStringChildren ? (
        <Text
          numberOfLines={1}
          textStyle={size === "tiny" ? "captionStrong" : "button"}
          color={tokens.button.color[type]}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </StyledView>
  )
}

export default ButtonView
