"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openSans = void 0;
const open_sans_bold_1 = require("../fonts/open-sans-bold");
const open_sans_semi_bold_1 = require("../fonts/open-sans-semi-bold");
const open_sans_regular_1 = require("../fonts/open-sans-regular");
exports.openSans = {
    headingXLarge: {
        font: open_sans_bold_1.openSansBold,
        fontSize: 28,
        lineHeight: 38,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingLarge: {
        font: open_sans_bold_1.openSansBold,
        fontSize: 22,
        lineHeight: 32,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingMedium: {
        font: open_sans_bold_1.openSansBold,
        fontSize: 18,
        lineHeight: 28,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingSmall: {
        font: open_sans_semi_bold_1.openSansSemiBold,
        fontSize: 14,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingXSmall: {
        font: open_sans_semi_bold_1.openSansSemiBold,
        fontSize: 12,
        lineHeight: 20,
        letterSpacing: 1.8,
        textTransform: "uppercase",
    },
    body: {
        font: open_sans_regular_1.openSansRegular,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    strong: {
        font: open_sans_semi_bold_1.openSansSemiBold,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    caption: {
        font: open_sans_regular_1.openSansRegular,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    captionStrong: {
        font: open_sans_semi_bold_1.openSansSemiBold,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    button: {
        font: open_sans_semi_bold_1.openSansSemiBold,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
};
