import {
  FetchQueryOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query"

import { ContentType } from "@treefort/api-spec"
import { User } from "@treefort/lib/authenticator"

import authenticator from "../lib/authenticator"
import { ContentResponseForType, getContent } from "../lib/content"
import { queryClient } from "../lib/query-client"
import useQueryKey, { getQueryKey } from "./use-query-key"

const CONTENT_QUERY_KEY_PREFIX = "content.v2"

export const getContentQueryKey = (
  user: User | null,
  contentId: number | undefined,
) => getQueryKey(user, [CONTENT_QUERY_KEY_PREFIX, contentId])

export const useContentQueryKey = (contentId: number | undefined) =>
  useQueryKey([CONTENT_QUERY_KEY_PREFIX, contentId])

/**
 * Use this hook for quick access to content.
 */
export default function useContent<T extends ContentType>(
  contentId: number | undefined,
  contentType?: T,
  options?: UseQueryOptions<ContentResponseForType<T>>,
): UseQueryResult<ContentResponseForType<T>> {
  const queryKey = useContentQueryKey(contentId)

  const result = useQuery(
    queryKey,
    () => {
      if (!contentId) {
        throw new Error("[Content] Can't fetch content without a content id")
      }
      return getContent<T>(contentId)
    },
    {
      ...options,
      enabled: contentId === undefined ? false : options?.enabled,
    },
  )

  if (result.isSuccess && contentType && result.data.type !== contentType) {
    throw new Error(
      `[Content] Fetched ${contentId} expecting a ${contentType} but got a ${result.data.type}`,
    )
  }

  return result
}

/**
 * Use this function to fetch content. This shares a cache with useContent.
 */
export async function fetchContent<T extends ContentType>(
  contentId: number,
  contentType?: T,
  options?: FetchQueryOptions<ContentResponseForType<T>>,
): Promise<ContentResponseForType<T>> {
  const user = authenticator.getUser()

  const queryKey = getContentQueryKey(user, contentId)

  const data = await queryClient.fetchQuery(
    queryKey,
    () => getContent<T>(contentId),
    options,
  )

  if (contentType && data.type !== contentType) {
    throw new Error(
      `[Content] Fetched ${contentId} expecting a ${contentType} but got a ${data.type}`,
    )
  }

  return data
}
