import {
  FetchQueryOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query"
import { CollectionResponse } from "@treefort/api-spec"
import { User } from "@treefort/lib/authenticator"
import { getCollection } from "../lib/collections"
import useQueryKey, { getQueryKey } from "./use-query-key"
import authenticator from "../lib/authenticator"
import { queryClient } from "../lib/query-client"

export const COLLECTION_QUERY_KEY_PREFIX = "collection.v2"

export const getCollectionQueryKey = (
  user: User | null,
  collectionId: number | undefined,
) => getQueryKey(user, [COLLECTION_QUERY_KEY_PREFIX, collectionId])

export const useCollectionQueryKey = (collectionId: number | undefined) =>
  useQueryKey([COLLECTION_QUERY_KEY_PREFIX, collectionId])

export default function useCollection(
  id: number | undefined,
  options?: UseQueryOptions<CollectionResponse>,
): UseQueryResult<CollectionResponse> {
  return useQuery(
    useCollectionQueryKey(id),
    () => {
      if (!id) {
        throw new Error(
          "[Collections] Can't fetch collection without collection id",
        )
      }
      return getCollection(id)
    },
    { ...options, enabled: typeof id === "number" ? options?.enabled : false },
  )
}

export function fetchCollection(
  id: number,
  options?: FetchQueryOptions<CollectionResponse>,
): Promise<CollectionResponse> {
  const user = authenticator.getUser()
  return queryClient.fetchQuery(getCollectionQueryKey(user, id), options)
}
