import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react"
import { ScrollViewProps, StyleSheet, Platform, Animated } from "react-native"
import { ScrollView } from "react-native-gesture-handler"

import {
  DataProvider,
  LayoutProvider,
  RecyclerListView,
  RecyclerListViewProps,
} from "recyclerlistview"
import { RecyclerListViewState } from "recyclerlistview/dist/reactnative/core/RecyclerListView"

type ViewSize = { width: number; height: number }

type Row<Item> =
  | { type: "padding"; key: string; size: number }
  | { type: "gap"; key: string }
  | { type: "item"; key: string; item: Item }

export type ListViewProps<Item> = {
  // Our custom props
  animated?: boolean
  getItemKey: (item: Item, index: number) => string
  getItemSize: (item: Item, index: number) => number
  getGapSize?: (
    itemBefore: Item,
    itemAfter: Item,
    indexBefore: number,
    indexAfter: number,
  ) => number
  horizontal?: boolean
  items: Item[]
  onVisibleIndicesChange?: (indices: number[]) => void
  paddingEnd?: number
  paddingStart?: number
  renderItem: (item: Item, index: number) => JSX.Element | null
  scrollBehavior?: "auto" | "smooth"
  viewSize: ViewSize
  windowScroll?: boolean
  scrollViewDataSet?: Record<string, unknown>
  // Recyclerlistview props
  initialOffset?: RecyclerListViewProps["initialOffset"]
  onScroll?: RecyclerListViewProps["onScroll"]
  renderAheadOffset?: RecyclerListViewProps["renderAheadOffset"]
  style?: RecyclerListViewProps["style"]
  // Scroll view props that will be passed through
  contentContainerStyle?: ScrollViewProps["contentContainerStyle"]
  keyboardDismissMode?: ScrollViewProps["keyboardDismissMode"]
  scrollEventThrottle?: ScrollViewProps["scrollEventThrottle"]
  showsHorizontalScrollIndicator?: ScrollViewProps["showsHorizontalScrollIndicator"]
  showsVerticalScrollIndicator?: ScrollViewProps["showsVerticalScrollIndicator"]
}

export type ListViewRef<Item> = {
  scrollToIndex: (index: number, extraOffset?: number) => void
  scrollToOffset: (offset: number) => void
  getIndexSize: (index: number) => number
  getIndexOffset: (index: number) => number
  viewSize: ViewSize
  items: Item[]
}

// HACK: Without this RecyclerListView throws an error saying "RecyclerListView
// needs to have a bounded size. Currently height or, width is 0." under
// seemingly random circumstances. I obtained this solution from:
// https://github.com/Flipkart/recyclerlistview/issues/144#issuecomment-388729199
const { minSize } = StyleSheet.create({
  minSize: { minHeight: 1, minWidth: 1 },
})

// HACK: While RecyclerListView works fine with react-native-gesture-handler's
// ScrollView component, its PropTypes and TypeScript types do not like it at
// all. To work around this we remove the prop type for externalScrollView and
// then cast to the expected type with TypeScript.
const recyclerListViewPropsTypes = RecyclerListView.propTypes as Record<
  string,
  unknown
>
delete recyclerListViewPropsTypes.externalScrollView
const externalScrollView =
  ScrollView as unknown as RecyclerListViewProps["externalScrollView"]

/**
 * Returns true if two arrays of visible indices are equal. Assumes that all
 * numbers in the arrays are consequtive integers.
 */
const visibleIndicesEqual = (a: number[], b: number[]) =>
  a.length === b.length && a[0] === b[0]

const AnimatedRecyclerListView =
  Animated.createAnimatedComponent(RecyclerListView)

/**
 * A wrapper around recyclerlistview with a few benefits for our use-case:
 * - provides a more sane, FlatList-esque API
 * - can easily add start/end padding and gaps within the scrollable area
 * - works around some quirks in recyclerlistview
 */
function ListView<Item = unknown>(
  {
    animated,
    contentContainerStyle,
    getItemKey,
    getItemSize,
    getGapSize,
    horizontal,
    initialOffset,
    items,
    keyboardDismissMode,
    onScroll,
    onVisibleIndicesChange: onVisibleIndicesChangeProp,
    paddingEnd,
    paddingStart,
    renderAheadOffset,
    renderItem,
    scrollBehavior,
    scrollEventThrottle,
    showsHorizontalScrollIndicator,
    showsVerticalScrollIndicator,
    style,
    viewSize: viewSizeProp,
    windowScroll,
    scrollViewDataSet,
  }: ListViewProps<Item>,
  ref: React.Ref<ListViewRef<Item>> | undefined,
): JSX.Element {
  const windowScrollWeb = windowScroll && Platform.OS === "web"
  const dataProvider: MutableRefObject<DataProvider> = useRef(
    new DataProvider(
      (a, b) => a.key !== b.key,
      (index) => dataProvider.current.getDataForIndex(index).key,
    ),
  )
  const lastReportedVisibleIndices = useRef<number[]>([])
  const recyclerListView =
    useRef<RecyclerListView<RecyclerListViewProps, RecyclerListViewState>>(null)

  // Enforce a minimum size of 1px in either dimension to match the `minSize`
  // style above.
  const viewSize = useMemo<ViewSize>(
    () => ({
      width: Math.max(viewSizeProp.width, 1),
      height: Math.max(viewSizeProp.height, 1),
    }),
    [viewSizeProp.width, viewSizeProp.height],
  )

  const allItems = useMemo(() => {
    const allItems = items.flatMap<Row<Item>>((item, index) => {
      const listItem: Row<Item> = {
        type: "item",
        key: "item-" + getItemKey(item, index),
        item,
      }
      const gapItem: Row<Item> | null =
        getGapSize && index > 0
          ? {
              type: "gap",
              key: `gap-${listItem.key}`,
            }
          : null
      return gapItem ? [gapItem, listItem] : [listItem]
    })
    if (paddingStart) {
      allItems.unshift({
        type: "padding",
        key: "padding-start",
        size: paddingStart,
      })
    }
    if (paddingEnd) {
      allItems.push({
        type: "padding",
        key: "padding-end",
        size: paddingEnd,
      })
    }
    return allItems
  }, [items, paddingStart, paddingEnd, getItemKey, getGapSize])

  const internalIndexOfFirstItem = paddingStart ? 1 : 0

  // We inject spacer items into the list between the items passed to us. We
  // don't want the spacer items to affect the indices that we pass back to the
  // caller via renderItem and getItemSize though, so we run the actual indices
  // through this function before reporting them out.
  const getExternalIndex = useCallback(
    (internalIndex: number): number => {
      const gapItemCount = getGapSize
        ? Math.max(
            Math.floor((internalIndex - internalIndexOfFirstItem) / 2),
            0,
          )
        : 0
      const externalIndex =
        internalIndex - internalIndexOfFirstItem - gapItemCount
      return items[externalIndex] ? externalIndex : -1
    },
    [internalIndexOfFirstItem, getGapSize, items],
  )

  // Like getExternalIndex, but it works the opposite way
  const getInternalIndex = useCallback(
    (externalIndex: number): number => {
      const gapItemCount = getGapSize ? externalIndex : 0
      return externalIndex + internalIndexOfFirstItem + gapItemCount
    },
    [internalIndexOfFirstItem, getGapSize],
  )

  // Calculates the size of the item at a given index for RecyclerListView's
  // layout logic.
  const getIndexSize = useCallback(
    (index: number) => {
      const item = allItems[index]
      if (item?.type === "padding") {
        return item.size
      } else if (item?.type === "item") {
        return getItemSize(item.item, getExternalIndex(index))
      } else if (item?.type === "gap" && getGapSize) {
        const indexBefore = index - 1
        const indexAfter = index + 1
        const itemBefore = allItems[indexBefore]
        const itemAfter = allItems[indexAfter]
        return itemBefore?.type === "item" && itemAfter?.type === "item"
          ? getGapSize(
              itemBefore.item,
              itemAfter.item,
              getExternalIndex(indexBefore),
              getExternalIndex(indexAfter),
            )
          : 0
      } else {
        return 0
      }
    },
    [allItems, getExternalIndex, getItemSize, getGapSize],
  )

  const layoutProvider = useMemo(() => {
    const layoutProvider = new LayoutProvider(
      (index) => allItems[index].type,
      (_, dimensions, index) => {
        dimensions[horizontal ? "width" : "height"] = getIndexSize(index)
        dimensions[horizontal ? "height" : "width"] =
          viewSize[horizontal ? "height" : "width"]
      },
    )

    // Prevent recyclerlistview from attempting to scroll to the first visible
    // item every time it's rerendered. See:
    // https://github.com/Flipkart/recyclerlistview/issues/581#issuecomment-852360835
    layoutProvider.shouldRefreshWithAnchoring = false

    return layoutProvider
  }, [allItems, viewSize, horizontal, getIndexSize])

  const rowRenderer = useCallback(
    (_: unknown, row: Row<Item>, index: number) =>
      row.type === "item"
        ? renderItem(row.item, getExternalIndex(index))
        : null,
    [renderItem, getExternalIndex],
  )

  const onVisibleIndicesChange = useCallback(
    (indices: number[]) => {
      if (onVisibleIndicesChangeProp) {
        const visibleIndices = indices
          // Filter out the "gap" items but leave the start/end padding items so
          // that listeners can detect when those are scrolled out of view. The
          // indices for both of those items will be reported as -1
          .filter((index) => {
            const item = allItems[index]
            return item && item.type !== "gap"
          })
          .map(getExternalIndex)
        if (
          !visibleIndicesEqual(
            visibleIndices,
            lastReportedVisibleIndices.current,
          )
        ) {
          lastReportedVisibleIndices.current = visibleIndices
          onVisibleIndicesChangeProp(visibleIndices)
        }
      }
    },
    [onVisibleIndicesChangeProp, getExternalIndex, allItems],
  )

  const styleSheet = useMemo(
    () =>
      StyleSheet.flatten([
        style,
        minSize,
        horizontal ? { height: viewSize.height } : { width: viewSize.width },
      ]),
    [horizontal, style, viewSize.width, viewSize.height],
  )

  const getIndexOffset = useCallback(
    (externalIndex: number) => {
      const internalIndex = getInternalIndex(externalIndex)
      let offset = 0
      for (let i = 0; i < internalIndex; i++) {
        const layout = recyclerListView.current?.getLayout(i)
        if (layout) {
          offset += layout[horizontal ? "width" : "height"]
        }
      }
      return offset
    },
    [getInternalIndex, horizontal],
  )

  const scrollToOffset = useCallback(
    (offset: number) =>
      recyclerListView.current?.scrollToOffset(
        horizontal ? offset : 0,
        horizontal ? 0 : offset,
        scrollBehavior === "smooth",
      ),
    [horizontal, scrollBehavior],
  )

  const scrollToIndex = useCallback(
    (externalIndex: number, extraOffset = 0) =>
      scrollToOffset(getIndexOffset(externalIndex) + extraOffset),
    [scrollToOffset, getIndexOffset],
  )

  const scrollViewProps = useMemo(
    () => ({
      keyboardDismissMode,
      contentContainerStyle,
      scrollEventThrottle,
      showsVerticalScrollIndicator,
      showsHorizontalScrollIndicator,
      dataSet: scrollViewDataSet,
    }),
    [
      keyboardDismissMode,
      contentContainerStyle,
      scrollEventThrottle,
      showsVerticalScrollIndicator,
      showsHorizontalScrollIndicator,
      scrollViewDataSet,
    ],
  )

  // Keep recyclerlistview's data in sync with our items array
  useMemo(() => {
    dataProvider.current = dataProvider.current.cloneWithRows(allItems)
  }, [allItems])

  // Make helper functions accessible via the ref
  useImperativeHandle(ref, () => ({
    scrollToIndex,
    scrollToOffset,
    getIndexSize,
    getIndexOffset,
    viewSize,
    items,
  }))

  const RecyclerListViewComponent = animated
    ? AnimatedRecyclerListView
    : RecyclerListView

  // Calculate the "safe" initial offset. This is used to workaround a bug in
  // recyclerlistview where setting an initial offset greater than the available
  // scroll distance causes items not to be rendered.
  const totalItemHeight = useMemo(() => {
    return allItems.reduce(
      (totalItemHeight, _item, index) => totalItemHeight + getIndexSize(index),
      0,
    )
  }, [allItems, getIndexSize])
  const maxInitialOffset = Math.max(
    totalItemHeight - viewSize[horizontal ? "width" : "height"],
    0,
  )
  const safeInitialOffset =
    initialOffset !== undefined
      ? Math.min(initialOffset, maxInitialOffset)
      : undefined

  return (
    <RecyclerListViewComponent
      ref={recyclerListView}
      onScroll={onScroll}
      initialOffset={safeInitialOffset}
      style={styleSheet}
      externalScrollView={windowScrollWeb ? undefined : externalScrollView}
      layoutSize={viewSize}
      dataProvider={dataProvider.current}
      layoutProvider={layoutProvider}
      rowRenderer={rowRenderer}
      scrollViewProps={scrollViewProps}
      useWindowScroll={windowScrollWeb}
      isHorizontal={horizontal}
      renderAheadOffset={renderAheadOffset}
      canChangeSize={true}
      onVisibleIndicesChanged={
        onVisibleIndicesChangeProp ? onVisibleIndicesChange : undefined
      }
      suppressBoundedSizeException
    />
  )
}

export default forwardRef(ListView)
