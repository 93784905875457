import { useEffect, useRef } from "react"

import { useAuth } from "@treefort/lib/auth-provider"

import { firebaseAnalyticsPlugin } from "../lib/analytics/plugins/firebase"
import api from "../lib/api"
import { isAxiosNetworkError, logError } from "../lib/logging"
import { useOfflineState } from "./use-offline-state"

export function useInitializeGoogleAnalyticsClient() {
  const auth = useAuth()
  const [offline] = useOfflineState()
  const initialized = useRef(false)
  const userId = auth.user?.id
  useEffect(() => {
    if (userId && !offline && !initialized.current) {
      initialized.current = true
      firebaseAnalyticsPlugin
        .getGoogleAnalyticsClient()
        .then((googleAnalyticsClient) => {
          if (googleAnalyticsClient) {
            return api.post("/integrations/google-analytics/clients", {
              googleAnalyticsClient,
            })
          }
        })
        .catch((cause) => {
          // Only log the error if we actually got a response from API. If we
          // can't reach the API at all then the AppInit component will log an
          // error.
          if (!isAxiosNetworkError(cause)) {
            logError(
              new Error("[Analytics] Failed to post Google Analytics client", {
                cause,
              }),
            )
          }

          initialized.current = false
        })
    }
  }, [userId, offline])
}
