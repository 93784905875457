import React, { useCallback, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import { DisplayableError } from "@treefort/lib/displayable-error"

import { AsyncViewOfflineProvider } from "../../../components/async-view-offline-provider"
import {
  LibraryEmptyMessage,
  getLibraryEmptyMessageHeight,
} from "../../../components/library/empty-message"
import {
  getLibraryListItemHeight,
  getLibraryListItemPaddingVertical,
  LibraryListItem,
} from "../../../components/library/list-item"
import { ListViewRef } from "../../../components/list-view"
import { useTokens } from "../../../components/tokens-provider"
import { useActiveProfileId } from "../../../hooks/use-active-profile-id"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useMenu } from "../../../hooks/use-menu"
import { useOfflineState } from "../../../hooks/use-offline-state"
import useQueryKey from "../../../hooks/use-query-key"
import { stopAudioPlayerIfCurrent } from "../../../lib/content-audio"
import DownloadItem from "../../../lib/download-item"
import {
  LibraryItem,
  getLibraryItemKey,
  sortLibraryItems,
} from "../../../lib/library"
import { logError } from "../../../lib/logging"
import { fetchProgressItemFromSettings } from "../../../lib/progress-item"
import { queryClient } from "../../../lib/query-client"
import settings from "../../../lib/settings"
import ScrollableLayout from "../../layouts/scrollable"

// This represents an item to display in the virtualized list view. This could
// be a library item or any other bit of UI we need to stack in the library
// page.
type ListViewItem =
  | {
      type: "libraryItem"
      libraryItem: LibraryItem
    }
  | { type: "spacer"; size: number }
  | { type: "emptyMessage" }

function getLibraryItemListViewItem(libraryItem: LibraryItem): ListViewItem {
  return { type: "libraryItem", libraryItem }
}

function getSpacerListViewItem(size: number): ListViewItem {
  return { type: "spacer", size }
}

function getEmptyMessageListViewItem(): ListViewItem {
  return { type: "emptyMessage" }
}

export function DownloadsScreen(): JSX.Element {
  const { tokens } = useTokens()
  const { close } = useMenu()
  const [offline] = useOfflineState()
  const listView = useRef<ListViewRef<ListViewItem>>(null)
  const { t } = useTranslation()
  const profileId = useActiveProfileId()

  // Extract content and progress data for all downloads out of the local store
  const downloadsQueryKey = useQueryKey(["downloads.menu", profileId])
  const downloadsQuery = useQuery(
    downloadsQueryKey,
    async () => {
      const downloadItems = await DownloadItem.getAll()
      return Promise.all(
        downloadItems.map(async (downloadItem) => {
          const consumableContent = downloadItem.getConsumableContent()
          const { progressItem, timestamp } =
            await fetchProgressItemFromSettings({
              consumableContent,
              profileId,
              strategy: "local",
            })
          return {
            contentId: consumableContent.content.id,
            downloadItem,
            progressItem,
            progressUpdatedAt: timestamp || 0,
          }
        }),
      )
    },
    { refetchOnMount: true, staleTime: 0 },
  )

  // Sort items first by most recently played and second by most recently
  // downloaded.
  const sortedItems = useMemo(
    () => (downloadsQuery.data ? sortLibraryItems(downloadsQuery.data) : []),
    [downloadsQuery.data],
  )

  // Calculate various dimensions (important for virtualized lists)
  const libraryItemHeight = getLibraryListItemHeight(tokens)
  const libraryItemPaddingVertical = getLibraryListItemPaddingVertical(tokens)
  const emptyMessageHeight = getLibraryEmptyMessageHeight(tokens)

  // Add spacers above/below the library items (if we're rendering any). This
  // may seem a bit complex just for some padding, but its necessary when
  // working with a virtualized layout.
  const listViewItems = useMemo(
    () =>
      downloadsQuery.isSuccess && sortedItems.length === 0
        ? [
            getSpacerListViewItem(libraryItemPaddingVertical),
            getEmptyMessageListViewItem(),
          ]
        : [
            getSpacerListViewItem(libraryItemPaddingVertical),
            ...sortedItems.map(getLibraryItemListViewItem),
            getSpacerListViewItem(libraryItemPaddingVertical),
          ],
    [sortedItems, libraryItemPaddingVertical, downloadsQuery.isSuccess],
  )

  // Remove an item from the library by deleting its setting, optimistically
  // updating the library query data, and clearing the audio player if the item
  // is loaded into it.
  const onPressRemoveItem = useCallback(
    async (libraryItem: LibraryItem) => {
      try {
        if (!libraryItem.progressItem) {
          throw new Error(
            `[Library] Missing progress item for content #${libraryItem.contentId}`,
          )
        }

        const progressItemKey = libraryItem.progressItem.getKey()

        // Optimistically update the downloads query
        if (libraryItem.downloadItem) {
          const downloadItemKey = libraryItem.downloadItem.getKey()
          queryClient.setQueriesData<LibraryItem[]>(
            downloadsQueryKey,
            (items) =>
              items?.filter(
                (item) =>
                  !item.downloadItem ||
                  item.downloadItem.getKey() !== downloadItemKey,
              ) || [],
          )
        }

        // Clear the audio player if the item we're removing is loaded
        await stopAudioPlayerIfCurrent(libraryItem.progressItem)

        // Clear out the store and delete any downloaded content
        await Promise.all([
          settings.clearLocal(progressItemKey, { profileId }),
          libraryItem.downloadItem?.deleteDownload(),
        ])
      } catch (error) {
        logError(
          new DisplayableError(
            t("An error occurred while removing item. Please try again."),
            error,
          ),
        )
      }
    },
    [downloadsQueryKey, t, profileId],
  )

  const asyncViewProps = useAsyncViewPropsForQueries([downloadsQuery])

  return (
    <AsyncViewOfflineProvider {...asyncViewProps} offlineStateDisabled>
      <ScrollableLayout
        listViewProps={
          // Only render the list view when we're in a success state. This
          // prevents performance issues related to trying to update many list
          // items in-place.
          asyncViewProps.state === "success"
            ? {
                ref: listView,
                items: listViewItems,
                renderItem: (item) =>
                  item.type === "libraryItem" ? (
                    <LibraryListItem
                      isOffline={offline}
                      onPressRemove={onPressRemoveItem}
                      onPressPlayAudio={close}
                      libraryItem={item.libraryItem}
                      removeLabel="Delete download"
                    />
                  ) : item.type === "emptyMessage" ? (
                    <LibraryEmptyMessage
                      tab="downloaded"
                      height={emptyMessageHeight}
                    />
                  ) : null,
                getItemKey: (item, index) =>
                  `${item.type}-${
                    item.type === "libraryItem"
                      ? getLibraryItemKey(item.libraryItem)
                      : item.type === "spacer"
                        ? `${index}-${item.size}`
                        : index
                  }`,
                getItemSize: (item) =>
                  item.type === "libraryItem"
                    ? libraryItemHeight
                    : item.type === "spacer"
                      ? item.size
                      : item.type === "emptyMessage"
                        ? emptyMessageHeight
                        : 0,
              }
            : undefined
        }
      />
    </AsyncViewOfflineProvider>
  )
}
