import rawTokens from "@treefort/tokens/app"

type Spacing = Exclude<keyof typeof rawTokens.spacing, "pagePaddingHorizontal">

/**
 * Convert named dimensions and numbers to CSS pixel values (e.g. "medium" ->
 * "16px" or 10 -> "10px"). Can handle shorthand syntax containing multiple
 * values (e.g. "small medium" -> "8px 16px". Everything else is passed through
 * (e.g. "5rem", "8%", or "9px").
 */
export const withUnits = (
  dimension: string | number | undefined,
): string | undefined => {
  const formatDimension = (dimension: string | number | undefined) => {
    const value = rawTokens.spacing[dimension as Spacing] ?? dimension
    return typeof value === "number" ? `${value}px` : value
  }
  return typeof dimension === "string"
    ? dimension.split(" ").map(formatDimension).join(" ")
    : formatDimension(dimension)
}
