import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import icons from "@treefort/tokens/app/icons"

import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import {
  COLOR_SCHEMES,
  EbookReader,
  ebookReader,
  EbookReaderEvent,
} from "../../lib/ebook-reader"
import { Button } from "../button"
import Column from "../column"
import Icon from "../icon"
import Modal from "../modal"
import Row from "../row"
import Text from "../text"
import { useTokens } from "../tokens-provider"
import Touchable from "../touchable"

const COLOR_SWATCH_SIZE = 48
const MODAL_MAX_WIDTH = 320

const ColorSwatchAccent = styled.View<{
  backgroundColor: string
}>`
  width: ${COLOR_SWATCH_SIZE + 8}px;
  height: ${COLOR_SWATCH_SIZE + 8}px;
  border-radius: ${COLOR_SWATCH_SIZE + 8}px;
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
  justify-content: center;
`

const ColorSwatchBorder = styled.View<{
  backgroundColor: string
}>`
  width: ${COLOR_SWATCH_SIZE + 4}px;
  height: ${COLOR_SWATCH_SIZE + 4}px;
  border-radius: ${COLOR_SWATCH_SIZE + 4}px;
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
  justify-content: center;
`

const ColorSwatch = styled.View<{
  backgroundColor: string
}>`
  width: ${COLOR_SWATCH_SIZE}px;
  height: ${COLOR_SWATCH_SIZE}px;
  border-radius: ${COLOR_SWATCH_SIZE}px;
  background-color: ${(props) => props.backgroundColor};
`

export default function Options({
  open,
  onRequestClose,
}: {
  open: boolean
  onRequestClose: () => void
}): JSX.Element | null {
  const { tokens, displayMode, displayWidth } = useTokens()
  const theme = useEventEmitterValue(
    ebookReader,
    EbookReaderEvent.ThemeChanged,
    ebookReader.getTheme,
  )

  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      showScrim={false}
      showBorder
      showBoxShadow
      onPressCloseButton={onRequestClose}
      onPressOutside={onRequestClose}
      title={t("Options")}
      type="sheet"
      floatingAlignmentHorizontal={
        displayWidth > MODAL_MAX_WIDTH * 1.5 ? "right" : "center"
      }
      floatingAlignmentVertical="bottom"
      maxWidth={MODAL_MAX_WIDTH}
      portalHost="foreground"
    >
      <Column
        paddingHorizontal="large"
        paddingVertical="medium"
        alignItems="stretch"
      >
        <Row justifyContent="space-between" paddingBottom="medium">
          {COLOR_SCHEMES.map((colorScheme, index) => {
            const selected =
              theme.colorScheme[displayMode].backgroundColor ===
              colorScheme.backgroundColor
            return (
              <Touchable
                key={index}
                onPress={() => {
                  ebookReader.updateTheme((theme) => ({
                    ...theme,
                    colorScheme: {
                      ...theme.colorScheme,
                      [displayMode]: colorScheme,
                    },
                  }))
                }}
              >
                <ColorSwatchAccent
                  backgroundColor={
                    selected
                      ? tokens.colors.border.accent
                      : tokens.colors.background.primary
                  }
                >
                  <ColorSwatchBorder
                    backgroundColor={
                      colorScheme.displayMode === displayMode && !selected
                        ? tokens.colors.border.primary
                        : tokens.colors.background.primary
                    }
                  >
                    <ColorSwatch
                      backgroundColor={colorScheme.backgroundColor}
                    />
                  </ColorSwatchBorder>
                </ColorSwatchAccent>
              </Touchable>
            )
          })}
        </Row>
        <Row
          justifyContent="space-between"
          borderTopColor="primary"
          paddingTop="medium"
          paddingBottom="medium"
        >
          <Button
            type="secondary"
            onPress={() =>
              ebookReader.updateTheme(
                EbookReader.decrement("fontSizeMultiplier"),
              )
            }
            aria-label={t("Decrease font size")}
          >
            <Icon
              size="large"
              source={icons.fontSizeDecrease}
              color={tokens.button.color.secondary}
            />
          </Button>
          {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
          <Text textStyle="body">
            {Math.round(theme.fontSizeMultiplier * 100)}%
          </Text>
          <Button
            type="secondary"
            onPress={() =>
              ebookReader.updateTheme(
                EbookReader.increment("fontSizeMultiplier"),
              )
            }
            aria-label={t("Increase font size")}
          >
            <Icon
              size="large"
              source={icons.fontSizeIncrease}
              color={tokens.button.color.secondary}
            />
          </Button>
        </Row>
        <Row
          justifyContent="space-between"
          borderTopColor="primary"
          paddingTop="medium"
        >
          <Button
            type="secondary"
            onPress={() =>
              ebookReader.updateTheme(
                EbookReader.decrement("lineHeightMultiplier"),
              )
            }
            aria-label={t("Decrease line height")}
          >
            <Icon
              size="large"
              source={icons.lineHeightDecrease}
              color={tokens.button.color.secondary}
            />
          </Button>
          {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
          <Text textStyle="body">
            {Math.round(theme.lineHeightMultiplier * 100)}%
          </Text>
          <Button
            type="secondary"
            onPress={() =>
              ebookReader.updateTheme(
                EbookReader.increment("lineHeightMultiplier"),
              )
            }
            aria-label={t("Increase line height")}
          >
            <Icon
              size="large"
              source={icons.lineHeightIncrease}
              color={tokens.button.color.secondary}
            />
          </Button>
        </Row>
      </Column>
    </Modal>
  )
}
