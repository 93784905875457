import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import styled from "styled-components/native"

import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import { Event, PlaybackState, VideoPlayer } from "../../lib/video-player"
import ActivityIndicator from "../activity-indicator"
import Text from "../text"

const Container = styled.View`
  align-items: center;
  justify-content: center;
`

const CenteredContainer = styled.View`
  position: absolute;
  padding: ${(props) => props.theme.spacing.large}px;
`

/**
 * This component renders an overlay with a loading spinner or error message
 * over its children based on the state of the VideoPlayer instance. This is
 * designed to be wrapped around our video players.
 */
export default function PlayerOverlay({
  children,
  videoPlayer,
  dimensions,
}: {
  children: React.ReactNode
  videoPlayer: VideoPlayer
  dimensions: { width: number; height: number }
}): JSX.Element {
  const playbackState = useEventEmitterValue(
    videoPlayer,
    Event.PlaybackState,
    videoPlayer.getPlaybackState,
  )
  const { t } = useTranslation()

  return (
    <Container style={dimensions}>
      {children}
      {
        // Don't show the loading spinner on the web. Most browsers have a
        // default spinner (which we can't hide without hiding all controls) and
        // we don't want to conflict with that.
        Platform.OS !== "web" &&
        (playbackState === PlaybackState.Loading ||
          playbackState === PlaybackState.Buffering) ? (
          <CenteredContainer>
            <ActivityIndicator size="jumbo" />
          </CenteredContainer>
        ) : playbackState === PlaybackState.Error ? (
          <CenteredContainer>
            <Text textStyle="body">
              {t("An error occurred. Please contact us if the issue persists.")}
            </Text>
          </CenteredContainer>
        ) : null
      }
    </Container>
  )
}
