"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileExtensionFromUrl = void 0;
/**
 * Extract a file extension from a URL. Return value does not include the "."
 */
function getFileExtensionFromUrl(url) {
    var _a, _b;
    return (_b = (_a = url.match(/\.(\w+)(\?|#|$)/)) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.toLowerCase();
}
exports.getFileExtensionFromUrl = getFileExtensionFromUrl;
