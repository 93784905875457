import React from "react"
import { useTranslation } from "react-i18next"

import { Result } from "@treefort/lib/result"

import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useContent from "../../hooks/use-content"
import { useRoute } from "../../hooks/use-route"
import { ModuleLayout } from "../layouts/module"
import { CardListViewModule } from "../layouts/module/list-view-modules/card"
import { EbookListViewModule } from "../layouts/module/list-view-modules/ebook"
import { SliderListViewModule } from "../layouts/module/list-view-modules/slider"

export function ContentEbookScreen(): JSX.Element {
  const manifest = useAppManifest()
  const { params } = useRoute<"contentId">()

  if (!params.contentId) {
    throw new Error(`Visited the ebook screen without a content id!`)
  }

  const contentId = parseInt(params.contentId)

  const ebook = useContent(contentId, "ebook")

  const { t } = useTranslation()

  const listViewModules = Result.mapSuccess(ebook, (ebook) =>
    Result.success([
      new EbookListViewModule({
        id: 0,
        type: "ebook",
        config: { contentId },
        conditions: {},
      }),
      ...(ebook.details.relatedAudiobookId || ebook.links.length
        ? [
            new CardListViewModule({
              id: -1,
              type: "card",
              config: {
                items: [
                  ...(ebook.details.relatedAudiobookId
                    ? [
                        {
                          type: "content",
                          appLink: {
                            type: "content",
                            contentType: "book",
                            contentId: ebook.details.relatedAudiobookId,
                          },
                          title: t("Listen to the audiobook"),
                          subtitle: ebook.title,
                        } as const,
                      ]
                    : []),
                  ...ebook.links,
                ],
              },
              conditions: {},
            }),
          ]
        : []),
      ...(manifest.features.contentPageRecommendations
        ? [
            new SliderListViewModule(
              {
                id: -2,
                type: "slider",
                config: {
                  title: t("Recommendations"),
                  itemShape: "tall",
                  size: "medium",
                  showItemTitles: true,
                  items: {
                    type: "recombee",
                    recommendation: {
                      type: "itemsToItem",
                      contentId,
                      personalized:
                        manifest.features.contentPageRecommendations
                          .personalized,
                      scenario:
                        manifest.features.contentPageRecommendations.scenario,
                    },
                  },
                },
                conditions: {},
              },
              { key: `content-page-recommendations-${contentId}` },
            ),
          ]
        : []),
    ]),
  )

  const asyncViewProps = useAsyncViewPropsForQueries(ebook)

  const key = `ebook-page-${contentId}`

  return (
    <AsyncViewOfflineProvider
      key={key}
      {
        // If we failed to load what we need at this level then provide that
        // information to the async view so that an appropriate error message
        // can be rendered. Otherwise delegate the async view props to our
        // children (by omitting them here).
        ...(asyncViewProps.state === "error" ? asyncViewProps : null)
      }
    >
      <ModuleLayout pageKey={key} listViewModules={listViewModules} />
    </AsyncViewOfflineProvider>
  )
}
