import { UseQueryOptions, useQuery } from "react-query"

import { EntitlementResponse } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import api from "../lib/api"
import useQueryKey from "./use-query-key"

/**
 * Fetch entitlements for the logged in user
 */
export function useEntitlements(
  options?: UseQueryOptions<EntitlementResponse[]>,
) {
  const auth = useAuth()
  return useQuery(
    useQueryKey("entitlements"),
    async () => {
      if (!auth.user) return []
      const res = await api.get<{ entitlements: EntitlementResponse[] }>(
        `/library/entitlements`,
      )
      return res.data.entitlements
    },
    options,
  )
}
