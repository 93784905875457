import { AppPage } from "@treefort/api-spec"
import { getPageFromRoute } from "../navigation/routes"
import useAppManifest from "./use-app-manifest"
import { useRoute } from "./use-route"

export function useAppPage(): AppPage | null {
  const manifest = useAppManifest()
  const route = useRoute()
  return getPageFromRoute(route, manifest)
}
