import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import {
  SubscriptionPlanResponse,
  UserSubscriptionsResponse,
} from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import authenticator from "../lib/authenticator"
import { queryClient } from "../lib/query-client"
import { getSubscriptionPlan } from "../lib/subscription-plans"
import {
  getCurrentSubscription,
  getUserSubscriptions,
} from "../lib/subscriptions"
import useQueryKey, { getQueryKey } from "./use-query-key"
import { USER_INFO_QUERY_KEY } from "./use-user-info"

const USER_SUBSCRIPTIONS_QUERY_KEY = "userSubscriptions"

export function useUserSubscriptions(
  options?: UseQueryOptions<UserSubscriptionsResponse>,
): UseQueryResult<UserSubscriptionsResponse> {
  const auth = useAuth()

  // Disable the query if a user isn't signed in
  const enabled =
    auth.user !== null && (options?.enabled === undefined || options.enabled)

  // This helps keep subscription data up-to-date
  const refetchOnWindowFocus = "always"

  return useQuery(
    useQueryKey(USER_SUBSCRIPTIONS_QUERY_KEY),
    getUserSubscriptions,
    { refetchOnWindowFocus, ...options, enabled },
  )
}

/**
 * Fetch the user's latest subscription data and invalidate all queries. Should
 * be called when the user's subscription state may have changed.
 */
export const refetchUserSubscriptions = async () => {
  const user = authenticator.getUser()
  await authenticator.refreshAccessToken()
  await Promise.all([
    // Invalidate all queries so that if the user's access has changed content
    // etc. will be refetched in the background as needed
    queryClient.invalidateQueries(),
    // Refetch subscription related queries
    queryClient.refetchQueries(getQueryKey(user, USER_SUBSCRIPTIONS_QUERY_KEY)),
    queryClient.refetchQueries(getQueryKey(user, USER_INFO_QUERY_KEY)),
  ])
  const userSubscriptions = await queryClient.fetchQuery(
    getQueryKey(user, USER_SUBSCRIPTIONS_QUERY_KEY),
    getUserSubscriptions,
  )
  return getCurrentSubscription(userSubscriptions)
}

export const useSubscriptionPlan = (
  id?: number,
  options?: UseQueryOptions<SubscriptionPlanResponse>,
): UseQueryResult<SubscriptionPlanResponse> => {
  const enabled =
    id !== undefined && (options?.enabled === undefined || options.enabled)
  return useQuery(
    useQueryKey(["subscription-plan", id]),
    // The query is disabled if id is undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getSubscriptionPlan(id!),
    { ...options, enabled },
  )
}
