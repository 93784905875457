import React from "react"
import { useTranslation } from "react-i18next"

import { ActionLinkStack } from "../../../components/action-link"
import Column from "../../../components/column"
import Text from "../../../components/text"
import { useTokens } from "../../../components/tokens-provider"
import config from "../../../config"
import { useAppMenuLinkGroups } from "../../../hooks/use-app-menu-link-groups"
import MenuLayout from "../../layouts/menu"

export default function AboutScreen(): JSX.Element {
  const { t } = useTranslation()
  const { tokens } = useTokens()
  const desktop = tokens.appHeader.mode === "desktop"
  const linkGroups = useAppMenuLinkGroups(
    desktop ? "desktopAbout" : "mobileAbout",
  )
  return (
    <MenuLayout>
      <Column alignItems="stretch" gap="large">
        <ActionLinkStack linkGroups={linkGroups} />
        <Text textStyle="body" color="secondary" alignment="center">
          {t("App version {{version}}", { version: config.APP_VERSION })}
        </Text>
      </Column>
    </MenuLayout>
  )
}
