import { useCallback, useEffect, useRef, useState } from "react"
import { NetworkState } from "expo-network"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"
import {
  addNetworkStateListener,
  getCachedNetworkState,
  getNetworkState,
  networkStateNotEqual,
} from "../lib/network-state"

/**
 * A hook that returns the current network state along with a function to
 * refresh the state. The cached network state will be returned immediately and
 * then the hook will fetch the latest state in the background. The refresh
 * function can also be called to fetch the latest state.
 *
 * This hook also allows a shouldUpdate function to be provided which can reduce
 * unnecessary re-renders. Note that the shouldUpdate cannot be changed after
 * this hook is mounted.
 */
export function useNetworkState(
  options: {
    shouldUpdate?: (prevState: NetworkState, state: NetworkState) => boolean
  } = {},
): [NetworkState, () => Promise<NetworkState>] {
  const { shouldUpdate = networkStateNotEqual } = options
  const prevState = useRef(getCachedNetworkState())
  const [state, setState] = useState(prevState.current)
  const willUnmount = useWillUnmount()

  const handleNewState = useCallback(
    (state: NetworkState) => {
      if (!willUnmount.current && shouldUpdate(prevState.current, state)) {
        setState(state)
        prevState.current = state
      }
    },
    // As the docs state, shouldUpdate cannot be changed after this hook is
    // mounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const refreshState = useCallback(
    () =>
      getNetworkState().then((state) => {
        handleNewState(state)
        return state
      }),
    [handleNewState],
  )

  useEffect(() => {
    const removeListener = addNetworkStateListener(handleNewState)
    refreshState()
    return removeListener
  }, [handleNewState, refreshState])

  return [state, refreshState]
}
