import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import confirm from "../lib/confirm"
import { i18nKey } from "../types/i18next"
import { useOpenCheckoutPage } from "./use-open-checkout-page"

export function usePromptCreateFreeAccount() {
  const { t } = useTranslation()
  const openCheckoutPage = useOpenCheckoutPage({
    availability: { status: "notAvailable", reasonCode: "notAuthenticated" },
  })

  return useCallback(
    async (message: i18nKey) => {
      if (
        await confirm({
          title: t("Account Required"),
          message: t(message),
          confirmLabel: t("Create FREE Account"),
          cancelLabel: t("Cancel"),
        })
      ) {
        openCheckoutPage()
      }
    },
    [openCheckoutPage, t],
  )
}
