import { Dimensions, ScaledSize } from "react-native"

export const getWindowDimensions = ({
  webHeightMode = "client",
}: {
  webHeightMode?: "inner" | "client"
} = {}): ScaledSize =>
  webHeightMode === "inner"
    ? {
        ...Dimensions.get("window"),
        // Using innerHeight can be useful because it accounts for changes in
        // height caused by iOS expanding/collapsing the browser chrome
        height: window.innerHeight,
      }
    : Dimensions.get("window")
