import React, { ReactNode } from "react"
import { TextProps as RNTextProps } from "react-native"

import styled from "styled-components/native"

import tokens from "@treefort/tokens/app"

import { getTextStyleString, TextStyle } from "../lib/text-style"
import TextPlaceholder from "./text-placeholder"
import { ResolvedTokens } from "./tokens-provider"

type Color = keyof typeof tokens.colors.text
type MaxWidth = keyof typeof tokens.text.maxWidth

export type TextAlignment = "left" | "center" | "right"
export type TextMaxWidth = MaxWidth | string | number
export type TextColor = Color | string
export type { TextStyle }

export type TextProps = RNTextProps & {
  children: ReactNode
  textStyle: TextStyle
  color?: TextColor
  maxWidth?: TextMaxWidth
  alignment?: TextAlignment
  shadow?: boolean
  loadingWidth?: number
  loadingNumberOfLines?: number
}

export const getTextMaxWidthStyle = ({
  tokens,
  maxWidth,
}: {
  tokens: ResolvedTokens
  maxWidth?: TextMaxWidth
}): string | undefined => {
  const maxWidthValue =
    maxWidth && maxWidth in tokens.text.maxWidth
      ? tokens.text.maxWidth[maxWidth as MaxWidth]
      : maxWidth
  return maxWidthValue
    ? `max-width: ${maxWidthValue}${
        typeof maxWidthValue === "number" ? "px" : ""
      };`
    : undefined
}

const StyledText = styled.Text<{
  textStyle: TextStyle
  alignment: TextAlignment
  inverse?: boolean
  color: TextColor
  shadow: boolean
  maxWidth?: TextMaxWidth
}>`
  ${(props) => getTextStyleString(props.textStyle, props.theme)};
  color: ${(props) =>
    props.color in props.theme.colors.text
      ? props.theme.colors.text[props.color as Color]
      : props.color};
  text-align: ${(props) => props.alignment};
  ${(props) =>
    getTextMaxWidthStyle({
      tokens: props.theme,
      maxWidth: props.maxWidth,
    })}
  ${({ shadow, theme }) =>
    shadow &&
    `text-shadow: ${theme.shadows.text.offsetX}px ${theme.shadows.text.offsetY}px ${theme.shadows.text.radius}px ${theme.shadows.text.color};`}
`

function Text({
  children,
  textStyle,
  color = "primary",
  alignment = "left",
  shadow = false,
  maxWidth,
  loadingWidth,
  loading,
  loadingNumberOfLines = 1,
  ...extraProps
}: Omit<TextProps, "children"> & {
  loading?: boolean
  loadingNumberOfLines?: number
  children?: ReactNode
}): JSX.Element | null {
  return loading ? (
    <>
      {[...Array(loadingNumberOfLines)].map((_, index) => (
        <TextPlaceholder
          key={index}
          maxWidth={loadingWidth}
          textStyle={textStyle}
          {...extraProps}
        />
      ))}
    </>
  ) : children ? (
    <StyledText
      textStyle={textStyle}
      color={color}
      maxWidth={maxWidth}
      alignment={alignment}
      shadow={shadow}
      {...extraProps}
    >
      {children}
    </StyledText>
  ) : null
}

export default Text
