import React, { ReactNode } from "react"
import { Platform, ScrollViewProps } from "react-native"
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import {
  ListViewProps as ListViewPropsOriginal,
  ListViewRef,
} from "../../../components/list-view"
import {
  backgroundColorToString,
  BackgroundColor,
} from "../../../lib/background-color"

export type ListViewProps<ListViewItem = unknown> = Omit<
  ListViewPropsOriginal<ListViewItem>,
  "viewSize"
> & {
  ref?: React.Ref<ListViewRef<ListViewItem>>
}

export type ScrollableLayoutProps<ListViewItem = unknown> = {
  children?: ReactNode
  header?: ReactNode
  footer?: ReactNode
  keyboardDismissMode?: ScrollViewProps["keyboardDismissMode"]
  contentContainerStyle?: ScrollViewProps["contentContainerStyle"]
  headerHeight?: number
  footerHeight?: number
  backgroundColor?: BackgroundColor
  listViewProps?: ListViewProps<ListViewItem>
}

export const DEFAULT_SCROLL_EVENT_THROTTLE = 8

export const DEFAULT_RENDER_AHEAD_OFFSET = 240

const FixedContainer = styled.View<{
  insets: EdgeInsets
  top?: number
  bottom?: number
}>`
  position: ${Platform.OS === "web" ? "fixed" : "absolute"};
  z-index: 1;
  width: 100%;
  left: 0;
  ${({ top }) => (top === undefined ? "" : `top: ${top}px`)};
  ${({ bottom }) => (bottom === undefined ? "" : `bottom: ${bottom}px`)};
`

const StaticContainer = styled.View<{
  insets: EdgeInsets
  backgroundColor: BackgroundColor
}>`
  position: relative;
  flex: auto;
  background: ${(props) =>
    backgroundColorToString(props.theme, props.backgroundColor)};
  padding-left: ${(props) => props.insets.left}px;
  padding-right: ${(props) => props.insets.right}px;
  width: 100%;
`

export function PageContainer(props: {
  children: ReactNode
  backgroundColor: BackgroundColor
}): JSX.Element {
  const insets = useSafeAreaInsets()
  return <StaticContainer {...props} insets={insets} />
}

export function HeaderContainer(props: { children: ReactNode }): JSX.Element {
  const insets = useSafeAreaInsets()
  return (
    <FixedContainer
      {...props}
      pointerEvents="box-none"
      insets={insets}
      top={0}
    />
  )
}

export function FooterContainer(props: { children: ReactNode }): JSX.Element {
  const insets = useSafeAreaInsets()
  return (
    <FixedContainer
      {...props}
      pointerEvents="box-none"
      insets={insets}
      bottom={0}
    />
  )
}
