
import { PROGRESS_VERSION } from "@treefort/constants"
import { IKeyValueStore } from "@treefort/lib/types/settings"

import { getClient } from "../db"
import { Progress } from "../models/progress"
import { KeyValueStore } from "./key-value"

type ProgressOptions = {
  profileId: string | null
}

const CURRENT_AUDIO_PROGRESS_KEY = `currentProgressKey.${PROGRESS_VERSION}`

const getProfileCurrentAudioKey = (profileId: string | null) =>
  `${CURRENT_AUDIO_PROGRESS_KEY}:${profileId || ""}`

class ProgressStore extends KeyValueStore<Progress> implements IKeyValueStore {
  constructor() {
    super(Progress.table)
  }

  async saveCurrentAudioProgress({
    profileId,
    value,
  }: {
    profileId: string | null
    value: string
  }) {
    await getClient().localStorage.set(
      getProfileCurrentAudioKey(profileId),
      value,
    )
  }

  async clearCurrentAudioProgress({ profileId }: ProgressOptions) {
    await getClient().localStorage.remove(getProfileCurrentAudioKey(profileId))
  }
}

export const progressStore = new ProgressStore()
