import { t } from "i18next"
import debounce from "lodash/debounce"

import { DisplayableError } from "@treefort/lib/displayable-error"
import filter from "@treefort/lib/filter"
import isObject from "@treefort/lib/is-object"

import analytics from "./analytics"
import audioPlayer from "./audio-player"
import { EbookConsumableContent } from "./consumable-content"
import DownloadItem from "./download-item"
import {
  Ebook,
  EbookReader,
  ebookReader,
  EbookReaderLocation,
  EbookReaderEvent,
  EbookReaderTheme,
  DEFAULT_EBOOK_READER_THEME,
} from "./ebook-reader"
import { logError } from "./logging"
import { getNetworkState, isOfflineState } from "./network-state"
import {
  fetchProgressItemFromSettings,
  EbookProgressItem,
} from "./progress-item"
import { Store } from "./store"

// Extra data that we stuff inside the ebook object
type EbookExtra = {
  consumableContent: EbookConsumableContent
  progressItem: EbookProgressItem
}

const themeStore = new Store({ key: "contentEbookThemes" })

// Debounce the handling of theme changes aggressively. This is not
// time-sensitive and there's no need to pummel the local store if the user does
// something like mash the increase font size button.
const handleThemeChange = debounce(
  async (theme: EbookReaderTheme) => {
    // Save the theme for the current ebook
    const ebook = ebookReader.getEbook()
    const extra = getEbookExtra(ebook)
    if (extra) {
      themeStore.set(extra.progressItem.getKey(), theme)
    }

    // Save the theme as the new default
    themeStore.set("default", theme)
  },
  1000,
  { leading: true, trailing: true },
)

// Return extra data from the ebook object if it looks like the kind of data
// that we originally stuffed in there
const getEbookExtra = (ebook: Ebook | null): EbookExtra | undefined =>
  ebook &&
  isObject(ebook.extra) &&
  "progressItem" in ebook.extra &&
  "consumableContent" in ebook.extra
    ? (ebook.extra as EbookExtra)
    : undefined

function addListeners(ebookReader: EbookReader): void {
  let engagementTimeStart: number | null = null

  // Save settings at most once per second. This avoids thrashing the settings
  // system if someone is rapidly paging through a book
  const saveLocalAndRemoteProgress = debounce(
    (extra: EbookExtra) => extra.progressItem.saveLocalAndRemote(),
    1000,
  )

  // Pause the audio player when a new book is loaded
  ebookReader.on(EbookReaderEvent.EbookChanged, (ebook) => {
    engagementTimeStart = null
    if (ebook) {
      audioPlayer.pause()
    }
  })

  // Save progress and log analytics on location change
  ebookReader.on(EbookReaderEvent.LocationChanged, ({ previous, current }) => {
    const ebook = ebookReader.getEbook()
    const extra = getEbookExtra(ebook)

    // Save progress for the current location
    if (extra && current?.type === "rendered") {
      // Update the progress item
      extra.progressItem.updateProgress(
        filter(
          {
            percent: current.metadata.percent,
            finished: current.metadata.endOfBookVisible,
          },
          (value) => value !== undefined,
        ),
      )
      extra.progressItem.setLocation(current)

      // Save progress locally and remotely
      saveLocalAndRemoteProgress(extra)
    }

    // Log analytics for the previous location (now that we can calculate
    // engagement time for that location)
    if (engagementTimeStart && extra && previous?.type === "rendered") {
      analytics.logReadProgress({
        progressItem: extra.progressItem,
        consumableContent: extra.consumableContent,
        engagementTime: performance.now() - engagementTimeStart,
        wordsVisible: previous.metadata.wordsVisible,
      })
    }

    // Reset the engagement start time each time we render a new location
    if (current?.type === "rendered") {
      engagementTimeStart = performance.now()
    }
  })

  // Log analytics for the last page when closing the book
  ebookReader.on(EbookReaderEvent.EbookWillChange, (nextEbook) => {
    const ebook = ebookReader.getEbook()
    const location = ebookReader.getLocation()
    const extra = getEbookExtra(ebook)
    if (
      ebook &&
      !nextEbook &&
      extra &&
      engagementTimeStart &&
      location?.type === "rendered"
    ) {
      analytics.logReadProgress({
        progressItem: extra.progressItem,
        consumableContent: extra.consumableContent,
        engagementTime: performance.now() - engagementTimeStart,
        wordsVisible: location.metadata.wordsVisible,
      })
    }
  })

  // Save theme selection
  ebookReader.on(EbookReaderEvent.ThemeChanged, handleThemeChange)
}

export async function readContentEbook({
  consumableContent,
  profileId,
  location,
}: {
  consumableContent: EbookConsumableContent
  profileId: string | null
  location?: EbookReaderLocation
}): Promise<void> {
  try {
    // Make sure we're either online or the ebook is downloaded
    const downloadItem = new DownloadItem({ consumableContent })
    const [networkState, downloadItemState] = await Promise.all([
      getNetworkState(),
      downloadItem.getState(),
    ])
    if (
      isOfflineState(networkState) &&
      downloadItemState.type !== "downloaded"
    ) {
      throw new DisplayableError(
        t("Cannot read ebook - no network connection."),
      )
    }

    // Make sure we were given an ebook to read
    const { content } = await downloadItem.getOfflineConsumableContent()

    if (content.details.ebookMedia?.status !== "available") {
      throw new Error(
        `Attempted to read ebook "${consumableContent.content.id}" without any media.`,
      )
    }

    // Load progress
    const { progressItem } = await fetchProgressItemFromSettings({
      consumableContent,
      profileId,
      strategy: "localOrRemote",
    })
    if (location) {
      progressItem.setLocation(location)
    }

    // Load any saved theme settings
    const [theme, defaultTheme] = await Promise.all([
      themeStore.get<EbookReaderTheme>(progressItem.getKey()),
      themeStore.get<EbookReaderTheme>("default"),
    ])

    // Start reading
    const extra = { progressItem, consumableContent }
    ebookReader.setEbook(
      {
        media: content.details.ebookMedia.data,
        title: content.title,
        extra,
      },
      {
        location: progressItem.getLocation(),
        theme: {
          ...DEFAULT_EBOOK_READER_THEME,
          ...defaultTheme,
          ...theme,
          invertImagesInDarkMode: Boolean(
            content.details.invertImagesInDarkMode,
          ),
        },
      },
    )

    // Log a read request but don't block playback while we do so
    analytics.logReadRequest(extra)
  } catch (error) {
    logError(
      error instanceof DisplayableError
        ? error
        : new DisplayableError(
            t("An error occurred. Please contact us if the issue persists."),
            error,
          ),
    )
  }
}

addListeners(ebookReader)
