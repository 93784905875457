import React from "react"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import rawTokens from "@treefort/tokens/app"

import Column from "./column"
import Icon, { IconSource } from "./icon"
import Image from "./image"
import Text from "./text"
import Touchable from "./touchable"

const PROFILE_IMAGE_SIZE_MEDIUM = 88
export const PROFILE_IMAGE_SIZE_SMALL = 64
export const PROFILE_ACCENT_RING_OUTER_OFFSET = 6

const IconContainer = styled.View<{ showScrim?: boolean }>`
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${({ showScrim }) =>
    showScrim
      ? // The dark mode scrim is a little too dark for this use case
        rawTokens.colors.background.scrim.light
      : "transparent"};
  border-radius: ${({ theme }) => theme.borderRadius.roundedFull}px;
  top: ${PROFILE_ACCENT_RING_OUTER_OFFSET / 2}px;
  bottom: ${PROFILE_ACCENT_RING_OUTER_OFFSET / 2}px;
  right: ${PROFILE_ACCENT_RING_OUTER_OFFSET / 2}px;
  left: ${PROFILE_ACCENT_RING_OUTER_OFFSET / 2}px;
`

const StyledImage = styled(Image)<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ theme }) => theme.borderRadius.roundedFull}px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
`

const AccentRing = styled.View<{ active: boolean; outerOffset: number }>`
  align-items: center;
  justify-content: center;
  padding: ${({ outerOffset }) => outerOffset / 2}px;
  border-radius: ${({ theme }) => theme.borderRadius.roundedFull}px;
  border: ${({ outerOffset }) => outerOffset / 2}px solid
    ${({ theme, active }) => (active ? theme.colors.accent : "transparent")};
`

export function ProfileButton({
  text,
  onPress,
  profileImage,
  isHighlighted,
  icon,
  size: sizeProp = "medium",
}: {
  size?: "small" | "medium"
  text?: string
  onPress: () => void
  profileImage?: string
  isHighlighted?: boolean
  icon?: IconSource
}) {
  const imageSize =
    sizeProp === "medium" ? PROFILE_IMAGE_SIZE_MEDIUM : PROFILE_IMAGE_SIZE_SMALL
  return (
    <Touchable onPress={onPress}>
      <Column
        rowGap="xsmall"
        width={imageSize + PROFILE_ACCENT_RING_OUTER_OFFSET * 2}
      >
        <AccentRing
          active={Boolean(isHighlighted)}
          outerOffset={PROFILE_ACCENT_RING_OUTER_OFFSET}
        >
          <StyledImage
            source={getOptimizedImageSource({ uri: profileImage }, imageSize)}
            resizeMode="cover"
            size={imageSize}
          />
          {icon ? (
            <IconContainer showScrim={Boolean(profileImage)}>
              <Icon
                source={icon}
                color={
                  profileImage
                    ? // If an image is provided then always use a light color for
                      // the icon regardless of display mode
                      rawTokens.colors.background.primary.light
                    : "primary"
                }
              />
            </IconContainer>
          ) : undefined}
        </AccentRing>
        {text ? (
          <Text numberOfLines={1} textStyle="headingSmall" color="primary">
            {text}
          </Text>
        ) : null}
      </Column>
    </Touchable>
  )
}
