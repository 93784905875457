import React from "react"

import { Result } from "@treefort/lib/result"

import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useContent from "../../hooks/use-content"
import { useRoute } from "../../hooks/use-route"
import { ModuleLayout } from "../layouts/module"
import { CardListViewModule } from "../layouts/module/list-view-modules/card"
import { VideoSeriesListViewModule } from "../layouts/module/list-view-modules/video-series"

export function ContentVideoSeriesScreen(): JSX.Element {
  const { params } = useRoute<"contentId">()

  if (!params.contentId) {
    throw new Error(`Visited the video series screen without a content id!`)
  }

  const contentId = parseInt(params.contentId)

  const videoSeriesQuery = useContent(contentId)

  const videoSeriesModule = new VideoSeriesListViewModule({
    id: 0,
    type: "videoSeries",
    config: { contentId },
    conditions: {},
  })

  const listViewModules = Result.mapSuccess(videoSeriesQuery, (videoSeries) =>
    Result.success([
      videoSeriesModule,
      ...(videoSeries.links.length
        ? [
            new CardListViewModule({
              id: -1,
              type: "card",
              config: { items: videoSeries.links },
              conditions: {},
            }),
          ]
        : []),
    ]),
  )

  const asyncViewProps = useAsyncViewPropsForQueries(videoSeriesQuery)
  const key = `video-series-${contentId}`

  return (
    <AsyncViewOfflineProvider
      key={key}
      {
        // If we failed to load what we need at this level then provide that
        // information to the async view so that an appropriate error message
        // can be rendered. Otherwise delegate the async view props to our
        // children (by omitting them here).
        ...(asyncViewProps.state === "error" ? asyncViewProps : null)
      }
    >
      <ModuleLayout pageKey={key} listViewModules={listViewModules} />
    </AsyncViewOfflineProvider>
  )
}
