import { IS_BOT } from "./is-bot"
import { logWarning } from "./logging"

declare const __DEV_DISABLE_SERVICE_WORKER__: boolean

type RegistrationPromise = Promise<ServiceWorkerRegistration | null>

let registration: RegistrationPromise | undefined

/**
 * Registers a service worker once the page has loaded. If service workers are
 * not supported then null will be returned.
 */
export function registerServiceWorker(): RegistrationPromise {
  if (
    "serviceWorker" in navigator &&
    registration === undefined &&
    !__DEV_DISABLE_SERVICE_WORKER__ &&
    !IS_BOT
  ) {
    registration = new Promise((resolve) =>
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then(resolve)
          .catch((cause) => {
            logWarning(
              new Error("[Service Worker] Failed to register", { cause }),
            )
            return null
          })
      }),
    )
  } else if (registration === undefined) {
    registration = Promise.resolve(null)
  }
  return registration
}

/**
 * Returns the current service worker registration if one exists and is active.
 * If service workers are not supported, a service worker is not registered, or
 * the registration is not active then this will return null.
 */
export async function getActiveServiceWorkerRegistration(): RegistrationPromise {
  const serviceWorker = await registerServiceWorker()
  return serviceWorker && serviceWorker.active ? serviceWorker : null
}
