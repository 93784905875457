import { addEventListeners } from "@treefort/lib/dom"
import { PlaybackState } from "./types"

/**
 * Binds a listener to a media element so that it will be called any time the
 * element becomes seekable or unseekable.
 */
export function addSeekableListener(
  element: HTMLMediaElement,
  listener: (seekable: boolean) => void,
): () => void {
  return addEventListeners(element, {
    loadstart: () => listener(false),
    durationchange: () => listener(element.seekable.length > 0),
    canplay: () => listener(element.seekable.length > 0),
    canplaythrough: () => listener(element.seekable.length > 0),
    emptied: () => listener(false),
  })
}

/**
 * Binds a listener to a media element so that it will be called any time the
 * playback state changes.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events
 */
export function addPlaybackStateListener(
  element: HTMLMediaElement,
  listener: (playbackState: PlaybackState) => void,
): () => void {
  return addEventListeners(element, {
    loadstart: () => listener(PlaybackState.Loading),
    canplay: () =>
      listener(element.paused ? PlaybackState.Paused : PlaybackState.Playing),
    play: () => listener(PlaybackState.Playing),
    playing: () => listener(PlaybackState.Playing),
    pause: () => listener(PlaybackState.Paused),
    seeked: () =>
      listener(element.paused ? PlaybackState.Paused : PlaybackState.Playing),
    waiting: () => listener(PlaybackState.Buffering),
    emptied: () => listener(PlaybackState.Idle),
    error: () => listener(PlaybackState.Error),
  })
}
