import { useEffect, useState, useCallback } from "react"
import { AppState as ReactNativeAppState, AppStateStatus } from "react-native"
import { getAppState, AppState } from "../lib/app-state"

export type { AppState }

const useAppState = ({
  inactiveStateiOS,
}: {
  inactiveStateiOS?: AppState
} = {}): AppState => {
  const reactNativeAppState = ReactNativeAppState.currentState
  const [appState, setAppState] = useState<AppState>(
    getAppState({ reactNativeAppState, inactiveStateiOS }),
  )
  const onAppStateChange = useCallback(
    (reactNativeAppState: AppStateStatus) => {
      setAppState(getAppState({ reactNativeAppState, inactiveStateiOS }))
    },
    [inactiveStateiOS],
  )

  useEffect(() => {
    const sub = ReactNativeAppState.addEventListener("change", onAppStateChange)
    return () => sub.remove()
  }, [onAppStateChange])

  return appState
}

export default useAppState
