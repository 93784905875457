import React, { useEffect, useState } from "react"
import { Animated, Platform } from "react-native"

import styled from "styled-components/native"

import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import { useKeepAwake } from "../../hooks/use-keep-awake"
import { useLockBodyScroll } from "../../hooks/use-lock-body-scroll"
import { OpenState, useOpenAnimation } from "../../hooks/use-open-animation"
import { ebookReader, EbookReaderEvent } from "../../lib/ebook-reader"
import { syncManager } from "../../watermelon/sync"
import { Portal } from "../portal"
import { NavigationBar } from "../system-bars/navigation-bar"
import { StatusBar } from "../system-bars/status-bar"
import { useTokens } from "../tokens-provider"
import { ViewerWithHud } from "./viewer-with-hud"

const OPEN_ANIMATION_TRANSLATE_Y = 50

const Container = styled(Animated.View)`
  position: ${Platform.OS === "web" ? "fixed" : "absolute"};
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background.primary};
`

export function EbookReaderFullscreen(): JSX.Element {
  const ebook = useEventEmitterValue(
    ebookReader,
    EbookReaderEvent.EbookChanged,
    ebookReader.getEbook,
  )
  const [open, setOpen] = useState(ebook !== null)
  const [openValue, openState] = useOpenAnimation({ open })

  // Keep the device awake for slow readers
  useKeepAwake(openState === "open")

  // Don't allow the page behind the reader to scroll
  useLockBodyScroll(openState === "open")

  // Open the reader when an ebook is loaded
  useEffect(() => {
    if (ebook) {
      setOpen(true)
    }
  }, [ebook])

  // Close the reader when requested
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.CloseRequested, () => {
        setOpen(false)
        // Request an immediate sync to persist progress to the server
        syncManager.requestSync({ syncType: "immediate" })
      }),
    [],
  )

  // Clear the ebook when closed
  useEffect(() => {
    if (openState === "closed") {
      ebookReader.setEbook(null)
    }
  }, [openState])

  // NOTE: We do not unmount the portal when the reader is closed because of a
  // bug with @gorhom/portal. For an unknown reason unmounting one portal while
  // mounting another (e.g. closing the chapter selector on the ebook page while
  // opening the ebook reader) results in an extra unmounted/remounted cycle on
  // the new portal, failed animations, etc.
  return (
    <Portal>
      <EbookReaderSystemBars openState={openState} />
      {openState === "closed" ? null : (
        <Container
          style={{
            opacity: openValue,
            transform: [
              {
                translateY: openValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [OPEN_ANIMATION_TRANSLATE_Y, 0],
                }),
              },
            ],
          }}
        >
          {ebook ? (
            <ViewerWithHud ebook={ebook} animating={openState !== "open"} />
          ) : null}
        </Container>
      )}
    </Portal>
  )
}

function EbookReaderSystemBars({ openState }: { openState: OpenState }) {
  const theme = useEventEmitterValue(
    ebookReader,
    EbookReaderEvent.ThemeChanged,
    ebookReader.getTheme,
  )
  const { displayMode } = useTokens()
  const colorScheme = theme.colorScheme[displayMode]

  // If the ebook reader isn't in an open state then bail
  if (openState !== "opening" && openState !== "open") {
    return null
  }

  // On android we keep the nav and status bar visible to avoid a host of layout
  // jitter and keyboard-avoiding-view bugs. To make this look nice we update
  // the colors of the bars to match the selected ebook theme.
  if (Platform.OS === "android") {
    return (
      <>
        <StatusBar
          barStyle={
            colorScheme.displayMode === "light"
              ? "dark-content"
              : "light-content"
          }
        />
        <NavigationBar
          buttonStyle={colorScheme.displayMode === "light" ? "dark" : "light"}
          backgroundColor={colorScheme.backgroundColor}
          onPressBack={ebookReader.requestClose}
        />
      </>
    )
  }

  // Everywhere other than Android we simply hide the status bar.
  return <StatusBar hidden={true} />
}
