import { DeepPartial } from "@treefort/lib/deep-partial"
import merge from "@treefort/lib/merge"

import { PlaybackState } from "../types"

export type SkipInterval = 10000 | 30000

export type MediaSessionData = {
  track: {
    title: string
    artwork?: string
    artist?: string
    album?: string
  }
  state?: {
    position?: number
    duration?: number
    playbackRate?: number
    playbackState?: PlaybackState
  }
}

export type MediaSessionConfig = {
  skipPositionBackwardInterval?: SkipInterval
  skipPositionForwardInterval?: SkipInterval
  onPlay?: () => void
  onPause?: () => void
  onSkipTrack?: (interval: number) => void
  onSkipPosition?: (interval: number) => void
  onSeek?: (position: number) => void
}

let activeMediaSessionKey: unknown

/**
 * Use this to update the metadata and controls shown on the device's lock
 * screen for the currently playing media. Supports Android, iOS, and the web.
 * NOTE: On the web only metadata is supported. Custom controls will be ignored.
 */
export abstract class MediaSession {
  protected key: unknown
  protected data?: MediaSessionData
  protected config: MediaSessionConfig

  constructor(key: unknown, config: MediaSessionConfig = {}) {
    this.key = key
    this.config = config
  }

  /**
   * Processes data sent to the media session. Specifically this clones the data
   * so that any mutuations made by the media session don't bleed out.
   */
  private processIncomingData = <T extends DeepPartial<MediaSessionData>>(
    data: T,
  ): T => merge(true, data)

  /**
   * Should be extended to support updates to active media sessions.
   */
  protected abstract _updateData: (data: DeepPartial<MediaSessionData>) => void

  /**
   * Should be extended to support updates to media session config.
   */
  protected abstract _updateConfig: (config: MediaSessionConfig) => void

  /**
   * Should be extended to support activating an inactive session.
   */
  protected abstract _activate: (data: MediaSessionData) => void

  /**
   * Should be extended to support deactivating an active session.
   */
  protected abstract _deactivate: () => void

  /**
   * Update the session with new metadata. Has no effect if the session is
   * inactive. Note that this should only be called when the track, playback
   * rate, or playback state changes. The session will automatically update the
   * playback position on its own as time progresses, and does not need to be
   * informed of new positions during the course of normal, uninterrupted
   * playback.
   */
  public updateData = (data: DeepPartial<MediaSessionData>): void => {
    // Ignore updates if the session is not active
    if (!this.active) return

    const processedData = this.processIncomingData(data)

    // Trigger the update function with the new data
    this._updateData(processedData)

    // Merge the new data into the old data. Do this after triggering the
    // _update function so that the function can compare the new data to the old
    // data if necessary
    this.data = merge(this.data, processedData)
  }

  public updateConfig = (config: Partial<MediaSessionConfig>): void => {
    this.config = { ...this.config, ...config }
    this._updateConfig(this.config)
  }

  /**
   * Activate the session. This should be called immediately after playback
   * begins.
   */
  public activate = (
    data: MediaSessionData,
    config: MediaSessionConfig = this.config,
  ): void => {
    if (this.active) {
      this.updateData(data)
      this.updateConfig(config)
    } else {
      activeMediaSessionKey = this.key
      this.data = this.processIncomingData(data)
      this._activate(this.data)
      this.updateConfig(config)
    }
  }

  /**
   * Deactivate the session. This should be called when playback is stopped or
   * suspended.
   */
  public deactivate = (): void => {
    if (this.active) {
      activeMediaSessionKey = undefined
      this._deactivate()
    }
  }

  /**
   * Checks if the session is currently active.
   */
  public get active(): boolean {
    return this.key === activeMediaSessionKey
  }
}
