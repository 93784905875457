import { UserSubscription, UserSubscriptionsResponse } from "@treefort/api-spec"

import api from "./api"
import authenticator from "./authenticator"
import { canCancelPlanFromProvider } from "./subscription-plans"

export async function getUserSubscriptions() {
  const user = authenticator.getUser()
  const result = await api.get<UserSubscriptionsResponse>(
    `/users/${user?.id}/subscriptions`,
  )
  return result.data
}

export function canCancelSubscription(
  subscription: UserSubscription | undefined,
): subscription is UserSubscription {
  return (
    subscriptionWillRenew(subscription) &&
    canCancelPlanFromProvider(subscription.provider)
  )
}

export function subscriptionWillRenew(
  subscription: UserSubscription | undefined,
): subscription is UserSubscription {
  return subscription
    ? !subscription.deactivatedAt && !subscription.canceledAt
    : false
}

export function getCurrentSubscription(
  userSubscriptions: UserSubscriptionsResponse | undefined,
) {
  return userSubscriptions?.subscriptions.find(
    (subscription) =>
      subscription.id === userSubscriptions.currentSubscriptionId,
  )
}
