import { Q } from "@nozbe/watermelondb"

import { getClient } from "../../db"
import { SourceAwareModel } from "../../models/source-aware-model"

export class Finder<TModel extends SourceAwareModel> {
  private table: string

  constructor(table: string) {
    this.table = table
  }

  async find(id: string) {
    const result = await getClient()
      .get<TModel>(this.table)
      .query(Q.where("id", id))
      .fetch()

    if (result.length === 0) {
      return null
    }

    if (result.length > 1) {
      throw new Error(
        `More than one model returned for id '${id}', but we expect only one`,
      )
    }

    return result[0]
  }
}
