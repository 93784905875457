import { Highlight } from "../models/highlight"
import { BaseStore } from "./base"

class HighlightStore extends BaseStore<Highlight> {
  constructor() {
    super(Highlight)
  }
}

export const highlightStore = new HighlightStore()
