import { i18n } from "i18next"

import { Duration } from "@treefort/api-spec"
import { simplifyDuration } from "@treefort/lib/duration"

export function getTrialButtonText({
  duration,
  i18n,
}: {
  duration: Duration
  i18n: i18n
}) {
  const simplified = simplifyDuration(duration)
  const [unit, count] = Object.entries(simplified).find(
    ([, count]) => count,
  ) || ["days", 0]

  switch (unit) {
    case "years":
      return i18n.t("Start {{count}} year free trial", { count })
    case "months":
      return i18n.t("Start {{count}} month free trial", { count })
    case "weeks":
      return i18n.t("Start {{count}} week free trial", { count })
    default:
      return i18n.t("Start {{count}} day free trial", { count })
  }
}

export function getTrialBadgeText({
  duration,
  i18n,
}: {
  duration: Duration
  i18n: i18n
}) {
  const simplified = simplifyDuration(duration)
  const [unit, count] = Object.entries(simplified).find(
    ([, count]) => count,
  ) || ["days", 0]

  switch (unit) {
    case "years":
      return i18n.t("{{count}} year free trial", { count })
    case "months":
      return i18n.t("{{count}} month free trial", { count })
    case "weeks":
      return i18n.t("{{count}} week free trial", { count })
    default:
      return i18n.t("{{count}} day free trial", { count })
  }
}
