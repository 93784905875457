import { differenceInDays, isSameYear, isToday, isYesterday } from "date-fns"
import type { i18n } from "i18next"

import { DateDisplayStrategy } from "@treefort/api-spec"

export function formatDate(
  date: Date,
  options: {
    i18n: i18n
    strategy?: DateDisplayStrategy
  },
) {
  const now = new Date()
  const locale = options.i18n.language.replace("_", "-")
  switch (options.strategy) {
    case "naturalDate":
      // NOTE: We dont' use Intl.RelativeTimeFormat for today and yesterday
      // because of its lack of control over capitalization
      if (isToday(date)) {
        return options.i18n.t("Today")
      } else if (isYesterday(date)) {
        return options.i18n.t("Yesterday")
      } else if (differenceInDays(now, date) < 6) {
        return new Intl.DateTimeFormat(locale, { weekday: "long" }).format(date)
      } else if (isSameYear(date, now)) {
        return new Intl.DateTimeFormat(locale, {
          month: "long",
          day: "numeric",
        }).format(date)
      } else {
        return new Intl.DateTimeFormat(locale, {
          month: "short",
          day: "numeric",
          year: "numeric",
        }).format(date)
      }
    case "numericDate":
      return new Intl.DateTimeFormat(locale, {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      }).format(date)
    default:
      return new Intl.DateTimeFormat(locale, {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(date)
  }
}
