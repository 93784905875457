import React from "react"
import { useTranslation } from "react-i18next"

import { Result } from "@treefort/lib/result"

import { AsyncViewProvider } from "../../components/async-view"
import useAppManifest from "../../hooks/use-app-manifest"
import useContent from "../../hooks/use-content"
import { useOfflineState } from "../../hooks/use-offline-state"
import { useRoute } from "../../hooks/use-route"
import { ModuleLayout } from "../layouts/module"
import { CardListViewModule } from "../layouts/module/list-view-modules/card"
import { SliderListViewModule } from "../layouts/module/list-view-modules/slider"
import { VideoListViewModule } from "../layouts/module/list-view-modules/video"

export function ContentVideoScreen(): JSX.Element {
  const manifest = useAppManifest()
  const { params } = useRoute<"contentId">()
  const [offline] = useOfflineState()

  if (!params.contentId) {
    throw new Error(`Visited the video screen without a content id!`)
  }

  const contentId = parseInt(params.contentId)

  const videoQuery = useContent(contentId)

  const { t } = useTranslation()

  const videoModule = new VideoListViewModule({
    id: 0,
    type: "video",
    config: { contentId },
    conditions: {},
  })

  const listViewModules =
    !videoQuery.data || offline
      ? Result.success([videoModule])
      : Result.mapSuccess(videoQuery, (video) =>
          Result.success([
            videoModule,
            ...(video.links.length
              ? [
                  new CardListViewModule({
                    id: -1,
                    type: "card",
                    config: { items: video.links },
                    conditions: {},
                  }),
                ]
              : []),
            ...(manifest.features.contentPageRecommendations
              ? [
                  new SliderListViewModule(
                    {
                      id: -2,
                      type: "slider",
                      config: {
                        title: t("Recommendations"),
                        itemShape: "wide",
                        size: "medium",
                        showItemTitles: true,
                        items: {
                          type: "recombee",
                          recommendation: {
                            type: "itemsToItem",
                            contentId,
                            personalized:
                              manifest.features.contentPageRecommendations
                                .personalized,
                            scenario:
                              manifest.features.contentPageRecommendations
                                .scenario,
                          },
                        },
                      },
                      conditions: {},
                    },
                    { key: `content-page-recommendations-${contentId}` },
                  ),
                ]
              : []),
          ]),
        )

  const key = `video-page-${contentId}`

  return (
    <AsyncViewProvider key={key}>
      <ModuleLayout pageKey={key} listViewModules={listViewModules} />
    </AsyncViewProvider>
  )
}
