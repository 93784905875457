"use strict";
/**
 * An axios interceptor that pauses all requests until the provided promise is
 * resolved _or_ rejected. The onSuccess and onFailure arguments can be used to
 * modify a request's config before resuming. The interceptor removes itself
 * after the promise is complete.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const addPauseInterceptor = ({ axios, pauseUntil, onSuccess = (c) => c, onFailure = (c) => c, }) => {
    const interceptor = axios.interceptors.request.use(async (config) => {
        const eject = () => axios.interceptors.request.eject(interceptor);
        try {
            await pauseUntil;
            eject();
            return onSuccess(config);
        }
        catch (error) {
            eject();
            return onFailure(config, error);
        }
    });
    return interceptor;
};
exports.default = addPauseInterceptor;
