"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubscriptionStatusLabel = void 0;
function getSubscriptionStatusLabel(status) {
    switch (status) {
        case "canceled":
            return "Canceled";
        case "canceledWithTimeLeft":
            return "Canceled with time left";
        case "freeTrial":
            return "Free trial";
        case "paymentPastDue":
            return "Payment past due";
        case "subscribed":
            return "Subscribed";
        case "lapsed":
            return "Lapsed";
        case "none":
        default:
            return "Not subscribed";
    }
}
exports.getSubscriptionStatusLabel = getSubscriptionStatusLabel;
