import React from "react"
import { useRoute } from "../../hooks/use-route"
import { Result } from "@treefort/lib/result"
import { PodcastEpisodeListViewModule } from "../layouts/module/list-view-modules/podcast-episode"
import { ModuleLayout } from "../layouts/module"
import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"

export function ContentPodcastEpisodeScreen() {
  const {
    params: { contentId, episodeNumber },
  } = useRoute<"contentId" | "episodeNumber">()

  if (!contentId) {
    throw new Error(
      "Visited the podcast episode detail screen without a content id!",
    )
  }

  if (!episodeNumber) {
    throw new Error(
      "Visited the podcast episode detail screen without an episode number!",
    )
  }

  const listViewModules = Result.success([
    new PodcastEpisodeListViewModule({
      id: 0,
      type: "podcastEpisode",
      config: {
        podcastId: parseInt(contentId),
        episodeNumber: parseInt(episodeNumber),
      },
      conditions: {},
    }),
  ])

  const key = `podcast-episode-page-${contentId}-${episodeNumber}`

  return (
    <AsyncViewOfflineProvider key={key}>
      <ModuleLayout pageKey={key} listViewModules={listViewModules} />
    </AsyncViewOfflineProvider>
  )
}
