import React, { useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import icons from "@treefort/tokens/app/icons"

import useHover from "../../hooks/use-hover"
import Icon from "../icon"
import ListView, { ListViewRef } from "../list-view"
import { useTokens } from "../tokens-provider"
import Touchable from "../touchable"
import { HorizontalListViewWithArrowsProps } from "./types"

// Unlocks a scroll view during body scroll locking on the web
const WEB_SCROLL_UNLOCK = { webScrollUnlock: true }

const ListContainer = styled.View`
  position: relative;
`

const ArrowButtonContainer = styled.View<{
  alignment: "left" | "right"
  height: number
  show: boolean
}>`
  position: absolute;
  opacity: ${(props) => (props.show ? 1 : 0)};
  height: 100%;
  z-index: 1;
  width: ${(props) =>
    props.theme.horizontalListViewWithArrows.arrowButton.width}px;
  top: 0;
  ${(props) => `${props.alignment}: 0`};
  height: ${(props) =>
    typeof props.height === "number" ? `${props.height}px` : "100%"};
  background-color: ${(props) =>
    props.theme.horizontalListViewWithArrows.arrowButton.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 150ms ease;
`

const ArrowButton = styled(Touchable)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function HorizontalListViewWithArrowsDesktop<T>({
  arrowButtonHeight,
  ...listViewProps
}: HorizontalListViewWithArrowsProps<T>): JSX.Element {
  const [visibleIndices, setVisibleIndices] = useState<number[]>([])
  const isScrolledToStart = visibleIndices[0] === -1
  const isScrolledToEnd = visibleIndices[visibleIndices.length - 1] === -1
  const [isHovering, hoverProps] = useHover()
  const { tokens } = useTokens()
  const listView = useRef<ListViewRef<T>>(null)
  const {
    paddingStart = 0,
    paddingEnd = 0,
    getGapSize = () => 0,
    viewSize,
    items,
  } = listViewProps
  const itemCount = listViewProps.items.length

  const scrollLeft = useCallback(() => {
    const index = Math.max(0, visibleIndices[0] || 0)
    listView.current?.scrollToOffset(
      listView.current.getIndexOffset(index) +
        listView.current.getIndexSize(index) +
        paddingStart +
        getGapSize(items[index - 1], items[index], index - 1, index) -
        viewSize.width,
    )
  }, [items, visibleIndices, paddingStart, getGapSize, viewSize.width])

  const scrollRight = useCallback(() => {
    const index = Math.min(
      visibleIndices[visibleIndices.length - 1] || 0,
      itemCount - 1,
    )
    listView.current?.scrollToIndex(
      index,
      -paddingEnd -
        getGapSize(items[index], items[index + 1], index, index + 1),
    )
  }, [items, visibleIndices, paddingEnd, getGapSize, itemCount])

  const { t } = useTranslation()

  return (
    <ListContainer {...hoverProps}>
      {visibleIndices.length ? (
        <ArrowButtonContainer
          alignment="left"
          height={arrowButtonHeight}
          show={isHovering && !isScrolledToStart}
        >
          <ArrowButton aria-label={t("Scroll left")} onPress={scrollLeft}>
            <Icon
              color={tokens.horizontalListViewWithArrows.arrowButton.iconColor}
              source={icons.chevronLeft}
            />
          </ArrowButton>
        </ArrowButtonContainer>
      ) : null}
      <ListView
        {...listViewProps}
        ref={listView}
        onVisibleIndicesChange={setVisibleIndices}
        horizontal
        showsHorizontalScrollIndicator={false}
        scrollBehavior="smooth"
        scrollViewDataSet={WEB_SCROLL_UNLOCK}
      />
      {visibleIndices.length ? (
        <ArrowButtonContainer
          alignment="right"
          height={arrowButtonHeight}
          show={isHovering && !isScrolledToEnd}
        >
          <ArrowButton aria-label={t("Scroll right")} onPress={scrollRight}>
            <Icon
              color={tokens.horizontalListViewWithArrows.arrowButton.iconColor}
              source={icons.chevronRight}
            />
          </ArrowButton>
        </ArrowButtonContainer>
      ) : null}
    </ListContainer>
  )
}
