import { initializeApp, getApps, FirebaseApp, getApp } from "firebase/app"

import config from "../../config"

/**
 * Initialize firebase if it hasn't been already and return the default app.
 * This is helpful because we have several modules that need firebase ASAP and
 * we don't want to have to coordinate initialization between them. Will return
 * undefined if Firebase is not configured
 */
export function getFirebaseApp(): FirebaseApp | undefined {
  if (!config.FIREBASE_CONFIG) {
    return undefined
  } else if (getApps().length === 0) {
    const firebaseConfig = JSON.parse(atob(config.FIREBASE_CONFIG))
    return initializeApp(firebaseConfig)
  } else {
    return getApp()
  }
}
