import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { UseQueryResult } from "react-query"

import { isAxiosError } from "@treefort/lib/is-axios-error"
import { Result } from "@treefort/lib/result"

import { AsyncViewProps, AsyncViewState } from "../components/async-view"
import { queryClient } from "../lib/query-client"

type Queries = UseQueryResult | UseQueryResult[] | Result | Result[]

type Options = {
  // Set this to true to ignore errors, moving from "loading" to "success"
  // regardless of whether queries actually succeeded (defaults to false)
  ignoreErrors?: boolean
  // Set this to true to show a loading state when queries are refetching
  // (defaults to true)
  disableBackgroundRefetch?: boolean
  // Set this to true to force the "loading" state even if all queries succeed.
  // This is helpful when the view state depends on more than just queries. If a
  // query fails this option will be ignored and the "error" state will be
  // returned.
  forceLoading?: boolean
}

/**
 * This utility returns the best state for an asyncronous view that loads data
 * using react-query. This utility handles a couple behavioral nuances that are
 * tricky to get right by checking queries directly:
 * - avoiding loading states for queries that are disabled
 * - waiting for non-query loading states to resolve
 * - determining whether to show a loading state when queries are refetching
 *
 * Note that a "success" state does not necessarily mean that all queries have
 * succeeded - "success" may be returned if some (or all) queries are disabled.
 * This function does not act as a type-guard for queries - it's important that
 * the UI logic still check the isSuccess flag of each query before attempting
 * to access its results.
 */
const getAsyncViewStateForQueries = (
  queries: Queries,
  { disableBackgroundRefetch, ignoreErrors, forceLoading }: Options = {},
): AsyncViewState => {
  const queriesArray = Array.isArray(queries) ? queries : [queries]
  const isError = !ignoreErrors && queriesArray.some((query) => query.isError)
  const isLoading =
    forceLoading ||
    queriesArray.some(
      (query) =>
        query.isLoading || (disableBackgroundRefetch && query.isRefetching),
    )
  if (isError) {
    return "error"
  } else if (isLoading) {
    return "loading"
  } else {
    return "success"
  }
}

/**
 * This hook generates async view props based on the state of a query or list of
 * queries.
 */
export function useAsyncViewPropsForQueries(
  queries: Queries,
  options?: Options,
): AsyncViewProps {
  const { t } = useTranslation()
  const state = getAsyncViewStateForQueries(queries, options)
  const isNotFoundError =
    state === "error" &&
    (Array.isArray(queries) ? queries : [queries]).some(
      (query) =>
        isAxiosError(query.error) && query.error.response?.status === 404,
    )
  const props = useMemo<AsyncViewProps>(
    () =>
      isNotFoundError
        ? {
            state,
            title: t("Not Found"),
            message: t("Sorry, that page doesn't exist!"),
          }
        : state === "error"
          ? {
              state,
              message: t("An error occurred. Please try again."),
              secondaryAction: {
                label: "Refresh",
                onPress: () => queryClient.refetchQueries({ active: true }),
              },
            }
          : { state },
    [state, isNotFoundError, t],
  )

  return props
}
