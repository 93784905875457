import { useEffect } from "react"

import { Event } from "@treefort/lib/authenticator"

import authenticator from "../lib/authenticator"
import {
  handleSubscriptionResponseForCheckout,
  handleCompletedAuthActionForCheckout,
} from "../lib/checkout"
import useUserInfo from "./use-user-info"

/**
 * Set up the listeners to handle completed checkout sessions
 */
export const useInitializeCheckout = () => {
  const userInfo = useUserInfo()

  useEffect(() => {
    if (userInfo.data?.subscription) {
      handleSubscriptionResponseForCheckout(userInfo.data?.subscription)
    }
  }, [userInfo.data?.subscription])

  useEffect(() => {
    return authenticator.on(
      Event.ActionComplete,
      handleCompletedAuthActionForCheckout,
    )
  }, [])
}
