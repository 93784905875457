"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.joinContributorNames = void 0;
/**
 * Generate a single CSV string of names from a list of contributors.
 */
function joinContributorNames(contributors, role) {
    return ((contributors === null || contributors === void 0 ? void 0 : contributors.flatMap((contributor) => contributor.role === role ? contributor.name : []).join(", ")) || undefined);
}
exports.joinContributorNames = joinContributorNames;
