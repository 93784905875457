import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useImperativeHandle, useMemo, useRef } from "react";
import isObject from "@treefort/lib/is-object";
import { config } from "./config";
function EpubViewerWithRef({ app, style, onEvent }, ref) {
    const iframeRef = useRef(null);
    const viewerStyle = useMemo(() => (Object.assign({ border: "none" }, style)), [style]);
    // Add a method to the ref to trigger actions in the iframe
    useImperativeHandle(ref, () => ({
        triggerAction: (action) => {
            var _a, _b;
            (_b = (_a = iframeRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage({ type: "action", action }, "*");
        },
    }), []);
    // Listen for messages from the iframe
    useEffect(() => {
        const listener = ({ source, data }) => {
            var _a;
            if (source === ((_a = iframeRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow) &&
                isObject(data) &&
                "type" in data &&
                data.type === "event") {
                onEvent(data.event);
            }
        };
        window.addEventListener("message", listener);
        return () => {
            window.removeEventListener("message", listener);
        };
    }, [onEvent]);
    return (_jsx("iframe", { ref: iframeRef, src: 
        // The PROXY_ORIGIN config can be used to load the epub viewer via
        // a proxy with a different origin to prevent scripts in epubs from
        // having same-origin privileges.
        config.PROXY_ORIGIN
            ? `${config.PROXY_ORIGIN}/${encodeURIComponent(window.location.origin + app)}`
            : app, style: viewerStyle, sandbox: "allow-same-origin allow-scripts" }));
}
const EpubViewer = React.forwardRef(EpubViewerWithRef);
export default EpubViewer;
export * from "../types";
export * from "../utils";
export const EPUB_VIEWER_PROXY_ORIGIN = config.PROXY_ORIGIN;
