import React from "react"
import { useTranslation } from "react-i18next"

import { ResolvedTokens } from "@treefort/tokens/app"

import { stringKeyLookup } from "../../lib/i18n/string-key-lookup"
import CenteredContent from "../centered-content"
import { Heading } from "../heading"
import Spacer from "../spacer"
import Text from "../text"
import { Tokens } from "../tokens-provider"
import { LibraryTab } from "./tabs"

export function getLibraryEmptyMessageHeight(
  tokens: ResolvedTokens<Tokens>,
): number {
  return tokens.library.emptyMessageHeight
}

export function LibraryEmptyMessage({
  tab,
  height,
}: {
  tab: LibraryTab
  height: number
}) {
  const { t } = useTranslation()
  // We don't show the purchased tab if the user doesn't have any entitlements
  return tab === "purchased" ? null : (
    <CenteredContent height={height}>
      <Heading level={1} textStyle="headingMedium">
        {t("Nothing here... yet!")}
      </Heading>
      <Spacer size="medium" />
      <Text textStyle="body">
        {t(stringKeyLookup.libraryTabEmptyMessages[tab])}
      </Text>
    </CenteredContent>
  )
}
