import React, { useEffect } from "react"

import queryString from "query-string"

import authenticator from "../../../../lib/authenticator"
import { SplashScreen } from "../../splash"

/**
 * This allows deep linking to the login page. If a "hint" query parameter is
 * passed then it’ll be forwarded on to the auth service to prefill the login
 * email field.
 */
export default function AuthLoginScreen(): JSX.Element | null {
  useEffect(() => {
    const { hint } = queryString.parse(window.location.search)
    authenticator.login({
      query: { login_hint: typeof hint === "string" ? hint : undefined },
    })
  }, [])

  return <SplashScreen />
}
