import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import { StripeProrationAvailableResponse } from "@treefort/api-spec"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { getCurrencyDivisor } from "@treefort/lib/money"
import { formatMoney } from "@treefort/lib/money"

import { useCheckoutSession } from "../hooks/use-checkout-session"
import { logError } from "../lib/logging"
import { Button } from "./button"
import ExpandableInfo from "./expandable-info"
import Modal from "./modal"
import Spacer from "./spacer"
import Text from "./text"

const ContentContainer = styled.View`
  padding: ${({ theme }) => theme.spacing.medium}px;
`

const TitleContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`

const HorizontalLine = styled.View`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.primary};
`

const LineItemPaddingContainer = styled.View`
  padding-left: ${({ theme }) => theme.icon.size.small}px;
  padding-right: ${({ theme }) => theme.spacing.xsmall}px;
`

const LineItemContainer = styled.View`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

function LineItem({
  title,
  currency,
  amount,
}: {
  title: string
  currency: string
  amount: number
}) {
  const { i18n } = useTranslation()
  return (
    <LineItemContainer>
      {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
      <Text textStyle="caption" color="secondary">
        {title}:
      </Text>
      <Text textStyle="caption" color="secondary">
        {formatMoney(
          {
            currency,
            amount,
            divisor: getCurrencyDivisor(currency),
          },
          i18n.language,
        )}
      </Text>
    </LineItemContainer>
  )
}

/**
 * NOTE: the modal won't open if the preview prop isn't provided
 */
export default function StripeProrationPreviewModal({
  open,
  onClose,
  title,
  action,
  preview,
}: {
  open: boolean
  onClose: () => void
  title?: string
  action?: () => Promise<void>
  preview?: StripeProrationAvailableResponse
}): JSX.Element {
  const checkoutSession = useCheckoutSession()
  const [isCheckingOut, setIsCheckingOut] = useState(false)
  const isLoading =
    checkoutSession?.type === "paidPlanWithProrationPreview" &&
    checkoutSession.plan.id === preview?.subscriptionPlanId
  const totalDiscount = preview?.totalDiscountAmounts.reduce(
    (sum, discount) => sum + discount.amount,
    0,
  )
  const { t, i18n } = useTranslation()

  // If something goes wrong it can be helpful to allow the user to press the
  // subscribe button again when they come back to the app, even if we have an
  // in progress checkout session, but we also want to gaurd against the user
  // double pressing the subscribe button
  useEffect(() => {
    if (!isLoading) {
      setIsCheckingOut(false)
    }
  }, [isLoading])

  return (
    <Modal
      type="sheet"
      open={Boolean(open && preview)}
      onPressOutside={onClose}
      onPressCloseButton={onClose}
      title={title}
    >
      <ContentContainer>
        {preview ? (
          <>
            <Spacer size="small" />
            <TitleContainer>
              <Text textStyle="headingLarge">
                {t("Due Now: {{amount}}", {
                  amount: formatMoney(
                    {
                      currency: preview.currency,
                      amount: preview.amountDue,
                      divisor: getCurrencyDivisor(preview.currency),
                    },
                    i18n.language,
                  ),
                })}
              </Text>
            </TitleContainer>
            <Spacer size="small" />
            <ExpandableInfo
              headerContent={
                <>
                  <LineItem
                    currency={preview.currency}
                    title={t("Subtotal")}
                    amount={preview.subtotal}
                  />
                  <Spacer horizontal size="xsmall" />
                </>
              }
            >
              {preview.lineItems.map((item, i) => (
                <React.Fragment key={item.description}>
                  {i === 0 ? null : <Spacer size="tiny" />}
                  <LineItem
                    currency={preview.currency}
                    title={item.description}
                    amount={item.amount}
                  />
                </React.Fragment>
              ))}
            </ExpandableInfo>
            <LineItemPaddingContainer>
              <Spacer size="tiny" />
              <LineItem
                currency={preview.currency}
                title={t("Tax")}
                amount={preview.tax}
              />
              <Spacer size="tiny" />
              {totalDiscount ? (
                <>
                  <LineItem
                    currency={preview.currency}
                    amount={-totalDiscount}
                    title={t("Discount")}
                  />
                  <Spacer size="tiny" />
                </>
              ) : null}
              {preview.startingBalance ? (
                <>
                  <LineItem
                    currency={preview.currency}
                    amount={preview.startingBalance}
                    title={t("Balance")}
                  />
                  <Spacer size="tiny" />
                </>
              ) : null}
            </LineItemPaddingContainer>
            <HorizontalLine />
            <LineItemPaddingContainer>
              <Spacer size="tiny" />
              <LineItem
                currency={preview.currency}
                amount={
                  preview.endingBalance < 0
                    ? preview.endingBalance
                    : preview.amountDue
                }
                title={t("Total")}
              />
              <Spacer size="medium" />
            </LineItemPaddingContainer>
            <Button
              type="primary"
              loading={isLoading}
              onPress={async () => {
                if (!isCheckingOut) {
                  setIsCheckingOut(true)
                  try {
                    action?.()
                  } catch (e) {
                    logError(
                      new DisplayableError(
                        t("An error occurred. Please try again."),
                        e,
                      ),
                    )
                  }
                  setIsCheckingOut(false)
                }
              }}
            >
              {t("Subscribe")}
            </Button>
          </>
        ) : null}
      </ContentContainer>
    </Modal>
  )
}
