import { useContext } from "react"
import {
  AppPreviewData,
  AppPreviewContext,
} from "../components/app-preview-provider/base"

/**
 * Access app preview state and controls.
 */
const useAppPreview = (): AppPreviewData => useContext(AppPreviewContext)

export default useAppPreview
