import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import icons from "@treefort/tokens/app/icons"

import {
  getAbsoluteLineHeight,
  getTextStyleString,
  TextStyle,
} from "../lib/text-style"
import Icon from "./icon"
import Spacer from "./spacer"
import Text from "./text"
import TextInput from "./text-input"
import { useTokens } from "./tokens-provider"

export type InputState =
  | { type: "idle" }
  | { type: "loading" }
  | { type: "success"; message?: string }
  | { type: "error"; message: string }

const Container = styled.View<{
  layout: "wide" | "narrow"
  // type: InputState["type"]
  hasMessage: boolean
}>`
  max-width: ${({ theme, layout }) => theme.promoCodeInput.maxWidth[layout]}px;
  margin-bottom: ${({ theme, hasMessage, layout }) =>
    hasMessage
      ? layout === "wide"
        ? -(getAbsoluteLineHeight("caption", theme) + theme.spacing.tiny)
        : -theme.spacing.tiny
      : 0}px;
  width: 100%;
`

const BorderContainer = styled.View<{
  layout: "wide" | "narrow"
  type: InputState["type"]
}>`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.promoCodeInput.height}px;
  border-radius: ${({ theme }) => theme.borderRadius.roundedMedium}px;
  border: ${({ theme }) => theme.spacing.xtiny}px solid
    ${({ theme, type }) =>
      theme.promoCodeInput.borderColor[
        type === "success" ? "success" : "default"
      ]};
  background-color: ${({ theme, type }) =>
    theme.promoCodeInput.backgroundColor[
      type === "success" ? "success" : "default"
    ]};
`

const StyledTextInput = styled(TextInput)<{
  textStyle: TextStyle
  type: InputState["type"]
}>`
  height: 100%;
  padding-left: ${({ theme }) => theme.promoCodeInput.paddingLeft}px;
  padding-right: ${({ theme }) => theme.promoCodeInput.paddingRight}px;
  ${({ theme, textStyle }) => getTextStyleString(textStyle, theme)};
  color: ${({ theme, type }) =>
    type === "success"
      ? theme.colors.text.success
      : theme.promoCodeInput.textColor.default};
`

const TagIconContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  padding-left: ${({ theme }) =>
    theme.promoCodeInput.tagIconContainer.paddingLeft}px;
`

export function PromoCodeInput({
  layout,
  state,
  value,
  onChange,
}: {
  layout: "wide" | "narrow"
  state: InputState
  value: string
  onChange: (value: string) => void
}) {
  const { tokens } = useTokens()
  const { t } = useTranslation()

  const hasMessage =
    state.type !== "idle" &&
    (state.type === "loading" || Boolean(state.message))

  return (
    <Container layout={layout} hasMessage={hasMessage}>
      <BorderContainer layout={layout} type={state.type}>
        <TagIconContainer>
          <Icon
            source={icons.priceTag}
            size="mediumsmall"
            color={
              state.type === "success"
                ? tokens.colors.success
                : tokens.promoCodeInput.placeholderTextColor
            }
          />
        </TagIconContainer>
        <StyledTextInput
          value={value}
          onChangeText={onChange}
          type={state.type}
          textStyle={value.length ? "captionStrong" : "caption"}
          placeholderTextColor={tokens.promoCodeInput.placeholderTextColor}
          placeholder={t("Enter Promo Code or Group Code")}
        />
      </BorderContainer>
      {hasMessage ? <Spacer size="tiny" /> : null}
      {state.type === "success" ? (
        <Text textStyle="caption" color="success">
          {state.message}
        </Text>
      ) : state.type === "error" ? (
        <Text textStyle="caption" color="danger">
          {state.message}
        </Text>
      ) : state.type === "loading" ? (
        <Text textStyle="caption" color="secondary">
          {t("Verifying...")}
        </Text>
      ) : null}
    </Container>
  )
}
