import { i18n } from "i18next"

import confirm from "../confirm"
import openUrl from "../open-url"

/**
 * Get a URL pointing to instructions on how to enable notifications for the
 * given browser.
 */
async function getBrowserInstructionsUrl(): Promise<string | null> {
  // Lazy-load the ua-parser-js package which is heavy hardly ever needed.
  const { default: parseUserAgent } = await import("ua-parser-js")
  const userAgent = parseUserAgent()
  const browser = userAgent.browser.name || ""
  if (/chrome|brave/i.test(browser)) {
    return "https://support.google.com/chrome/answer/3220216"
  } else if (/firefox/i.test(browser)) {
    return "https://support.mozilla.org/en-US/kb/push-notifications-firefox#w_upgraded-notifications"
  } else if (/edge/i.test(browser)) {
    return "https://techswift.org/2021/03/26/how-to-manage-notifications-in-microsoft-edge/"
  } else {
    return null
  }
}

/**
 * Open notification settings
 */
export async function openNotificationSettings(t: i18n["t"]) {
  const browserInstructionsUrl = await getBrowserInstructionsUrl()
  if (
    (await confirm({
      title: t("Permission required"),
      message: t(
        "Permission is required to receive push notifications. You can grant permission via your browser settings.",
      ),
      confirmLabel: t("Ok"),
      cancelLabel: t("Cancel"),
    })) &&
    browserInstructionsUrl
  ) {
    openUrl(browserInstructionsUrl, {
      openInApp: false,
      parentalGateway: false,
    })
  }
}
