import React from "react"

import styled from "styled-components/native"

import { AppLink, AppLinkProps } from "./app-link"
import Icon, { IconProps } from "./icon"
import Text, { TextColor } from "./text"
import Touchable from "./touchable"

const TabTouchable = styled(Touchable)`
  min-height: ${(props) => props.theme.minTapTarget}px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const TabAppLink = styled(AppLink)`
  min-height: ${(props) => props.theme.minTapTarget}px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const TabContent = styled.View`
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing.xsmall}px;
`

const TabUnderline = styled.View<{ withIcon: boolean }>`
  position: absolute;
  left: ${(props) =>
    props.theme.appHeader.desktopTabUnderline.offsetHorizontal +
    // Add 4 to account for the padding baked into a small icon
    (props.withIcon ? 4 : 0)}px;
  right: ${(props) =>
    props.theme.appHeader.desktopTabUnderline.offsetHorizontal}px;
  bottom: -${(props) => props.theme.appHeader.desktopTabUnderline.height}px;
  height: ${(props) => props.theme.appHeader.desktopTabUnderline.height}px;
  background-color: ${(props) =>
    props.theme.appHeader.desktopTabUnderline.color};
`

export function DesktopHeaderTab({
  to,
  onPress,
  color,
  active,
  icon,
  children,
}: {
  onPress?: () => void
  to?: AppLinkProps["to"]
  color?: TextColor
  active?: boolean
  icon?: IconProps["source"]
  children: string
}): JSX.Element {
  const content = (
    <TabContent>
      {icon ? <Icon size="small" source={icon} color={color} /> : null}
      <Text textStyle={active ? "strong" : "body"} color={color}>
        {children}
      </Text>
      {active ? <TabUnderline withIcon={Boolean(icon)} /> : null}
    </TabContent>
  )
  return to ? (
    <TabAppLink to={to} aria-label={children}>
      {content}
    </TabAppLink>
  ) : (
    <TabTouchable onPress={onPress} aria-label={children}>
      {content}
    </TabTouchable>
  )
}
