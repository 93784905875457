module.exports = {
  accountCircle: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M51.583 97C26.96 97 7 76.853 7 52 7 27.146 26.96 7 51.583 7c24.624 0 44.584 20.146 44.584 45 0 24.853-19.96 45-44.584 45ZM29.35 80.152A35.309 35.309 0 0 0 51.583 88c8.783 0 16.822-3.204 23.037-8.514a31.105 31.105 0 0 0-10.21-7.025A30.866 30.866 0 0 0 52.297 70a30.876 30.876 0 0 0-12.53 2.642 31.132 31.132 0 0 0-10.417 7.51Zm-6.23-6.462a40.015 40.015 0 0 1 13.287-9.393A39.695 39.695 0 0 1 52.297 61a39.678 39.678 0 0 1 15.353 3.07 39.979 39.979 0 0 1 13.01 8.783 36.213 36.213 0 0 0 6.512-18.441 36.282 36.282 0 0 0-4.013-19.154 35.86 35.86 0 0 0-13.35-14.203 35.414 35.414 0 0 0-18.74-5.05 35.429 35.429 0 0 0-18.587 5.596 35.888 35.888 0 0 0-12.938 14.587 36.292 36.292 0 0 0-3.461 19.263 36.18 36.18 0 0 0 7.038 18.239ZM51.583 56.5a17.75 17.75 0 0 1-12.61-5.272A18.086 18.086 0 0 1 33.75 38.5c0-4.774 1.879-9.352 5.223-12.728a17.75 17.75 0 0 1 12.61-5.272 17.75 17.75 0 0 1 12.61 5.272A18.086 18.086 0 0 1 69.417 38.5c0 4.774-1.88 9.352-5.224 12.728a17.75 17.75 0 0 1-12.61 5.272Zm0-9a8.875 8.875 0 0 0 6.305-2.636A9.043 9.043 0 0 0 60.5 38.5c0-2.387-.94-4.676-2.612-6.364a8.875 8.875 0 0 0-6.305-2.636 8.875 8.875 0 0 0-6.305 2.636 9.043 9.043 0 0 0-2.611 6.364c0 2.387.94 4.676 2.611 6.364a8.875 8.875 0 0 0 6.305 2.636Z'/%3e%3c/svg%3e",
  alert: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='m55.753 13 41.28 71.5A4.336 4.336 0 0 1 93.28 91H10.72a4.337 4.337 0 0 1-4.332-4.333c0-.761.2-1.508.58-2.167L48.248 13a4.33 4.33 0 0 1 5.919-1.586c.659.38 1.206.927 1.586 1.586Zm-8.086 56.333V78h8.667v-8.667h-8.667Zm0-30.333v21.667h8.667V39h-8.667Z'/%3e%3c/svg%3e",
  back10: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 105'%3e%3cpath fill='black' d='M82.295 22.012c16.923 16.923 16.923 44.36 0 61.282-16.924 16.923-44.36 16.923-61.283 0-16.923-16.923-16.923-44.36 0-61.282l6.128 6.128c-13.537 13.537-13.537 35.489 0 49.026 13.538 13.537 35.489 13.537 49.026 0 13.538-13.537 13.538-35.489 0-49.026-7.55-7.55-17.716-10.89-27.577-10.02v8.999L30.204 14.35 48.59.563l.003 8.864c12.082-.848 24.461 3.343 33.703 12.585Z'/%3e%3cpath fill='black' d='M61.254 66c6.303.012 10.066-4.782 10.066-13.043C71.32 44.745 67.531 40 61.254 40c-6.278 0-10.055 4.733-10.067 12.957C51.187 61.206 54.95 66 61.254 66Zm0-3.848c-3.254 0-5.295-3.147-5.283-9.195.013-6 2.042-9.158 5.283-9.158 3.253 0 5.282 3.159 5.295 9.158 0 6.048-2.03 9.195-5.296 9.195ZM44.883 40h-4.325l-6.238 4.095v4.259l5.87-3.833h.147V65.65h4.546V40Z'/%3e%3c/svg%3e",
  back15: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 105'%3e%3cpath fill='black' d='M82.294 22.012c16.923 16.923 16.923 44.36 0 61.282-16.923 16.923-44.36 16.923-61.282 0-16.924-16.923-16.924-44.36 0-61.282l6.128 6.128c-13.537 13.537-13.537 35.489 0 49.026 13.537 13.537 35.489 13.537 49.026 0 13.537-13.537 13.537-35.489 0-49.026-7.55-7.55-17.717-10.89-27.577-10.02v8.999L30.204 14.35 48.59.563l.003 8.864c12.082-.848 24.46 3.343 33.702 12.585Z'/%3e%3cpath fill='black' d='M44.883 39.037h-4.326l-6.237 4.095v4.259l5.87-3.833h.147v21.128h4.546V39.037Zm15.097 26c5.306 0 9.007-3.682 9.007-8.78 0-4.934-3.358-8.49-7.916-8.49-2.034 0-3.836.826-4.767 1.953h-.147l.723-6.8h10.796v-3.883H53.118l-1.311 13.225 4.105.69c.845-.953 2.438-1.591 3.946-1.579 2.757.013 4.742 2.092 4.73 4.997.012 2.868-1.924 4.91-4.608 4.91-2.267 0-4.093-1.466-4.252-3.57h-4.412c.123 4.259 3.762 7.327 8.664 7.327Z'/%3e%3c/svg%3e",
  back30: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M82.64 21.975c16.924 16.923 16.924 44.36 0 61.282-16.923 16.923-44.36 16.923-61.282 0-16.923-16.923-16.923-44.36 0-61.282l6.128 6.128c-13.537 13.537-13.537 35.489 0 49.026 13.537 13.537 35.489 13.537 49.026 0 13.537-13.537 13.537-35.489 0-49.026-7.55-7.55-17.717-10.89-27.577-10.02v8.999L30.55 14.314 48.935.526l.003 8.864c12.082-.848 24.46 3.343 33.702 12.585Z'/%3e%3cpath fill='black' d='M39.916 64.865c5.588 0 9.658-3.086 9.645-7.34.013-3.146-2.028-5.408-5.69-5.912v-.197c2.832-.59 4.733-2.618 4.72-5.446.014-3.835-3.38-6.97-8.599-6.97-5.078 0-9.008 2.913-9.11 7.13h4.555c.077-2.114 2.118-3.43 4.53-3.43 2.437 0 4.057 1.426 4.044 3.54.013 2.201-1.875 3.664-4.58 3.664h-2.31v3.516h2.31c3.304 0 5.27 1.598 5.256 3.872.013 2.225-1.99 3.75-4.784 3.75-2.629 0-4.657-1.316-4.772-3.369h-4.798c.128 4.254 4.07 7.192 9.583 7.192ZM63.6 65c6.303.012 10.067-4.782 10.067-13.043C73.667 43.745 69.877 39 63.6 39c-6.278 0-10.055 4.733-10.067 12.957C53.533 60.206 57.297 65 63.6 65Zm0-3.848c-3.254 0-5.295-3.147-5.283-9.195.013-6 2.042-9.158 5.283-9.158 3.253 0 5.282 3.159 5.295 9.158 0 6.048-2.029 9.195-5.295 9.195Z'/%3e%3c/svg%3e",
  bookmark: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M21.667 8.667h60.666A4.334 4.334 0 0 1 86.667 13v82.953a2.165 2.165 0 0 1-3.32 1.837L52 78.13 20.653 97.786a2.168 2.168 0 0 1-3.32-1.833V13a4.333 4.333 0 0 1 4.334-4.333ZM78 17.333H26v66.872l26-16.297 26 16.297V17.333Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  checkCircle: {fill: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M52 95.5C27.975 95.5 8.5 76.025 8.5 52S27.975 8.5 52 8.5 95.5 27.975 95.5 52 76.025 95.5 52 95.5Zm-4.337-26.1 30.755-30.759-6.151-6.15-24.604 24.607-12.306-12.306-6.151 6.15L47.663 69.4Z'/%3e%3c/svg%3e", outline: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M52 95.5C27.975 95.5 8.5 76.025 8.5 52S27.975 8.5 52 8.5 95.5 27.975 95.5 52 76.025 95.5 52 95.5Zm0-8.7a34.8 34.8 0 1 0 0-69.6 34.8 34.8 0 0 0 0 69.6Z'/%3e%3cpath fill='black' d='m47.663 69.4 30.755-30.759-6.151-6.15-24.604 24.607-12.306-12.306-6.15 6.15L47.662 69.4Z'/%3e%3c/svg%3e"},
  checkboxBlankCircle: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M52 95.333c-23.933 0-43.334-19.4-43.334-43.333S28.066 8.667 52 8.667c23.933 0 43.333 19.4 43.333 43.333S75.933 95.333 52 95.333Zm0-8.666a34.667 34.667 0 1 0 0-69.334 34.667 34.667 0 0 0 0 69.334Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  chevronDown: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M52 61.16 88.79 26l6.543 6.254L52 73.666 8.667 32.254 15.21 26 52 61.16Z'/%3e%3c/svg%3e",
  chevronLeft: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M42.84 51.738 78 88.872l-6.254 6.605-41.413-43.739L71.746 8 78 14.605 42.84 51.738Z'/%3e%3c/svg%3e",
  chevronRight: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M61.16 52 26 15.21l6.254-6.543L73.666 52 32.254 95.333 26 88.79 61.16 52Z'/%3e%3c/svg%3e",
  chevronShallowLeft: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath stroke='black' stroke-width='9' d='M61 2 43 52l18 50'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M104 0H0v104h104z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  chevronShallowRight: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath stroke='black' stroke-width='9' d='m43 2 18 50-18 50'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  closeCircle: {fill: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M52 95.333c-23.933 0-43.334-19.4-43.334-43.333S28.066 8.667 52 8.667c23.933 0 43.333 19.4 43.333 43.333S75.933 95.333 52 95.333Zm0-49.46-12.255-12.26-6.131 6.132L45.873 52l-12.26 12.255 6.132 6.131L52 58.127l12.254 12.26 6.132-6.132L58.127 52l12.26-12.255-6.133-6.131L52 45.873Z'/%3e%3c/svg%3e", outline: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' fill-rule='evenodd' d='M95.5 52C95.5 27.988 76.012 8.5 52 8.5S8.5 27.988 8.5 52 27.988 95.5 52 95.5 95.5 76.012 95.5 52Zm-8.7 0c0 19.227-15.573 34.8-34.8 34.8-19.227 0-34.8-15.573-34.8-34.8 0-19.227 15.573-34.8 34.8-34.8 19.227 0 34.8 15.573 34.8 34.8ZM52 45.85 39.698 33.542l-6.155 6.155L45.849 52 33.543 64.302l6.155 6.155L52 58.151l12.302 12.306 6.155-6.155L58.151 52l12.306-12.302-6.155-6.155L52 45.849Z' clip-rule='evenodd'/%3e%3c/svg%3e"},
  downloadCircle: {fill: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' fill-rule='evenodd' d='M95.5 52C95.5 27.988 76.012 8.5 52 8.5S8.5 27.988 8.5 52 27.988 95.5 52 95.5 95.5 76.012 95.5 52ZM52 69.4 69.4 52H56.35V34.6h-8.7V52H34.6L52 69.4Z' clip-rule='evenodd'/%3e%3c/svg%3e", outline: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M52 8.5c24.012 0 43.5 19.488 43.5 43.5S76.012 95.5 52 95.5 8.5 76.012 8.5 52 27.988 8.5 52 8.5Zm0 78.3c19.227 0 34.8-15.573 34.8-34.8 0-19.227-15.573-34.8-34.8-34.8-19.227 0-34.8 15.573-34.8 34.8 0 19.227 15.573 34.8 34.8 34.8ZM56.35 52H69.4L52 69.4 34.6 52h13.05V34.6h8.7V52Z'/%3e%3c/svg%3e"},
  downloadStop: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M38.286 36h27.428A2.285 2.285 0 0 1 68 38.286v27.428A2.285 2.285 0 0 1 65.714 68H38.286A2.285 2.285 0 0 1 36 65.714V38.286A2.285 2.285 0 0 1 38.286 36Z'/%3e%3c/svg%3e",
  externalLink: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M43.333 13v8.667H21.667v60.666h60.666V60.667H91v26A4.333 4.333 0 0 1 86.667 91H17.333A4.333 4.333 0 0 1 13 86.667V17.333A4.333 4.333 0 0 1 17.333 13h26Zm32.873 8.667H56.333V13H91v34.667h-8.667V27.794L52 58.127 45.873 52l30.333-30.333Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  fontSizeDecrease: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg fill='black' clip-path='url(%23a)'%3e%3cpath stroke='black' d='m24.537 76.533-.282.69h7.207l.127-.31 5.035-12.288h18.752l5.035 12.287.127.31h7.207l-.282-.689L49.139 31.81l-.128-.31h-6.023l-.127.31-18.324 44.723Zm28.107-18.577H39.356L46 41.74l6.644 16.216Z'/%3e%3cpath d='M73 16v15h-8l12 17 12-17h-8V16h-8Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  fontSizeIncrease: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg fill='black' clip-path='url(%23a)'%3e%3cpath fill-rule='evenodd' d='m13 87 29.09-71h8.497l29.09 71h-9.726l-8.194-20H30.921l-8.195 20H13Zm45.07-29H34.608l11.73-28.63L58.07 58Z' clip-rule='evenodd'/%3e%3cpath d='M73 48V33h-8l12-17 12 17h-8v15h-8Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  forward10: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M21.358 21.975c-16.923 16.923-16.923 44.36 0 61.282 16.923 16.923 44.36 16.923 61.282 0 16.924-16.923 16.924-44.36 0-61.282l-6.128 6.128c13.537 13.537 13.537 35.489 0 49.026-13.537 13.537-35.489 13.537-49.026 0-13.537-13.537-13.537-35.489 0-49.026 7.55-7.55 17.717-10.89 27.577-10.02v8.999l18.385-12.768L55.063.526 55.06 9.39c-12.082-.848-24.46 3.343-33.702 12.585Z'/%3e%3cpath fill='black' d='M62.254 65c6.303.012 10.066-4.782 10.066-13.043C72.32 43.745 68.531 39 62.254 39c-6.278 0-10.055 4.733-10.067 12.957C52.187 60.206 55.95 65 62.254 65Zm0-3.848c-3.254 0-5.295-3.147-5.283-9.195.013-6 2.042-9.158 5.283-9.158 3.253 0 5.282 3.159 5.295 9.158 0 6.048-2.03 9.195-5.296 9.195ZM45.883 39h-4.325l-6.238 4.095v4.259l5.87-3.833h.147V64.65h4.546V39Z'/%3e%3c/svg%3e",
  forward15: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M21.358 21.975c-16.923 16.923-16.923 44.36 0 61.282 16.923 16.923 44.36 16.923 61.282 0 16.924-16.923 16.924-44.36 0-61.282l-6.128 6.128c13.537 13.537 13.537 35.489 0 49.026-13.537 13.537-35.489 13.537-49.026 0-13.537-13.537-13.537-35.489 0-49.026 7.55-7.55 17.717-10.89 27.577-10.02v8.999l18.385-12.768L55.063.526 55.06 9.39c-12.082-.848-24.46 3.343-33.702 12.585Z'/%3e%3cpath fill='black' d='M45.23 39h-4.326l-6.238 4.095v4.259l5.87-3.833h.147V64.65h4.546V39Zm15.096 26c5.306 0 9.007-3.682 9.007-8.78 0-4.934-3.358-8.49-7.916-8.49-2.034 0-3.836.826-4.767 1.953h-.147l.723-6.8h10.796V39H53.464l-1.311 13.225 4.105.69c.845-.953 2.438-1.591 3.946-1.579 2.757.013 4.742 2.092 4.73 4.997.012 2.868-1.924 4.91-4.608 4.91-2.267 0-4.093-1.465-4.252-3.57h-4.411C51.785 61.932 55.425 65 60.326 65Z'/%3e%3c/svg%3e",
  forward30: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 105'%3e%3cpath fill='black' d='M21.012 22.012c-16.924 16.923-16.924 44.36 0 61.282 16.923 16.923 44.359 16.923 61.282 0 16.923-16.923 16.923-44.36 0-61.282l-6.128 6.128c13.537 13.537 13.537 35.489 0 49.026-13.537 13.537-35.489 13.537-49.026 0-13.538-13.537-13.538-35.489 0-49.026 7.55-7.55 17.717-10.89 27.577-10.02v8.999L73.102 14.35 54.717.563l-.003 8.864C42.632 8.58 30.253 12.77 21.012 22.012Z'/%3e%3cpath fill='black' d='M39.57 64.902c5.588 0 9.658-3.086 9.645-7.34.013-3.146-2.028-5.408-5.69-5.912v-.197c2.832-.59 4.733-2.618 4.72-5.446.013-3.835-3.38-6.97-8.599-6.97-5.078 0-9.008 2.913-9.11 7.13h4.555c.076-2.114 2.118-3.43 4.53-3.43 2.436 0 4.057 1.426 4.044 3.54.013 2.201-1.876 3.664-4.58 3.664h-2.31v3.516h2.31c3.304 0 5.27 1.598 5.256 3.872.013 2.225-1.99 3.75-4.784 3.75-2.629 0-4.657-1.316-4.772-3.369h-4.798c.128 4.254 4.07 7.192 9.582 7.192Zm23.684.135c6.303.012 10.067-4.782 10.067-13.043 0-8.212-3.79-12.957-10.067-12.957-6.278 0-10.055 4.733-10.067 12.957 0 8.249 3.764 13.043 10.067 13.043Zm0-3.848c-3.254 0-5.295-3.147-5.283-9.195.013-6 2.042-9.158 5.283-9.158 3.253 0 5.282 3.159 5.295 9.158 0 6.048-2.029 9.195-5.295 9.195Z'/%3e%3c/svg%3e",
  headphones: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M95.91 57.234c-.014-6.26-1.444-16.409-4.212-22.037a41.979 41.979 0 0 0-8.495-11.65 43.8 43.8 0 0 0-12.08-8.32 44.736 44.736 0 0 0-12.299-3.708 45.31 45.31 0 0 0-13.648 0 44.732 44.732 0 0 0-12.298 3.707 43.788 43.788 0 0 0-12.08 8.32 42.003 42.003 0 0 0-8.496 11.651C9.534 40.825 8.104 50.974 8.09 57.234 8.067 67.197 5.82 92 25.213 92h4.12c2.525 0 4.948-.99 6.737-2.754a9.407 9.407 0 0 0 2.812-6.658 7527.7 7527.7 0 0 1 .113-19.772 9.374 9.374 0 0 0-.71-3.637 9.445 9.445 0 0 0-2.066-3.087 9.552 9.552 0 0 0-3.106-2.065 9.628 9.628 0 0 0-3.668-.724l-12.558.006c.013-2.073.22-4.443.645-6.471 1.567-7.48 5.26-13.73 11.078-18.747 6.041-5.195 13.112-7.977 21.077-8.544A34.667 34.667 0 0 1 52 19.463c.77-.004 1.54.03 2.313.085 7.966.562 15.036 3.349 21.077 8.544 5.816 5 9.51 11.246 11.081 18.74.426 2.027.632 4.397.647 6.47l-12.56-.006a9.634 9.634 0 0 0-3.668.725 9.553 9.553 0 0 0-3.106 2.064 9.446 9.446 0 0 0-2.066 3.087 9.37 9.37 0 0 0-.71 3.637c.038 5.83.082 13.378.114 19.772a9.408 9.408 0 0 0 2.81 6.658 9.595 9.595 0 0 0 6.737 2.754h4.123c19.386.008 17.138-24.796 17.118-34.76Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M8 11h88v81H8z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  infoCircle: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M52 95.333c-23.933 0-43.334-19.4-43.334-43.333S28.066 8.667 52 8.667c23.933 0 43.333 19.4 43.333 43.333S75.933 95.333 52 95.333Zm0-8.666a34.667 34.667 0 1 0 0-69.334 34.667 34.667 0 0 0 0 69.334Zm-4.334-56.334h8.667V39h-8.666v-8.667Zm0 17.334h8.667v26h-8.666v-26Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  lineHeightDecrease: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg fill='black' clip-path='url(%23a)'%3e%3cpath d='M86 16v15h8L82 48 70 31h8V16h8ZM64 37v9H13v-9h51Zm0-21v9H13v-9h51Zm0 51v-9H13v9h51Zm0 21v-9H13v9h51Zm14 0V73h-8l12-17 12 17h-8v15h-8Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  lineHeightIncrease: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg fill='black' clip-path='url(%23a)'%3e%3cpath d='M86 56v15h8L82 88 70 71h8V56h8ZM64 16v9H13v-9h51Zm0 40.5v-9H13v9h51ZM64 88v-9H13v9h51Zm14-40V33h-8l12-17 12 17h-8v15h-8Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  lock: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' fill-rule='evenodd' d='M75 43.58V31C75 18.297 64.703 8 52 8S29 18.297 29 31v12.58h-7.815a3.818 3.818 0 0 0-2.723 1.148 3.956 3.956 0 0 0-1.128 2.772v39.58c0 1.04.405 2.036 1.128 2.772A3.818 3.818 0 0 0 21.185 91h61.63a3.818 3.818 0 0 0 2.724-1.148 3.956 3.956 0 0 0 1.128-2.773V47.5c0-1.04-.406-2.037-1.128-2.772a3.818 3.818 0 0 0-2.724-1.149H75ZM65 31c0-7.18-5.82-13-13-13s-13 5.82-13 13v12.58h26V31Z' clip-rule='evenodd'/%3e%3c/svg%3e",
  loopAll: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M85.834 23.399c1.333-.77 1.333-2.695 0-3.464l-20-11.547c-1.334-.77-3 .192-3 1.732v7.28a35.2 35.2 0 0 0-2.168-.067H43.334C24.187 17.333 8.667 32.854 8.667 52a34.513 34.513 0 0 0 4.224 16.599l7.524-4.31A25.885 25.885 0 0 1 17.333 52c0-14.36 11.64-26 26-26h17.334c.73 0 1.452.03 2.166.089v7.125c0 1.54 1.667 2.502 3 1.732l20-11.547ZM86.666 52c0-4.516-1.15-8.763-3.176-12.464l7.523-4.308A34.51 34.51 0 0 1 95.333 52c0 19.146-15.52 34.667-34.666 34.667H43.332c-.727 0-1.45-.023-2.166-.067v7.28c0 1.54-1.667 2.502-3 1.732l-20-11.547c-1.334-.77-1.334-2.694 0-3.464l3.152-1.82-.02-.017 5.982-3.425 10.886-6.285c1.333-.77 3 .193 3 1.732v7.125a26.32 26.32 0 0 0 2.166.089h17.334c14.359 0 26-11.64 26-26Z'/%3e%3c/svg%3e",
  loopOne: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M85.834 23.399c1.333-.77 1.333-2.695 0-3.464l-20-11.547c-1.334-.77-3 .192-3 1.732v7.28a35.2 35.2 0 0 0-2.168-.067H43.334C24.187 17.333 8.667 32.854 8.667 52a34.513 34.513 0 0 0 4.224 16.599l7.524-4.31A25.885 25.885 0 0 1 17.333 52c0-14.36 11.64-26 26-26h17.334c.73 0 1.452.03 2.166.089v7.125c0 1.54 1.667 2.502 3 1.732l20-11.547ZM86.666 52c0-4.516-1.15-8.763-3.176-12.464l7.523-4.308A34.51 34.51 0 0 1 95.333 52c0 19.146-15.52 34.667-34.666 34.667H43.332c-.727 0-1.45-.023-2.166-.067v7.28c0 1.54-1.667 2.502-3 1.732l-20-11.547c-1.334-.77-1.334-2.694 0-3.464l3.152-1.82-.02-.017 5.982-3.425 10.886-6.285c1.333-.77 3 .193 3 1.732v7.125a26.32 26.32 0 0 0 2.166.089h17.334c14.359 0 26-11.64 26-26Z'/%3e%3cpath fill='black' d='M56.333 34.667h-8.666v.032l-9.013 5.082 4.257 7.549 4.755-2.681v24.684h8.667V34.667Z'/%3e%3c/svg%3e",
  mailCheck: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M95.333 57.811A26 26 0 0 0 62.144 91H13a4.334 4.334 0 0 1-4.334-4.333V17.333A4.333 4.333 0 0 1 13 13h78a4.333 4.333 0 0 1 4.333 4.333v40.478ZM52.26 50.626 24.475 27.031l-5.612 6.604L52.316 62.04l32.851-28.426-5.668-6.557-27.235 23.57h-.004Zm30.073 44.707L67.01 80.011l6.132-6.128 9.191 9.196 15.323-15.323 6.127 6.127-21.45 21.45Z'/%3e%3c/svg%3e",
  meatballs: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M51.917 11C47.013 11 43 15.05 43 20s4.013 9 8.917 9 8.916-4.05 8.916-9-4.012-9-8.916-9Zm0 63C47.013 74 43 78.05 43 83s4.013 9 8.917 9 8.916-4.05 8.916-9-4.012-9-8.916-9Zm0-31.5c-4.904 0-8.917 4.05-8.917 9s4.013 9 8.917 9 8.916-4.05 8.916-9-4.012-9-8.916-9Z'/%3e%3c/svg%3e",
  menuStaggered: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M13 17.333h78V26H13v-8.667Zm0 30.334h52v8.666H13v-8.666ZM13 78h78v8.667H13V78Z'/%3e%3c/svg%3e",
  openBook: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M91 91H56.333V26a13 13 0 0 1 13-13H91a4.333 4.333 0 0 1 4.333 4.333v69.334A4.333 4.333 0 0 1 91 91Zm-43.333 0H13a4.333 4.333 0 0 1-4.333-4.333V17.333A4.333 4.333 0 0 1 13 13h21.667a13 13 0 0 1 13 13v65Zm0 0h8.666v8.667h-8.666V91Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  options: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M78 77.263a13.004 13.004 0 0 1 0-24.526V8.667h8.666v44.07a13.005 13.005 0 0 1 0 24.526v18.07H78v-18.07Zm-30.334-26a13.005 13.005 0 0 1 0-24.526V8.667h8.667v18.07a13.004 13.004 0 0 1 0 24.526v44.07h-8.667v-44.07Zm-30.333 26a13.004 13.004 0 0 1 0-24.526V8.667H26v44.07a13.004 13.004 0 0 1 0 24.526v18.07h-8.667v-18.07ZM26 65a4.333 4.333 0 1 0-8.666 0A4.333 4.333 0 0 0 26 65Zm30.333-26a4.333 4.333 0 1 0-8.666 0 4.333 4.333 0 0 0 8.666 0Zm30.333 26A4.333 4.333 0 1 0 78 65a4.333 4.333 0 0 0 8.666 0Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  pause: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M21.667 14h19.5v78h-19.5V14Zm41.166 0h19.5v78h-19.5V14Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  pencil: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='m68.155 41.973-6.128-6.128-40.36 40.361v6.127h6.127l40.36-40.36Zm6.127-6.128 6.127-6.127-6.127-6.127-6.127 6.127 6.127 6.127ZM31.382 91H13V72.614l58.218-58.219a4.334 4.334 0 0 1 6.128 0l12.259 12.26a4.334 4.334 0 0 1 0 6.127L31.386 91h-.004Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  playCircle: {fill: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M52 95.5C27.975 95.5 8.5 76.025 8.5 52S27.975 8.5 52 8.5 95.5 27.975 95.5 52 76.025 95.5 52 95.5Zm-5.994-59.095A1.74 1.74 0 0 0 43.3 37.85v28.3a1.74 1.74 0 0 0 2.706 1.445l21.223-14.146a1.74 1.74 0 0 0 0-2.898L46.001 36.405h.005Z'/%3e%3c/svg%3e", outline: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M52 95.5C27.975 95.5 8.5 76.025 8.5 52S27.975 8.5 52 8.5 95.5 27.975 95.5 52 76.025 95.5 52 95.5Zm0-8.7a34.8 34.8 0 1 0 0-69.6 34.8 34.8 0 0 0 0 69.6Zm-5.994-50.395 21.223 14.146a1.74 1.74 0 0 1 0 2.898L46.001 67.595A1.74 1.74 0 0 1 43.3 66.15v-28.3a1.74 1.74 0 0 1 2.706-1.445Z'/%3e%3c/svg%3e"},
  play: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='m89.67 54.383-63.36 40.47a3.097 3.097 0 0 1-3.067.14 2.939 2.939 0 0 1-1.152-1.051 2.774 2.774 0 0 1-.425-1.471V11.529c0-.518.147-1.026.425-1.47a2.939 2.939 0 0 1 1.153-1.053 3.097 3.097 0 0 1 3.066.14l63.36 40.471c.41.262.745.616.977 1.032a2.759 2.759 0 0 1 0 2.702c-.232.416-.567.77-.977 1.032Z'/%3e%3c/svg%3e",
  plusCircle: {fill: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' fill-rule='evenodd' d='M52 8.5c24.012 0 43.5 19.488 43.5 43.5S76.012 95.5 52 95.5 8.5 76.012 8.5 52 27.988 8.5 52 8.5Zm-4.35 21.75v17.4h-17.4v8.7h17.4v17.4h8.7v-17.4h17.4v-8.7h-17.4v-17.4h-8.7Z' clip-rule='evenodd'/%3e%3c/svg%3e", outline: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M47.65 47.65v-17.4h8.7v17.4h17.4v8.7h-17.4v17.4h-8.7v-17.4h-17.4v-8.7h17.4ZM52 95.5C27.975 95.5 8.5 76.025 8.5 52S27.975 8.5 52 8.5 95.5 27.975 95.5 52 76.025 95.5 52 95.5Zm0-8.7a34.8 34.8 0 1 0 0-69.6 34.8 34.8 0 0 0 0 69.6Z'/%3e%3c/svg%3e"},
  plus: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M84.754 46.82v10.36H19.246V46.82h65.508ZM46.82 18.941h10.512v66.117H46.82V18.941Z'/%3e%3c/svg%3e",
  priceTag: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='m47.233 9.1 42.896 6.132 6.127 42.9-39.831 39.832a4.333 4.333 0 0 1-6.128 0l-42.9-42.9a4.333 4.333 0 0 1 0-6.128L47.234 9.1Zm3.064 9.195L16.588 52l36.773 36.768 33.705-33.704-4.594-32.175-32.175-4.594Zm9.187 27.578a8.67 8.67 0 1 1 12.263-12.26 8.67 8.67 0 0 1-12.263 12.26Z'/%3e%3c/svg%3e",
  skipBackward: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M77.677 20.546a3.012 3.012 0 0 1 3.063-.203c.48.244.883.612 1.164 1.065.281.452.43.972.43 1.5v58.183c0 .53-.149 1.049-.43 1.501-.28.453-.683.82-1.164 1.065a3.01 3.01 0 0 1-3.063-.203l-42.15-29.097a2.91 2.91 0 0 1-.916-1.03 2.837 2.837 0 0 1 0-2.654c.217-.41.531-.763.917-1.03l42.15-29.097ZM29.152 80.889a5.713 5.713 0 0 1-1.731 4.085 5.977 5.977 0 0 1-4.178 1.693 5.978 5.978 0 0 1-4.179-1.693 5.714 5.714 0 0 1-1.73-4.085V23.11c0-1.532.622-3.002 1.73-4.085a5.978 5.978 0 0 1 4.179-1.693c1.567 0 3.07.61 4.178 1.693a5.714 5.714 0 0 1 1.73 4.085v57.78Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M104 104H0V0h104z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  skipForward: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M26.323 83.454a3.01 3.01 0 0 1-3.063.203 2.928 2.928 0 0 1-1.164-1.065 2.84 2.84 0 0 1-.43-1.5V22.908c0-.53.149-1.049.43-1.501.28-.453.683-.82 1.164-1.065a3.01 3.01 0 0 1 3.063.203l42.15 29.097c.385.267.7.62.916 1.03a2.837 2.837 0 0 1 0 2.654 2.91 2.91 0 0 1-.917 1.03l-42.15 29.097Zm48.525-60.343c0-1.532.623-3.002 1.731-4.085a5.978 5.978 0 0 1 4.178-1.693c1.568 0 3.07.61 4.179 1.693a5.714 5.714 0 0 1 1.73 4.085V80.89a5.714 5.714 0 0 1-1.73 4.085 5.978 5.978 0 0 1-4.179 1.693 5.977 5.977 0 0 1-4.178-1.693 5.713 5.713 0 0 1-1.73-4.085V23.11Z'/%3e%3c/svg%3e",
  trash: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cg clip-path='url(%23a)'%3e%3cpath fill='black' d='M30.333 17.333V8.667h43.334v8.666h21.666V26h-8.666v65a4.334 4.334 0 0 1-4.334 4.333H21.667A4.333 4.333 0 0 1 17.333 91V26H8.667v-8.667h21.666ZM26 26v60.667h52V26H26Zm13 13h8.667v34.667H39V39Zm17.333 0H65v34.667h-8.667V39Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='black' d='M0 0h104v104H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e",
  userAdd: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 104 104'%3e%3cpath fill='black' d='M56.088 62.209v30.524H17.666a32.178 32.178 0 0 1 3.137-13.878 31.373 31.373 0 0 1 8.775-11.044 30.393 30.393 0 0 1 12.621-5.955 30.01 30.01 0 0 1 13.889.353Zm-7.685-4.933c-12.736 0-23.052-10.578-23.052-23.638C25.35 20.578 35.667 10 48.403 10c12.737 0 23.053 10.578 23.053 23.638 0 13.06-10.316 23.638-23.053 23.638Zm23.053 15.759v-11.82h7.684v11.82h11.526v7.88H79.14v11.818h-7.684V80.914H59.93v-7.88h11.526Z'/%3e%3c/svg%3e",
}
