import { field, text } from "@nozbe/watermelondb/decorators"

import { DatabaseFieldsOf } from "../stores/types"
import { SourceAwareModel } from "./source-aware-model"

export type HighlightData = DatabaseFieldsOf<Highlight> & { id: string }

export class Highlight extends SourceAwareModel {
  static table = "Highlight"

  toPlainObject() {
    return {
      id: this.id,
      userId: this.userId,
      profileId: this.profileId,
      contentId: this.contentId,
      selectedText: this.selectedText,
      notes: this.notes,
      colorPresetId: this.colorPresetId,
      startCfi: this.startCfi,
      endCfi: this.endCfi,
      sectionId: this.sectionId,
      progressPercent: this.progressPercent,
      createdAtDate: this.createdAtDate,
      updatedAtDate: this.updatedAtDate,
    }
  }

  @field("contentId")
  contentId!: number
  @field("selectedText")
  selectedText!: string
  @text("notes")
  notes!: string | null
  @field("colorPresetId")
  colorPresetId!: number
  @field("startCfi")
  startCfi!: string
  @field("endCfi")
  endCfi!: string
  @field("sectionId")
  sectionId?: string
  @field("progressPercent")
  progressPercent?: number
}
