import React from "react"
import icons from "@treefort/tokens/app/icons"
import styled from "styled-components/native"
import Box from "./box"
import Icon from "./icon"
import { useTokens } from "./tokens-provider"

const InnerLockBox = styled(Box)`
  background-color: ${({ theme }) =>
    theme.lockedContentIndicator.backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.roundedFull}px;
`

const LockBox = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
`

export default function LockedContentIndicator(): JSX.Element {
  const { tokens } = useTokens()

  return (
    <LockBox
      paddingBottom={tokens.spacing.tiny}
      paddingRight={tokens.spacing.tiny}
    >
      <InnerLockBox padding="tiny">
        <Icon
          source={icons.lock}
          size="small"
          color={tokens.lockedContentIndicator.foregroundColor}
        />
      </InnerLockBox>
    </LockBox>
  )
}
