import React, {
  ReactNode,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react"
import { Animated, Platform } from "react-native"
import { getPathFromRoute } from "../navigation/routes"
import { useRoute } from "./use-route"

type Context = {
  getRouteScrollOffset: (path: string) => Animated.Value
  resetAllRouteScrollOffsets: () => void
}

const Context = React.createContext<Context | null>(null)

export const useRouteScrollOffsetContext = (): Context => {
  const context = useContext(Context)

  if (!context) {
    throw new Error(
      "No context found - make sure the app is wrapped in RouteScrollOffsetProvider.",
    )
  }

  return context
}

export const useCurrentRouteScrollOffset = (): Animated.Value => {
  const route = useRoute()
  const { getRouteScrollOffset } = useRouteScrollOffsetContext()

  // On the web we use window.location to determine the current route
  // because that's how the browser restores scroll position (based on
  // window.location and not our JavaScript-land routes).
  return Platform.OS === "web"
    ? getRouteScrollOffset(window.location.pathname)
    : getRouteScrollOffset(getPathFromRoute(route))
}

export function RouteScrollOffsetProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const offsets = useRef<Map<string, Animated.Value>>(new Map())

  const getRouteScrollOffset: Context["getRouteScrollOffset"] = useCallback(
    (path) => {
      const scrollOffset = offsets.current.get(path)
      if (scrollOffset) {
        return scrollOffset
      } else {
        const newScrollOffset = new Animated.Value(0)
        offsets.current.set(path, newScrollOffset)
        return newScrollOffset
      }
    },
    [offsets],
  )

  const resetAllRouteScrollOffsets: Context["resetAllRouteScrollOffsets"] =
    useCallback(() => (offsets.current = new Map()), [])

  const context = useMemo(
    () => ({
      getRouteScrollOffset,
      resetAllRouteScrollOffsets,
    }),
    [getRouteScrollOffset, resetAllRouteScrollOffsets],
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}
