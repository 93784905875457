import type { DownloadPauseState } from "expo-file-system"

import { EventEmitter } from "@treefort/lib/event-emitter"

export type DownloadSuccessful = {
  state: "successful"
  bytes: number
  data: DownloadPauseState
}

export type DownloadInProgress = {
  state: "queued" | "downloading" | "paused"
  progress: number
  bytesWritten: number
  bytesExpected: number
  allowOverCellular: boolean
  data: DownloadPauseState
}

export type DownloadFailed = {
  state: "failed"
  error: unknown
  data: DownloadPauseState
}

export type DownloadWillDelete = {
  state: "willDelete"
  data: DownloadPauseState
}

export type DownloadDeleted = {
  state: "deleted"
  data: DownloadPauseState
}

export type Download = DownloadSuccessful | DownloadInProgress | DownloadFailed

export enum Event {
  DownloadProgress = "DOWNLOAD_PROGRESS",
  DownloadSuccess = "DOWNLOAD_SUCCESS",
  DownloadDeleted = "DOWNLOAD_DELETED",
  DownloadFailure = "DOWNLOAD_FAILURE",
  DownloadWillDelete = "DOWNLOAD_WILL_DELETE",
}

export interface EventMap {
  [Event.DownloadProgress]: DownloadInProgress & { key: string }
  [Event.DownloadFailure]: DownloadFailed & { key: string }
  [Event.DownloadSuccess]: DownloadSuccessful & { key: string }
  [Event.DownloadDeleted]: DownloadDeleted & { key: string }
  [Event.DownloadWillDelete]: DownloadWillDelete & { key: string }
}

export abstract class DownloadManager extends EventEmitter<EventMap> {
  private static VERSION = 4

  static STORE_KEY = `downloadManager.${DownloadManager.VERSION}`

  /**
   * Get the download metadata that is currently associated with a URL.
   */
  abstract getDownload: (key: string) => Promise<Download | undefined>

  /**
   * Get the download metadata for multiple URLs in parallel.
   */
  abstract getDownloads: (
    keys: string[],
  ) => Promise<Array<Download | undefined>>

  /**
   * Request that the provided URL be downloaded. The timing of the download is
   * not guaranteed - it may be queued depending on other files being
   * downloaded.
   */
  abstract requestDownload: (
    url: string,
    options?: {
      key?: string
      allowOverCellular?: boolean
      headers?: Record<string, string>
      query?: Record<string, string>
    },
  ) => Promise<void>

  /**
   * Pause an in-flight download. This will only work for downloads that are
   * actively being downloaded or downloads that are queued but have not yet
   * been started. For everything else this is a noop.
   */
  abstract pauseDownload: (key: string) => Promise<void>

  /**
   * Delete the downloaded file associated with a URL.
   */
  abstract deleteDownload: (key: string) => Promise<void>

  /**
   * Delete the entire downloads folder along with all local state.
   */
  abstract deleteAllDownloads: () => Promise<void>

  /**
   * Get the total size of all downloads in bytes.
   */
  abstract getTotalSizeOfDownloads: () => Promise<number>
}
