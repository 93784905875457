import React from "react"
import { useTranslation } from "react-i18next"

import { useAuth } from "@treefort/lib/auth-provider"
import icons from "@treefort/tokens/app/icons"

import config from "../config"
import { useActiveProfileId } from "../hooks/use-active-profile-id"
import { usePromptCreateFreeAccount } from "../hooks/use-prompt-create-free-account"
import { useSetting } from "../hooks/use-setting"
import { AppManifest } from "../lib/app-manifest"
import confirm from "../lib/confirm"
import {
  ConsumableContent,
  isConsumableContentAvailable,
} from "../lib/consumable-content"
import { stopAudioPlayerIfCurrent } from "../lib/content-audio"
import DownloadItem from "../lib/download-item"
import {
  getProgressItemFromConsumableContent,
  getProgressItemFromSettingValue,
  ProgressItemData,
} from "../lib/progress-item"
import settings from "../lib/settings"
import { toast } from "../lib/toaster"
import { isLibraryTab } from "../navigation/routes"
import AsyncIconButton from "./async-icon-button"
import IconButton from "./icon-button"

function AddToLibraryButtonAuthenticated({
  height,
  consumableContent,
}: {
  consumableContent?: ConsumableContent
  height?: number
}): JSX.Element {
  const { t } = useTranslation()
  const profileId = useActiveProfileId()
  const emptyProgressItem =
    consumableContent &&
    getProgressItemFromConsumableContent({ consumableContent, profileId })
  const [remoteSetting] = useSetting({
    key: emptyProgressItem?.getKey(),
    settings,
    strategy: "remote",
    shouldUpdate: (a, b) => Boolean(a) !== Boolean(b),
    profileId,
  })
  const addedToLibrary = Boolean(remoteSetting)

  async function addToLibrary() {
    if (emptyProgressItem) {
      const progressItemKey = emptyProgressItem.getKey()
      // Get the latest local _or_ remote setting to make sure we don't stomp
      // any locally saved progress
      const { value } = await settings.getLocalOrRemote<ProgressItemData>(
        progressItemKey,
        { profileId },
      )
      const progressItem = value
        ? getProgressItemFromSettingValue({ value, profileId }) ||
          emptyProgressItem
        : emptyProgressItem
      await progressItem.saveLocalAndRemote()
      toast.success(t("Added to your library!"))
    }
  }

  async function removeFromLibrary() {
    if (emptyProgressItem) {
      const confirmRemove = await confirm({
        title: t("Remove from library?"),
        message: `${t(
          "Do you want to remove {{contentTitle}} from your library?",
          { contentTitle: consumableContent.content.title },
        )} ${t(
          config.DOWNLOADS_SUPPORTED
            ? "This will clear your saved progress and delete any downloaded data."
            : "This will clear your saved progress.",
        )}`,
        confirmLabel: t("Remove"),
        cancelLabel: t("Keep"),
        danger: true,
      })
      if (confirmRemove) {
        await stopAudioPlayerIfCurrent(emptyProgressItem)
        await Promise.all([
          emptyProgressItem.clearLocalAndRemote(),
          new DownloadItem({
            consumableContent,
          }).deleteDownload(),
        ])
      }
    }
  }

  return (
    <AsyncIconButton
      source={addedToLibrary ? icons.plusCircle.fill : icons.plusCircle.outline}
      label={addedToLibrary ? t("Remove from library") : t("Add to library")}
      color="secondary"
      onPress={addedToLibrary ? removeFromLibrary : addToLibrary}
      minSize="buttonHeight"
      height={height}
    />
  )
}

function AddToLibraryButtonUnauthenticated({
  height,
}: {
  height?: number
}): JSX.Element {
  const { t } = useTranslation()
  const promptCreateFreeAccount = usePromptCreateFreeAccount()

  return (
    <IconButton
      source={icons.plusCircle.outline}
      label={t("Add to library")}
      color="secondary"
      onPress={() =>
        promptCreateFreeAccount(
          "You must have an account to add content to your library. Would you like to create one now?",
        )
      }
      minSize="buttonHeight"
      height={height}
    />
  )
}

export default function AddToLibraryButton({
  height,
  consumableContent,
}: {
  consumableContent?: ConsumableContent
  height?: number
}): JSX.Element {
  const auth = useAuth()
  return auth.user ? (
    <AddToLibraryButtonAuthenticated
      consumableContent={consumableContent}
      height={height}
    />
  ) : (
    <AddToLibraryButtonUnauthenticated height={height} />
  )
}

export function canAddToLibrary({
  manifest,
  consumableContent,
}: {
  manifest: AppManifest
  consumableContent?: ConsumableContent
}) {
  return (
    manifest.tabs.some(isLibraryTab) &&
    isConsumableContentAvailable(consumableContent)
  )
}
