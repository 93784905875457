import { Platform } from "react-native"

import { Client, Parameter } from "@treefort/lib/analytics"

import config from "../../config"

export const client: Client = {
  [Parameter.TenantId]: config.TENANT_ID,
  [Parameter.ClientId]: config.CLIENT_ID,
  [Parameter.Platform]: Platform.OS,
  [Parameter.AppVersion]: config.APP_VERSION,
}
