import { MediaImageFile, MediaUrl } from "@treefort/api-spec"
import { getAppPageArtworkItem } from "@treefort/lib/app-page-artwork-item"

import useAppManifest from "./use-app-manifest"
import useCollection from "./use-collection"
import useContent from "./use-content"

/**
 * Returns artwork media representative of the page. This may be asynchronous,
 * in which case `undefined` is returned until the necessary link has been
 * fetched. If no artwork media can be found for the page  then `null` is
 * returned.
 */
export function usePageArtworkMedia(
  pageId?: number,
): MediaImageFile | MediaUrl | null | undefined {
  const manifest = useAppManifest()
  const item = getAppPageArtworkItem({ pageId, manifest })

  const contentQuery = useContent(
    item?.type === "content" ? item.id : undefined,
  )
  const collectionQuery = useCollection(
    item?.type === "collection" ? item.id : undefined,
  )

  switch (item?.type) {
    case "url":
      return { type: "url", url: item.url }

    case "content":
      return contentQuery.data?.artworkMedia

    case "collection": {
      const content = collectionQuery.data?.content.find(
        (content) => content.artworkMedia,
      )
      return content?.artworkMedia
    }

    default:
      return null
  }
}
