import {
  captureException,
  captureMessage,
  setUser,
  addBreadcrumb,
  setTag,
  withScope,
} from "@sentry/react-native"
import { ScopeContext } from "@sentry/types"

import { debugFactory } from "@treefort/lib/debug"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { isAxiosError } from "@treefort/lib/is-axios-error"

import config from "../config"
import { toast } from "./toaster"

export function setCurrentUserId(userId: string | null) {
  if (userId) {
    addBreadcrumb({
      category: "auth",
      message: "Authenticated user " + userId,
      level: "info",
    })
    setUser({ id: userId })
  } else {
    setUser(null)
  }
}

export function setCurrentProfileId(profileId: string | null) {
  setTag("profileId", profileId ?? null)
}

/**
 * Returns true if an error represents a failed network request as indicated by
 * an axios error object with a request field but no response field. See:
 * https://axios-http.com/docs/handling_errors.
 */
export function isAxiosNetworkError(error: unknown) {
  return isAxiosError(error) && error.request && !error.response
}

/**
 * Log an error somewhere easily accessible (e.g. send to Sentry in production,
 * drop in the console during development). If the error is an instance of
 * DisplayableError it will also be presented to the user.
 */
export const logError = (
  error: Parameters<typeof captureException>[0],
  captureContext?: Partial<ScopeContext>,
): void => {
  const displayable = error instanceof DisplayableError
  // Only log displayable errors if they have a cause associated with them
  if (!displayable || error.cause) {
    captureException(error, {
      ...captureContext,
      tags: { ...captureContext?.tags, displayable },
    })
  }
  if (displayable) {
    toast.error(error.message)
  }
}

/**
 * Log a warning (a low priority error)
 */
export const logWarning = (
  error: Parameters<typeof captureException>[0],
): void => {
  withScope((scope) => {
    scope.setLevel("warning")
    logError(error)
  })
}

/**
 * Log something other than an error
 */
export const logMessage = captureMessage

export const debug = debugFactory("app", config)
