"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const merge_1 = require("merge");
/**
 * Only returns true if thing's prototype === Object.prototype or null (i.e.
 * this won't return true for things like JS date objects)
 */
const isPlainObject = (thing) => (0, merge_1.isPlainObject)(thing) &&
    (Object.getPrototypeOf(thing) === Object.prototype ||
        Object.getPrototypeOf(thing) === null);
exports.default = isPlainObject;
