"use strict";
/**
 * This takes in an object of environment variable names mapped to values. This
 * function should be used any time environment variables are needed. It has
 * two benefits over direct access: 1) it'll throw with a nice error message
 * when variables aren't set and 2) it'll stop TypeScript from yelling about
 * possibly undefined variables.
 *
 * NOTE: We use babel-plugin-transform-define and Webpack's DefinePlugin to
 * replace environment variable references with their values at build time for
 * our web packages. These plugins are not super smart and will only replace
 * variables referenced via `process.env.<VARIABLE_NAME>`. That means we have to
 * call out each variable we want explicitly when using this function like so:
 *
 * const env = ensureEnv({ MY_VAR: process.env.MY_VAR })
 * env.MY_VAR // Works!
 *
 * Anything fancy like destructring or dynamic access will break:
 *
 * const env = ensureEnv(process.env)
 * env.MY_VAR // Oh no, busted!
 *
 * This constraint does not apply to Node, but for our sanity it'll probably be
 * better to just use this utility and the same access pattern everywhere to
 * avoid issues.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const ensureEnv = (envVariables) => {
    Object.keys(envVariables).forEach((key) => {
        if (envVariables[key] === undefined) {
            throw new Error(`${key} environment variable has not been set.`);
        }
    });
    return envVariables;
};
exports.default = ensureEnv;
