import { DefaultTheme } from "styled-components/native"
import tokens from "@treefort/tokens/app"

type BorderColorToken = keyof typeof tokens.colors.border

export type BorderColor = keyof typeof tokens.colors.border | string

export const borderColorToString = <Value extends BorderColor | undefined>(
  tokens: DefaultTheme,
  borderColor: Value,
): Value extends undefined ? undefined : string =>
  (borderColor && borderColor in tokens.colors.border
    ? tokens.colors.border[borderColor as BorderColorToken]
    : borderColor) as Value extends undefined ? undefined : string
