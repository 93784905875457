import React, { ReactNode } from "react"

import { AsyncViewProps } from "../../../components/async-view"
import { useTokens } from "../../../components/tokens-provider"
import { Spacing } from "../../../lib/spacing"
import MenuLayoutDesktop from "./layout-desktop"
import MenuLayoutMobile from "./layout-mobile"

export default function MenuLayout(props: {
  asyncViewProps?: AsyncViewProps & { offlineStateDisabled?: boolean }
  children?: ReactNode
  backgroundColor?: string
  paddingHorizontal?: Spacing
}): JSX.Element {
  const { tokens } = useTokens()
  return tokens.appHeader.mode === "desktop" ? (
    <MenuLayoutDesktop {...props} />
  ) : (
    <MenuLayoutMobile {...props} />
  )
}
