import React, { useEffect } from "react"

import queryString from "query-string"

import authenticator from "../../../../lib/authenticator"
import { SplashScreen } from "../../splash"

/**
 * This allows deep linking to the registration page. If a "hint" query
 * parameter is passed then it’ll be forwarded on to the auth service to prefill
 * the registration email field.
 */
export default function AuthRegisterScreen(): JSX.Element | null {
  useEffect(() => {
    const { hint } = queryString.parse(window.location.search)
    authenticator.register({
      query: {
        login_hint: typeof hint === "string" ? hint : undefined,
      },
    })
  }, [])

  return <SplashScreen />
}
