"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icon = void 0;
exports.icon = {
    size: {
        xsmall: 12,
        small: 16,
        mediumsmall: 20,
        medium: 24,
        large: 28,
        xlarge: 32,
        jumbo: 52,
    },
    opacity: {
        default: 1,
        active: 1,
        inactive: 0.5,
        loading: 1,
    },
};
