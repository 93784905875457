import React, { useMemo } from "react"

import { useAuth } from "@treefort/lib/auth-provider"

import config from "../config"
import useAppManifest from "../hooks/use-app-manifest"
import { useMenu } from "../hooks/use-menu"
import { useNavigate } from "../hooks/use-navigate"
import { useOfflineState } from "../hooks/use-offline-state"
import { queryClient } from "../lib/query-client"
import { getPathFromTab, isLibraryTab } from "../navigation/routes"
import { AsyncViewProps, AsyncViewProvider } from "./async-view"

/**
 * This is a wrapper around the generic AsyncViewProvider that detects when the
 * device is offline and sets AsyncView props accordingly.
 */
export function AsyncViewOfflineProvider({
  offlineStateDisabled,
  ...asyncViewProviderProps
}: {
  offlineStateDisabled?: boolean
} & Parameters<typeof AsyncViewProvider>[0]): JSX.Element {
  const [offline, refreshOffline] = useOfflineState()
  const auth = useAuth()
  const manifest = useAppManifest()
  const menu = useMenu()
  const navigate = useNavigate()
  const libraryTab = manifest.tabs.find(isLibraryTab)

  const offlineProps = useMemo<AsyncViewProps | null>(
    () =>
      offline && !offlineStateDisabled
        ? {
            state: "offline",
            message:
              "You appear to be offline. Please connect to the internet to view this page.",
            primaryAction:
              config.DOWNLOADS_SUPPORTED &&
              (auth.user || !manifest.features.userAccounts)
                ? {
                    label: "View Downloads",
                    onPress: async () => {
                      libraryTab
                        ? navigate(getPathFromTab(libraryTab))
                        : menu.open("/downloads")
                    },
                  }
                : undefined,
            secondaryAction: {
              label: "Refresh",
              onPress: async () => {
                const nextOffline = await refreshOffline()
                if (!nextOffline) {
                  await queryClient.refetchQueries({ active: true })
                }
              },
            },
          }
        : null,
    [
      offline,
      navigate,
      offlineStateDisabled,
      auth.user,
      manifest.features.userAccounts,
      menu,
      refreshOffline,
      libraryTab,
    ],
  )

  return <AsyncViewProvider {...asyncViewProviderProps} {...offlineProps} />
}
