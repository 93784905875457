import throttle from "lodash/throttle"

import { PROGRESS_VERSION } from "@treefort/constants"

import analytics from "./analytics"
import { LOCAL_SYNC_INTERVAL, REMOTE_SYNC_INTERVAL } from "./av/constants"
import { Track } from "./av/types"
import { logError } from "./logging"
import { PlayProgressTracker } from "./play-progress-tracker"
import {
  PlayableProgressItemEvent,
  PlayableProgressItem,
} from "./progress-item"
import settings from "./settings"

const CURRENT_AUDIO_PROGRESS_KEY = `currentProgressKey.${PROGRESS_VERSION}`

const saveLocalProgress = throttle(
  ({ progressItem }: { progressItem: PlayableProgressItem }) =>
    progressItem.saveLocal(),
  LOCAL_SYNC_INTERVAL,
  { leading: true, trailing: true },
)

const saveLocalAndRemoteProgress = throttle(
  ({
    progressItem,
    tracks,
    playProgressTracker,
  }: {
    progressItem: PlayableProgressItem
    tracks: Track[]
    playProgressTracker: PlayProgressTracker
  }) => {
    progressItem.saveLocalAndRemote()
    analytics.logPlayProgress({
      tracks,
      progressItem,
      playProgressTracker,
      maxLoggableEnagementTime: REMOTE_SYNC_INTERVAL * 1.5,
    })
  },
  REMOTE_SYNC_INTERVAL,
  { leading: true, trailing: true },
)

/**
 * Cancels any pending calls to saveProgress (so that they're never called)
 */
export const cancelSaveProgress = () => {
  saveLocalProgress.cancel()
  saveLocalAndRemoteProgress.cancel()
}

/**
 * Flushes any pending calls to saveProgress (so that they're called
 * immediately)
 */
export const flushSaveProgress = () => {
  saveLocalAndRemoteProgress.flush()
}

/**
 * This keeps settings in sync with updates to a progress item
 */
export const syncSettingsWithProgressItem = (data: {
  progressItem: PlayableProgressItem
  tracks: Track[]
  playProgressTracker: PlayProgressTracker
}) => {
  return data.progressItem.on(
    PlayableProgressItemEvent.ProgressUpdated,
    ({ updatedFields }) => {
      // Call the throttled "save progress" functions
      saveLocalProgress(data)
      saveLocalAndRemoteProgress(data)

      // If something other than the position changed (e.g. a change in playback
      // rate, track, etc.) then save right away
      if (updatedFields.some((field) => field !== "position")) {
        flushSaveProgress()
      }

      // Don't persist finished items in the audio player
      if (
        updatedFields.includes("finished") &&
        data.progressItem.getProgress().finished
      ) {
        settings
          .clearLocal(CURRENT_AUDIO_PROGRESS_KEY, {
            profileId: data.progressItem.getProfileId(),
          })
          .catch(logError)
      }
    },
  )
}
