"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collection = void 0;
const helpers_1 = require("../../../lib/helpers");
exports.collection = {
    shape: {
        wide: {
            perRow: (0, helpers_1.displayWidthToken)({
                0: 1,
                480: 2,
                640: 3,
                1024: 4,
                1280: 5,
            }),
        },
        tall: {
            perRow: (0, helpers_1.displayWidthToken)({
                0: 2,
                640: 3,
                832: 4,
                1280: 5,
            }),
        },
        square: {
            perRow: (0, helpers_1.displayWidthToken)({
                0: 2,
                640: 3,
                832: 4,
                1280: 5,
            }),
        },
        circle: {
            perRow: (0, helpers_1.displayWidthToken)({
                0: 2,
                640: 3,
                832: 4,
                1280: 5,
            }),
        },
    },
};
