import React, { useState, useCallback, useRef } from "react"
import { LayoutChangeEvent } from "react-native"

import styled from "styled-components/native"

import { useWillUnmount } from "@treefort/lib/use-will-unmount"

import { Button, ButtonProps } from "./button"

// This forces the loading state to persist for a certain amount of time,
// avoiding awkward UI jitters for fast actions that only take a few
// milliseconds. This won't block the caller of the button from unmounting the
// button or updating the UI, so this timeout won't actually slow the UX down at
// all (unless we implement a UX where button mashing is important, but we don't
// have plans for that currently).
const FORCE_LOADING_DISPLAY_DURATION = 600

const StyledButton = styled(Button)<{
  loading: boolean
  loadingWidth?: number
}>`
  ${(props) =>
    props.loading && props.loadingWidth
      ? `width: ${props.loadingWidth}px`
      : ""};
`

export function AsyncButton(props: Omit<ButtonProps, "loading">): JSX.Element {
  const action = props.onPress
  const loadingWidth = useRef<number>()
  const forceLoadingDisplayTimeout = useRef<unknown>()
  const [loading, setLoading] = useState(false)
  const [forceLoadingDisplay, setForceLoadingDisplay] = useState(false)
  const willUnmount = useWillUnmount()

  const onPress = useCallback(() => {
    if (action) {
      clearTimeout(forceLoadingDisplayTimeout.current as number)
      setLoading(true)
      setForceLoadingDisplay(true)
      forceLoadingDisplayTimeout.current = setTimeout(
        () => !willUnmount.current && setForceLoadingDisplay(false),
        FORCE_LOADING_DISPLAY_DURATION,
      )
      Promise.resolve(action()).finally(() => {
        if (!willUnmount.current) {
          setLoading(false)
        }
      })
    }
  }, [action, willUnmount])

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (!loading) {
        loadingWidth.current = event.nativeEvent.layout.width
      }
    },
    [loading],
  )

  return (
    <StyledButton
      {...props}
      onLayout={onLayout}
      disabled={loading || forceLoadingDisplay || props.disabled}
      onPress={onPress}
      loading={loading || forceLoadingDisplay}
      loadingWidth={loadingWidth.current}
    />
  )
}
