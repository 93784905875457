import React from "react"

import styled from "styled-components/native"

import icons from "@treefort/tokens/admin/icons"

import useAppManifest from "../hooks/use-app-manifest"
import { AppLink } from "./app-link"
import Icon from "./icon"
import Row from "./row"
import Spacer from "./spacer"
import { useTokens } from "./tokens-provider"

const LINKS = {
  facebook: icons.facebookCircle,
  instagram: icons.instagram,
  twitter: icons.twitter,
  youtube: icons.youtube,
  website: icons.global,
} as const

const StyledAppLink = styled(AppLink)`
  width: ${(props) => props.theme.minTapTarget}px;
  height: ${(props) => props.theme.minTapTarget}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export function SocialLinks(props: Parameters<typeof Row>[0]) {
  const manifest = useAppManifest()
  const { tokens } = useTokens()
  const isMobile = tokens.appHeader.mode === "mobile"

  const links = Object.entries(LINKS).flatMap(([key, icon]) => {
    const url = manifest.externalLinks?.[key as keyof typeof LINKS]
    if (url) {
      return { title: key, icon, url }
    } else {
      return []
    }
  })

  return Object.entries(manifest.externalLinks || {}).some(
    ([key, url]) => Object.keys(LINKS).includes(key) && url,
  ) ? (
    <Row {...props}>
      {links.map(({ title, icon, url }, i) => {
        return (
          <React.Fragment key={title}>
            <StyledAppLink to={{ type: "url", url }} aria-label={title}>
              <Icon size={isMobile ? "large" : "medium"} source={icon} />
            </StyledAppLink>
            {isMobile && i !== links.length - 1 ? (
              <Spacer horizontal size="xsmall" />
            ) : null}
          </React.Fragment>
        )
      })}
    </Row>
  ) : null
}
