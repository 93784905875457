import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Animated } from "react-native"
import { EdgeInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { Theme } from "@treefort/epub-viewer"
import { useAuth } from "@treefort/lib/auth-provider"
import icons from "@treefort/tokens/app/icons"

import useAppManifest from "../../hooks/use-app-manifest"
import { useBooleanState } from "../../hooks/use-boolean-state"
import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import useHover from "../../hooks/use-hover"
import { useOpenAnimation } from "../../hooks/use-open-animation"
import authenticator from "../../lib/authenticator"
import confirm from "../../lib/confirm"
import {
  Ebook,
  ebookReader,
  EbookReaderEvent,
  EbookReaderLocation,
} from "../../lib/ebook-reader"
import { findTableOfContentsSection } from "../../lib/epub"
import { isParent } from "../../lib/parental-gateway"
import { EbookHighlightsSelect } from "../ebook-highlights-select"
import { EbookTableOfContentsSelect } from "../ebook-table-of-contents-select"
import Icon from "../icon"
import IconButton from "../icon-button"
import Row from "../row"
import Text from "../text"
import Touchable from "../touchable"
import Options from "./options"

export const getHudTopHeight = (width: number) =>
  width > 1200 ? 64 : width > 1024 ? 58 : 52

export const getHudBottomHeight = getHudTopHeight

const getEbookReaderLocation = () => ({ current: ebookReader.getLocation() })

const HudTopContainer = styled.View<{ safeAreaInsets: EdgeInsets }>`
  position: absolute;
  top: ${(props) => props.safeAreaInsets.top}px;
  left: 0;
  right: 0;
  z-index: 1;
`

const HudBottomContainer = styled.View<{ safeAreaInsets: EdgeInsets }>`
  position: absolute;
  bottom: ${(props) => props.safeAreaInsets.bottom}px;
  left: 0;
  right: 0;
  z-index: 1;
`

const Title = styled.View<{ height: number }>`
  flex: 1;
  justify-content: center;
  height: ${(props) => props.height}px;
`

const GutterContainer = styled(Animated.View)<{
  width: number
  gutter: number
  edge: "left" | "right"
  safeAreaInsets: EdgeInsets
}>`
  position: absolute;
  top: ${(props) => props.safeAreaInsets.top + getHudTopHeight(props.width)}px;
  bottom: ${(props) =>
    props.safeAreaInsets.bottom + getHudBottomHeight(props.width)}px;
  ${(props) => (props.edge === "left" ? "left: 0" : "right: 0")};
  width: ${(props) => props.gutter}px;
  align-items: center;
  justify-content: center;
`

const BookmarkButtonContainer = styled(Animated.View)`
  position: absolute;
  left: 0;
  bottom: 0;
`

const OptionsButtonContainer = styled(Animated.View)`
  position: absolute;
  right: 0;
  bottom: 0;
`

/**
 * HUD elements positioned statically at the top of the reader.
 */
function HudTop({
  ebook,
  width,
  location,
  showControls,
  theme,
  safeAreaInsets,
}: {
  ebook: Ebook
  width: number
  location: EbookReaderLocation | null
  showControls: boolean
  theme: Theme
  safeAreaInsets: EdgeInsets
}): JSX.Element | null {
  const manifest = useAppManifest()
  const [hover, hoverProps] = useHover()
  const section = findTableOfContentsSection(
    ebook?.media.tableOfContents,
    location?.metadata?.sectionId,
  )
  const [tableOfContentsOpen, openTableOfContents, closeTableOfContents] =
    useBooleanState(false)
  const open = showControls || tableOfContentsOpen || hover
  const [openAnimationValue, openState] = useOpenAnimation({ open })
  const [titleAnimation] = useOpenAnimation({
    open: open && Boolean(section?.label),
  })
  const height = getHudTopHeight(width)

  const { t } = useTranslation()

  return (
    <HudTopContainer {...hoverProps} safeAreaInsets={safeAreaInsets}>
      <Touchable
        onPress={() => {
          ebookReader.setShowControls(true)
          ebookReader.emitTap({ region: "top" })
        }}
      >
        <Row alignItems="center" justifyContent="space-between">
          <Animated.View
            style={{
              opacity:
                ebook.media.tableOfContents.length > 0 ? openAnimationValue : 0,
            }}
          >
            <IconButton
              source={icons.menuStaggered}
              label={t("Table of Contents")}
              color={theme.borderColor}
              onPress={openTableOfContents}
              disabled={openState === "closed"}
              height={height}
              width={height}
            />
          </Animated.View>
          <Title height={height}>
            <Animated.View
              style={{
                transform: [
                  {
                    translateY: titleAnimation.interpolate({
                      inputRange: [0, 1],
                      outputRange: [8, 0],
                    }),
                  },
                ],
              }}
            >
              <Text
                textStyle="captionStrong"
                alignment="center"
                numberOfLines={1}
                color={theme.textColorSubdued}
              >
                {ebook.title}
              </Text>
            </Animated.View>
            <Animated.View style={{ opacity: titleAnimation }}>
              <Text
                textStyle="caption"
                alignment="center"
                numberOfLines={1}
                color={theme.textColorSubdued}
              >
                {section?.label || "\u00A0"}
              </Text>
            </Animated.View>
          </Title>
          <Animated.View style={{ opacity: openAnimationValue }}>
            <IconButton
              source={manifest.icons.close}
              label={t("Close")}
              color={theme.borderColor}
              onPress={ebookReader.requestClose}
              disabled={openState === "closed"}
              height={height}
              width={height}
            />
          </Animated.View>
          {ebook.media.tableOfContents.length ? (
            <EbookTableOfContentsSelect
              label={ebook.title}
              open={tableOfContentsOpen}
              onClose={closeTableOfContents}
              sectionId={location?.metadata?.sectionId}
              onChange={(section) => {
                closeTableOfContents()
                ebookReader.setShowControls(false)
                ebookReader.setLocation({
                  type: "requested",
                  location: { type: "href", href: section.href },
                  metadata: { sectionId: section.id },
                })
              }}
              tableOfContents={ebook.media.tableOfContents}
            />
          ) : null}
        </Row>
      </Touchable>
    </HudTopContainer>
  )
}

/**
 * HUD elements positioned statically at the bottom of the reader.
 */
function HudBottom({
  ebook,
  gutter,
  width,
  location,
  showControls,
  theme,
  safeAreaInsets,
}: {
  ebook: Ebook
  gutter: number
  width: number
  location: EbookReaderLocation | null
  showControls: boolean
  theme: Theme
  safeAreaInsets: EdgeInsets
}): JSX.Element | null {
  const [hover, hoverProps] = useHover()
  const [optionsOpen, openOptions, closeOptions] = useBooleanState(false)
  const [highlightsOpen, openHighlights, closeHighlights] =
    useBooleanState(false)
  const open = showControls || optionsOpen || hover
  const [openAnimationValue, openState] = useOpenAnimation({ open })
  const manifest = useAppManifest()
  const auth = useAuth()
  const onPress = useCallback(() => {
    ebookReader.setShowControls(true)
    ebookReader.emitTap({ region: "bottom" })
  }, [])
  const height = getHudBottomHeight(width)
  const { parentalGateway } = manifest.features

  const promptCreateFreeAccount = async () => {
    if (
      await confirm({
        title: t("Account Required"),
        message: t(
          "You must have an account to highlight and take notes. Would you like to create one now?",
        ),
        confirmLabel: t("Create FREE Account"),
        cancelLabel: t("Cancel"),
      })
    ) {
      if (!parentalGateway || (await isParent())) {
        authenticator.register()
      }
    }
  }

  // Don't render progress if we're looking at the first page of the book.
  const hideProgress =
    location?.type === "rendered" && location.metadata.startOfBookVisible

  const sectionPagesLeft =
    location?.type === "rendered" && location.metadata.sectionPagesCount > 2
      ? location.metadata.sectionPagesCount -
        location.metadata.sectionPagesVisible.slice(-1)[0]
      : undefined

  // HACK: When the user adjusts the theme there's a good chance page numbers
  // will change (e.g. if they increase the font size then more pages will be
  // required). We could re-calculate page numbers every time the theme changes,
  // but that would result in a jankier theme-customization experience. Instead
  // we just temporarily hide the page number until the next time the user
  // navigates.
  const [hidePageNumbers, disablePageNumbers, enablePageNumbers] =
    useBooleanState(false)
  useEffect(
    () => ebookReader.on(EbookReaderEvent.LocationChanged, enablePageNumbers),
    [enablePageNumbers],
  )
  useEffect(
    () => ebookReader.on(EbookReaderEvent.ThemeChanged, disablePageNumbers),
    [disablePageNumbers],
  )

  const { t } = useTranslation()

  return (
    <>
      <HudBottomContainer {...hoverProps} safeAreaInsets={safeAreaInsets}>
        <Touchable onPress={onPress}>
          <Row
            height={height}
            paddingHorizontal={gutter}
            alignItems="center"
            justifyContent="space-between"
          >
            {!hideProgress &&
            location?.type === "rendered" &&
            location.metadata.percent !== undefined ? (
              <Animated.View
                style={{
                  // Animate percent progress out when controls are shown and
                  // we don't have room for it and the highlights button
                  opacity:
                    gutter < height
                      ? openAnimationValue.interpolate({
                          inputRange: [0, 1],
                          outputRange: [1, 0],
                        })
                      : undefined,
                }}
              >
                {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
                <Text textStyle="caption" color={theme.textColorSubdued}>
                  {Math.round(location.metadata.percent * 100)}%
                </Text>
              </Animated.View>
            ) : null}
            <BookmarkButtonContainer style={{ opacity: openAnimationValue }}>
              <IconButton
                source={icons.bookmark}
                onPress={auth.user ? openHighlights : promptCreateFreeAccount}
                label={t("Highlights")}
                color={theme.borderColor}
                disabled={openState === "closed"}
                height={height}
                width={height}
              />
            </BookmarkButtonContainer>
            {!hideProgress &&
            !hidePageNumbers &&
            sectionPagesLeft !== undefined ? (
              <Animated.View
                style={{
                  opacity:
                    // Animate the page number out when controls are shown and we
                    // don't have room for it and the options button
                    gutter < height
                      ? openAnimationValue.interpolate({
                          inputRange: [0, 1],
                          outputRange: [1, 0],
                        })
                      : undefined,
                }}
              >
                <Text textStyle="caption" color={theme.textColorSubdued}>
                  {sectionPagesLeft === 0
                    ? t("Last page in chapter")
                    : t("{{number}} pages left in chapter", {
                        number: sectionPagesLeft,
                      })}
                </Text>
              </Animated.View>
            ) : null}
            <OptionsButtonContainer style={{ opacity: openAnimationValue }}>
              <IconButton
                source={icons.options}
                label={t("Options")}
                color={theme.borderColor}
                onPress={openOptions}
                disabled={openState === "closed"}
                height={height}
                width={height}
              />
            </OptionsButtonContainer>
            <Options open={optionsOpen} onRequestClose={closeOptions} />
          </Row>
        </Touchable>
      </HudBottomContainer>
      <EbookHighlightsSelect
        consumableContent={ebook.extra.consumableContent}
        open={highlightsOpen}
        onClose={closeHighlights}
      />
    </>
  )
}

/**
 * HUD elements positioned absolutely over the reader.
 */
function HudGutters({
  width,
  gutter,
  location,
  showControls,
  theme,
  safeAreaInsets,
}: {
  width: number
  gutter: number
  location: EbookReaderLocation | null
  showControls: boolean
  theme: Theme
  safeAreaInsets: EdgeInsets
}): JSX.Element {
  const [prevPageIconOpacity] = useOpenAnimation({
    open:
      showControls &&
      (location?.type === "requested" ||
        !location?.metadata.startOfBookVisible),
  })
  const [nextPageIconOpacity] = useOpenAnimation({
    open:
      showControls &&
      (location?.type === "requested" || !location?.metadata.endOfBookVisible),
  })
  return (
    <>
      <GutterContainer
        width={width}
        gutter={gutter}
        edge="left"
        style={{ opacity: prevPageIconOpacity }}
        pointerEvents={
          // Let the epub viewer underneath this element handle tap events
          "none"
        }
        safeAreaInsets={safeAreaInsets}
      >
        <Icon
          source={icons.chevronShallowLeft}
          size="large"
          color={theme.borderColor}
        />
      </GutterContainer>
      <GutterContainer
        width={width}
        gutter={gutter}
        edge="right"
        style={{ opacity: nextPageIconOpacity }}
        pointerEvents={
          // Let the epub viewer underneath this element handle tap events
          "none"
        }
        safeAreaInsets={safeAreaInsets}
      >
        <Icon
          source={icons.chevronShallowRight}
          size="large"
          color={theme.borderColor}
        />
      </GutterContainer>
    </>
  )
}

export function Hud({
  width,
  gutter,
  ebook,
  theme,
  safeAreaInsets,
}: {
  width: number
  gutter: number
  ebook: Ebook
  theme: Theme
  safeAreaInsets: EdgeInsets
}): JSX.Element {
  const { current: location } = useEventEmitterValue(
    ebookReader,
    EbookReaderEvent.LocationChanged,
    getEbookReaderLocation,
  )
  const showControls = useEventEmitterValue(
    ebookReader,
    EbookReaderEvent.ShowControlsChanged,
    ebookReader.getShowControls,
  )

  return (
    <>
      <HudTop
        ebook={ebook}
        width={width}
        location={location}
        showControls={showControls}
        theme={theme}
        safeAreaInsets={safeAreaInsets}
      />
      <HudGutters
        width={width}
        gutter={gutter}
        location={location}
        showControls={showControls}
        theme={theme}
        safeAreaInsets={safeAreaInsets}
      />
      <HudBottom
        ebook={ebook}
        width={width}
        gutter={gutter}
        location={location}
        showControls={showControls}
        theme={theme}
        safeAreaInsets={safeAreaInsets}
      />
    </>
  )
}
