import React, { useRef } from "react"

import { MediaWebEmbed } from "@treefort/api-spec"
import { secureUrl } from "@treefort/lib/validation"

import useUserInfo from "../../../hooks/use-user-info"
import { useTokens } from "../../tokens-provider"
import {
  hasPlaceholders,
  replaceWebEmbedPlaceholders,
} from "./replace-placeholders"

const ON_READY_TIMEOUT_MS = 500

export function WebEmbedContent({
  contentId,
  media,
  dimensions,
  onReady,
}: {
  contentId: number
  media: MediaWebEmbed
  dimensions: { height: number; width: number }
  onReady: () => void
}) {
  const { tokens, displayMode } = useTokens()

  const contentHasPlaceholders =
    (media.type === "secureUrl" && hasPlaceholders(media.secureUrl)) ||
    (media.type === "html" && hasPlaceholders(media.html))

  const userInfo = useUserInfo({
    enabled: contentHasPlaceholders,
  })

  const ref = useRef<HTMLIFrameElement>(null)

  const tokenReplacements = {
    user: userInfo.isSuccess ? userInfo.data : null,
    contentId,
    displayMode,
  }

  return (
    (!contentHasPlaceholders || !userInfo.isLoading) && (
      <iframe
        ref={ref}
        {...dimensions}
        allowFullScreen
        style={{
          border: "none",
          backgroundColor: tokens.colors.background.primary,
        }}
        src={
          // There are multiple layers of validaiton that should prevent a non
          // https url from ever showing up here, but checking again just to be
          // extra careful
          media.type === "secureUrl" && secureUrl().isValidSync(media.secureUrl)
            ? replaceWebEmbedPlaceholders(media.secureUrl, tokenReplacements)
            : undefined
        }
        srcDoc={
          media.type === "html"
            ? replaceWebEmbedPlaceholders(media.html, tokenReplacements)
            : undefined
        }
        onLoad={() => setTimeout(onReady, ON_READY_TIMEOUT_MS)}
      />
    )
  )
}
