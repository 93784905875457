import React from "react"
import type { BottomTabBarProps } from "@react-navigation/bottom-tabs"
import styled from "styled-components/native"
import type { EdgeInsets } from "react-native-safe-area-context"
import Tabs from "./tabs"

interface Props {
  height: number
  safeAreaInsets: EdgeInsets
  state?: BottomTabBarProps["state"]
  navigation?: BottomTabBarProps["navigation"]
}

const Container = styled.View<{
  height: number
  safeAreaInsets: EdgeInsets
}>`
  flex-direction: row;
  align-items: center;
  border-color: ${({ theme }) => theme.appFooter.borderColor};
  border-top-width: 1px;
  background: ${({ theme }) => theme.colors.background.primary};
  height: ${(props) => props.height + props.safeAreaInsets.bottom}px;
  padding-bottom: ${(props) => props.safeAreaInsets.bottom}px;
  padding-left: ${(props) => props.safeAreaInsets.left}px;
  padding-right: ${(props) => props.safeAreaInsets.right}px;
`

function BottomTabBar({
  height,
  safeAreaInsets,
  state,
  navigation,
}: Props): JSX.Element | null {
  return (
    <Container height={height} safeAreaInsets={safeAreaInsets}>
      <Tabs state={state} navigation={navigation} />
    </Container>
  )
}

export default BottomTabBar
