"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getNameOfUser(user) {
    if (user.firstName || user.lastName) {
        return [user.firstName, user.lastName].filter(Boolean).join(" ");
    }
    else {
        return user.fullName;
    }
}
exports.default = getNameOfUser;
