import React, { ReactNode } from "react"

import useAppPreview from "../../hooks/use-app-preview"
import LazyLoadComponent from "../lazy-load-component"
import AppManifestProviderPublished from "./published"

export * from "./base"

export default function AppManifestProvider(props: {
  children: ReactNode
}): JSX.Element | null {
  const appPreview = useAppPreview()
  return (
    <LazyLoadComponent
      importKey="appPreviewManifestProvider"
      importComponent={() => import("./draft")}
      enabled={appPreview.state === "on"}
      props={props}
      FallbackComponent={AppManifestProviderPublished}
    />
  )
}
