import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs"

import { WatermelonError } from "@treefort/lib/errors"

import authenticator from "../../lib/authenticator"
import confirm from "../../lib/confirm"
import { logError } from "../../lib/logging"
import migrations from "../migrations"
import schema from "../schema"
import { syncManager } from "../sync"

const adapter = new LokiJSAdapter({
  schema,
  migrations,
  useWebWorker: false,
  useIncrementalIndexedDB: true,

  onQuotaExceededError: async (cause) => {
    // Browser ran out of disk space -- offer to reload the app
    logError(
      new WatermelonError("QuotaExceededError: browser ran out of disk space", {
        cause,
      }),
    )
    if (
      await confirm({
        title: "Out of disk space",
        message:
          "Insufficient disk space to maintain offline database; do you want to reload the app?",
        cancelLabel: "Cancel",
        confirmLabel: "Reload",
        danger: true,
      })
    ) {
      window.location.reload()
    }
  },
  onSetUpError: async (cause) => {
    // Database failed to load -- offer to reload the app
    logError(new WatermelonError("Error setting up LokiJSAdapter", { cause }))

    if (
      await confirm({
        title: "Initialization failure",
        message:
          "Failed to initialize offline database; do you want to try reloading the app?",
        cancelLabel: "Cancel",
        confirmLabel: "Reload",
        danger: true,
      })
    ) {
      window.location.reload()
    }
  },
  extraIncrementalIDBOptions: {
    onDidOverwrite: async () => {
      // Called when this adapter is forced to overwrite contents of IndexedDB.
      // This happens if there's another open tab of the same app that's making changes.
      // Try to synchronize the app now, and if user is offline, alert them that if they close this
      // tab, some data may be lost
      await syncManager.requestSync({ syncType: "immediate" })
    },
    onversionchange: () => {
      // database was deleted in another browser tab (user logged out), so we must make sure we delete
      // it in this tab as well - usually best to just refresh the page
      if (authenticator.getUser()) {
        window.location.reload()
      }
    },
  },
})

export { adapter }
