import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAuth } from "@treefort/lib/auth-provider"
import { DisplayableError } from "@treefort/lib/displayable-error"

import { Button } from "../../../components/button"
import Spacer from "../../../components/spacer"
import Text from "../../../components/text"
import {
  refetchUserSubscriptions,
  useUserSubscriptions,
} from "../../../hooks/subscriptions"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useNavigate } from "../../../hooks/use-navigate"
import api from "../../../lib/api"
import { logError } from "../../../lib/logging"
import {
  canCancelSubscription,
  getCurrentSubscription,
} from "../../../lib/subscriptions"
import MenuLayout from "../../layouts/menu"

export function CancelSubscriptionScreen(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()
  const userSubscriptions = useUserSubscriptions()
  const subscription = getCurrentSubscription(userSubscriptions?.data)
  const [canceling, setCanceling] = useState(false)

  async function cancelSubscription() {
    try {
      await setCanceling(true)
      await api.delete(
        `/users/${auth.user?.id}/subscriptions/${subscription?.id}`,
      )
      await refetchUserSubscriptions()
    } catch (error) {
      logError(
        new DisplayableError(
          t("Something went wrong, please try again."),
          error,
        ),
      )
    }
  }

  const asyncViewProps = useAsyncViewPropsForQueries(userSubscriptions, {
    forceLoading: canceling,
  })

  // Navigate back to the subscription page if a) the user just canceled their
  // subscription or b) the user ended up here but doesn't have a subscription
  // that we can cancel.
  useEffect(() => {
    if (userSubscriptions.isSuccess && !canCancelSubscription(subscription)) {
      navigate("/menu/subscription")
    }
  }, [navigate, userSubscriptions.isSuccess, subscription])

  return (
    <MenuLayout asyncViewProps={asyncViewProps}>
      <Text textStyle="body">
        {subscription?.provider === "groupMembership" ||
        !subscription?.currentPeriodEndsAt
          ? t(
              "Press the button below to cancel your subscription. You will lose access to the benefits of your subscription immediately.",
            )
          : t(
              "Press the button below to cancel your subscription. You may continue to access the benefits of your subscription until {{date}}.",
              { date: new Date(subscription.currentPeriodEndsAt) },
            )}
      </Text>
      <Spacer size="large" />
      <Button type="danger" onPress={cancelSubscription}>
        {t("Cancel subscription")}
      </Button>
    </MenuLayout>
  )
}
