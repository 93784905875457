import React, { useCallback } from "react"
import { View, ViewProps } from "react-native"

import styled from "styled-components/native"

import icons from "@treefort/tokens/admin/icons"

import { Track } from "../lib/audio-player"
import Icon from "./icon"
import Row from "./row"
import Text from "./text"
import Touchable from "./touchable"

const ChevronRight = styled(Icon)`
  margin-top: 2px;
  margin-left: 4px;
`

// flex: 1 is required to get text truncation
// with ellipses working on the web
const TitleLinesView = styled(View)`
  flex: 1;
`

const TitleContainer = styled(View)`
  width: 100%;
`

export default function Title({
  track,
  onPress,
  style,
}: {
  track: Track
  onPress?: (track: Track) => void
  style?: ViewProps["style"]
}): JSX.Element | null {
  const onPressCallback = useCallback(() => {
    if (onPress) {
      onPress(track)
    }
  }, [onPress, track])

  // If we weren't given any non-empty lines then don't render anything
  const lines = [track.title, track.album].filter(Boolean)
  if (lines.length === 0) {
    return null
  }

  // Separate out the first line and render it in bold.
  const firstLine = (
    <Text textStyle="headingSmall" numberOfLines={1}>
      {lines[0]}
    </Text>
  )
  const otherLines = lines.slice(1).map((line) => (
    <Text key={line} textStyle="caption" numberOfLines={1} color="secondary">
      {line}
    </Text>
  ))

  // Return a touchable if props.onPress was provided
  return (
    <TitleContainer style={style}>
      {onPress ? (
        <Touchable onPress={onPressCallback} role="link" aria-label={lines[0]}>
          <Row>
            <TitleLinesView>
              {firstLine}
              {otherLines}
            </TitleLinesView>
            <ChevronRight source={icons.chevronRight} size="small" />
          </Row>
        </Touchable>
      ) : (
        <>
          {firstLine}
          {otherLines}
        </>
      )}
    </TitleContainer>
  )
}
