import React, { useEffect, useMemo, useRef } from "react"
import { View, Animated, StyleSheet, Easing } from "react-native"

import { ResolvedTokens, useTokens } from "./tokens-provider"

/**
 * Adapted from https://github.com/RobertFOConnor/react-native-progress-wheel,
 * converted to TypeScript + hooks, tweaked to fit our needs.
 */
export default function ProgressCircle({
  // The diameter of the circle
  size = 200,
  // A number between 0 and 1
  progress = 0,
  color,
  trackColor,
  backgroundColor,
  animatedValue: animatedValueProp,
}: {
  size: number
  progress?: number
  color?: string
  trackColor?: string
  backgroundColor?: string
  animatedValue?: Animated.Value
}): JSX.Element {
  const prevProgress = useRef(progress)
  const animatedValue = useRef(
    animatedValueProp || new Animated.Value(progress),
  )
  const { tokens } = useTokens()

  const styles = useMemo(
    () => getStyles({ size, tokens, color, trackColor, backgroundColor }),
    [size, color, trackColor, backgroundColor, tokens],
  )

  useEffect(() => {
    if (progress !== prevProgress.current) {
      Animated.timing(animatedValue.current, {
        toValue: progress,
        duration: tokens.progressCircle.animationDuration,
        easing: Easing.inOut(Easing.exp),
        useNativeDriver: true,
      }).start()
      prevProgress.current = progress
    }
  }, [progress, tokens.progressCircle.animationDuration])

  return (
    <View style={[styles.container, { transform: [{ rotate: "-90deg" }] }]}>
      <View style={styles.background} />
      <HalfCircle
        styles={styles}
        isSecondHalf={false}
        animatedValue={animatedValue.current}
      />
      <View style={styles.halfCircle}>
        <View
          style={{
            ...styles.cutOff,
            borderColor: backgroundColor || tokens.colors.background.primary,
          }}
        />
      </View>
      <View style={styles.halfCircle}>
        <View style={styles.cutOff} />
      </View>
      <View style={styles.secondHalfContainer}>
        <HalfCircle
          styles={styles}
          isSecondHalf={true}
          animatedValue={animatedValue.current}
        />
      </View>
    </View>
  )
}

function HalfCircle({
  animatedValue,
  styles,
  isSecondHalf,
}: {
  animatedValue: Animated.Value
  styles: ReturnType<typeof getStyles>
  isSecondHalf: boolean
}): JSX.Element {
  return (
    <Animated.View
      style={[
        styles.container,
        {
          opacity: isSecondHalf
            ? animatedValue.interpolate({
                inputRange: [0.5, 0.5001],
                outputRange: [0, 1],
                extrapolate: "clamp",
              })
            : 1,
          transform: [
            {
              rotate: animatedValue.interpolate({
                inputRange: isSecondHalf ? [0.5, 1] : [0, 0.5],
                outputRange: ["0deg", "180deg"],
                extrapolate: "clamp",
              }),
            },
          ],
        },
      ]}
    >
      <View
        style={[
          styles.halfCircle,
          isSecondHalf && {
            bottom: 0,
            transform: [{ rotate: "180deg" }],
          },
        ]}
      >
        <View style={styles.circleArc} />
      </View>
    </Animated.View>
  )
}

const getStyles = ({
  size,
  tokens,
  color,
  trackColor,
  backgroundColor,
}: {
  size: number
  tokens: ResolvedTokens
  color?: string
  trackColor?: string
  backgroundColor?: string
}) =>
  StyleSheet.create({
    container: {
      width: size,
      height: size,
      borderRadius: size / 2,
    },
    background: {
      width: size,
      height: size,
      borderRadius: size / 2,
      borderWidth: tokens.progressCircle.thickness,
      borderColor: trackColor || tokens.progressCircle.color.empty,
      position: "absolute",
    },
    cutOff: {
      backgroundColor: backgroundColor || tokens.colors.background.primary,
      width: size,
      height: size,
      borderWidth: tokens.progressCircle.thickness,
      borderColor: trackColor || tokens.progressCircle.color.empty,
      borderRadius: size / 2,
    },
    secondHalfContainer: {
      position: "absolute",
    },
    halfCircle: {
      width: size,
      height: size / 2,
      overflow: "hidden",
      position: "absolute",
    },
    circleArc: {
      width: size,
      height: size,
      borderColor: color || tokens.progressCircle.color.fill,
      borderRadius: size / 2,
      borderWidth: tokens.progressCircle.thickness,
    },
  })
