import React, { useState, useCallback, useMemo, useRef, useEffect } from "react"
import { Animated, LayoutChangeEvent, Platform, ViewStyle } from "react-native"
import styled from "styled-components/native"
import {
  HandlerStateChangeEvent,
  PanGestureHandlerEventPayload,
  State as PanState,
} from "react-native-gesture-handler"
import Time from "./time"
import PanGestureHandler from "./pan-gesture-handler"
import audioPlayer, { Event } from "../lib/audio-player"
import { useNativeDriver } from "../lib/animation-use-native-driver"
import { useProgress, useSeekable } from "../hooks/audio"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

// HACK: Applying `transform: translateZ(0);` fixes a known bug with Safari
// where `overflow: hidden;` doesn't work properly when both border radii and
// transforms are at play. See:
// https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
const trackBackgroundSafariStyleHack =
  Platform.OS === "web"
    ? ({ transform: [{ translateZ: 0 }] } as unknown as ViewStyle)
    : undefined

export const ProgressContainer = styled.View<{ width?: number }>`
  position: relative;
  flex-shrink: 0;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
`

const Track = styled.View`
  flex-direction: row;
  align-items: center;
  height: ${({ theme }) => theme.minTapTarget}px;
`

const TrackBackground = styled.View`
  width: 100%;
  height: ${({ theme }) => theme.audioPlayerProgress.trackHeight}px;
  border-radius: ${({ theme }) => theme.audioPlayerProgress.trackHeight / 2}px;
  background-color: ${({ theme }) =>
    theme.audioPlayerProgress.trackBackgroundColor};
  overflow: hidden;
`

const TrackProgress = styled(Animated.View)`
  position: absolute;
  right: 100%;
  width: 100%;
  height: ${({ theme }) => theme.audioPlayerProgress.trackHeight}px;
  border-radius: ${({ theme }) => theme.audioPlayerProgress.trackHeight / 2}px;
  background-color: ${({ theme }) => theme.audioPlayerProgress.color};
`

const HandleContainer = styled(Animated.View)`
  position: absolute;
  left: ${({ theme }) => -theme.minTapTarget / 2}px;
  width: ${({ theme }) => theme.minTapTarget}px;
  height: ${({ theme }) => theme.minTapTarget}px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const HandleDisplayBackground = styled.View<{ show: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.primary};
  width: ${({ theme }) => theme.audioPlayerProgress.handleRadius * 2}px;
  height: ${({ theme }) => theme.audioPlayerProgress.handleRadius * 2}px;
  border-radius: ${({ theme }) => theme.audioPlayerProgress.handleRadius}px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  align-items: center;
  justify-content: center;
`

const HandleDisplayForeground = styled.View`
  background-color: ${({ theme }) => theme.audioPlayerProgress.handleColor};
  width: ${({ theme }) => theme.audioPlayerProgress.handleRadius * 2 - 4}px;
  height: ${({ theme }) => theme.audioPlayerProgress.handleRadius * 2 - 4}px;
  border-radius: ${({ theme }) => theme.audioPlayerProgress.handleRadius}px;
`

const TimeLabelContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
`

/**
 * Render the start/end time labels. These labels live in their own component
 * because they re-render a *lot* (every second + continuously if the user is
 * seeking).
 */
function TimeLabels({
  duration,
  width,
  initialPosition,
  positionX,
}: {
  duration: number
  width: number
  initialPosition: number
  positionX: Animated.AnimatedInterpolation<number>
}) {
  const [position, setPosition] = useState(initialPosition)

  useEffect(() => {
    if (width > 0) {
      const listener = positionX.addListener((event) =>
        setPosition(duration * (event.value / width)),
      )
      return () => positionX.removeListener(listener)
    }
  }, [width, duration, positionX])

  return (
    <TimeLabelContainer pointerEvents="none">
      <Time textStyle="caption">{duration > 0 ? position : undefined}</Time>
      <Time textStyle="caption" countdown={true}>
        {duration > position ? duration - position : undefined}
      </Time>
    </TimeLabelContainer>
  )
}

export default function AudioPlayerProgress(props: {
  width?: number
}): JSX.Element {
  const [isSeeking, setIsSeeking] = useState(false)
  const [width, setWidth] = useState<number>(props.width || 0)
  const { position = 0, duration = 0, index } = useProgress()
  const seekable = useSeekable()
  const willUnmount = useWillUnmount()

  // The audio player may technically be seekable without a set duration, but we
  // can only show a seekable UI if we have a duration.
  const showSeekable = seekable && duration > 0

  const currentX = (duration > 0 ? position / duration : 0) * width

  // Why the three variables here to track the current position instead of one?
  // This bit o complexity is needed because the pan gesture event used to track
  // the user's finger only gives us the distance panned. To translate this
  // into the actual x position of their finger we have to do some math, and to
  // do math with RN's Animated API we need all these variables. We use the
  // Animated API because that allows us to keep the seek animation on the UI
  // thread (away from JS) which makes things much smoother (no re-renders while
  // panning - except for the time labels).
  const seekFromX = useRef(new Animated.Value(currentX))
  const seekX = useRef(new Animated.Value(0))
  const positionX = useMemo(
    () =>
      Animated.add(seekX.current, seekFromX.current).interpolate({
        inputRange: [0, width],
        outputRange: [0, width],
        extrapolate: "clamp",
      }),
    [width],
  )
  // Tie a ref to the positionX animated variable so we can access it when we
  // need to trigger the onChange handler (there's no syncronous way to get an
  // animated variable's value, and the data in the event sent to onPanGestureChange
  // is not enough to calcuate the final position on its own). This ref's value
  // is updated via an animation event listener in an effect below.
  const positionXRef = useRef(currentX)

  // Attatch the seekX variable to the pan gesture
  const panGestureEvent = Animated.event(
    [{ nativeEvent: { translationX: seekX.current } }],
    { useNativeDriver },
  )

  // Do stuff when the pan gesture state changes (entering a pan, exiting a pan,
  // etc.)
  const onPanGestureChange = useCallback(
    (event: HandlerStateChangeEvent<PanGestureHandlerEventPayload>) => {
      const { state } = event.nativeEvent
      if (state === PanState.ACTIVE) {
        setIsSeeking(true)
      } else if (state === PanState.END) {
        const nextCurrentX = positionXRef.current
        audioPlayer
          .once([Event.Seeked, Event.SeekCancelled])
          .then(() => setIsSeeking(false))
        audioPlayer.skipToPosition(duration * (nextCurrentX / width))
        seekFromX.current.setValue(nextCurrentX)
        seekX.current.setValue(0)
      } else {
        setIsSeeking(false)
      }
    },
    [duration, width],
  )

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (!props.width && !willUnmount.current) {
        setWidth(event.nativeEvent.layout.width)
      }
    },
    [props.width, willUnmount],
  )

  // When we're not panning, make the seek variables track the currentX prop.
  useEffect(
    () => {
      if (!isSeeking) {
        seekX.current.setValue(0)
        seekFromX.current.setValue(currentX)
      }
    },
    // We only want to force an update to the animation when we get a new currentX
    // value (indicating that the current position has changed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentX],
  )

  // Reset the seek variables when changing tracks
  useEffect(
    () => {
      seekX.current.setValue(0)
      seekFromX.current.setValue(currentX)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index],
  )

  // Update the positionXRef any time the animation value changes.
  useEffect(() => {
    const listener = positionX.addListener((event) => {
      positionXRef.current = event.value
    })
    return () => positionX.removeListener(listener)
  }, [positionX])

  return (
    <ProgressContainer onLayout={onLayout} width={props.width}>
      <Track>
        <TrackBackground style={trackBackgroundSafariStyleHack}>
          <TrackProgress style={{ transform: [{ translateX: positionX }] }} />
        </TrackBackground>
        <PanGestureHandler
          enabled={showSeekable}
          activeOffsetX={[0, 0]}
          onGestureEvent={panGestureEvent}
          onHandlerStateChange={onPanGestureChange}
        >
          <HandleContainer style={{ transform: [{ translateX: positionX }] }}>
            <HandleDisplayBackground show={showSeekable}>
              <HandleDisplayForeground />
            </HandleDisplayBackground>
          </HandleContainer>
        </PanGestureHandler>
      </Track>
      <TimeLabels
        duration={duration}
        width={width}
        initialPosition={position}
        positionX={positionX}
      />
    </ProgressContainer>
  )
}
