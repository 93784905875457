import {
  QueryClientProvider,
  QueryClient,
  setLogger,
  focusManager,
} from "react-query"
import { AppState, AppStateStatus } from "react-native"
import isAccessTokenError from "@treefort/lib/is-access-token-error"
import { logError, logMessage } from "./logging"
import { isAxiosError } from "@treefort/lib/is-axios-error"

const MILLISECONDS_PER_MINUTE = 1000 * 60

// The time in milliseconds until we consider cached data "stale" and allow it
// to be refetched
const STALE_TIME = MILLISECONDS_PER_MINUTE * 5

// The time in milliseconds that data is kept in the cache after no mounted
// components are using it
const CACHE_TIME = MILLISECONDS_PER_MINUTE * 5

// The number of additional times that failing queries are retried
const RETRY_COUNT = 2

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // We retry access token errors via Axios, so don't bother retrying them
      // at this level
      retry: (failureCount, error) =>
        failureCount <= RETRY_COUNT &&
        !isAccessTokenError(error) &&
        !(isAxiosError(error) && error.response?.status === 404) &&
        !(isAxiosError(error) && error.response?.status === 403),
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
    },
  },
})

// Use AppState to trigger react-query's refetchOnWindowFocus behavior.
// See: https://react-query.tanstack.com/guides/window-focus-refetching#managing-focus-in-react-native
focusManager.setEventListener((handleFocus) => {
  const onAppStateChange = (state: AppStateStatus) => {
    handleFocus(state === "active")
  }
  const sub = AppState.addEventListener("change", onAppStateChange)
  return () => sub.remove()
})

// Tell react-query to use our custom log functions for logging
setLogger({ log: logMessage, warn: logMessage, error: logError })

export { queryClient, QueryClientProvider }
