import React from "react"

import { AppModuleBanner } from "@treefort/api-spec"

import {
  BannerModule,
  getBannerHeight,
} from "../../../../components/modules/banner"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleBanner["config"]

export class BannerListViewModule
  implements ListViewModule<AppModuleBanner, Item>
{
  readonly appModule: AppModuleBanner
  readonly appHeaderPresentation = "transparent"
  readonly appHeaderState = undefined
  readonly gapSize = 0
  readonly maxWidth = undefined

  constructor(appModule: AppModuleBanner) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <BannerModule
      {...args.item}
      height={this.getItemSize(args)}
      scrollRestorationKey={this.getItemKey(args)}
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id

  getItemSize = (args: ItemArgs<Item>) =>
    getBannerHeight({
      tokens: args.tokens,
      viewportHeight: args.displayHeight - args.safeAreaInsets.top,
      size: this.appModule.config.size,
    }) + args.safeAreaInsets.top
}
