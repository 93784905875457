import { useCallback } from "react"

import { Availability } from "@treefort/api-spec"

import authenticator from "../lib/authenticator"
import { checkoutSessionManager } from "../lib/checkout/session"
import { isParent } from "../lib/parental-gateway"
import { useNavigate } from "./use-navigate"
import { useParentalGateway } from "./use-parental-gateway"
import { useRoute } from "./use-route"

export const useOpenCheckoutPage = ({
  availability,
  contentId,
}: {
  /**
   * Pass this if the checkout page is being opened b/c of availability issues
   * (e.g. attempting to access locked content or features)
   */
  availability?: Availability
  /**
   * Pass this if the checkout page is being opened in order to access a
   * specific content item (e.g. if "Subscribe to watch" is tapped on a video)
   */
  contentId?: number
} = {}) => {
  const parentalGateway = useParentalGateway()
  const {
    params: { recommId },
  } = useRoute()

  const navigate = useNavigate()

  return useCallback(async () => {
    if (parentalGateway && !(await isParent())) return

    if (
      availability?.status === "notAvailable" &&
      availability.reasonCode === "notAuthenticated"
    ) {
      // Open the auth registration flow directly when the user has requested to
      // create a free account.
      await authenticator.register()
    } else {
      // End any outstanding checkout session (this'll prevent the checkout page
      // from showing a loading state right off the bat)
      await checkoutSessionManager.endSession({ complete: false })
      navigate("/menu/checkout", {
        recommId,
        // NOTE: Don't use the parameter name "contentId" here - that would
        // cause isContentRoute to return true for the checkout page and wreak
        // all sorts of havoc.
        checkoutContentId: contentId,
        ...(availability?.status === "notAvailable" &&
        availability.reasonCode === "offeringRequired"
          ? {
              offeringIds: availability.data.offeringIds,
            }
          : undefined),
      })
    }
  }, [navigate, parentalGateway, availability, recommId, contentId])
}
