import { WATERMELON_SYNC_TABLE_NAMES } from "@treefort/constants"
import { Semaphore } from "@treefort/lib/semaphore"
import { IKeyValueStore } from "@treefort/lib/types/settings"
import { keyToId } from "@treefort/lib/watermelon-key-to-id"

import authenticator from "../../lib/authenticator"
import { debug as appDebug } from "../../lib/logging"
import { getClient } from "../db"
import { SourceAwareModel } from "../models/source-aware-model"
import { syncManager } from "../sync"
import { Deleter } from "./behaviors/deleter"
import { Finder } from "./behaviors/finder"
import { Updater } from "./behaviors/updater"
import { DatabaseFieldsOf } from "./types"

const debug = appDebug.extend("sync:keyValueStore")

const ANONYMOUS_USER_ID = "anonymous"

const semaphore = new Semaphore(1)

type KeyValueModel = SourceAwareModel & { data: string }

export abstract class KeyValueStore<TModel extends KeyValueModel>
  implements IKeyValueStore
{
  private table: (typeof WATERMELON_SYNC_TABLE_NAMES)[number]

  private finder: Finder<TModel>
  private updater: Updater<KeyValueModel>
  private deleter: Deleter<TModel>

  constructor(table: (typeof WATERMELON_SYNC_TABLE_NAMES)[number]) {
    this.table = table
    this.finder = new Finder<TModel>(table)
    this.updater = new Updater<KeyValueModel>(table, this.finder)
    this.deleter = new Deleter<TModel>(this.finder)
  }

  protected getUserId() {
    return authenticator.getUser()?.id || ANONYMOUS_USER_ID
  }

  private async create(
    id: string,
    value: DatabaseFieldsOf<SourceAwareModel> & {
      data: string
    },
  ) {
    const database = getClient()
    const result = await database.write(async () => {
      const createDate = new Date().getTime()

      return await database.get<TModel>(this.table).create((model) => {
        Object.assign(model, value)

        // Watermelon docs recommend against setting ID, but they also
        // provide no way to ensure uniqueness based on anything besides the
        // autogenerated primary key. For a key-value store such as Progress,
        // this doesn't work, because the user could start playing the same
        // content on two different devices and easily generate two different
        // IDs for the same progress key. So we're overriding the key here.
        // This key is prefixed with the table name to ensure uniqueness across
        // all collections, since Watermelon makes that assumption.
        model._raw.id = id

        model.createdAtDate = createDate
        model.updatedAtDate = createDate
        model.syncIgnore_createdLocally = true
      })
    })

    syncManager.requestSync({ syncType: "user-initiated" })

    return result
  }

  async get(key: string, options?: { profileId: string | null }) {
    const userId = this.getUserId()
    const id = keyToId({
      collection: this.table,
      key,
      userId,
      profileId: options?.profileId,
    })
    const value = await this.finder.find(id)

    return value?.data || null
  }

  async set(
    key: string,
    value: string,
    options?: { profileId: string | null },
  ) {
    // The lock is required to avoid concurrent creates, as progress is sometimes
    // saved very frequently and there can be a race between the initial create of
    // a progress and the find of a subsequent set (which should be an update).
    const lock = await semaphore.enter()

    try {
      // Setting an anonymous user ID permits a logged-out user to
      // track progress locally on the device for an indefinite period of
      // time, but anonymous records won't be synced (all records not matching
      // the logged-in user are filtered in the sync push logic) and everything
      // gets wiped on logout, so they won't grow forever.
      const userId = authenticator.getUser()?.id || ANONYMOUS_USER_ID
      const id = keyToId({
        collection: this.table,
        key,
        userId,
        profileId: options?.profileId,
      })
      const existing = await this.finder.find(id)

      if (!existing) {
        debug("No existing record found; checking deleted records.", { id })

        const deletedRecords = await getClient().adapter.getDeletedRecords(
          this.table,
        )

        debug("Deleted records:", { deletedRecords })

        if (deletedRecords.includes(id)) {
          debug(
            "ID of record being created is present in our list of deleted IDs. Purging deleted record so we can re-create.",
            { id },
          )
          // Special case: a record has been deleted but not yet synced,
          // which means it hasn't been removed from the local database and
          // attempting to insert it again will result in a duplicate key violation.
          // By deleting it here and then re-creating it, our server-side
          // sync code will handle things gracefully and treat it as an update.
          await getClient().adapter.destroyDeletedRecords(this.table, [id])
        }

        debug("Creating record.", {
          id,
          userId,
          profileId: options?.profileId,
          data: value,
        })

        await this.create(id, {
          userId,
          profileId: options?.profileId ?? null,
          data: value,
        })

        return
      }

      await this.updater.update(existing.id, { data: value })
    } finally {
      lock.release()
    }

    syncManager.requestSync({ syncType: "periodic" })
  }

  public async delete(key: string, options?: { profileId: string | null }) {
    const lock = await semaphore.enter()

    try {
      const userId = this.getUserId()

      await this.deleter.delete(
        keyToId({
          collection: this.table,
          key,
          userId,
          profileId: options?.profileId,
        }),
      )
    } finally {
      lock.release()
    }

    syncManager.requestSync({ syncType: "periodic" })
  }
}
