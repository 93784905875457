import { useSafeAreaInsets } from "react-native-safe-area-context"

import { useTokens } from "../components/tokens-provider"
import { useTracks } from "./audio"
import { useBottomTabsHeight } from "./use-bottom-tabs-height"

/**
 * This returns the number of pixels at the bottom of the viewport that the app
 * footer might cover. This can be used to push content up so that it is not
 * obscured by the footer.
 */
const useSafeAreaInsetAppFooter = (): number => {
  const { tokens } = useTokens()
  const insets = useSafeAreaInsets()
  const audioPlayerInlineShown = useTracks().length > 0
  const bottomTabsHeight = useBottomTabsHeight()
  return tokens.appFooter.mode === "desktop"
    ? 0
    : bottomTabsHeight +
        (audioPlayerInlineShown ? tokens.audioPlayerInline.height : 0) +
        insets.bottom
}

export default useSafeAreaInsetAppFooter
