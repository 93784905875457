import React from "react"
import { useTranslation } from "react-i18next"

import IconButton from "../../../components/icon-button"
import useAppManifest from "../../../hooks/use-app-manifest"
import { useMenu } from "../../../hooks/use-menu"

export function MenuCloseButton(): JSX.Element {
  const manifest = useAppManifest()
  const menu = useMenu()
  const { t } = useTranslation()

  return (
    <IconButton
      source={manifest.icons.close}
      iconSize="medium"
      onPress={menu.close}
      label={t("Close")}
    />
  )
}
