import unique from "@treefort/lib/unique"
import { EbookReaderLocation } from "../ebook-reader"
import { BaseProgressItem } from "./base"

type ProgressReadable = {
  percent: number
  finished: boolean
}

export enum ReadableProgressItemEvent {
  ProgressUpdated = "PROGRESS_UPDATED",
}

interface ReadableProgressItemEventMap {
  [ReadableProgressItemEvent.ProgressUpdated]: {
    updatedFields: Array<"percent" | "finished">
  }
}

export type EbookProgressItemData = {
  contentType: "ebook"
  contentId: number
  progress?: ProgressReadable
  location?: EbookReaderLocation
}

export type WebEmbedProgressItemData = {
  contentType: "webEmbed"
  contentId: number
  progress?: ProgressReadable
}

export type ReadableProgressItemData =
  | EbookProgressItemData
  | WebEmbedProgressItemData

export class ReadableProgressItem<
  Data extends ReadableProgressItemData = ReadableProgressItemData,
> extends BaseProgressItem<
  Data,
  ProgressReadable,
  ReadableProgressItemEventMap
> {
  getKeySuffix = (): string => `${this.data.contentType}.${this.data.contentId}`

  getProgress = (): ProgressReadable => ({
    percent: this.data.progress?.percent || 0,
    finished: this.data.progress?.finished || false,
  })

  updateProgress = (progress: Partial<ProgressReadable>): void => {
    // Generate the next progress object and determine which fields were updated
    const nextProgress = { ...this.data.progress, ...progress }
    const fields = unique(Object.keys(this.getProgress())) as Array<
      keyof ProgressReadable
    >
    const updatedFields = fields.filter(
      (key) => nextProgress[key] !== this.data.progress?.[key],
    )

    // If at least one field changed then replace our internal progress object
    // and emit a ProgressUpdated event
    if (updatedFields.length > 0) {
      this.data = {
        ...this.data,
        progress: nextProgress as typeof this.data.progress,
      }
      this.emitter.emit(ReadableProgressItemEvent.ProgressUpdated, {
        updatedFields,
      })
    }
  }
}

export class EbookProgressItem extends ReadableProgressItem<EbookProgressItemData> {
  getLocation = (): EbookReaderLocation | undefined => this.data.location

  setLocation = (location: EbookReaderLocation): void => {
    this.data.location = location
  }
}

export class WebEmbedProgressItem extends ReadableProgressItem<WebEmbedProgressItemData> {}
