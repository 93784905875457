import React from "react"
import { getAbsoluteLineHeight } from "../../lib/text-style"
import Row from "../row"
import Text from "../text"
import { ResolvedTokens } from "../tokens-provider"
import { useTranslation } from "react-i18next"

export function getLibraryOfflineMessageHeight(tokens: ResolvedTokens): number {
  return getAbsoluteLineHeight("body", tokens) * 2
}

export function LibraryOfflineMessage({
  height,
}: {
  height: number
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <Row
      paddingHorizontal="pagePaddingHorizontal"
      alignItems="center"
      justifyContent="center"
      height={height}
    >
      <Text textStyle="body" alignment="center">
        {t("You are offline. Only downloaded content will be playable.")}
      </Text>
    </Row>
  )
}
