"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.feature = void 0;
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
exports.feature = {
    sliderMarginVertical: base_1.spacing.medium,
    backgroundColor: (0, helpers_1.displayModeToken)({
        dark: base_1.colors.black,
        light: base_1.colors.background.primary.dark,
    }),
};
