import React, { useCallback, useEffect, useState } from "react"
import {
  LayoutChangeEvent,
  ViewStyle,
  Image as ReactNativeImage,
} from "react-native"

import styled from "styled-components/native"

import tokens from "@treefort/tokens/app"

import { ImageWithFade } from "./image-with-fade"

export type BorderRadius = keyof typeof tokens.borderRadius

const Container = styled.View`
  align-items: center;
  justify-content: center;
`

const StyledImage = styled(ImageWithFade)<{
  // NOTE: iOS gets angry if we call this prop borderRadius ¯\_(ツ)_/¯
  radius: BorderRadius
  imageLoaded: boolean
  imageMissing: boolean
  width: number
  height: number
}>`
  resize-mode: cover;
  border-radius: ${(props) => props.theme.borderRadius[props.radius]}px;
  opacity: ${(props) => (props.imageLoaded || props.imageMissing ? 1 : 0)};
  width: ${(props) => (props.width ? `${props.width}px` : undefined)};
  height: ${(props) => (props.height ? `${props.height}px` : undefined)};
  ${(props) =>
    props.imageMissing
      ? `background-color: ${props.theme.colors.loading.image}`
      : ""};
`

/**
 * Render an image at it's native aspect ratio (or a fixed aspect ratio using
 * the `aspectRatio` prop) within the bounds of a container. This component has
 * two benefits over `resizeMmode="contain"`:
 * - A border radius can be applied to the image.
 * - If the containerSize prop is supplied then it is possible to achieve a
 *   layout where the contained image only takes up the space that it needs (as
 *   opposed to taking up the space that the container needs).
 */
export default function ImageContained({
  uri,
  aspectRatio,
  containerStyle,
  containerSize: containerSizeProp,
  borderRadius = "square",
  onReady,
}: {
  uri?: string
  aspectRatio?: number
  containerStyle?: ViewStyle
  containerSize?: { width: number; height: number }
  borderRadius?: BorderRadius
  onReady?: (ready: boolean) => unknown
}) {
  const renderContainer = !containerSizeProp
  const [containerSizeState, setContainerSizeState] = useState({
    width: 0,
    height: 0,
  })
  const containerSize = containerSizeProp ?? containerSizeState
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const [error, setError] = useState(false)
  const onLayout = useCallback(
    (event: LayoutChangeEvent) =>
      setContainerSizeState(event.nativeEvent.layout),
    [],
  )
  const viewAspectRatio = containerSize.width / containerSize.height
  const imageLoaded = imageSize.width > 0 && containerSize.width > 0
  const imageMissing = !uri || error
  const imageAspectRatio =
    aspectRatio !== undefined
      ? aspectRatio
      : imageLoaded
        ? imageSize.width / imageSize.height
        : 1
  const imageWidth =
    viewAspectRatio > imageAspectRatio
      ? containerSize.height * imageAspectRatio
      : containerSize.width
  const imageHeight =
    viewAspectRatio > imageAspectRatio
      ? containerSize.height
      : containerSize.width / imageAspectRatio

  // Key the element by source to ensure that it re-renders and calls
  // onLayout if the image is changed
  const key = uri

  useEffect(() => {
    if (onReady) {
      onReady(imageLoaded || imageMissing)
    }
  }, [imageLoaded, imageMissing, onReady])

  useEffect(() => {
    if (uri) {
      ReactNativeImage.getSize(
        uri,
        (width, height) => setImageSize({ width, height }),
        () => setError(true),
      )
    }
  }, [uri])

  const image = (
    <StyledImage
      imageLoaded={imageLoaded}
      imageMissing={imageMissing}
      width={imageWidth}
      height={imageHeight}
      radius={borderRadius}
      source={{ uri }}
      recyclingKey={key}
      style={
        // If we're rendering a container and measuring that to get the
        // container size then position the image absolutely to ensure that it
        // doesn't affect the container's layout (potentially triggering an
        // infinite layout change loop).
        renderContainer ? { position: "absolute" } : undefined
      }
    />
  )

  return renderContainer ? (
    <Container key={key} onLayout={onLayout} style={containerStyle}>
      {image}
    </Container>
  ) : (
    image
  )
}
