import { DefaultTheme } from "styled-components/native"
import tokens from "@treefort/tokens/app"

type BackgroundColorToken = keyof typeof tokens.colors.background

export type BackgroundColor = keyof typeof tokens.colors.background | string

export const backgroundColorToString = <
  Value extends BackgroundColor | undefined,
>(
  tokens: DefaultTheme,
  backgroundColor: Value,
): Value extends undefined ? undefined : string =>
  (backgroundColor && backgroundColor in tokens.colors.background
    ? tokens.colors.background[backgroundColor as BackgroundColorToken]
    : backgroundColor) as Value extends undefined ? undefined : string
