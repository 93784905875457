"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractPossibleLastName = exports.extractPossibleFirstName = void 0;
/**
 * A very naive attempt to get a person's first name from their full name.
 * Results _will be inconsistent_. Only use this when best-effort results are
 * acceptable (e.g. when generating hashes for advertising data).
 */
function extractPossibleFirstName(fullName) {
    return (fullName === null || fullName === void 0 ? void 0 : fullName.includes(" ")) ? fullName.split(" ")[0] : undefined;
}
exports.extractPossibleFirstName = extractPossibleFirstName;
/**
 * A very naive attempt to get a person's last name from their full name.
 * Results _will be inconsistent_. Only use this when best-effort results are
 * acceptable (e.g. when generating hashes for advertising data).
 */
function extractPossibleLastName(fullName) {
    return (fullName === null || fullName === void 0 ? void 0 : fullName.includes(" ")) ? fullName.split(" ").pop() : undefined;
}
exports.extractPossibleLastName = extractPossibleLastName;
