import { css } from "styled-components/native"

import styleObjectToString from "../../lib/style-object-to-string"
import {
  getAbsoluteLineHeight,
  getTextStyleObject,
  TextStyle,
} from "../../lib/text-style"
import { withUnits } from "../../lib/with-units"

export const SMALL_TEXT_STYLE = "caption"

/* NOTE: on Android if the text input grows to fill the whole screen, then
  scrolling becomes pretty much impossible since the text input captures the
  focus every time you try to scroll, unless you scroll along the edges of the
  screen. */
export const MAX_HEIGHT = 250

export const inputStyles = css<{
  textStyle: TextStyle
  size: "small" | "medium"
  maxHeight?: number | string
  minHeight?: number | string
  height?: number | string
}>`
  ${({ theme, textStyle, size }) => {
    const textStyleProps = getTextStyleObject(textStyle, theme)
    const paddingVertical =
      size === "small"
        ? theme.spacing.small
        : // For the medium size vertical padding is a function of line height and
          // input height
          (theme.textInput.height - getAbsoluteLineHeight(textStyle, theme)) / 2
    return styleObjectToString({
      ...textStyleProps,
      // NOTE: padding-vertical doesn't seem to work on multiline text inputs on
      // iOS
      paddingTop: paddingVertical,
      paddingBottom: paddingVertical,
    })
  }};
  height: ${({ height = "auto" }) => withUnits(height)};
  max-height: ${({ maxHeight = "auto" }) => withUnits(maxHeight)};
  min-height: ${({ minHeight, theme, height }) =>
    withUnits(height || minHeight || theme.textInput.height)};
  border-radius: ${(props) => props.theme.textInput.borderRadius}px;
  background-color: ${(props) => props.theme.textInput.backgroundColor};
  padding-horizontal: ${(props) => props.theme.textInput.paddingHorizontal}px;
  color: ${(props) => props.theme.colors.text.primary};
`
