"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.button = void 0;
const base_1 = require("../base");
exports.button = {
    backgroundColor: {
        primary: base_1.colors.accent,
        secondary: base_1.colors.formControl,
        danger: base_1.colors.red500,
    },
    color: {
        primary: base_1.colors.white,
        secondary: base_1.colors.text.primary,
        danger: base_1.colors.white,
    },
    paddingHorizontal: {
        tiny: base_1.spacing.small,
        small: base_1.spacing.medium,
        medium: base_1.spacing.medium,
    },
    height: {
        tiny: 24,
        small: 36,
        medium: 42,
    },
};
