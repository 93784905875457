"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const filter = (obj, callback) => Object.entries(obj).reduce((result, [k, v]) => {
    const value = v;
    const key = k;
    if (callback(value, key)) {
        result[key] = value;
    }
    return result;
}, {});
exports.default = filter;
