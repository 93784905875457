import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
  FetchQueryOptions,
} from "react-query"

import { User } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import api from "../lib/api"
import authenticator from "../lib/authenticator"
import { logError } from "../lib/logging"
import { queryClient } from "../lib/query-client"
import useQueryKey, { getQueryKey } from "./use-query-key"

export const USER_INFO_QUERY_KEY = "user-info"

export default function useUserInfo<Result = User>(
  options?: UseQueryOptions<User, unknown, Result>,
): UseQueryResult<Result> {
  const auth = useAuth()
  const enabled =
    auth.user !== null && (options?.enabled === undefined || options.enabled)
  return useQuery(useQueryKey(USER_INFO_QUERY_KEY), getUserInfo, {
    // This helps keep subscription data up-to-date
    refetchOnWindowFocus: "always",
    ...options,
    enabled,
  })
}

export async function fetchUserInfo(
  options?: FetchQueryOptions<User | null>,
): Promise<User | null> {
  const user = authenticator.getUser()
  return user
    ? queryClient.fetchQuery(
        getQueryKey(user, USER_INFO_QUERY_KEY),
        getUserInfo,
        options,
      )
    : null
}

export function resetUserInfoQuery() {
  queryClient
    .resetQueries(getQueryKey(authenticator.getUser(), USER_INFO_QUERY_KEY))
    .catch((cause) =>
      logError(new Error("[User] Failed to reset user info query", { cause })),
    )
}

async function getUserInfo() {
  const result = await api.get("/auth/user")
  return result.data
}
