import useAppManifest from "./use-app-manifest"
import { useRoute } from "./use-route"

export function useParentalGateway() {
  const route = useRoute()
  const manifest = useAppManifest()
  const isParentArea =
    route.path.startsWith("/menu") && route.component !== "DownloadsScreen"
  // Enable the parental gateway if the feature is turned on via the manifest
  // and we're not already in a parent area.
  return manifest.features.parentalGateway && !isParentArea
}
