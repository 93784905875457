"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.promoCodeInput = void 0;
const base_1 = require("../base");
exports.promoCodeInput = {
    height: 44,
    maxWidth: {
        wide: 336,
        narrow: 304,
    },
    borderRadius: base_1.borderRadius.roundedMedium,
    borderColor: {
        default: base_1.colors.border.primary,
        success: base_1.colors.success,
    },
    backgroundColor: {
        default: "transparent",
        success: base_1.colors.green100,
    },
    paddingLeft: 40,
    paddingRight: base_1.spacing.small,
    tagIconContainer: {
        paddingLeft: 10,
    },
    textColor: {
        default: base_1.colors.text.primary,
        success: base_1.colors.text.success,
    },
    placeholderTextColor: base_1.colors.gray500,
};
