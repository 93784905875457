import miniSvgDataUri from "mini-svg-data-uri"
import React from "react"
import styled from "styled-components/native"

import strictEncodeUriComponent from "@treefort/lib/strict-encode-uri-component"
import {
  isSvgDataUri,
  isSvgString,
  setSvgDataUriColor,
} from "@treefort/lib/svg"

import Image, { ImageProps } from "../image"
import { useTokens } from "../tokens-provider"
import { IconImageProps, IconSize, IconState } from "./base"

const StyledImage = styled(Image)<{
  size: IconSize
  state: IconState
}>`
  width: ${(props) => props.theme.icon.size[props.size]}px;
  height: ${(props) => props.theme.icon.size[props.size]}px;
  opacity: ${(props) => props.theme.icon.opacity[props.state]};
`

export default function IconImage({
  state,
  size,
  color,
  source,
  style,
}: IconImageProps): JSX.Element {
  const { tokens } = useTokens()
  const colorResolved =
    color in tokens.colors.text
      ? tokens.colors.text[color as keyof typeof tokens.colors.text]
      : color
  return (
    <StyledImage
      style={style}
      source={
        isSvgString(source)
          ? (setSvgDataUriColor(
              miniSvgDataUri(source),
              strictEncodeUriComponent(colorResolved),
            ) as ImageProps["source"])
          : isSvgDataUri(source)
          ? (setSvgDataUriColor(
              source,
              strictEncodeUriComponent(colorResolved),
            ) as ImageProps["source"])
          : source
      }
      size={size}
      state={state}
      tintColor={
        // We tint SVGs by swapping the color directly in the source because
        // react-native-web's tinting strategy doesn't work well in Safari:
        // https://github.com/necolas/react-native-web/issues/1914
        !isSvgString(source) && !isSvgDataUri(source)
          ? colorResolved
          : undefined
      }
      resizeMode="contain"
    />
  )
}
