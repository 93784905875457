import React from "react"
import { useTranslation } from "react-i18next"

import i18next from "i18next"

import icons from "@treefort/tokens/app/icons"

import useAppManifest from "../hooks/use-app-manifest"
import { useBooleanState } from "../hooks/use-boolean-state"
import { useLocaleSetting } from "../hooks/use-locale-setting"
import Icon from "./icon"
import Row from "./row"
import Select from "./select"
import SettingsFieldset from "./settings-fieldset"
import SettingsRow from "./settings-row"
import Spacer from "./spacer"
import Text from "./text"
import Touchable from "./touchable"

export function SettingsLocale(): JSX.Element {
  const { t } = useTranslation()
  const manifest = useAppManifest()

  const [isOpen, open, close] = useBooleanState(false)

  const [_, setLocale] = useLocaleSetting()

  // Pull the current locale from i18next so that it's perfectly in sync with
  // the locale used by the app (using the value of the locale setting
  // introduces lag when changing the setting).
  const locale = i18next.language

  const options = Object.entries(manifest.i18n.locales)
    .map(([locale, data]) => {
      return {
        label: data.strings.translation.language_name,
        value: locale,
      }
    })
    // Sort the default locale first and then everything else alphabetically
    .sort((a, b) =>
      a.value === manifest.i18n.defaultLocale
        ? -1
        : a.value.localeCompare(b.value, locale.replace("_", "-")),
    )

  const handleLanguageSelect = (value: string) => {
    i18next.changeLanguage(value)
    setLocale(value)
    close()
  }

  return (
    <>
      {options ? (
        <SettingsFieldset title={t("Language")}>
          <Touchable onPress={open}>
            <SettingsRow label={t("Select language")}>
              <Select
                open={isOpen}
                options={options}
                i18nIsDynamicList={false}
                onClose={close}
                value={locale}
                label={t("Select language")}
                onChange={handleLanguageSelect}
              />
              <Row alignItems="center">
                <Text textStyle="strong" color="accent">
                  {options.find((option) => option.value === locale)?.label}
                </Text>
                <Spacer size="tiny" horizontal />
                <Icon
                  color="secondary"
                  size="small"
                  source={icons.chevronRight}
                />
              </Row>
            </SettingsRow>
          </Touchable>
        </SettingsFieldset>
      ) : null}
    </>
  )
}
