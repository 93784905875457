import { AppStateStatus } from "react-native"

export type AppState = "active" | "background"

/**
 * This converts React Native's "AppStateStatus" (either "active", "inactive",
 * or "background") into our "AppState" (either "active" or "background"). The
 * inactiveStateiOS field determine's how the iOS-only "inactive" state is
 * treated - either as "active" or "background". See:
 * https://reactnative.dev/docs/appstate#app-states
 */
export const getAppState = ({
  reactNativeAppState,
  inactiveStateiOS = "active",
}: {
  reactNativeAppState: AppStateStatus
  inactiveStateiOS?: AppState
}): AppState => {
  return reactNativeAppState === "inactive"
    ? inactiveStateiOS
    : reactNativeAppState === "active"
    ? "active"
    : "background"
}
