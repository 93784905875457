import React, { useCallback, useRef, useState } from "react"
import { View } from "react-native"

import styled from "styled-components/native"

import icons from "@treefort/tokens/app/icons"

import { getAbsoluteLineHeight } from "../lib/text-style"
import ActivityIndicator from "./activity-indicator"
import { AppLink } from "./app-link"
import { AppLinkProps } from "./app-link/types"
import Icon from "./icon"
import Row from "./row"
import Text from "./text"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

type ActionLinkProps = {
  to?: AppLinkProps["to"]
  onPress?: () => void
  children: string
  loading?: boolean
  disabled?: boolean
}

// This forces the loading state to persist for a certain amount of time,
// avoiding awkward UI jitters for fast actions that only take a few
// milliseconds.
const FORCE_LOADING_DISPLAY_DURATION = 600

const ACTION_LINK_TEXT_STYLE = "headingSmall"

const ActionLinkText = styled(Text)`
  flex: 1;
`

const ActionLinkSeparator = styled.View`
  border-top-width: 1px;
  border-color: ${(props) => props.theme.colors.border.primary};
  margin-horizontal: ${(props) => props.theme.spacing.medium}px;
  margin-vertical: ${(props) => props.theme.spacing.small}px;
`

export function ActionLink({
  to,
  onPress,
  children,
  loading,
  disabled,
}: ActionLinkProps): JSX.Element {
  const { tokens } = useTokens()
  const content = (
    <Row
      height={Math.max(
        tokens.minTapTarget,
        getAbsoluteLineHeight(ACTION_LINK_TEXT_STYLE, tokens) + 24,
      )}
      paddingHorizontal="medium"
    >
      <ActionLinkText
        textStyle={ACTION_LINK_TEXT_STYLE}
        numberOfLines={1}
        color={disabled ? "disabled" : "secondary"}
      >
        {children}
      </ActionLinkText>
      {loading ? (
        <ActivityIndicator size="small" />
      ) : (
        <Icon
          color={disabled ? "disabled" : "secondary"}
          size="small"
          source={icons.chevronRight}
        />
      )}
    </Row>
  )
  return to ? (
    <AppLink
      onPress={onPress}
      aria-label={children}
      to={to}
      disabled={disabled}
    >
      {content}
    </AppLink>
  ) : (
    <Touchable
      onPress={onPress}
      aria-label={children}
      role="link"
      disabled={disabled}
    >
      {content}
    </Touchable>
  )
}

export function AsyncActionLink(props: Omit<ActionLinkProps, "loading">) {
  const action = props.onPress
  const forceLoadingDisplayTimeout = useRef<unknown>()
  const [loading, setLoading] = useState(false)
  const [forceLoadingDisplay, setForceLoadingDisplay] = useState(false)

  const onPress = useCallback(() => {
    if (action) {
      clearTimeout(forceLoadingDisplayTimeout.current as number)
      setLoading(true)
      setForceLoadingDisplay(true)
      forceLoadingDisplayTimeout.current = setTimeout(
        () => setForceLoadingDisplay(false),
        FORCE_LOADING_DISPLAY_DURATION,
      )
      Promise.resolve(action()).finally(() => {
        setLoading(false)
      })
    }
  }, [action])

  return (
    <ActionLink
      {...props}
      disabled={loading || forceLoadingDisplay || props.disabled}
      onPress={onPress}
      loading={loading || forceLoadingDisplay}
    />
  )
}

export function ActionLinkStack({
  linkGroups,
}: {
  linkGroups: Array<
    Array<Pick<ActionLinkProps, "to" | "onPress"> & { title: string }>
  >
}) {
  return (
    <View>
      {linkGroups.map((links, index) => (
        <React.Fragment key={index}>
          {index !== 0 ? <ActionLinkSeparator /> : null}
          {links.map((link) => (
            <ActionLink to={link.to} key={link.title}>
              {link.title}
            </ActionLink>
          ))}
        </React.Fragment>
      ))}
    </View>
  )
}
