import React, { useEffect, useState } from "react"
import { Animated } from "react-native"

import { useIsFocused } from "../../hooks/use-is-focused"
import { StatusBar } from "../system-bars/status-bar"
import { useTokens } from "../tokens-provider"
import { AppHeaderPresentation } from "./types"

export function AppHeaderStatusBar({
  presentation,
  itemOpacity,
}: {
  presentation: AppHeaderPresentation
  itemOpacity: Animated.AnimatedInterpolation<number>
}): JSX.Element | null {
  const isFocused = useIsFocused()
  const { displayMode } = useTokens()

  const [transparentStatusBarStyle, setTransparentStatusBarStyle] = useState<
    "light-content" | "dark-content"
  >("light-content")

  useEffect(() => {
    if (presentation === "transparent" && displayMode === "light") {
      const listener = itemOpacity.addListener(
        ({ value }: { value: number }) => {
          const statusBarStyle = value < 0.5 ? "light-content" : "dark-content"
          if (statusBarStyle !== transparentStatusBarStyle) {
            setTransparentStatusBarStyle(statusBarStyle)
          }
        },
      )
      return () => itemOpacity.removeListener(listener)
    }
  }, [presentation, itemOpacity, displayMode, transparentStatusBarStyle])

  return isFocused && displayMode === "light" ? (
    <StatusBar
      barStyle={
        presentation === "transparent"
          ? transparentStatusBarStyle
          : "dark-content"
      }
    />
  ) : null
}
