import type { TableSchemaSpec } from "@nozbe/watermelondb/Schema"

export const highlightTable: TableSchemaSpec = {
  name: "Highlight",
  columns: [
    { name: "userId", type: "string" },
    { name: "profileId", type: "string", isOptional: true },
    { name: "contentId", type: "number", isIndexed: true },
    { name: "selectedText", type: "string" },
    { name: "notes", type: "string", isOptional: true },
    { name: "colorPresetId", type: "number" },
    { name: "startCfi", type: "string" },
    { name: "endCfi", type: "string" },
    { name: "sectionId", type: "string", isOptional: true },
    { name: "progressPercent", type: "number", isOptional: true },
    { name: "createdAtDate", type: "number" },
    { name: "updatedAtDate", type: "number" },
    { name: "archivedAtDate", type: "number", isOptional: true },
    { name: "syncIgnore_createdLocally", type: "boolean", isOptional: true },
  ],
}
