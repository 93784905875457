import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import tokens from "@treefort/tokens/app"

import { AsyncViewProps } from "../../../components/async-view"
import { AsyncViewOfflineProvider } from "../../../components/async-view-offline-provider"
import Box from "../../../components/box"
import { PoweredByTreefort } from "../../../components/powered-by-treefort"
import Text from "../../../components/text"
import { useRoute } from "../../../hooks/use-route"
import { Spacing } from "../../../lib/spacing"
import { i18nKey } from "../../../types/i18next"
import { MENU_TAB, getPathFromTab } from "../../routes"
import ScrollableLayout from "../scrollable"
import { MenuBackButton } from "./back-button"
import { MenuCloseButton } from "./close-button"
import { MENU_CONTENT_MAX_WIDTH_PX } from "./utils"

const Footer = styled(Box)`
  flex-direction: row;
  justify-content: center;
`

const HeaderContainer = styled.View<{ paddingTop: number }>`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.theme.appMenu.headerHeight + props.paddingTop}px;
  background-color: ${(props) => props.theme.colors.background.primary};
  border-bottom-width: 1px;
  border-bottom-color: ${(props) => props.theme.colors.border.primary};
  padding-top: ${(props) => props.paddingTop}px;
  /* Make sure the header text doesn't run into the icon buttons */
  padding-horizontal: ${(props) => props.theme.icon.size.medium}px;
`

const StyledMenuBackButton = styled(MenuBackButton)`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.theme.minTapTarget}px;
  height: ${(props) => props.theme.minTapTarget}px;
`

const CloseButtonContainer = styled.View`
  position: absolute;
  right: 0;
`

function Header(): JSX.Element {
  const route = useRoute()
  const insets = useSafeAreaInsets()
  const { t } = useTranslation()

  return (
    <HeaderContainer paddingTop={insets.top}>
      {route.path !== getPathFromTab(MENU_TAB) ? (
        <StyledMenuBackButton />
      ) : null}
      <Text textStyle="headingMedium" numberOfLines={1}>
        {route.title ? t(route.title as i18nKey) : undefined}
      </Text>
      <CloseButtonContainer>
        <MenuCloseButton />
      </CloseButtonContainer>
    </HeaderContainer>
  )
}

// When the menu content doesn't overflow the screen these styles will pull the
// content to the top of the screen and push the footer to the bottom.
const { contentContainerStyle } = StyleSheet.create({
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
})

function MenuLayoutMobileWeb({
  children,
  asyncViewProps,
  backgroundColor,
  paddingHorizontal = "pagePaddingHorizontal",
}: {
  children: ReactNode
  asyncViewProps?: AsyncViewProps
  backgroundColor?: string
  paddingHorizontal?: Spacing
}): JSX.Element {
  const insets = useSafeAreaInsets()
  const layout = (
    <ScrollableLayout
      backgroundColor={backgroundColor}
      contentContainerStyle={contentContainerStyle}
      header={<Header />}
      headerHeight={tokens.appMenu.headerHeight + insets.top}
    >
      <Box
        paddingHorizontal={paddingHorizontal}
        paddingVertical="large"
        width="100%"
        maxWidth={MENU_CONTENT_MAX_WIDTH_PX}
      >
        {children}
      </Box>
      <Footer paddingHorizontal={paddingHorizontal} paddingVertical="large">
        <PoweredByTreefort />
      </Footer>
    </ScrollableLayout>
  )
  return asyncViewProps ? (
    <AsyncViewOfflineProvider {...asyncViewProps}>
      {layout}
    </AsyncViewOfflineProvider>
  ) : (
    layout
  )
}

export default MenuLayoutMobileWeb
