import React from "react"

import { t } from "i18next"

import { ActionLink } from "../../../components/action-link"
import Column from "../../../components/column"
import { SettingsConsent } from "../../../components/settings-consent"
import SettingsDisplayMode from "../../../components/settings-display-mode"
import SettingsDownloads from "../../../components/settings-downloads"
import { SettingsLocale } from "../../../components/settings-locale"
import SettingsNotifications from "../../../components/settings-notifications"
import config from "../../../config"
import useAppManifest from "../../../hooks/use-app-manifest"
import { useOfflineState } from "../../../hooks/use-offline-state"
import { consentSettingsEnabled } from "../../../lib/consent"
import MenuLayout from "../../layouts/menu"

export default function SettingsScreen(): JSX.Element {
  const manifest = useAppManifest()
  const [offline] = useOfflineState()
  const settings = [
    manifest.features.userDisplayMode ? SettingsDisplayMode : null,
    config.NOTIFICATIONS_SUPPORTED && !offline ? SettingsNotifications : null,
    config.DOWNLOADS_SUPPORTED ? SettingsDownloads : null,
    Object.keys(manifest.i18n.locales).length > 1 ? SettingsLocale : null,
    consentSettingsEnabled(manifest) ? SettingsConsent : null,
  ]
  return (
    <MenuLayout>
      <Column gap="large" alignItems="stretch">
        {settings
          .filter((Setting): Setting is () => JSX.Element => Setting !== null)
          .map((Setting, index) => (
            <Setting key={index} />
          ))}
        <ActionLink to={{ type: "path", path: "/menu/settings/advanced" }}>
          {t("Advanced")}
        </ActionLink>
      </Column>
    </MenuLayout>
  )
}
