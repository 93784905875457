import {
  Event,
  EventName,
  Parameter,
  AnalyticsPlugin,
  User,
} from "@treefort/lib/analytics"

import config from "../../../../config"
import {
  ConsentOptionValue,
  addConsentListener,
  checkConsent,
} from "../../../consent"

// The Singular install script will set a global variable called "singularSdk"
declare const singularSdk: SingularSdk | undefined

class SingularAnalyticsPlugin implements AnalyticsPlugin {
  private consent: ConsentOptionValue | undefined
  private singular: undefined | Promise<SingularSdk> = undefined

  constructor() {
    addConsentListener("advertisingCookies", (advertisingCookies) => {
      this.consent = advertisingCookies
      this.initializeSingularSdk()
    })

    checkConsent("advertisingCookies").then((consent) => {
      this.consent = consent
      this.initializeSingularSdk()
    })
  }

  initializeSingularSdk = async () => {
    if (typeof singularSdk === "undefined" || this.consent !== "granted") {
      return
    }

    if (this.singular) {
      return this.singular
    }

    this.singular = new Promise<SingularSdk>((resolve) => {
      if (!config.SINGULAR_APP_ID) {
        throw new Error("[Singular] SDK was initialized but app ID is unset")
      }

      singularSdk.init(
        new SingularConfig(
          config.SINGULAR_SDK_KEY,
          config.SINGULAR_SDK_SECRET,
          config.SINGULAR_APP_ID,
        ).withInitFinishedCallback(() => resolve(singularSdk)),
      )
    })
  }

  identifyUser = async (user: User) => {
    const singular = await this.singular
    if (!singular || this.consent !== "granted") {
      return
    }

    const userId = user[Parameter.UserId]
    if (userId) {
      singular.login(userId)
    } else {
      singular.logout()
    }
  }

  logEvent = async (event: Event) => {
    const singular = await this.singular
    if (!singular || this.consent !== "granted") {
      return
    }

    // Content page views
    if (
      event.name === EventName.ScreenView &&
      event.parameters[Parameter.ContentId]
    ) {
      return singular.event("sng_content_view", {
        ...event.parameters,
        sng_attr_content: event.parameters[Parameter.ContentTitle],
        sng_attr_content_id: event.parameters[Parameter.ContentId],
        sng_attr_content_type: event.parameters[Parameter.ContentType],
      })
    }

    // Collection page views
    if (
      event.name === EventName.ScreenView &&
      event.parameters[Parameter.CollectionTitle]
    ) {
      return singular.event("sng_content_view_list", {
        ...event.parameters,
        sng_attr_content_list: event.parameters[Parameter.CollectionTitle],
      })
    }

    // Checkout session started
    if (event.name === EventName.CheckoutStart) {
      return singular.event("sng_checkout_initiated", {
        ...event.parameters,
        sng_attr_item_price: event.parameters[Parameter.Price],
      })
    }

    // Search
    if (event.name === EventName.Search) {
      return singular.event("sng_search", {
        ...event.parameters,
        sng_attr_search_string: event.parameters[Parameter.SearchQuery],
      })
    }

    // Share
    if (event.name === EventName.Share) {
      return singular.event("sng_share", {
        ...event.parameters,
        sng_attr_content: event.parameters[Parameter.ContentTitle],
        sng_attr_content_id: event.parameters[Parameter.ContentId],
        sng_attr_content_type: event.parameters[Parameter.ContentType],
      })
    }

    // Registartion
    if (event.name === EventName.UserRegistered) {
      return singular.event("sng_complete_registration", event.parameters)
    }

    // Login
    if (event.name === EventName.UserAuthenticated) {
      return singular.event("sng_login", event.parameters)
    }

    // Checkout session ended
    if (event.name === EventName.CheckoutComplete) {
      const currency = event.parameters[Parameter.Currency]
      const price = event.parameters[Parameter.Price]
      const eventName = event.parameters[Parameter.SubscriptionFreeTrial]
        ? "sng_start_trial"
        : "sng_subscribe"
      if (currency && price) {
        return singular.revenue(eventName, currency, price, event.parameters)
      } else {
        return singular.event(eventName, event.parameters)
      }
    }

    singular.event(event.name, event.parameters)
  }
}

export const singularAnalyticsPlugin = new SingularAnalyticsPlugin()
