import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { addBreadcrumb, ErrorBoundary } from "@sentry/react-native"
import throttle from "lodash/throttle"

import { AppManifest } from "@treefort/api-spec"
import { AppManifestError } from "@treefort/lib/errors"

import useAppState from "../../hooks/use-app-state"
import {
  getLocalAppManifest,
  getRemoteAppManifest,
  appManifestDebug,
  setLocalAppManifest,
} from "../../lib/app-manifest"
import { isAxiosNetworkError, logError, logWarning } from "../../lib/logging"
import { SplashScreen } from "../../navigation/screens/splash"
import { AppManifestData, AppManifestContext } from "./base"

// Minimum time between manifest loads.
const LOAD_MANIFEST_THROTTLE_INTERVAL_MS = 1000

// Amount of time to wait before caching the manifest locally. This gives us a
// chance to detect bad manifest errors before caching a manifest.
const CACHE_MANIFEST_TIMEOUT_MS = 5000

const debug = appManifestDebug.extend("published")

/**
 * This component handles fetching and loading app manifests.
 *
 * There are a few terms that are helpful in understanding the logic here:
 * - *manifest*: a json object containing all the info about the app theme,
 *   navigation, and structure that is necessary to render the app
 * - *local manifest*: a locally cached copy of the manifest that is known to
 *   have rendered the app successfully at some point in the past
 * - *remote manifest*: a remote copy of the manifest served via a CDN (this is
 *   where new updates are pushed to)
 *
 * The primary objectives of this component are to:
 * - Return a working manifest_as quickly as possible.
 * - Keep the manifest as up-to-date as possible.
 * - Allow the user to recover from a bad manifest.
 */
export default function AppManifestProviderPublished(props: {
  children?: ReactNode
}): JSX.Element {
  const appState = useAppState()
  const [data, setData] = useState<AppManifestData>({ state: "loading" })
  const cacheManifestTimeout = useRef<NodeJS.Timeout>()

  // Set the manifest _unless_ we've already got the same manifest (or a newer
  // one) in memory. This avoids an unnecessary re-mount of the entire app for
  // the vast majority of calls where the manifest hasn't been updated. This
  // behavior can be overridden by setting the "forceRefresh" param to true.
  const loadManifest = useMemo(
    () =>
      throttle(
        (
          manifest: AppManifest,
          options?: { forceRefresh?: boolean; cacheOnSuccess?: boolean },
        ) => {
          setData((data) => {
            // Skip loading the provided manifest if we've already got one that's
            // as new or newer
            if (
              !options?.forceRefresh &&
              data.state === "loaded" &&
              data.manifest.created >= manifest.created
            ) {
              debug("Ignoring outdated manifest")
              return data
            }

            addBreadcrumb({
              category: "started",
              message: "Loading manifest " + manifest.created,
              level: "info",
            })

            // Schedule the manifest to be cached locally. We don't cache it right
            // away because we want to wait and make sure it didn't tank the app.
            if (options?.cacheOnSuccess) {
              debug("Scheduling manifest to be cached")
              clearTimeout(cacheManifestTimeout.current)
              cacheManifestTimeout.current = setTimeout(() => {
                debug("Caching manifest")
                setLocalAppManifest(manifest).catch((cause) =>
                  logWarning(
                    new AppManifestError("Failed to cache manifest locally", {
                      cause,
                    }),
                  ),
                )
              }, CACHE_MANIFEST_TIMEOUT_MS)
            }

            return {
              state: "loaded",
              manifest,
              forceRefreshCount: options?.forceRefresh
                ? ((data.state === "loaded" && data.forceRefreshCount) || 0) + 1
                : undefined,
            }
          })
        },
        LOAD_MANIFEST_THROTTLE_INTERVAL_MS,
      ),
    [],
  )

  // Fetch the remote manifest and load it
  const refreshManifest = useCallback(async () => {
    const manifest = await getRemoteAppManifest({
      status: "published",
      publishedAfter:
        data.state === "loaded" ? data.manifest.created : undefined,
    })
    if (manifest) {
      debug("Loading remote manifest")
      loadManifest(manifest, { cacheOnSuccess: true })
    } else {
      debug("No newer manifest found")
    }
  }, [loadManifest, data])

  // Display a manifest error to the user. To be used as a last resort (when we
  // can't get our hands on a valid manifest).
  const displayFailedToLoadManifestError = useCallback((cause: unknown) => {
    if (!isAxiosNetworkError(cause)) {
      logError(
        new AppManifestError("Failed to load a working manifest", { cause }),
      )
    }

    // Re-use the old data object if it's already in an error state. This avoids
    // infinite re-renders if we get stuck in an error state.
    setData((data) =>
      data.state === "error" ? data : { state: "error", error: cause },
    )
  }, [])

  // When the app boots we load the local manifest immediately (if it exists)
  // and then attempt a refresh of the remote manifest
  const onMount = useCallback(async () => {
    debug("Reacting to app mount")

    // Fetch the locally cached manifest
    let local: AppManifest | null = null
    try {
      local = await getLocalAppManifest()
    } catch (cause) {
      logWarning(
        new AppManifestError("Failed to load local manifest on mount", {
          cause,
        }),
      )
    }

    // Fire off a request for the remote manifest ASAP, but don't wait on it
    // just yet.
    const remoteRequest = getRemoteAppManifest({
      status: "published",
      publishedAfter: local?.created,
    })

    // Load the locally cached manifest
    if (local) {
      debug("Loading local manifest")
      loadManifest(local)
    }

    // Load the remote manifest
    try {
      const remote = await remoteRequest
      if (remote) {
        debug("Loading remote manifest")
        loadManifest(remote, { cacheOnSuccess: true })
      } else if (!local) {
        throw new Error("No manifest returned from the API")
      }
    } catch (cause) {
      // If we didn't have a local manifest to load then we're toast
      if (!local) {
        displayFailedToLoadManifestError(cause)
      } else if (!isAxiosNetworkError(cause)) {
        logWarning(
          new AppManifestError("Failed to load remote manifest on mount", {
            cause,
          }),
        )
      }
    }
  }, [displayFailedToLoadManifestError, loadManifest])

  // When the app moves to the background or to the foreground we attempt to
  // refresh the manifest
  const onAppStateChange = useCallback(async () => {
    debug("Reacting to app state change")
    try {
      await refreshManifest()
    } catch (cause) {
      if (!isAxiosNetworkError(cause)) {
        logWarning(
          new AppManifestError(
            "Failed to refresh manifest on app state change",
            { cause },
          ),
        )
      }
    }
  }, [refreshManifest])

  // When the user hits "Refresh" after a catastrophic meltdown:
  // - IF a local manifest exists and it's different than the manifest we
  //   already tried to render, use it
  // - ELSE fetch the remote manifest and use that. If the network call fails
  //   then show an error screen to the user.
  const onManualRetry = useCallback(async () => {
    debug("Retrying")
    try {
      const local = await getLocalAppManifest().catch((cause) =>
        logWarning(
          new AppManifestError("Failed to get local manifest", { cause }),
        ),
      )
      if (
        local &&
        (data.state !== "loaded" || data.manifest.created !== local.created)
      ) {
        debug("Force loading local manifest")
        loadManifest(local, { forceRefresh: true })
      } else {
        const remote = await getRemoteAppManifest({ status: "published" })
        if (remote) {
          debug("Force loading remote manifest")
          loadManifest(remote, { forceRefresh: true, cacheOnSuccess: true })
        } else {
          throw new Error("No manifest returned from the API")
        }
      }
    } catch (cause) {
      displayFailedToLoadManifestError(cause)
    }
  }, [data, loadManifest, displayFailedToLoadManifestError])

  // This powers the "Refresh" button on the error screens
  const errorAction = useMemo(
    () => ({
      // NOTE: These strings are not translated because locale data is stored in
      // the manifest, but we don't have the manifest here...
      title: "Refresh",
      waitingTitle: "Refreshing...",
      callback: onManualRetry,
    }),
    [onManualRetry],
  )

  // Render a generic error screen with a retry button that will refresh the
  // manifest. This will be shown if a bad manifest is loaded and the app
  // crashes (as long as it's a JavaScript-land crash).
  const renderError = useCallback(() => {
    // Bail on any plans to cache the manifest - it could be the reason we're in
    // an error state
    clearTimeout(cacheManifestTimeout.current)

    return (
      <SplashScreen
        message={{
          // NOTE: These strings are not translated because locale data is
          // stored in the manifest, but we don't have the manifest here...
          title: "App Error",
          description:
            "An error occurred. Please contact us if the issue persists.",
          action: errorAction,
        }}
      />
    )
  }, [errorAction])

  const contextValue = useMemo(
    () =>
      data.state === "loaded"
        ? { manifest: data.manifest, refreshManifest }
        : null,
    [data, refreshManifest],
  )

  const prevAppState = useRef(appState)
  useEffect(() => {
    if (prevAppState.current !== appState) {
      prevAppState.current = appState
      onAppStateChange()
    }
  }, [appState, onAppStateChange])

  useEffect(() => {
    onMount()
  }, [onMount])

  useEffect(() => {
    if (data.state === "error") {
      // Bail on any plans to cache the manifest - it could be the reason we're
      // in an error state
      clearTimeout(cacheManifestTimeout.current)
    }
  }, [data])

  return (
    <AppManifestContext.Provider
      value={contextValue}
      key={
        // Key by the state of our manifest data. This will ensure that the app
        // is completely remounted when moving from the "error" to the "loaded"
        // state, kicking the user back to the home page and allowing them to
        // temporarily recover from any errors that were triggered by a
        // particular page they navigated to.
        data.state + (data.state === "loaded" ? data.forceRefreshCount : "")
      }
    >
      <ErrorBoundary
        fallback={renderError}
        beforeCapture={(scope) => scope.setTag("boundary", "manifest")}
      >
        {data.state === "loaded" ? (
          props.children
        ) : data.state === "error" ? (
          <SplashScreen
            message={{
              title: "Network Error",
              description: "Please check your connection and try again.",
              action: errorAction,
            }}
          />
        ) : (
          <SplashScreen />
        )}
      </ErrorBoundary>
    </AppManifestContext.Provider>
  )
}
