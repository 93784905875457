import React from "react"

import { AppModuleVideoSeries } from "@treefort/api-spec"

import { VideoSeriesModule } from "../../../../components/modules/video-series"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleVideoSeries["config"]

export class VideoSeriesListViewModule
  implements ListViewModule<AppModuleVideoSeries, Item>
{
  readonly appModule: AppModuleVideoSeries
  readonly appHeaderPresentation = "transparent"
  readonly appHeaderState = undefined
  readonly gapSize = 36
  readonly maxWidth = undefined

  constructor(appModule: AppModuleVideoSeries) {
    this.appModule = appModule
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <VideoSeriesModule {...args.item} paddingTop={args.safeAreaInsets.top} />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id
}
