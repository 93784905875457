"use strict";
/**
 * This module is helpful when handling asyncronously loaded data. It is
 * particularly useful when processing react-query results.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Result = exports.ResultIdle = exports.ResultLoading = exports.ResultError = exports.ResultSuccess = void 0;
class ResultSuccess {
    constructor(data) {
        this.status = "success";
        this.isSuccess = true;
        this.isError = false;
        this.isLoading = false;
        this.error = null;
        this.data = data;
    }
}
exports.ResultSuccess = ResultSuccess;
class ResultError {
    constructor(error) {
        this.status = "error";
        this.isSuccess = false;
        this.isError = true;
        this.isLoading = false;
        this.data = undefined;
        this.error = error;
    }
}
exports.ResultError = ResultError;
class ResultLoading {
    constructor() {
        this.status = "loading";
        this.isSuccess = false;
        this.isError = false;
        this.isLoading = true;
        this.data = undefined;
        this.error = null;
    }
}
exports.ResultLoading = ResultLoading;
class ResultIdle {
    constructor() {
        this.status = "idle";
        this.isSuccess = false;
        this.isError = false;
        this.isLoading = false;
        this.data = undefined;
        this.error = null;
    }
}
exports.ResultIdle = ResultIdle;
class Result {
}
exports.Result = Result;
Result.success = (data) => new ResultSuccess(data);
Result.error = (error) => new ResultError(error);
Result.loading = () => new ResultLoading();
Result.idle = () => new ResultIdle();
/**
 * If a result is successful then this will pass the data to the mapSuccess
 * function and return the result, otherwise the original result will be
 * returned.
 */
Result.mapSuccess = (result, map) => result.isSuccess
    ? map(result.data)
    : result.isError
        ? Result.error(result.error)
        : result.isLoading
            ? Result.loading()
            : Result.idle();
/**
 * Similar to Promise.all, this will return an error or loading result if any
 * individual result is in an error or loading state. Otherwise this will pass
 * the data from every successful result to the mapSuccess function and return
 * the result.
 */
Result.all = (results, mapSuccess) => {
    const errorResult = results.find((result) => result.isError);
    if (errorResult) {
        return Result.error(errorResult.error);
    }
    else if (results.some((result) => result.isLoading)) {
        return Result.loading();
    }
    else if (results.every((result) => result.isSuccess)) {
        return mapSuccess(results.map((result) => result.data));
    }
    else {
        return Result.idle();
    }
};
