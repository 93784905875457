import { useTokens } from "../components/tokens-provider"
import { getAbsoluteLineHeight } from "../lib/text-style"
import useAppManifest from "./use-app-manifest"

export function useBottomTabsHeight(): number {
  const { tokens } = useTokens()
  const manifest = useAppManifest()
  return manifest.tabs.length > 1
    ? 36 + getAbsoluteLineHeight("caption", tokens)
    : 0
}
