import i18next, { t } from "i18next"

import { ERRORS } from "@treefort/constants"
import { getTreefortErrorsFromAxiosError } from "@treefort/lib/errors"

import { i18nKey } from "../types/i18next"

/**
 * Parses an axios error and returns the translated message corresponding to the
 * Treefort error code. If the error doesn't have a Treefort error code or the
 * message for the code doesn't exist in our i18n config then a default
 * "Something went wrong, please try again" message is returned.
 */
export function getTreefortMessageFromError(e: unknown) {
  const [error] = getTreefortErrorsFromAxiosError(e)
  switch (error?.code) {
    case "TF4201":
      return t("You already have a profile with the name “{{name}}”.", {
        name: error.duplicateName,
      })
    case "TF4202":
      return t("You already have the maximum of {{max}} profiles.", {
        max: error.limit,
      })
    default: {
      const message = ERRORS[error.code].message
      if (i18next.exists(message)) {
        return t(message as i18nKey)
      }

      return t("Something went wrong, please try again.")
    }
  }
}
